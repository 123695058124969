<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Área Relacionada"></etads-label>
            <etads-input-text [formGroup]="form" controlName="areaDescription" placeholder="Informe uma descrição."></etads-input-text>
            <etads-vmessage *ngIf="form.get('areaDescription').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('areaDescription').errors?.minlength" text="Tamanho mínimo de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('areaDescription').errors?.maxlength" text="Tamanho máximo de 60 caracteres"></etads-vmessage>

            <etads-label label="Situação"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>
