<form [formGroup]="diagnosisTroubleForm" (submit)="actionSubmitDiagnosisTrouble()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Diagnóstic Trouble Code (DTC)"></etads-label>
    </div>
    <div class="form-row pt-2 pl-2 pr-2 pb-0">
      <div class="form-group col-2">
        <etads-label label="Possui DTC?"></etads-label>
        <etads-input-select [formGroup]="diagnosisTroubleForm" de controlName="hasDtc">
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </etads-input-select>
        <etads-vmessage *ngIf="diagnosisTroubleForm.get('hasDtc').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-2">
        <etads-label label="Código"></etads-label>
        <etads-input-text [formGroup]="diagnosisTroubleForm" controlName="code" placeholder=""></etads-input-text>
      </div>
      <div class="form-group col">
        <etads-label label="Descrição"></etads-label>
        <etads-input-text [formGroup]="diagnosisTroubleForm" controlName="troubleDescription" placeholder="">
        </etads-input-text>
      </div>
      <div class="form-group col-2">
        <etads-label label="Status"></etads-label>
        <etads-input-select [formGroup]="diagnosisTroubleForm"
          firstOptionLabel="Selecione" controlName="troubleStatus">
          <option value="Atual">Atual</option>
          <option value="Histórico">Histórico</option>
          <option value="Pendente">Pendente</option>
        </etads-input-select>
      </div>
      <div class="form-group">
        <etads-label label="Add"></etads-label>
        <button type="button" id="btntrouble" class="form-control btn btn-info" (click)='actionAdd()'> + </button>
      </div>
    </div>

    <etads-table class="table table-striped px-2">
      <thead>
        <tr>
          <th class="col-2">Código</th>
          <th class="col-8">Descrição</th>
          <th class="col-1">Status</th>
          <th class="col-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let it of listDiagnosisTrouble">
          <td>{{ it.code }}</td>
          <td>{{ it.troubleDescription }}</td>
          <td>{{ it.troubleStatus }}</td>
          <td>
            <button type="button" id="btnRemoveTrouble" class="form-control btn btn-info" (click)='actionRemove(it)'> X
            </button>
          </td>
        </tr>
      </tbody>
    </etads-table>
  </div>
</form>
