
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { AnswerScheduleCrudService } from '../../../services/answer-schedule-crud.service';
import { AnswerSchedule } from '../../../interfaces/answer-schedule';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './answer-schedule-remove.component.html'
})
export class AnswerScheduleRemoveComponent implements OnInit {

    form: FormGroup;    
    answerScheduleId : number;
    messageLabel : string;
    answerScheduleToDel : AnswerSchedule;
    subscription : Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private answerScheduleCrudService: AnswerScheduleCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.answerScheduleId = this.route.snapshot.params.areaRelatedId;
        this.answerScheduleToDel = this.stateService.retrieveAnswerSchedule();
      
        this.alertService.warning("Deseja excluir o registro de Escalonamento de Resposta : " + this.answerScheduleToDel.answerScheduleDescription + " ?");

        this.form = this.formBuilder.group({
            answerScheduleId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        this.subscription = this.answerScheduleCrudService.del(this.answerScheduleToDel).subscribe ((data) => {
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
        

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['answer-schedule-list']);
    }

}
