import { Injectable } from '@angular/core';
import { LocalStorageCrudService } from './local-storage-crud.service';
import { AzureStorageCrudService } from './azure-storage-crud.service';
import { Priority } from '../../app/interfaces/priority';

@Injectable({
  providedIn: 'root'
})
export class PriorityCrudService {
  key = 'etads/priority';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : Priority) {
    return this.azureCrudService.add(newReg, "addpriority");
  }

  upd(oldReg : Priority, newReg : Priority) {
    return this.azureCrudService.upd(oldReg, newReg, "updatepriority");
  }

  del(reg : Priority) {
    return this.azureCrudService.del(reg, "deletepriority");
  }

  get() {
    return this.azureCrudService.get("getpriority");
  }

  /*getById(id:number) {
    return vRecord[0];
  }*/

}
