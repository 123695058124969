import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';
import { ElibManual } from '../interfaces/elib-manual';

@Injectable({
  providedIn: 'root'
})
export class ElibManualCrudService {
  key = 'etads/elib_manual';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : ElibManual) {
    return this.azureCrudService.add(newReg, "addElibManual");
  }

  upd(oldReg : ElibManual, newReg : ElibManual) {
    return this.azureCrudService.upd(oldReg, newReg, "updateelibmanual");
  }

  del(reg : ElibManual) {
    return this.azureCrudService.del(reg, "deleteelibmanual");
  }

  get(filter: Map<string,string>) {
    let url = "getelibmanual?";
    if(filter){
      filter.forEach((value,key) => {
        if(value && value != '')
          url = url + "&" + key + "=" + value;
      });
    }
    return this.azureCrudService.get(url);
  }

  getByCode(code: String) {
    let url = "getelibmanual?code=" + code;
    return this.azureCrudService.get(url);
  }

}
