import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { ElibModelCrudService } from '../../../services/elib-model-crud.service';
import { ElibModel, ElibModelForm, EtadsElibModel } from '../../../interfaces/elib-model';

@Component ({
    templateUrl: './elib-model-create.component.html'
})

export class ElibModelCreateComponent implements OnInit {

    form: FormGroup;
    modelId: number;
    filterMap: Map<string, string> = new Map<string, string>();
    modelList: ElibModel[];
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private elibModelCrudService : ElibModelCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService,
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Modelo" , "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();

    }

    loadData() {
        this.modelList = this.stateService.retrieveElibModelList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            description: ['',   [  Validators.required,  Validators.minLength(3)  ] ]
        });
    }


    actionSubmit() {
        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Modelo Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const prForm = this.form.getRawValue() as ElibModelForm;
        let pxdata = new EtadsElibModel() ;

        pxdata.description = prForm.description.valueOf().toUpperCase();

        this.subscription = this.elibModelCrudService.add(pxdata).subscribe(
          (data) => {
            let idList = this.modelList.push(pxdata);
            this.stateService.storeElibModelList(this.modelList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeElibModelList(this.modelList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
          }
        );
    }

    actionClear() {
        this.ngOnInit();
    }

    actionBack() {
        this.router.navigate(['elib-model-list']);
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    validateForm() {
        const newPr = this.form.getRawValue() as ElibModelForm;

        for (let prData of this.modelList) {
             let parse1 = prData.description.toUpperCase();
             let parse2 = newPr.description.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }

        return true;
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}

