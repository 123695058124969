import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { T1CrudService } from '../../../services/t1-crud.service';
import { T1 } from '../../../interfaces/t1';
import { Subscription } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';

@Component({
    templateUrl: './t1-remove.component.html'
})
export class T1RemoveComponent implements OnInit {

    form: FormGroup;    
    t1Id : number;
    messageLabel : string;
    t1ToDel : T1;
    subscription: Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private t1CrudService: T1CrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.t1Id = this.route.snapshot.params.areaRelatedId;
        this.t1ToDel = this.stateService.retrieveT1();
      
        this.alertService.warning("Deseja excluir o cadastro T1: " + this.t1ToDel.t1Code + " - " + this.t1ToDel.t1Name + " ?");

        this.form = this.formBuilder.group({
            t1Id: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        
        this.subscription = this.t1CrudService.del(this.t1ToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.t1ToDel = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['t1-list']);
    }

}
