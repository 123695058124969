export interface T2 {
    id: string;
    t2Id: number;
    t2Code: string;
    t2Name: string;
    active: boolean;
}

export interface T2Form {
    t2Code: string;
    t2Name: string;
    active: boolean;
}

export class EtadsT2 implements T2{
    id: string;
    t2Id: number;
    t2Code: string;
    t2Name: string;
    active: boolean;
}