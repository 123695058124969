import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-small-message',
    templateUrl: './small-message.component.html'
})
export class SmallMessageComponent { 

    @Input() text = '';
    
}