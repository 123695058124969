import { FormBuilder,FormsModule, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe, HashLocationStrategy } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsSymptom, Symptom, SymptomForm, SymptomScrSel } from '../../../interfaces/symptom';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { EtadsSymptomGroup, SymptomGroup } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { Priority } from '../../../interfaces/priority';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './symptom-edit.component.html'
})

export class SymptomEditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();
    symptomId: number;
    symptom: Symptom;
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    symptomScrList: SymptomScrSel[] = [];
    priorityList : Priority[] = [];
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private symptomCrudService: SymptomCrudService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private priorityCrudService: PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Sintoma", "");
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();
    }

    loadData() {

        this.symptomId = this.route.snapshot.params.symptomId;
        this.symptom = this.stateService.retrieveSymptom();
        this.symptomList = this.stateService.retrieveSymptomList();

        /* caregando lista de Grupo de Sintomas  */
        this.symptomGroupList = [];
        this.subscription = this.symptomGroupCrudService.get().subscribe( (list) => {
             for (let reg of list) {
                 this.symptomGroupList.push(reg);
             }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        /* caregando lista de Prioridades */
        this.priorityList = [];
        this.subscription = this.priorityCrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.priorityList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

    }

    createForm() {

        this.form = this.formBuilder.group( {
            symptomDescription:    [this.symptom.symptomDescription, [ Validators.required, Validators.minLength(3), Validators.maxLength(200)  ] ,   ],
            symptomGroupId:        [this.symptom.symptomGroupId    , [ Validators.required  ], ],
            priorityDescription:   [this.symptom.priorityDescription , [ Validators.required  ], ],
            initials:   [this.symptom.initials , null, ],
            active:                [this.symptom.active            , [ Validators.required  ], ],
        });

    }

    updateForm() {
        this.form.patchValue({symptomDescription:  this.symptom.symptomDescription});
        this.form.patchValue({symptomGroupId:      this.symptom.symptomGroupId});
        this.form.patchValue({priorityDescription: this.symptom.priorityDescription});
        this.form.patchValue({initials: this.symptom.initials})
        this.form.patchValue({active: this.symptom.active});

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Sintoma Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const symform   = this.form.getRawValue() as SymptomForm;
        let updSymptom = new EtadsSymptom();

        updSymptom.id = this.symptom.id;
        updSymptom.symptomId = this.symptom.symptomId;
        updSymptom.symptomDescription  = symform.symptomDescription;
        updSymptom.symptomGroupId      = Number(symform.symptomGroupId);
        updSymptom.priorityDescription = symform.priorityDescription;
        updSymptom.active              = symform.active;

        for (let gr of this.symptomGroupList) {
            if (Number(gr.symptomGroupId) === Number(symform.symptomGroupId) )   {
               updSymptom.symptomGroupDescription = gr.symptomGroupDescription;
               updSymptom.areaRelatedDescription = gr.areaRelatedDescription;
               updSymptom.areaRelatedId = Number(gr.areaRelatedId);
            break;
            }

        }
        let priority = this.priorityList.find(pr => pr.alias === symform.priorityDescription)
        updSymptom.priorityId     = priority?.priorityId;
        updSymptom.initials     = priority?.initials;

        this.subscription = this.symptomCrudService.upd(this.symptom, updSymptom).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            updSymptom = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['symptom-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as SymptomForm;

            for (let sgData of this.symptomList) {
               let parse1 = sgData.symptomDescription.toUpperCase();
               let parse2 = newSg.symptomDescription.toUpperCase();

               if (parse1 == parse2 && sgData.symptomId != this.symptomId) {
                   return false;
               }
           }

        return true;
    }

}
