<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Codigo T2</th>    
                <th>Nome T2</th>    
                <th>Situação</th> 
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let t2x of t2List | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{t2x.t2Code}}</td>
                <td>{{t2x.t2Name}}</td>
                <td>{{(t2x.active === true ) ? "Ativo" : "Inativo"}}</td>

                <td>
                    <etads-tooltip label="Editar Item T2" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(t2x)" 
                            link="t2-edit" [parameter]="t2x.t2Id">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Item T2" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(t2x)"
                            link="t2-remove" [parameter]="t2x.t2Id">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Item"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
