import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { PriorityCrudService } from 'src/app/services/priority-crud.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsPriority, Priority,  PriorityForm } from '../../../interfaces/priority';
import { Subscription } from 'rxjs';
import { throwError, concat, of } from 'rxjs';
import { delay } from 'rxjs/operators';

@Component ({
    templateUrl: './priority-create.component.html'
})

export class PriorityCreateComponent implements OnInit {

    form: FormGroup;
    priorityId: number;
    filterMap: Map<string, string> = new Map<string, string>();
    priorityList: Priority[];
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private priorityCrudService : PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService,
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Prioridade" , "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();

    }

    loadData() {
        this.priorityList = this.stateService.retrievePriorityList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            initials: ['',   [  Validators.required,  Validators.minLength(1), Validators.maxLength(1)  ] ],
            alias:    ['',   [  Validators.required,  Validators.minLength(3), Validators.maxLength(50) ] ],
            sequence: ['',   [  Validators.required,  Validators.minLength(1), Validators.maxLength(1)  ] ],
            active:   [null, [  Validators.required  ] ],
        });
    }


    actionSubmit() {
        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Prioridade Inválida ou  já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const prForm = this.form.getRawValue() as PriorityForm;
        let pxdata = new EtadsPriority() ;

        pxdata.initials = prForm.initials.valueOf().toUpperCase();
        pxdata.alias    = prForm.alias.valueOf().toUpperCase();
        pxdata.active   = prForm.active.valueOf();
        pxdata.sequence = prForm.sequence.valueOf();

        this.subscription = this.priorityCrudService.add(pxdata).subscribe(
          (data) => {
            let idList = this.priorityList.push(pxdata);
            this.stateService.storePriorityList(this.priorityList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storePriorityList(this.priorityList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
          }
        );
    }

    actionClear() {
        this.ngOnInit();
    }

    actionBack() {
        this.router.navigate(['priority-list']);
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    validateForm() {
        const newPr = this.form.getRawValue() as PriorityForm;

        for (let prData of this.priorityList) {
             let parse1 = prData.initials.toUpperCase();
             let parse2 = newPr.initials.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }

        if (Number(newPr.sequence) <= 0 && Number(newPr.sequence) >= 9 )  {
            return false;
        }

        return true;
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}

