import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { DailyScheduleCrudService } from '../../../services/daily-schedule-crud.service';
import { EtadsDailySchedule, DailySchedule } from '../../../interfaces/daily-schedule';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './daily-schedule-list.component.html'
})
export class DailyScheduleListComponent implements OnInit {


    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    dailyScheduleList : DailySchedule[] = [];   
    dailySchedule : DailySchedule;
    dailyScheduleId : number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private dailyScheduleCrudService:  DailyScheduleCrudService,
        ) {}

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Escalonamento Diário",  "" );
        this.loadData();
        
        const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
        if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
        
    }

    loadData() {

        this.dailyScheduleList = [];
        this.subscription = this.dailyScheduleCrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.dailyScheduleList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }      
           
    actionAdd() {
        this.stateService.storeDailyScheduleList(this.dailyScheduleList);
        this.router.navigate(['daily-schedule-create']);
    }

    actionEdit( dailyScheduledata: DailySchedule) {
        this.stateService.storeDailySchedule(dailyScheduledata);
        this.stateService.storeDailyScheduleList(this.dailyScheduleList);
    }

    actionRemove( dailySchedule: DailySchedule ) {
        this.stateService.storeDailySchedule (dailySchedule);
    }


}