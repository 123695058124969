<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Título</th>    
                <th>Nível</th> 
                <th>Email</th> 
                <th>Situação</th>
                <th>Distribuidor</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let gdlreg of groupDlrScheduleList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{gdlreg.groupDlrScheduleTitle}}</td>
                <td>{{gdlreg.level}}</td>
                <td>{{gdlreg.email}}</td>
                <td>{{(gdlreg.active === true) ? "Ativo" : "Inativo" }}</td>
                <td>{{gdlreg.distributor}}</td>
                <td>
                    <etads-tooltip label="Editar Escalonamento Grupo DLR" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(gdlreg)" 
                            link="group-dlr-schedule-edit" [parameter]="gdlreg.groupDlrScheduleId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Escalonamento Grupo DLR" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(gdlreg)"
                            link="group-dlr-schedule-remove" [parameter]="gdlreg.groupDlrScheduleId">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Nova Escalonamento Grupo DLR"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>