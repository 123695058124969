<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
            
            <etads-label label="Grupo de Sintoma"></etads-label>
            <etads-input-text [formGroup]="form" controlName="symptomGroupDescription" placeholder="Informe um grupo de sintoma."></etads-input-text>
            <etads-vmessage *ngIf="form.get('symptomGroupDescription').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('symptomGroupDescription').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('symptomGroupDescription').errors?.maxlength" text="Tamanho máximo de 50 caracteres"></etads-vmessage>
            
            <etads-label label="Área Relacionada"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="areaRelatedId">
                <option *ngFor=" let area of areaRelatedList " [value]="area.areaRelatedId" >{{ area.areaDescription}}</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('areaRelatedId').errors?.required" text="Campo obrigatório"></etads-vmessage>
            
            <etads-label label="Situação"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>
            <etads-vmessage *ngIf="form.get('active').errors?.required" text="Campo obrigatório"></etads-vmessage>


            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>