<form [formGroup]="repairHistoryForm" (submit)="actionSubmitRepairHistory()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Histórico de reparo"></etads-label>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-2">
        <etads-label label="Historico de reparo (Sinistro)"></etads-label>
        <etads-input-checkbox [formGroup]="repairHistoryForm" controlName="repairHistory" (change)="actionChangeRepairHistory()"></etads-input-checkbox>
      </div>
      <div class="form-group col-3">
        <etads-label label="Relacionado ao problema"></etads-label>
        <etads-input-select [formGroup]="repairHistoryForm"
          firstOptionLabel="Selecione" controlName="relatedProblem" *ngIf="repairHistorySelected">
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </etads-input-select>
      </div>
      <div class="form-group col">
        <etads-label label="Descreva"></etads-label>
        <etads-input-text [formGroup]="repairHistoryForm" controlName="problemDescription" placeholder="" *ngIf="repairHistorySelected">
        </etads-input-text>
      </div>
    </div>

    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-2">
        <etads-label label="Historico de reparo (Técnico)"></etads-label>
        <etads-input-checkbox [formGroup]="repairHistoryForm" controlName="repairHistoryTec" (change)="actionChangeRepairHistoryTec()"></etads-input-checkbox>
      </div>
      <div class="form-group col-3">
        <etads-label label="Relacionado ao problema"></etads-label>
        <etads-input-select [formGroup]="repairHistoryForm"
          firstOptionLabel="Selecione" controlName="relatedProblemTec" *ngIf="repairHistoryTecSelected">
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </etads-input-select>
      </div>
      <div class="form-group col">
        <etads-label label="Descreva"></etads-label>
        <etads-input-text [formGroup]="repairHistoryForm" controlName="problemDescriptionTec" placeholder="" *ngIf="repairHistoryTecSelected">
        </etads-input-text>
      </div>
    </div>

    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-4">
        <etads-label label="Atendimento SAC"></etads-label>
        <etads-input-text [formGroup]="repairHistoryForm" controlName="sac" placeholder=""></etads-input-text>
      </div>
    </div>
  </div>
</form>
