import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EtadsPriority, Priority } from '../../../interfaces/priority';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { Subscription } from 'rxjs';
import { error } from '@angular/compiler/src/util';

@Component({
    templateUrl: './priority-list.component.html'
})
export class PriorityListComponent implements OnInit {

    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private priorityCrudService: PriorityCrudService
        ) {}

    currentPage : Number = 1;
    itemsPerPage : Number = 13;
    priorityList : EtadsPriority[] = [];
    filterMap: Map<string, string> = new Map<string, string>();

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Prioridade",  "" );
        this.loadData();

       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);

        }

    }

    loadData() {

       this.subscription = this.priorityCrudService.get().subscribe( reg => {
            for (let data of reg ) {
                let idList = this.priorityList.push(data);
            }
            this.spinnerService.hide();
        } , err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
    }

    actionAdd() {
        this.stateService.storePriorityList(this.priorityList);
        this.router.navigate(['priority-create']);
    }

    actionEdit( priorityData: Priority ) {
        this.stateService.storePriority(priorityData);
        this.stateService.storePriorityList(this.priorityList);
    }

    actionRemove( priorityData: EtadsPriority ) {
        this.stateService.storePriority(priorityData);
    }


}
