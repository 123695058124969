import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { AreaRelated, AreaRelatedForm, EtadsAreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './area-related-create.component.html'
})
export class AreaRelatedCreateComponent implements OnInit {

    form:FormGroup;
    areaRelatedId: number;
    areaRelatedList: AreaRelated[];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Área Relacionada", "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();
    }

    loadData() {
        this.areaRelatedList = this.stateService.retrieveAreaRelatedList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            areaDescription: ['' , [ Validators.required, Validators.minLength(3), Validators.maxLength(60) ] ],
            active:   [null, [  Validators.required  ] ],
        });
    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Área relacionada Inválida ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const areaForm = this.form.getRawValue() as AreaRelatedForm;
        let newAreaRelated = new EtadsAreaRelated();

        newAreaRelated.areaDescription = areaForm.areaDescription.valueOf().toUpperCase();
        newAreaRelated.active = areaForm.active.valueOf();

        this.subscription = this.areaRelatedCrudService.add(newAreaRelated).subscribe((data) => {
            let idList = this.areaRelatedList.push(newAreaRelated);
            this.stateService.storeAreaRelatedList(this.areaRelatedList);
            this.stateService.storeAlertSuccessMessage('Dados cadastrados com sucesso.');
            this.spinnerService.hide();
            this.actionBack();
        },
        (error) => {
            this.stateService.storeAreaRelatedList(this.areaRelatedList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
        } );


    }

    actionBack() {
        this.router.navigate(['area-related-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newAr = this.form.getRawValue() as AreaRelatedForm;

        for (let arData of this.areaRelatedList) {
             let parse1 = arData.areaDescription.toUpperCase();
             let parse2 = newAr.areaDescription.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }
        return true;

    }

}
