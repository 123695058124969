export interface GroupDlrSchedule {
    id: string;
    groupDlrScheduleId: number;
    groupDlrScheduleTitle: string;
    level: number;
    email: string;
    active: boolean;
    distributor: string;
}

export interface GroupDlrScheduleForm {
    groupDlrScheduleTitle: string;
    level: number;
    email: string;
    active: boolean;
    distributor: string ;
    // distributorSelected: any;
}

export class EtadsGroupDlrSchedule implements GroupDlrSchedule  {
    id: string;
    groupDlrScheduleId: number;
    groupDlrScheduleTitle: string;
    level: number;
    email: string;
    active: boolean;
    distributor: string;
}