import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'etads-button-save',
    templateUrl: './button-save.component.html',
    styleUrls: ['./button-save.component.css']
})
export class ButtonSaveComponent { 

    constructor() {}

    @Input() label = 'Salvar';
    @Input() disabled: boolean = false; 

    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}