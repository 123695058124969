import { Injectable } from '@angular/core';
import { Acessories } from '../interfaces/acessories';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AcessoriesCrudService {
  key = 'etads/acessories';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : Acessories) {
    return this.azureCrudService.add(newReg, "addAcessories");
  }

  upd(oldReg : Acessories, newReg : Acessories) {
    return this.azureCrudService.upd(oldReg, newReg, "updateAcessories");
  }

  del(reg : Acessories) {
    return this.azureCrudService.del(reg, "deleteAcessories");
  }

  get() {
    return this.azureCrudService.get("getAcessories");
  }

}
