import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { FinalityCrudService } from '../../../services/finality-crud.service';
import { EtadsFinality, Finality } from '../../../interfaces/finality';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './finality-list.component.html'
})
export class FinalityListComponent implements OnInit {


    currentPage: Number = 1;
    itemsPerPage: Number = 13; 
    finalityList: Finality[] = [];   
    finality: Finality;
    finalityId: number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private finalityCrudService: FinalityCrudService,
        ) {}

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Finalidade",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
        
        this.spinnerService.hide();
    }

    loadData() {

        this.finalityList = [];
        this.subscription = this.finalityCrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.finalityList.push(regx);
             }
            this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }      
           
    actionAdd() {
        this.stateService.storeFinalityList(this.finalityList);
        this.router.navigate(['finality-create']);
    }

    actionEdit( finalitydata: Finality) {
        this.stateService.storeFinality(finalitydata);
        this.stateService.storeFinalityList(this.finalityList);
    }

    actionRemove( finality: Finality) {
        this.stateService.storeFinality(finality);
    }


}