<header id="headerId">
    <nav class="fixed-top navbar navbar-expand-lg navbar-default">
        <a [routerLink]="['']">
          <img [routerLink]="['']" src="assets/images/logo_toyota.png" alt="Toyota">
        </a>
        <a class="navbar-brand pl-2 font-weight-bold" [routerLink]="['']">E-TADS</a>

        <div id="divmain" style="width: 60%;" >
        <div class="collapse navbar-collapse" style="display: none;" id="navbarSupportedContent" >
          <ul class="navbar-nav mr-auto" >
            <etads-menu-dropdown [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" [hideMenu]="isDealerElibraryUSer || isTdbElibraryUser || readOnlyEtads || isOnlyBoletim" menuSequence="1" label="Cadastros">
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="priority-list" label="Manter Prioridade"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="chassis-list" label="Manter Chassi Importacao Direta"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="area-related-list" label="Manter Área Relacionada"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="symptom-group-list" label="Manter Grupo de Sintomas"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="symptom-list" label="Manter Sintomas"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="acessories-list" label="Manter Acessórios"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="t1-list" label="Manter Item T1"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="t2-list" label="Manter Item T2"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="general-schedule-list" label="Manter Escalonamento Geral"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="daily-schedule-list" label="Manter Escalonamento Diário"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="answer-schedule-list" label="Manter Escalonamento Tempo de Resposta"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="finality-list" label="Manter Finalidade"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="distributor-list" label="Manter Distribuidores"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="group-tdb-schedule-list" label="Manter Escalonamento Grupo TDB"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="group-dlr-schedule-list" label="Manter Escalonamento Grupo DLR"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="technicaltips-list" label="Manter Dicas Técnicas"></etads-menu-dropdown-item>
            </etads-menu-dropdown>
            <etads-menu-dropdown  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" [hideMenu]="isDealerElibraryUSer || isTdbElibraryUser || readOnlyEtads || isOnlyBoletim" menuSequence="2" label="Chamados">
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" routerLink="ticket-create" label="Novo Chamado"></etads-menu-dropdown-item>
            </etads-menu-dropdown>
            <etads-menu-dropdown  [isTdbUser]="!isTdbUser" [hideMenu]="isDealerElibraryUSer || isTdbElibraryUser || readOnlyEtads || isOnlyBoletim" menuSequence="3" label="Consultas">
              <etads-menu-dropdown-item  [isTdbUser]="!isTdbUser" routerLink="ticket-open" label="Chamados Abertos"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [isTdbUser]="!isTdbUser" routerLink="ticket-close" label="Chamados Fechados"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [isTdbUser]="!isTdbUser" routerLink="ticket-draft" label="Rascunho"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [isTdbUser]="!isTdbUser" routerLink="technicaltips-list" label="Dicas Técnicas"></etads-menu-dropdown-item>
            </etads-menu-dropdown>
            <etads-menu-dropdown  [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" [hideMenu]="isDealerElibraryUSer || isTdbElibraryUser || readOnlyEtads || isOnlyBoletim" menuSequence="4" label="Toyota">
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="ticket-entry" label="Entrada"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="ticket-sent" label="Enviados"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="ticket-all" label="Todos"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="ticket-pending-tdb" label="Pendente de Alocação"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="true" [isTdbUser]="isTdbUser" routerLink="ticket-report" label="Relatorio Geral"></etads-menu-dropdown-item>              
            </etads-menu-dropdown>
            <etads-menu-dropdown  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" [hideMenu]="!readOnlyEtads || isOnlyBoletim" menuSequence="6" label="Toyota">
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" routerLink="ticket-all" label="Todos"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" routerLink="ticket-open" label="Chamados Abertos"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" routerLink="ticket-close" label="Chamados Fechados"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" routerLink="ticket-report" label="Relatorio Geral"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" routerLink="technicaltips-list" label="Dicas Técnicas"></etads-menu-dropdown-item>
            </etads-menu-dropdown>
            <etads-menu-dropdown id="e_library_menu" [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" [hideMenu]="isOnlyBoletim" menuSequence="5" label="e-Library">
              <etads-menu-dropdown-item  [isTdbUser]="isTdbUser" routerLink="elib-brand-list" label="Manter Marca"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [isTdbUser]="isTdbUser" routerLink="elib-model-list" label="Manter Modelo"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [isTdbUser]="isTdbUser" routerLink="elib-manual-list" label="Manter Manual"></etads-menu-dropdown-item>
              <etads-menu-dropdown-item  [isTdbUser]="!isTdbUser" routerLink="elib-manual-onlyread" label="Consultar Manual"></etads-menu-dropdown-item>
            </etads-menu-dropdown>
            <etads-menu-dropdown id="boletim_menu" [visibleOnlyTdbUser]="false" [isTdbUser]="isTdbUser" menuSequence="7" label="Boletim">
              <a class="dropdown-item" href="https://icy-pond-08920ec10.2.azurestaticapps.net">Sistema de Boletim</a>
            </etads-menu-dropdown>
           
          </ul>
        </div>
      </div>



        <div class="user" *ngIf="loggedIn">
          <i class="fa fa-user-circle mr-1 username"></i>
          <label style="padding-top: 10px;" class="username" >{{username}}</label>
          <i class="fa fa-sign-out logout ml-3" (click)="logout()" > Logout</i>
        </div>

        <div class="user" *ngIf="!loggedIn">
          <i class="fa fa-sign-in logout ml-3" (click)="login()" > Login</i>
        </div>

        <button class="navbar-toggler" style="background-color: #EBEBEB" type="button"
          data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span >
          <i class="fa fa-bars fa-lg" aria-hidden="true"></i>
        </span>
      </button>

      </nav>

      <ngx-spinner></ngx-spinner>

</header>
