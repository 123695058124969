<form [formGroup]="diagnosisForm" (submit)="actionSubmitDiagnosis()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Diagnóstico"></etads-label>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Reclamação do cliente"></etads-label>
        <etads-input-textArea [formGroup]="diagnosisForm" controlName="clientComplaint" style="height: 100px;" [readonly]="osFile">
        </etads-input-textArea>
        <etads-vmessage *ngIf="diagnosisForm.get('clientComplaint').errors?.required" text="Campo obrigatório">
        </etads-vmessage>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Detalhes da confirmação do sintoma"></etads-label>
        <etads-input-textArea [formGroup]="diagnosisForm" controlName="symptomDetail" style="height: 100px;">
        </etads-input-textArea>
        <etads-vmessage *ngIf="diagnosisForm.get('symptomDetail').errors?.required" text="Campo obrigatório">
        </etads-vmessage>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Resultado do diagnóstico"></etads-label>
        <etads-input-textArea [formGroup]="diagnosisForm" controlName="diagnosisResult" style="height: 100px;">
        </etads-input-textArea>
        <etads-vmessage *ngIf="diagnosisForm.get('diagnosisResult').errors?.required" text="Campo obrigatório">
        </etads-vmessage>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Causa provável"></etads-label>
        <etads-input-textArea [formGroup]="diagnosisForm" controlName="cause" style="height: 100px;">
        </etads-input-textArea>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-2">
        <etads-label label="T2"></etads-label>
        <etads-input-select (change)="actionChangeT2()" [formGroup]="diagnosisForm" firstOptionLabel="Selecione"
          controlName="t2Id">
          <option *ngFor="let it of listT2"  [value]="it.t2Id" [selected]="(diagnosisGroup && it.t2Id == diagnosisGroup.t2Id)">{{it.t2Code}}</option>
        </etads-input-select>
      </div>
      <div class="form-group col">
        <etads-label label="-"></etads-label>
        <etads-input-select (change)="actionChangeT2Description()" [formGroup]="diagnosisForm" firstOptionLabel="Selecione"
          controlName="t2Id2">
          <option *ngFor="let it of listT2"  [value]="it.t2Id" [selected]="(diagnosisGroup && it.t2Id == diagnosisGroup.t2Id)">{{it.t2Name}}</option>
        </etads-input-select>
      </div>
    </div>

  </div>
</form>