import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Account } from '../../interfaces/account';
import { EtadsAnalyst } from 'src/app/interfaces/analyst';
import { DistributorCrudService } from 'src/app/services/distributor-crud.service';
const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};
@Injectable({ providedIn: 'root' })
export class UserService {

    private graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";
    adminProfile: string = "frontadmin";
    tdbProfile: string = "fronttdb";
    dealerProfile: string = "frontdealer";
    isAuthorized: boolean = false;
    dealerElibraryProfile: string = "frontdealerelibrary";
    tdbElibraryProfile: string = "frontadminelibrary";
    readOnlyEtadsProfile: string = "frontread";
    onlyBoletim: string = "frontboletim";
    analystList: EtadsAnalyst[] = [];

    constructor(
        private authService: MsalService,
        private http: HttpClient) { }

    adminUser: boolean;
    tdbUser: boolean;
    dealerUser: boolean;
    dealerNumber: string;
    userName: string;
    userLogin: string;
    usercontact: string;
    userbusinessphone: string;
    usermobilephone: string;
    userjobtitle: string;
    importOsFileRequired: boolean;

    checkAuthorized(){
      this.isAuthorized = true;
      try{
        let isAdminUser = this.checkAdminUser();
        let isTdbUser = this.checkTdbUser();
        let isDealerUser = this.checkDealerUser();
        let isDealerElibraryUser = this.checkDealerElibrary();
        let isTdbElibraryUser = this.checkTdbElibrary();
        let isReadEtadsUser = this.checkReadOnlyEtads();
        let isOnlyBoletim = this.checkOnlyBoletim();

        if((!isAdminUser && !isDealerUser && !isTdbUser && !isDealerElibraryUser && !isTdbElibraryUser && !isReadEtadsUser && !isOnlyBoletim)
            || (isDealerUser && !this.getDealerNumber())){
          this.logout();
          this.isAuthorized = false;
        }
      }catch(e){

      }

      return this.isAuthorized;
    }

    getProfile() {

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
        return account;
      }
    }

    checkAdminUser() {

        let isAdminUser: boolean = false;

        let activeAccount: Account = this.authService.instance.getActiveAccount();
        for (let account of activeAccount.idTokenClaims?.roles) {
            if (account == this.adminProfile) {
                isAdminUser = true;
            }
        }

        return isAdminUser;
    }

    checkTdbUser() {

      let isTdbUser: boolean = false;

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
          if (account == this.adminProfile || account == this.tdbProfile || account == this.tdbElibraryProfile) {
              isTdbUser = true;
          }
      }

      return isTdbUser;
    }

    checkDealerUser() {

      let isDealerUser: boolean = false;

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
          if (account == this.dealerProfile) {
            isDealerUser = true;
          }
      }

      return isDealerUser;
    }

    checkDealerElibrary() {

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
          if (account == this.dealerElibraryProfile) {
            return true
          }
      }

      return false;
    }

    checkTdbElibrary() {

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
          if (account == this.tdbElibraryProfile) {
            return true
          }
      }

      return false;
    }

    checkReadOnlyEtads() {

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
          if (account == this.readOnlyEtadsProfile) {
            return true
          }
      }

      return false;
    }

    checkOnlyBoletim() {

      let activeAccount: Account = this.authService.instance.getActiveAccount();
      for (let account of activeAccount.idTokenClaims?.roles) {
          if (account == this.onlyBoletim) {
            return true
          }
      }

      return false;
    }


    loadAnalysts() {
      this.analystList = [];
      let items = this.http.get<any>('https://graph.microsoft.com/v1.0/groups/dbbe3338-521d-4ed5-a01a-fa3cc6c26804/members?$count=true', httpOptions);
      items.subscribe(it => {
          for (let usr of it.value) {
            let analyst: EtadsAnalyst = new EtadsAnalyst()
            analyst.id = usr.id;
            analyst.login = usr.userPrincipalName;
            analyst.name = usr.displayName;
            this.analystList.push(analyst);
          }
          this.analystList.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
      } )

    }

    getAdditionalInfo() {
      return this.http.get<any>(`${this.graphMeEndpoint}`, httpOptions);
    }

    getCompany() {
        return this.http.get<any>(`${this.graphMeEndpoint}/companyName`, httpOptions);
    }

    getPhones() {
      return this.http.get<any>(`${this.graphMeEndpoint}/user`, httpOptions);
    }

    storeIsAdminUser(adminUser: boolean) {
        this.adminUser = adminUser;
    }
    storeIsTdbUser(tdbUser: boolean) {
      this.tdbUser = tdbUser;
    }
    storeIsDealerUser(dealerUser: boolean) {
      this.dealerUser = dealerUser;
    }

    isAdminUser() {
        return this.adminUser;
    }

    isTdbUser() {
      return this.tdbUser;
    }

    isDealerUser() {
      return this.dealerUser;
    }

    storeDealerNumber(dealerNumber: string) {
        this.dealerNumber = dealerNumber;
    }

    getDealerNumber() {
        if(this.checkDealerUser() && !this.dealerNumber){
          return 'NA';
        }
        return this.dealerNumber;
    }

    storeUserName(userName: string) {
        this.userName = userName;
    }

    storeUserContact(data: string) {
      this.usercontact = data;
    }

    storeUserBusinessPhone(data: string) {
      this.userbusinessphone = data;
    }

    storeUserMobilePhone(data: string) {
      this.usermobilephone = data;
    }

    storeUserJobTitle(data: string) {
      this.userjobtitle = data;
    }

    storeUserLogin(userLogin: string) {
      this.userLogin = userLogin;
    }

    storeImportOsFileRequired(req: boolean) {
      this.importOsFileRequired = req;
    }

    getImportOsFileRequired() {
      return this.importOsFileRequired;
    }

    getUserBusinessPhone() {
      return this.userbusinessphone;
    }

    getUserMobilePhone() {
      return this.usermobilephone;
    }

    getUserJobTitle() {
      return this.userjobtitle;
    }

    getUserContact() {
      return this.usercontact;
    }

    getUserName() {
        return this.userName;
    }

    getUserLogin() {
      return this.userLogin;
    }

    getAnalysts(){
      return this.analystList;
    }

    logout() {
        this.authService.logout();
    }
}
