import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsPriority, Priority, PriorityForm } from '../../../interfaces/priority';
import { Subscription } from 'rxjs';
import { ElibBrand, ElibBrandForm } from '../../../interfaces/elib-brand';
import { ElibBrandCrudService } from '../../../services/elib-brand-crud.service';


@Component ({
    templateUrl: './elib-brand-edit.component.html'
})

export class ElibBrandEditComponent implements OnInit {

    form: FormGroup;
    brandId : Number;
    filterMap: Map<string, string > = new Map<string, string>();
    elibBrand: ElibBrand;
    brandList: ElibBrand[];
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private elibBrandCrudService : ElibBrandCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService) {}



    ngOnInit(): void {

        this.alertService.clear();
        this.filterMap.set("Editar Marca", "");
        this.brandId = this.route.snapshot.params.brandId;
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();


    }
    loadData() {

        this.elibBrand = this.stateService.retrieveElibBrand();
        this.brandList = this.stateService.retrieveElibBrandList();

    }

    createForm () {
        this.form = this.formBuilder.group({
          description: ['',   [  Validators.required,  Validators.minLength(3)  ] ]
        });
    }


    updateForm() {
        this.form.patchValue({description:this.elibBrand.description});
    }


    actionBack() {
        this.router.navigate(['elib-brand-list'])
    }

    actionClear() {
        this.ngOnInit();
    }

    actionSubmit () {

        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger("Marca Inválida ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();
        this.alertService.clear();
        const prEdit = this.form.getRawValue() as ElibBrand;

        prEdit.id         = this.elibBrand.id;
        prEdit.brandId = this.elibBrand.brandId;
        prEdit.description   = prEdit.description.toUpperCase();

        this.subscription = this.elibBrandCrudService.upd(this.elibBrand, prEdit).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
            this.reloadCurrentRoute();
          }
        );
    }

    validateForm() {
        const newPr = this.form.getRawValue() as ElibBrandForm;

        for (let prData of this.brandList) {
             let parse1 = prData.description.toUpperCase();
             let parse2 = newPr.description.toUpperCase();

            if (parse1 == parse2 && this.brandId != prData.brandId) {
                return false;
            }
        }
        return true;
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
