import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-card-filter-list',
    templateUrl: './card-filter-list.component.html'
})
export class CardFilterListComponent { 

    @Input() map: Map<string, string> = new Map<string, string>(); 

    constructor() {}

    originalOrder = (a: KeyValue<number,string>, b: KeyValue<number,string>): number => {
        return 0;
      }
      
    
}