<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
            
            <etads-label label="Código"></etads-label>
            <etads-input-text [formGroup]="form" controlName="t2Code" placeholder="Informe um Código."></etads-input-text>
            <etads-vmessage *ngIf="form.get('t2Code').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('t2Code').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('t2Code').errors?.maxlength" text="Tamanho máximo de 10 caracteres"></etads-vmessage>

            <etads-label label="Nome"></etads-label>
            <etads-input-text [formGroup]="form" controlName="t2Name" placeholder="Informe um nome."></etads-input-text>
            <etads-vmessage *ngIf="form.get('t2Name').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('t2Name').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('t2Name').errors?.maxlength" text="Tamanho máximo de 10 caracteres"></etads-vmessage>

            <etads-label label="Situação"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>
            <etads-vmessage *ngIf="form.get('active').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>