import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { AlertComponent } from './alert/alert.component';
import { ButtonAddComponent} from './buttons/button-add/button-add.component';
import { ButtonAddArrowComponent} from './buttons/button-add-arrow/button-add-arrow.component';
import { ButtonBackComponent} from './buttons/button-back/button-back.component';
import { ButtonClearComponent} from './buttons/button-clear/button-clear.component';
import { ButtonConfirmComponent} from './buttons/button-confirm/button-confirm.component';
import { ButtonDelComponent} from './buttons/button-del/button-del.component';
import { ButtonDelArrowComponent} from './buttons/button-del-arrow/button-del-arrow.component';
import { ButtonDespublishComponent} from './buttons/button-despublish/button-despublish.component';
import { ButtonDownloadComponent} from './buttons/button-download/button-download.component';
import { ButtonPublishComponent} from './buttons/button-publish/button-publish.component';
import { ButtonRemoveComponent} from './buttons/button-remove/button-remove.component';
import { ButtonSaveComponent} from './buttons/button-save/button-save.component';
import { ButtonSearchComponent} from './buttons/button-search/button-search.component';
import { ButtonUploadComponent} from './buttons/button-upload/button-upload.component';
import { CardBoxComponent} from './cards/card-box/card-box.component';
import { CardFilterListComponent } from './cards/card-filter-list/card-filter-list.component';
import { CardFormComponent} from './cards/card-form/card-form.component';
import { CardListComponent} from './cards/card-list/card-list.component';
import { DivButtonLgScreenComponent } from './divs/div-button-lg-screen/div-button-lg-screen.component';
import { DivButtonSmScreenComponent } from './divs/div-button-sm-screen/div-button-sm-screen.component';
import { DivCenterComponent } from './divs/div-center/div-center.component';
import { DivColMediumComponent } from './divs/div-col-medium/div-col-medium.component';
import { DivColSmallComponent } from './divs/div-col-small/div-col-small.component';
import { DivFormRowComponent } from './divs/div-form-row/div-form-row.component';
import { DivInputGroupComponent } from './divs/div-input-group/div-input-group.component';
import { IconDetailComponent } from './icons/detail/icon-detail.component';
import { IconEditComponent } from './icons/edit/icon-edit.component';
import { IconRemoveComponent } from './icons/remove/icon-remove.component';
import { InputDateComponent } from './input/input-date/input-date.component';
import { InputDatetimeComponent } from './input/input-datetime/input-datetime.component';
import { InputEmailComponent } from './input/input-email/input-email.component';
import { InputFileAllComponent } from './input/input-file-all/input-file-all.component';
import { InputFileExcelComponent } from './input/input-file-excel/input-file-excel.component';
import { InputMonthComponent } from './input/input-month/input-month.component';
import { InputMonthRangeComponent } from './input/input-month-range/input-month-range.component';
import { InputNumberComponent } from './input/input-number/input-number.component';
import { InputPeriodComponent } from './input/input-period/input-period.component';
import { InputSelectComponent } from './input/input-select/input-select.component';
import { InputSelectMultipleComponent } from './input/input-select-multiple/input-select-multiple.component';
import { InputSelectMultipleEditComponent } from './input/input-select-multiple-edit/input-select-multiple-edit.component';
import { InputSelectWithoutDefaultComponent } from './input/input-select-without-default/input-select-without-default.component';
import { InputTextComponent } from './input/input-text/input-text.component';
import { InputTextAreaComponent } from './input/input-text-area/input-text-area.component';
import { InputTextSizedComponent } from './input/input-text-sized/input-text-sized.component';
import { LabelComponent } from './labels/label/label.component';
import { LabelSmallComponent } from './labels/label-small/label-small.component';
import { LabelBetweenInputsComponent } from './labels/label-between-inputs/label-between-inputs.component';
import { LabelWithoutSpaceComponent } from './labels/label-without-space/label-without-space.component';
import { SmallMessageComponent } from './message/small-message/small-message.component';
import { VMessageComponent } from './message/vmessage/vmessage.component';
import { TableComponent } from './table/table.component';
import { TooltipComponent } from './tooltip/tooltip.component';
import { InputCheckBoxComponent} from './input/input-check-box/input-check-box.component';
import { InputRadioButtonComponent} from './input/input-radio/input-radio.component';
import { DivDealerGroupComponent } from './ticket/div-dealer-group/div-dealer-group.component';
import { DivVehicleGroupComponent } from './ticket/div-vehicle-group/div-vehicle-group.component';
import { DivRequestGroupComponent } from './ticket/div-request-group/div-request-group.component';
import { DivDiagnosisGroupComponent } from './ticket/div-diagnosis-group/div-diagnosis-group.component';
import { DivDiagnosisTroubleGroupComponent } from './ticket/div-diagnosis-trouble-group/div-diagnosis-trouble-group.component';
import { DivPartCauseGroupComponent } from './ticket/div-part-cause-group/div-part-cause-group.component';
import { DivAccessoryGroupComponent } from './ticket/div-accessory-group/div-accessory-group.component';
import { DivRepairHistoryGroupComponent } from './ticket/div-repair-history-group/div-repair-history-group.component';
import { DivFeedbackGroupComponent } from './ticket/div-feedback-group/div-feedback-group.component';
import { DivFeedbackHistoryGroupComponent } from './ticket/div-feedback-group/div-feedback-history-group.component';
import { CardSectionComponent } from './cards/card-section/card-section.component';
import { DaysOpenGroupComponent } from './ticket/days-open-group/days-open-group.component';

@NgModule({
    declarations: [
        AlertComponent,
        ButtonAddComponent,
        ButtonAddArrowComponent,
        ButtonBackComponent,
        ButtonClearComponent,
        ButtonConfirmComponent,
        ButtonDelComponent,
        ButtonDelArrowComponent,
        ButtonDespublishComponent,
        ButtonDownloadComponent,
        ButtonPublishComponent,
        ButtonRemoveComponent,
        ButtonSaveComponent,
        ButtonSearchComponent,
        ButtonUploadComponent,
        CardBoxComponent,
        CardFilterListComponent,
        CardFormComponent,
        CardListComponent,
        DivButtonLgScreenComponent,
        DivButtonSmScreenComponent,
        DivCenterComponent,
        DivColMediumComponent,
        DivColSmallComponent,
        DivFormRowComponent,
        DivInputGroupComponent,
        IconDetailComponent,
        IconEditComponent,
        IconRemoveComponent,
        InputDateComponent,
        InputDatetimeComponent,
        InputEmailComponent,
        InputFileAllComponent,
        InputFileExcelComponent,
        InputMonthComponent,
        InputMonthRangeComponent,
        InputNumberComponent,
        InputPeriodComponent,
        InputSelectComponent,
        InputSelectMultipleComponent,
        InputSelectMultipleEditComponent,
        InputSelectWithoutDefaultComponent,
        InputTextComponent,
        InputTextAreaComponent,
        InputTextSizedComponent,
        LabelBetweenInputsComponent,
        LabelComponent,
        LabelSmallComponent,
        LabelWithoutSpaceComponent,
        SmallMessageComponent,
        VMessageComponent,
        TableComponent,
        TooltipComponent,
        InputCheckBoxComponent,
        InputRadioButtonComponent,
        DivDealerGroupComponent,
        DivVehicleGroupComponent,
        DivRequestGroupComponent,
        DivDiagnosisGroupComponent,
        DivDiagnosisTroubleGroupComponent,
        DivPartCauseGroupComponent,
        DivAccessoryGroupComponent,
        DivRepairHistoryGroupComponent,
        DivFeedbackGroupComponent,
        DivFeedbackHistoryGroupComponent,
        CardSectionComponent,
        DaysOpenGroupComponent
    ],
    exports: [
        AlertComponent,
        ButtonAddComponent,
        ButtonAddArrowComponent,
        ButtonBackComponent,
        ButtonClearComponent,
        ButtonConfirmComponent,
        ButtonDelComponent,
        ButtonDelArrowComponent,
        ButtonDespublishComponent,
        ButtonDownloadComponent,
        ButtonPublishComponent,
        ButtonRemoveComponent,
        ButtonSaveComponent,
        ButtonSearchComponent,
        ButtonUploadComponent,
        CardBoxComponent,
        CardFilterListComponent,
        CardFormComponent,
        CardListComponent,
        DivButtonLgScreenComponent,
        DivButtonSmScreenComponent,
        DivCenterComponent,
        DivColMediumComponent,
        DivColSmallComponent,
        DivFormRowComponent,
        DivInputGroupComponent,
        IconDetailComponent,
        IconEditComponent,
        IconRemoveComponent,
        InputDateComponent,
        InputDatetimeComponent,
        InputEmailComponent,
        InputFileAllComponent,
        InputFileExcelComponent,
        InputMonthComponent,
        InputMonthRangeComponent,
        InputNumberComponent,
        InputPeriodComponent,
        InputSelectComponent,
        InputSelectMultipleComponent,
        InputSelectMultipleEditComponent,
        InputSelectWithoutDefaultComponent,
        InputTextComponent,
        InputTextAreaComponent,
        InputTextSizedComponent,
        LabelBetweenInputsComponent,
        LabelComponent,
        LabelSmallComponent,
        LabelWithoutSpaceComponent,
        SmallMessageComponent,
        VMessageComponent,
        TableComponent,
        TooltipComponent,
        InputCheckBoxComponent,
        InputRadioButtonComponent,
        DivDealerGroupComponent,
        DivVehicleGroupComponent,
        DivRequestGroupComponent,
        DivDiagnosisGroupComponent,
        DivDiagnosisTroubleGroupComponent,
        DivPartCauseGroupComponent,
        DivAccessoryGroupComponent,
        DivRepairHistoryGroupComponent,
        DivFeedbackGroupComponent,
        DivFeedbackHistoryGroupComponent,
        CardSectionComponent,
        DaysOpenGroupComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSpinnerModule
    ]
})
export class ComponentsModule { }
