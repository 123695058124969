<form [formGroup]="form" (submit)= "actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Prioridade"></etads-label>

            <etads-input-text [formGroup]="form" controlName="initials" placeholder="Sigla"></etads-input-text>
            <etads-vmessage *ngIf="form.get('initials').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('initials').errors?.minlength" text="Tamanho mínino de 1 caracter"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('initials').errors?.maxlength" text="Tamanho máximo de 1 caracter"></etads-vmessage>

            <etads-label label="Descrição"></etads-label>
            <etads-input-text [formGroup]="form" controlName="alias" placeholder="Informe uma descrição" ></etads-input-text>
            <etads-vmessage *ngIf="form.get('alias').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('alias').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('alias').errors?.maxlength" text="Tamanho máximo de 50 caracteres"></etads-vmessage>

            <etads-label label="Sequência"></etads-label>
            <etads-input-text [formGroup]="form" controlName="sequence" placeholder="Número da Sequencia" ></etads-input-text>

            <etads-label label="Situação"></etads-label>

            <etads-div-input-group>
              <etads-input-checkbox  [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>

    </etads-card-form>

</form>
