import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertService } from '../../alert/alert.service';

@Component({
    selector: 'etads-input-datetime',
    templateUrl: './input-datetime.component.html',

})
export class InputDatetimeComponent {

    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() readonly: string;
    @Input() inputLabel: string;

    @Output() changeEmmiter = new EventEmitter();

    constructor(
        private alertService: AlertService
    ) { }

    actionChange() {
        this.changeEmmiter.emit();
    }

    validateDateFormat(dataValue: string) {

        const validFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}$/.test(dataValue)

        console.log("Testando validação de data: ", validFormat)
        if (!validFormat) {
            this.alertService.danger(this.inputLabel + " está fora do padrão.")
            return false
        }
        return true;
    }

    onInputBlur(event: any) {
        this.validateDateFormat(event.target.value)
    }
}