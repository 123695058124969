<form [formGroup]="form" (submit)= "actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Nome"></etads-label>
            <etads-input-text [formGroup]="form" controlName="name" placeholder="Nome"></etads-input-text>

            <etads-label label="Código do manual"></etads-label>
            <etads-input-text [formGroup]="form" controlName="code" placeholder="Código"></etads-input-text>
            <etads-vmessage *ngIf="form.get('code').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Descrição"></etads-label>
            <etads-input-text [formGroup]="form" controlName="description" placeholder="Descrição"></etads-input-text>

            <etads-label label="Página inicial"></etads-label>
            <etads-input-text [formGroup]="form" controlName="startPage" placeholder="Página inicial"></etads-input-text>
            <etads-vmessage *ngIf="form.get('startPage').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Marca"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="brandId">
              <option *ngFor="let it of listBrand"  [value]="it.brandId" [selected]="it.brandId == elibManual.brand.brandId">{{it.description}}</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('brandId').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Modelo"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="modelId">
              <option *ngFor="let it of listModel"  [value]="it.modelId" [selected]="it.modelId == elibManual.model.modelId">{{it.description}}</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('modelId').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Data de início"></etads-label>
            <etads-input-date [formGroup]="form" controlName="startDate">
            </etads-input-date>
            <etads-vmessage *ngIf="form.get('startDate').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Data de término"></etads-label>
            <etads-input-date [formGroup]="form" controlName="endDate">
            </etads-input-date>

            <etads-label label="Ocultar"></etads-label>
            <etads-input-checkbox [formGroup]="form" controlName="hide"></etads-input-checkbox>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>

    </etads-card-form>

</form>
