import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { Distributor } from '../../../interfaces/distributor';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './distributor-remove.component.html'
})
export class DistributorRemoveComponent implements OnInit {


    form: FormGroup;    
    distributorId : number;
    messageLabel : string;
    distributorToDel : Distributor;
    subscription : Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private distributorCrudService: DistributorCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}

    ngOnInit(): void {
        this.spinnerService.show();

        this.distributorId = this.route.snapshot.params.distributorId;
        this.distributorToDel = this.stateService.retrieveDistributor();
        
        this.alertService.warning("Deseja excluir o Distribuidor: " + this.distributorToDel.distributorName + " ?");

        this.form = this.formBuilder.group({
            distributorId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        this.subscription = this.distributorCrudService.del(this.distributorToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.messge);
            this.spinnerService.hide();
        });
        
        

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['distributor-list']);
    }

}
