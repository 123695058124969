import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-select-multiple',
    templateUrl: './input-select-multiple.component.html',

})
export class InputSelectMultipleComponent { 

    @Input() formGroup: FormGroup;
    @Input() controlName: string;

    @Output() changeEmmiter = new EventEmitter(); 

    actionChange() { 
        this.changeEmmiter.emit(); 
    } 

}