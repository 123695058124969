import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'etads-icon-edit',
    templateUrl: './icon-edit.component.html',
    styleUrls: ['./icon-edit.component.css']
})
export class IconEditComponent implements OnInit {
    

    @Input() link : string;
    @Input() parameter : string;

    routerLink : string;

    ngOnInit(): void {
        this.routerLink="/" + this.link + "/" + this.parameter;
    } 

    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}