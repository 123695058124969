import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MultipleList } from '../../../../interfaces/multiple-list';

@Component({
    selector: 'etads-input-select-multiple-edit',
    templateUrl: './input-select-multiple-edit.component.html',

})
export class InputSelectMultipleEditComponent { 

    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() multipleList : MultipleList[];

    @Output() changeEmmiter = new EventEmitter(); 

    actionChange() { 
        this.changeEmmiter.emit(); 
    } 

}