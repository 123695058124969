import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsPriority, Priority, PriorityForm } from '../../../interfaces/priority';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './priority-edit.component.html'
})

export class PriorityEditComponent implements OnInit {

    form: FormGroup;
    priorityId : Number;
    filterMap: Map<string, string > = new Map<string, string>();
    priority: Priority;
    priorityList: Priority[];
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private priorityCrudService : PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService) {}



    ngOnInit(): void {

        this.alertService.clear();
        this.filterMap.set("Editar Prioridade", "");
        this.priorityId = this.route.snapshot.params.priorityId;
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();


    }
    loadData() {

        this.priority = this.stateService.retrievePriority();
        this.priorityList = this.stateService.retrievePriorityList();

    }

    createForm () {
        this.form = this.formBuilder.group({
            initials: ['',  [ Validators.required, Validators.minLength(1), Validators.maxLength(1) ] ],
            alias:    ['',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] ],
            sequence: ['',  [ Validators.required, Validators.minLength(1), Validators.maxLength(5)  ] ],
            active:   [null,  [ Validators.required ] ]
        });
    }


    updateForm() {
        this.form.patchValue({initials:this.priority.initials});
        this.form.patchValue({alias:this.priority.alias});
        this.form.patchValue({sequence:this.priority.sequence});
        this.form.patchValue({active:this.priority.active});
    }


    actionBack() {
        this.router.navigate(['priority-list'])
    }

    actionClear() {
        this.ngOnInit();
    }

    actionSubmit () {

        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger("Prioridade Inválida ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();
        this.alertService.clear();
        const prEdit = this.form.getRawValue() as Priority;

        prEdit.id         = this.priority.id;
        prEdit.priorityId = this.priority.priorityId;
        prEdit.initials   = prEdit.initials.toUpperCase();
        prEdit.alias      = prEdit.alias.toUpperCase();

        this.subscription = this.priorityCrudService.upd(this.priority, prEdit).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
            this.reloadCurrentRoute(); //????? definir navegacao?
          }
        );
    }

    validateForm() {
        const newPr = this.form.getRawValue() as PriorityForm;

        for (let prData of this.priorityList) {
             let parse1 = prData.initials.toUpperCase();
             let parse2 = newPr.initials.toUpperCase();

            if (parse1 == parse2 && this.priorityId != prData.priorityId) {
                return false;
            }
        }
        return true;
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
