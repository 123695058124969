import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VehicleGroup } from './vehicle-group';
import { VehicleService } from '../../../../services/vehicle-service';
import { AlertService } from '../../alert/alert.service';
import { formatDate } from '@angular/common';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/core/auth/user.service';
import { DistributorCrudService } from 'src/app/services/distributor-crud.service';

@Component({
  selector: 'etads-div-vehicle-group',
  templateUrl: './div-vehicle-group.component.html',

})
export class DivVehicleGroupComponent {
  vehicleForm: FormGroup;
  vehicleGroup: VehicleGroup;
  osFile: boolean;
  importOsFileRequired: boolean;
  dateStart: string;

  constructor(
    private formBuilder: FormBuilder,
    private vehicleService: VehicleService,
    private stateService: StateService,
    private alertService: AlertService,
    private distributorService: DistributorCrudService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    console.log("init vehicle");
    this.createForm();
    this.osFile = false;
    this.importOsFileRequired = this.userService.getImportOsFileRequired();

    let vehicleGroup: VehicleGroup = this.stateService.retrieveTicket().vehicleGroup;
    ////console.log("VehicleGroup: " + JSON.stringify(vehicleGroup));
    if (vehicleGroup != undefined) {
      this.updateForm(vehicleGroup);
    }
    if (!this.stateService.retrieveEdit())
      this.vehicleForm.disable();

    let dealerNumber = this.userService.getDealerNumber();
    if (dealerNumber == 'NA') {
      this.userService.getCompany().subscribe(company => {

        let importOsFileRequired = true;
        try {
          this.distributorService.getByCode(company.value).subscribe(dist => {
            if (dist?.importRequired == false) {
              importOsFileRequired = false;
            }

            this.importOsFileRequired = importOsFileRequired;
          });
        } catch { }
      });
    }
  }

  createForm() {

    this.vehicleForm = this.formBuilder.group({
      chassi: [null, [Validators.required, Validators.minLength(17)]],
      km: [null, [Validators.required]],
      os: [null, [Validators.required]],
      dtStart: [null, [Validators.required]],
      typeOs: [null, [Validators.required]],
      vehicle: [null, null],
      yearProdMod: [null, null],
      model: [null, null],
      motor: [null, null],
      dtProduction: [null, null],
      dtSale: [null, null],
      transmission: [null, null]
    });
  }

  updateForm(vehicleGroup: VehicleGroup) {
    this.vehicleForm.patchValue({
      chassi: vehicleGroup.chassi,
      km: vehicleGroup.km,
      os: vehicleGroup.os,
      dtStart: vehicleGroup.dtStart,
      typeOs: vehicleGroup.typeOs,
      vehicle: vehicleGroup.vehicle,
      yearProdMod: vehicleGroup.yearProdMod,
      model: vehicleGroup.model,
      motor: vehicleGroup.motor,
      dtProduction: vehicleGroup.dtProduction,
      dtSale: vehicleGroup.dtSale,
      transmission: vehicleGroup.transmission
    });
  }

  actionChangeChassi() {
    if (!this.vehicleForm.get("chassi").errors) {
      this.vehicleService.getByChassi(this.vehicleForm.get("chassi").value).subscribe(
        (v) => {
          this.vehicleForm.get("vehicle").setValue(v.VEICULO);
          this.vehicleForm.get("yearProdMod").setValue(v.ANOFAB_MODELO);
          this.vehicleForm.get("model").setValue(v.MODELO);
          this.vehicleForm.get("motor").setValue(v.MOTOR);
          this.vehicleForm.get("dtProduction").setValue(v.DATA_PRODUCAO);
          this.vehicleForm.get("dtSale").setValue(v.DATA_VENDA);
          this.vehicleForm.get("transmission").setValue(v.TRANSMISSAO);
        },
        (error) => {
          console.log(error);
          if (error.error.errorName = "DuplicatedInfo") {
            this.alertService.danger(error.error.errorMessage);
          }
        }
      );
    }
  }

  actionSubmit() {
    console.log("submit veiculo");
    this.vehicleGroup = this.vehicleForm.getRawValue() as VehicleGroup;
  }

  actionSubmitVehicle() {
    console.log(this.vehicleForm.valid);
    console.log("submit veiculo");

    this.vehicleGroup = this.vehicleForm.getRawValue() as VehicleGroup;
    console.log(this.vehicleGroup);

  }
  
}
