import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { EtadsTechnicalTips, TechnicalTips } from '../../../interfaces/technicaltips';
import { TechnicalTipsCrudService } from '../../../services/technicaltips-crud.service';
import { UserService } from 'src/app/core/auth/user.service';



@Component({
    templateUrl: './technicaltips-list.component.html'
})
export class TechnicalTipsListComponent implements OnInit {


    currentPage : Number = 1;
    itemsPerPage : Number = 13;
    list : EtadsTechnicalTips[] = [];
    technicalTips : TechnicalTips;
    technicalTipsId : number;
    filterMap: Map<string, string> = new Map<string, string>();
    subscription : Subscription;
    isTdbUser: boolean = false;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private technicalTipsCrudService:  TechnicalTipsCrudService,
        private userService: UserService
        ) {}



    ngOnInit() {

        this.isTdbUser = this.userService.checkTdbUser();
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Item Dicas Técnicas",  "" );
        this.loadData();

       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);

        }
    }

    loadData() {

        this.list = [];
        this.subscription = this.technicalTipsCrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.list.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }

    actionAdd() {
        this.stateService.storeTechnicalTipsList(this.list);
        this.router.navigate(['technicaltips-create']);
    }

    actionEdit( data: TechnicalTips) {
        this.stateService.storeTechnicalTips(data);
        this.stateService.storeTechnicalTipsList(this.list);
    }

    actionRemove( obj: TechnicalTips ) {
        this.stateService.storeTechnicalTips(obj);
    }


}
