export interface DirectImportChassis {
    id: string;
    directImportChassisId: number;
    chassi: string;
    vehicle: string;
    model: string;
    modelyear: number;
    production_date: string;
    sales_date: string;
    motor_number: string;
    transmission: string;

}

export interface DirectImportChassisForm {
    chassi : string;
    vehicle: string;
    model: string;
    modelyear: number;
    production_date: Date;
    sales_date: Date;
    motor_number: string;
    transmission: string;

}

export class EtadsDirectImportChassis implements DirectImportChassis  {
    id: string;
    directImportChassisId: number;
    chassi: string;
    vehicle: string;
    model: string;
    modelyear: number;
    production_date: string;
    sales_date: string;
    motor_number: string;
    transmission: string;

}