import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { ElibManualCrudService } from '../../../services/elib-manual-crud.service';
import { ElibManual, EtadsElibManual } from '../../../interfaces/elib-manual';
import { UserService } from 'src/app/core/auth/user.service';
import { formatDate } from '@angular/common';
import { ElibModelCrudService } from 'src/app/services/elib-model-crud.service';
import { ElibModel } from 'src/app/interfaces/elib-model';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
    templateUrl: './elib-manual-list.component.html'
})
export class ElibManualListComponent implements OnInit {

    subscription : Subscription;
    isTdbUser: boolean = false;
    onlyVisible: boolean = false;
    form: FormGroup;
    modelId: string;

    constructor(
        private stateService: StateService,
        private userService: UserService,
        private router: Router,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private elibManualCrudService: ElibManualCrudService,
        private elibModelCrudService: ElibModelCrudService,
        private formBuilder: FormBuilder,
        ) {}

    currentPage : Number = 1;
    itemsPerPage : Number = 13;
    manualList : EtadsElibManual[] = [];
    filterMap: Map<string, string> = new Map<string, string>();
    listModel: ElibModel[];

    ngOnInit() {

        this.isTdbUser = this.userService.checkTdbUser();
        this.onlyVisible = false;
        if(this.route.snapshot.url[0].path == 'elib-manual-onlyread'){
          this.onlyVisible = true;
          this.filterMap.set("Consultar Manual", "");
        }else{
          this.filterMap.set("Manter Manual",  "" );
        }

        this.alertService.clear();

        this.createForm();
        this.elibModelCrudService.get().subscribe(lst => {
            this.listModel = lst;
        }, error => {
            console.log(error);
        });

       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);

        }

    }

    loadData() {

       this.subscription = this.elibManualCrudService.get(this.getMapFilter()).subscribe( reg => {
            this.manualList = reg;
            this.spinnerService.hide();
        } , err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
    }

    createForm() {
      this.form = this.formBuilder.group({
        modelId: [null, null]
      });
    }

    getMapFilter(){
      return new Map([
        ['startDate', (this.onlyVisible) ? '<=' + formatDate(new Date(),'yyyy-MM-dd','en') : null],
        //['endDate', (this.onlyVisible) ? '>=' + formatDate(new Date(),'yyyy-MM-dd','en') : null], >> CKA-1627 - retirado a pedido do usuario
        ['hide', (this.onlyVisible) ? 'false' : null],
        ['statusUpload', (this.onlyVisible) ? 'Upload finalizado' : null],
        ['model.modelId',  this.modelId]
      ]);
    }

    actionSubmit (){
      this.spinnerService.show();

      this.modelId = this.form.get("modelId").value;

      this.loadData();
    }

    actionAdd() {
        this.stateService.storeElibManualList(this.manualList);
        this.router.navigate(['elib-manual-create']);
    }

    actionEdit( elibManualData: ElibManual ) {
        this.stateService.storeElibManual(elibManualData);
        this.stateService.storeElibManualList(this.manualList);
    }

    actionRemove( manualData: EtadsElibManual ) {
        this.stateService.storeElibManual(manualData);
    }

    actionRefresh() {
      window.location.reload();
    }

    actionOpen(url) {
      window.open(url, "_blank", 'resizable,height=260,width=370');
    }
}
