import { Component, Input, Output, EventEmitter} from '@angular/core'; 

@Component({
    selector: 'etads-button-add',
    templateUrl: './button-add.component.html',
    styleUrls: ['./button-add.component.css']
})
export class ButtonAddComponent { 

    constructor() {}

    @Input() label = 'Adicionar';

    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}