import { Injectable } from '@angular/core';
import { GroupDlrSchedule } from '../interfaces/group-dlr-schedule';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class GroupDlrScheduleCrudService {
  key = 'etads/groupDlrSchedule';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : GroupDlrSchedule) {
    return this.azureCrudService.add(newReg, "addGroupDlrSchedule");
  }

  upd(oldReg : GroupDlrSchedule, newReg : GroupDlrSchedule) {
    return this.azureCrudService.upd(oldReg, newReg, "updateGroupDlrSchedule");
  }

  del(reg : GroupDlrSchedule) {
    return this.azureCrudService.del(reg, "deleteGroupDlrSchedule");
  }

  get() {
    return this.azureCrudService.get("getGroupDlrSchedule");
  }

}
