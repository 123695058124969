import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { AreaRelated,  AreaRelatedForm, EtadsAreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './area-related-edit.component.html'
})
export class AreaRelatedEditComponent implements OnInit {

    form:FormGroup;
    areaRelatedId: number;
    areaRelated: AreaRelated;
    areaRelatedList: AreaRelated[] = [];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Editar Área Relacionada", "");
        this.loadData();
        this.createForm();
        this.updateForm();

        this.spinnerService.hide();
    }

    loadData() {
        this.areaRelatedId = this.route.snapshot.params.areaRelatedId;
        this.areaRelated = this.stateService.retrieveAreaRelated();
        this.areaRelatedList = this.stateService.retrieveAreaRelatedList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            areaDescription: ['' , [ Validators.required, Validators.minLength(3), Validators.maxLength(60) ] ],
            active:   [null, [  Validators.required  ] ],
        });
    }

    updateForm() {
        this.form.patchValue({areaDescription:this.areaRelated.areaDescription});
        this.form.patchValue({active:this.areaRelated.active});

    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Área relacionada Inválida ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const areaOrigin = this.stateService.retrieveAreaRelated();
        const areaForm = this.form.getRawValue() as AreaRelatedForm;
        let updAreaRelated = new EtadsAreaRelated();

        updAreaRelated.id            = areaOrigin.id;
        updAreaRelated.areaRelatedId = areaOrigin.areaRelatedId;
        updAreaRelated.areaDescription = areaForm.areaDescription.valueOf().toUpperCase();
        updAreaRelated.active = areaForm.active.valueOf();

        this.subscription = this.areaRelatedCrudService.upd (areaOrigin, updAreaRelated).subscribe (
            (data) => {
                let idList = this.areaRelatedList.push(updAreaRelated);
                this.stateService.storeAreaRelatedList(this.areaRelatedList);
                this.stateService.storeAlertSuccessMessage(data.message);
                this.spinnerService.hide();
                this.actionBack();
            },(error) => {
                this.stateService.storeAreaRelatedList(this.areaRelatedList);
                this.alertService.danger(error.error.errorMessage);
                this.spinnerService.hide();
            });
    }

    actionBack() {
        this.router.navigate(['area-related-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newAr = this.form.getRawValue() as AreaRelatedForm;

        for (let arData of this.areaRelatedList) {
             let parse1 = arData.areaDescription.toUpperCase();
             let parse2 = newAr.areaDescription.toUpperCase();

            if (parse1 == parse2 && arData.areaRelatedId != this.areaRelatedId) {
                return false;
            }
        }
        return true;

    }

}
