import { KeyValue } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-card-section',
    templateUrl: './card-section.component.html'
})
export class CardSectionComponent {

    constructor() {}

    @Input() title = '';

}
