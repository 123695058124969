import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from 'src/app/services/state.service';
import { RepairHistoryGroup } from './repair-history-group';

@Component({
  selector: 'etads-div-repair-history-group',
  templateUrl: './div-repair-history-group.component.html',

})
export class DivRepairHistoryGroupComponent {

  @Output() changeRelatedProblem = new EventEmitter();
  @Output() changeRelatedProblemTec = new EventEmitter();

  repairHistoryForm: FormGroup;
  repairHistoryGroup: RepairHistoryGroup;
  repairHistorySelected: boolean;
  repairHistoryTecSelected: boolean;

  constructor(
    private stateService: StateService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.repairHistorySelected = false;
    this.repairHistoryTecSelected = false;

    let repairHistoryGroup: RepairHistoryGroup = this.stateService.retrieveTicket().repairHistoryGroup;
    ////console.log("RepairHistoryGroup: " + JSON.stringify(repairHistoryGroup));
    if (repairHistoryGroup != undefined){
      this.updateForm(repairHistoryGroup);
    }

    if(!this.stateService.retrieveEdit())
      this.repairHistoryForm.disable();
  }

  createForm() {
    this.repairHistoryForm = this.formBuilder.group({
      repairHistory: [null, null],
      relatedProblem: [null, null],
      problemDescription: [null, null],
      repairHistoryTec: [null, null],
      relatedProblemTec: [null, null],
      problemDescriptionTec: [null, null],
      sac: [null, null]
    });
  }

  updateForm(repairHistoryGroup: RepairHistoryGroup) {

    this.repairHistoryForm.patchValue({
      repairHistory: repairHistoryGroup.repairHistory,
      relatedProblem: repairHistoryGroup.relatedProblem,
      problemDescription: repairHistoryGroup.problemDescription,
      repairHistoryTec: repairHistoryGroup.repairHistoryTec,
      relatedProblemTec: repairHistoryGroup.relatedProblemTec,
      problemDescriptionTec: repairHistoryGroup.problemDescriptionTec,
      sac: repairHistoryGroup.sac
    });

    this.actionChangeRepairHistory();
    this.actionChangeRepairHistoryTec();
  }

  actionSubmitRepairHistory() {
    console.log("submit repair history");
    this.repairHistoryGroup = this.repairHistoryForm.getRawValue() as RepairHistoryGroup;
  }

  actionChangeRepairHistory() {
    this.repairHistorySelected = this.repairHistoryForm.get("repairHistory").value;
  }

  actionChangeRepairHistoryTec() {
    this.repairHistoryTecSelected = this.repairHistoryForm.get("repairHistoryTec").value;
  }

}
