import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Symptom } from '../../../interfaces/symptom';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { TechnicalTipsForm } from '../../../interfaces/technicaltips';
import { Subscription } from 'rxjs';
import { Priority } from '../../../interfaces/priority';
import { EtadsTechnicalTips, TechnicalTips } from '../../../interfaces/technicaltips';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { TechnicalTipsCrudService } from '../../../services/technicaltips-crud.service';

@Component ({
    templateUrl: './technicaltips-create.component.html'
})

export class TechnicalTipsCreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    areaRelated: AreaRelated;
    sympton: Symptom;
    symptomGroup: SymptomGroup;
    priority: Priority;

    technicalTips: TechnicalTips;
    file: File;

    areaRelatedList : AreaRelated[] = [];
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    priorityList : Priority[] = [] ;
    subscription : Subscription;
    technicalTipsList: EtadsTechnicalTips[]=[];


    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        private symptomCrudService: SymptomCrudService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private technicalTipsCrudService: TechnicalTipsCrudService,
        private priorityCrudService: PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Item T1", "");
        this.loadData();
        this.createForm();
        this.spinnerService.hide();
    }

    loadData() {

        /* carregando Area Relacionada */
        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( (lista) => {
            for (let reg of lista) {
                this.areaRelatedList.push(reg);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        /* caregando lista de Prioridades */
        this.priorityList = [];
        this.subscription = this.priorityCrudService.get().subscribe( (listx) => {
             for (let regx of listx) {
                 this.priorityList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

    }


    createForm() {

      this.form = this.formBuilder.group( {
          name:           ['',     [ Validators.required, Validators.minLength(3) ] , ],
          vehicleDescription:  ['',  null, ],
          modelYear:  ['',  null, ],
          description:  ['',  null, ],
          areaRelatedId :   ['null', null, ],
          symptomGroupId:   ['null', null, ],
          symptomId:        ['null', null, ],
          priorityId:        ['null', null, ],
          attach:        [null, null, ],
        }
      );

  }

  onFileSelected(event: any) {

    let file: File = event.target.files[0];

    if (file) {
      this.form.get("name").setValue(file.name);

      this.file = file;
    }
  }

    actionChangeArea() {
      this.symptomGroupList = [];
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || this.form.get("areaRelatedId")?.value == '')
        return;

      this.symptomGroupCrudService.getByAreaRelated(this.form.get("areaRelatedId")?.value).subscribe(lst => {
          this.symptomGroupList = lst;
      }, error => {
        console.log(error);
      });
    }

    actionChangeGroup() {
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || !this.form.get("symptomGroupId")
          || this.form.get("areaRelatedId")?.value == '' || this.form.get("symptomGroupId")?.value == '')
        return;

      this.symptomCrudService.getByGroup(this.form.get("areaRelatedId")?.value, this.form.get("symptomGroupId")?.value).subscribe(lst => {
          this.symptomList = lst;
      }, error => {
        console.log(error);
      });
    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Dica Técnica Inválida ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const form   = this.form.getRawValue() as TechnicalTipsForm;
        var obj = new EtadsTechnicalTips();

        if (this.file) {
          obj.attach = this.file;
          console.log(this.file);
        }
        obj.name = form.name;
        obj.vehicleDescription = form.vehicleDescription;
        obj.modelYear = form.modelYear;
        obj.description = form.description;
        obj.areaRelatedId  = Number(form.areaRelatedId.valueOf());
        obj.symptomGroupId = Number(form.symptomGroupId.valueOf());
        obj.symptomId = Number(form.symptomId.valueOf());
        obj.priorityId = Number(form.priorityId.valueOf());

        for (let gr of this.symptomGroupList) {
            if (Number(gr.symptomGroupId) === Number(form.symptomGroupId) )   {
                obj.symptomGroupDescription = gr.symptomGroupDescription;
                break;
            }
        }

        for (let sy of this.symptomList) {
            if (Number(sy.symptomId) === Number(form.symptomId) )   {
                obj.symptomDescription = sy.symptomDescription;
                break;
            }
        }

        for (let p of this.priorityList) {
          if (Number(p.priorityId) === Number(form.priorityId) )   {
              obj.initials = p.initials;
              obj.priorityDescription = p.alias;
              break;
          }
      }

        let arearDescription = this.areaRelatedList.find(ar => ar.areaRelatedId == form.areaRelatedId)?.areaDescription;
        obj.areaRelatedDescription  = (arearDescription) ? arearDescription : "";

        this.subscription = this.technicalTipsCrudService.add(obj).subscribe((data) => {
            let idList = this.technicalTipsList.push(obj);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['technicaltips-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as TechnicalTipsForm;

            for (let sgData of this.technicalTipsList) {
               let parse1 = sgData.name;
               let parse2 = newSg.name;

               if (parse1 == parse2) {
                   return false;
               }
           }

        return true;
    }

}
