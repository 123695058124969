import { partitionArray } from '@angular/compiler/src/util';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Console } from 'console';
import { request } from 'http';
import { AreaRelated } from 'src/app/interfaces/area-related';
import { Finality } from 'src/app/interfaces/finality';
import { Symptom } from 'src/app/interfaces/symptom';
import { SymptomGroup } from 'src/app/interfaces/symptom-group';
import { T1 } from 'src/app/interfaces/t1';
import { AreaRelatedCrudService } from 'src/app/services/area-related-crud.service';
import { FinalityCrudService } from 'src/app/services/finality-crud.service';
import { StateService } from 'src/app/services/state.service';
import { SymptomCrudService } from 'src/app/services/symptom-crud.service';
import { SymptomGroupCrudService } from 'src/app/services/symptom-group-crud.service';
import { T1CrudService } from 'src/app/services/t1-crud.service';
import { RequestGroup } from './request-group';

@Component({
  selector: 'etads-div-request-group',
  templateUrl: './div-request-group.component.html',

})
export class DivRequestGroupComponent {
  @Output() changeAreaEmmiter = new EventEmitter();
  @Output() changeFinalityEmmiter = new EventEmitter();
  @Output() changeGroupEmmiter = new EventEmitter();


  requestForm: FormGroup;
  requestGroup: RequestGroup;

  listAreaRelated: AreaRelated[];
  listFinality: Finality[];
  listSymptomGroup: SymptomGroup[];
  listSymptom: Symptom[];
  listT1: T1[];

  constructor(
    private formBuilder: FormBuilder,
    private areaRelatedCrudService: AreaRelatedCrudService,
    private finalityCrudService: FinalityCrudService,
    private symptomGroupCrudService: SymptomGroupCrudService,
    private symptomCrudService: SymptomCrudService,
    private stateService: StateService,
    private t1CrudService: T1CrudService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadData();
    this.requestGroup = this.stateService.retrieveTicket().requestGroup;
    ////console.log("RequestGroup: " + JSON.stringify(requestGroup));
    if (this.requestGroup != undefined){
      this.updateForm(this.requestGroup);
    }

    if(!this.stateService.retrieveEdit())
      this.requestForm.disable();
  }

  createForm() {
    this.requestForm = this.formBuilder.group({
      relatedAreaId: [null, [Validators.required]],
      relatedAreaDescription: [null, null],
      finalityId: [null, [Validators.required]],
      finalityDescription: [null, null],
      symptomGroupId: [null, [Validators.required]],
      symptomGroupDescription: [null, null],
      priorityId: [null, null],
      initials: [null, null],
      priorityDescription: [null, null],
      symptomId: [null, [Validators.required]],
      symptomDescription: [null, null],
      t1Id: [null, null],
      t1Description: [null, null],
      guideInformation: [null, null]
    });
  }

  updateForm(requestGroup: RequestGroup) {

    this.requestForm.patchValue({
      relatedAreaId:  requestGroup.relatedAreaId,
      relatedAreaDescription: requestGroup.relatedAreaDescription,
      finalityId: requestGroup.finalityId,
      finalityDescription: requestGroup.finalityDescription,
      symptomGroupId: requestGroup.symptomGroupId,
      symptomGroupDescription: requestGroup.symptomGroupDescription,
      priorityId: requestGroup.priorityId,
      initials: requestGroup.initials,
      priorityDescription: requestGroup.priorityDescription,
      symptomId: requestGroup.symptomId,
      symptomDescription: requestGroup.symptomDescription,
      t1Id: requestGroup.t1Id,
      t1Description: requestGroup.t1Description,
      guideInformation: requestGroup.guideInformation

    });

    this.actionChangeArea();
    this.actionChangeGroup();


  }

  loadData() {
    // caregando lista de Distribuidores
    this.listAreaRelated = [];

    this.areaRelatedCrudService.get().subscribe(lst => {
        this.listAreaRelated = lst;
    }, error => {
        console.log(error);
    });

    this.listFinality = [];

    this.finalityCrudService.get().subscribe(lst => {
        this.listFinality = lst;
    }, error => {
      console.log(error);
    });
  }

  actionSubmitRequest() {
    console.log("submit request");
    this.requestGroup = this.requestForm.getRawValue() as RequestGroup;

    if (this.listSymptom) {
      let symptomGroup = this.listSymptomGroup.filter(sg => sg.symptomGroupId === parseInt(this.requestForm.get("symptomGroupId").value))

      if (symptomGroup.length != 0){
        this.requestGroup.relatedAreaDescription = symptomGroup[0].areaRelatedDescription;
        this.requestGroup.symptomGroupDescription = symptomGroup[0].symptomGroupDescription;
      }
    }
  }

  actionChangeArea() {
    this.listSymptomGroup = [];
    this.listSymptom = [];

    if(!this.requestForm.get("relatedAreaId") || this.requestForm.get("relatedAreaId").value == '')
      return;

    this.symptomGroupCrudService.getByAreaRelated(this.requestForm.get("relatedAreaId").value).subscribe(lst => {
        this.listSymptomGroup = lst;
    }, error => {
      console.log(error);
    });
  }

  actionChangeFinality() {
    if(!this.requestForm.get("finalityId") || this.requestForm.get("finalityId").value == '')
      return;

    let finality = this.listFinality.filter(f => f.finalityId === parseInt(this.requestForm.get("finalityId").value))
    this.requestForm.get("finalityDescription").setValue(finality[0].finalityDescription);
  }

  actionChangeGroup() {
    this.listSymptom = [];

    if(!this.requestForm.get("relatedAreaId") || this.requestForm.get("relatedAreaId").value == ''
        || !this.requestForm.get("symptomGroupId") || this.requestForm.get("symptomGroupId").value == '')
      return;

    this.symptomCrudService.getByGroup(this.requestForm.get("relatedAreaId").value, this.requestForm.get("symptomGroupId").value).subscribe(lst => {
        this.listSymptom = lst;
    }, error => {
      console.log(error);
    });
  }

  actionChangeSymptom() {

    if(!this.requestForm.get("symptomId") || this.requestForm.get("symptomId").value == '')
      return;

    let symptom = this.listSymptom.filter(s => s.symptomId === parseInt(this.requestForm.get("symptomId").value))
    this.requestForm.get("priorityId").setValue(symptom[0].priorityId);
    this.requestForm.get("initials").setValue(symptom[0].initials);
    this.requestForm.get("priorityDescription").setValue(symptom[0].priorityDescription);
    this.requestForm.get("symptomDescription").setValue(symptom[0].symptomDescription);

    this.requestForm.get("t1Id").setValue(null);
    this.requestForm.get("t1Description").setValue(null);

    this.t1CrudService.getBySymptom(this.requestForm.get("relatedAreaId").value,
      this.requestForm.get("symptomGroupId").value,
      this.requestForm.get("symptomId").value).subscribe(lst => {
        for (let item of lst) {
          this.requestForm.get("t1Id").setValue(item.t1Code);
          this.requestForm.get("t1Description").setValue(item.t1Name);
        }
      }, error => {
        console.log(error);
      });
  }
}
