import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild, NgModule} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe, formatDate, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { DivDealerGroupComponent } from 'src/app/shared/components/ticket/div-dealer-group/div-dealer-group.component';
import { DivVehicleGroupComponent } from 'src/app/shared/components/ticket/div-vehicle-group/div-vehicle-group.component';
import { DivRequestGroupComponent } from 'src/app/shared/components/ticket/div-request-group/div-request-group.component';
import { DivDiagnosisGroupComponent } from 'src/app/shared/components/ticket/div-diagnosis-group/div-diagnosis-group.component';
import { DivDiagnosisTroubleGroupComponent } from 'src/app/shared/components/ticket/div-diagnosis-trouble-group/div-diagnosis-trouble-group.component';
import { DivPartCauseGroupComponent } from 'src/app/shared/components/ticket/div-part-cause-group/div-part-cause-group.component';
import { DivAccessoryGroupComponent } from 'src/app/shared/components/ticket/div-accessory-group/div-accessory-group.component';
import { DivRepairHistoryGroupComponent } from 'src/app/shared/components/ticket/div-repair-history-group/div-repair-history-group.component';
import { DivFeedbackGroupComponent } from 'src/app/shared/components/ticket/div-feedback-group/div-feedback-group.component';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { TicketEncaminharComponent } from 'src/app/administration/ticket/ticket-encaminhar/ticket-encaminhar.component';

import { Ticket } from '../ticket';

import { TicketCrudService } from 'src/app/services/ticket-crud-service';
import { UploadService } from 'src/app/services/upload-service';
import { UserService } from 'src/app/core/auth/user.service';

@Component({
  templateUrl: './ticket-create.component.html'
})
export class TicketCreateComponent implements OnInit  {

  form: FormGroup;
  filterMap: Map<string, string> = new Map<string, string>();
  ticket: Ticket;
  file: File;
  id: number;
  edit: boolean;
  readOnly: boolean;
  isTdbUser: boolean;
  canClose: boolean;
  importOsFileRequired: boolean;

  @ViewChild(DivDealerGroupComponent)
  dealerComponent: DivDealerGroupComponent;

  @ViewChild(DivVehicleGroupComponent)
  vehicleComponent: DivVehicleGroupComponent;

  @ViewChild(DivRequestGroupComponent)
  requestComponent: DivRequestGroupComponent;

  @ViewChild(DivDiagnosisGroupComponent)
  diagnosisComponent: DivDiagnosisGroupComponent;

  @ViewChild(DivDiagnosisTroubleGroupComponent)
  diagnosisTroubleComponent: DivDiagnosisTroubleGroupComponent;

  @ViewChild(DivPartCauseGroupComponent)
  partCauseComponent: DivPartCauseGroupComponent;

  @ViewChild(DivAccessoryGroupComponent)
  accessoryComponent: DivAccessoryGroupComponent;

  @ViewChild(DivRepairHistoryGroupComponent)
  repairHistoryComponent: DivRepairHistoryGroupComponent;

  @ViewChild(DivFeedbackGroupComponent)
  feedbackComponent: DivFeedbackGroupComponent;

  private modalOptions:NgbModalOptions;

  constructor(
    private formBuilder: FormBuilder,
    private alertService: AlertService,
    public stateService: StateService,
    private router: Router,
    public datepipe: DatePipe,
    private spinnerService: NgxSpinnerService,
    private ticketCrudService: TicketCrudService,
    private uploadService: UploadService,
    private route: ActivatedRoute,
    private _location: Location,
    private userService: UserService,
    private modalService: NgbModal
  ) {
    this.modalOptions = {
      backdrop:'static',
      backdropClass:'customBackdrop'
    }
  }

  ngOnInit(): void {
    console.log('init ticket');
    this.id = this.route.snapshot.params.ticketId;
    this.importOsFileRequired = this.userService.getImportOsFileRequired();

    if (this.id) {
      this.ticket = this.stateService.retrieveTicket();
    } else {
      this.ticket = new Ticket();
      this.ticket.createUser = this.userService.getUserLogin();
      this.ticket.createUserName = this.userService.getUserName();
      this.stateService.ticket = this.ticket;
    }
    this.readOnly = false;
    if(this.userService.checkReadOnlyEtads()){
      this.edit = false;
      this.readOnly = true;
    }else{
      let profile = this.userService.getProfile();
      this.edit = ( (profile == this.ticket.currentProfile || profile == this.userService.adminProfile || !this.ticket.currentProfile) && this.ticket.status != 'Encerrado');
    }
    this.stateService.storeEdit(this.edit);
    this.isTdbUser = this.userService.isTdbUser();

    if(this.userService.isDealerUser() && (this.ticket.unfounded || this.ticket.closeReleased))
      this.canClose = true;
    else
      this.canClose = false;

    this.createForm();
    this.spinnerService.show();
    this.alertService.clear();
    this.filterMap.set("CHAMADO E-TADS", "TOYOTA");


    this.populateForm();
    setTimeout(() => {

    }, 3000);

    if(this.userService.isDealerUser()){
      this.form.get("closeReleased").disable();
      this.form.get("unfounded").disable();
      this.form.get("incorrect").disable();
      this.form.get("pnToyota").disable();
    }

    this.spinnerService.hide();

  }

  createForm() {
    this.form = this.formBuilder.group({
      createUser: [null, null],
      createUserName: [null, null],
      analyst: [null, null],
      analystName: [null, null],
      ticketId: [null, null],
      status: [null, null],
      osImportDate: [null, null],
      osImportFileName: [null, null],
      pnToyota: [{value: null, disabled: !this.edit}],
      unfounded: [{value: null, disabled: !this.edit}],
      incorrect: [{value: null, disabled: !this.edit}],
      closeReleased: [{value: null, disabled: !this.edit}],
      createDate: [null, null],
      openDate: [null, null],
      closeDate: [null, null]
    });
  }

  populateForm() {

    if (this.id) {
      this.form.patchValue({
        createUser: this.ticket.createUser,
        createUserName: this.ticket.createUserName,
        analyst: this.ticket.analyst,
        analystName: this.ticket.analystName,
        ticketId: this.ticket.ticketId,
        status: this.ticket.status,
        osImportDate: this.ticket.osImportDate,
        osImportFileName: this.ticket.osImportFileName,
        pnToyota: this.ticket.pnToyota,
        unfounded: this.ticket.unfounded,
        incorrect: this.ticket.incorrect,
        closeReleased: this.ticket.closeReleased,
        createDate: this.ticket.createDate,
        openDate: (this.ticket.openDate) ? formatDate(this.ticket.openDate,'dd/MM/yyyy HH:mm','en') : '',
        closeDate: (this.ticket.closeDate) ? formatDate(this.ticket.closeDate,'dd/MM/yyyy HH:mm','en') : ''
      });
    }

  }

  actionEncaminhar() {
    const modalRef = this.modalService.open(TicketEncaminharComponent);
    modalRef.componentInstance.modal_title = 'Encaminhar Chamado';
    modalRef.componentInstance.modal_content = 'Selecione um Usuário';
    modalRef.componentInstance.selectedTicket = this.ticket;
    this.ticket.updateUser = this.userService.getUserLogin();
    this.ticket.updateUserName = this.userService.getUserName();

    modalRef.result.then((result) => {
      const res : string = `${result}`;
      if (res == "mr_save"){
        this.ticketCrudService.allocateTicket(this.ticket, false).subscribe(
          (data) => {
            this.spinnerService.hide();
            this.router.navigate(["ticket-pending-tdb"]);
          },
          (error) => {
            if (error.error.errorName = "DuplicatedInfo") {
              this.alertService.danger(error.error.errorMessage);
              this.spinnerService.hide();
            }
          }
        );
      }
    })
  }

  actionSave() {
    console.log("save");
    this.actionSubmit();

    if(!this.ticket.currentProfile)
      this.ticket.currentProfile = this.userService.getProfile();

    this.ticket.updateUser = this.userService.getUserLogin();
    this.ticket.updateUserName = this.userService.getUserName();
    this.ticketCrudService.save(this.ticket, false).subscribe(
      (data) => {
        console.log(data);
        this.spinnerService.hide();
        if(this.ticket.status == 'Rascunho')
          this.router.navigate(["ticket-draft"]);
        else
          this.router.navigate(["ticket-open"]);
      },
      (error) => {
        if (error.error.errorName = "DuplicatedInfo") {
          this.alertService.danger(error.error.errorMessage);
          this.spinnerService.hide();
        }
      }
    );
  }

  actionSend() {
    console.log("send");

    this.actionSubmit();
    this.ticket.updateUser = this.userService.getUserLogin();
    this.ticket.updateUserName = this.userService.getUserName();

    let send = true;
    let lastprofile = this.ticket.currentProfile;
    if(this.ticket.ticketId && this.ticket.currentProfile != this.userService.getProfile()){
      send = false;
    }

    if(send){
      this.ticketCrudService.send(this.ticket, true).subscribe(
        (data) => {
          console.log(data);
          this.spinnerService.hide();
          if(this.ticket.currentProfile == this.userService.tdbProfile || this.ticket.currentProfile  == this.userService.adminProfile)
            this.router.navigate(["ticket-sent"]);
          else
            this.router.navigate(["ticket-open"]);
        },
        (error) => {
          this.ticket.currentProfile = lastprofile;
          if (error.error.errorName = "DuplicatedInfo") {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
          }
        }
      );
    }else{
      this.ticket.sendDate = new Date();
      this.ticketCrudService.save(this.ticket, false).subscribe(
        (data) => {
          console.log(data);
          this.spinnerService.hide();
          if(this.ticket.status == 'Rascunho')
            this.router.navigate(["ticket-draft"]);
          else
            this.router.navigate(["ticket-open"]);
        },
        (error) => {
          if (error.error.errorName = "DuplicatedInfo") {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
          }
        }
      );
    }
  }

  actionRemove(){

    this.ticketCrudService.del(this.ticket).subscribe(
      (data) => {
        console.log(data);
        this.spinnerService.hide();
        this._location.back();
      },
      (error) => {
          this.alertService.danger(error.error.errorMessage);
          this.spinnerService.hide();
      }
    );
  }

  actionClose(){

    this.actionSubmit();

    this.ticketCrudService.close(this.ticket).subscribe(
      (data) => {
        console.log(data);
        this.spinnerService.hide();
        this.router.navigate(["ticket-close"]);
      },
      (error) => {
          this.alertService.danger(error.error.errorMessage);
          this.spinnerService.hide();
      }
    );
  }

  actionSubmit() {
    this.alertService.clear();
    this.spinnerService.show();

    this.dealerComponent.actionSubmitDealer();
    this.vehicleComponent.actionSubmitVehicle();
    this.requestComponent.actionSubmitRequest();
    this.diagnosisComponent.actionSubmitDiagnosis();
    this.diagnosisTroubleComponent.actionSubmitDiagnosisTrouble();
    this.partCauseComponent.actionSubmitPartCause();
    this.accessoryComponent.actionSubmitAccessory();
    this.repairHistoryComponent.actionSubmitRepairHistory();
    this.feedbackComponent.actionSubmitFeedback();

    this.ticket.osImportDate = this.form.get("osImportDate").value;
    this.ticket.osImportFileName = this.form.get("osImportFileName").value;
    this.ticket.updateUser = this.userService.getUserLogin();
    this.ticket.updateUserName = this.userService.getUserName();
    this.ticket.closeReleased = this.form.get("closeReleased").value;
    this.ticket.unfounded = this.form.get("unfounded").value;
    this.ticket.incorrect = this.form.get("incorrect").value;
    this.ticket.pnToyota = this.form.get("pnToyota").value;

    this.ticket.dealerGroup = this.dealerComponent.dealerGroup;
    this.ticket.vehicleGroup = this.vehicleComponent.vehicleGroup;
    this.ticket.requestGroup = this.requestComponent.requestGroup;
    this.ticket.diagnosisGroup = this.diagnosisComponent.diagnosisGroup;
    this.ticket.listDiagnosisTroubleGroup = this.diagnosisTroubleComponent.listDiagnosisTrouble;
    this.ticket.listPartCauseGroup = this.partCauseComponent.listPartCauseGroup;
    this.ticket.listAccessoryGroup = this.accessoryComponent.listAccessoryGroup;
    this.ticket.repairHistoryGroup = this.repairHistoryComponent.repairHistoryGroup;
    this.ticket.listFeedbackGroup = this.feedbackComponent.listFeedbackGroup;

    this.ticket.hasDtc = this.diagnosisTroubleComponent.hasDtc;
  }

  validForm(): boolean {
    let valid = false;

    valid = this.dealerComponent?.dealerForm.valid &&
      this.vehicleComponent?.vehicleForm.valid &&
      this.requestComponent?.requestForm.valid &&
      this.diagnosisComponent?.diagnosisForm.valid &&
      this.diagnosisTroubleComponent?.diagnosisTroubleForm.valid &&
      this.partCauseComponent?.partCauseForm.valid &&
      this.accessoryComponent?.accessoryForm.valid &&
      this.repairHistoryComponent?.repairHistoryForm.valid &&
      this.feedbackComponent?.feedbackForm.valid;

    return true;

  }

  getISODate(date: Date): String{
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    return (new Date(date.getTime() - tzoffset)).toISOString().slice(0, 16);
  }

  onFileSelected(event: any) {

    if(!this.edit)
      return;

    let file: File = event.target.files[0];

    if (file) {
      this.form.get("osImportFileName").setValue(file.name);
      this.form.get("osImportDate").setValue(this.getISODate(new Date()));
      this.vehicleComponent.osFile = true;
      this.diagnosisComponent.osFile = true;

      this.file = file;

      file.text().then(r => {
        r.split(/\r\n|\n/).forEach(l => {

          if (l.substr(0, 1) == "O") {
            const dtStartStr = l.substr(10, 16);

            this.vehicleComponent.vehicleForm.get("os").setValue(Number(l.substr(1, 9)));
            this.vehicleComponent.vehicleForm.get("dtStart").setValue(this.getISODate(new Date(dtStartStr.substr(3, 3) + dtStartStr.substr(0, 3) + dtStartStr.substr(6))));
            this.vehicleComponent.vehicleForm.get("chassi").setValue(l.substr(42, 17));
            this.vehicleComponent.vehicleForm.get("km").setValue(Number(l.substr(59, 7)));
            this.vehicleComponent.vehicleForm.get("typeOs").setValue(l.substr(126, 3).trim());

            this.diagnosisComponent.diagnosisForm.get("clientComplaint").setValue(l.substr(66, 60));
            this.diagnosisComponent.diagnosisForm.get("symptomDetail").setValue(l.substr(190, 200));
            this.diagnosisComponent.diagnosisForm.get("diagnosisResult").setValue(l.substr(390, 200));

            this.vehicleComponent.actionChangeChassi();
          }

        });
      });
    }
  }

}
