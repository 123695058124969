import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './area-related-remove.component.html'
})
export class AreaRelatedRemoveComponent implements OnInit {


    form: FormGroup;    
    areaRelatedId : number;
    messageLabel : string;
    areaRelatedToDel : AreaRelated;
    subscription : Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private areaRelatedCrudService: AreaRelatedCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.areaRelatedId = this.route.snapshot.params.areaRelatedId;
        this.areaRelatedToDel = this.stateService.retrieveAreaRelated();
        
        this.alertService.warning("Deseja excluir a Área Relacionada: " + this.areaRelatedToDel.areaDescription + " ?");

        this.form = this.formBuilder.group({
            areaRelatedId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.areaRelatedCrudService.del(this.areaRelatedToDel).subscribe (
            (data) => {
                this.stateService.storeAlertSuccessMessage(data.message);
                this.spinnerService.hide();
                this.actionBack();
            },
            (error) => {
                this.stateService.storeAlertSuccessMessage(error.message);
                this.spinnerService.hide();
        });

        this.spinnerService.hide();
        this.actionBack();

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['area-related-list']);
    }

}
