import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { StateService } from 'src/app/services/state.service';
import { DiagnosisTroubleGroup } from './diagnosis-trouble-group';

@Component({
  selector: 'etads-div-diagnosis-trouble-group',
  templateUrl: './div-diagnosis-trouble-group.component.html',

})
export class DivDiagnosisTroubleGroupComponent {

  diagnosisTroubleForm: FormGroup;
  diagnosisTroubleGroup: DiagnosisTroubleGroup;

  listDiagnosisTrouble: DiagnosisTroubleGroup[];
  hasDtc: string;

  constructor(
    private stateService: StateService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.listDiagnosisTrouble = [];

    let listDiagnosisTroubleGroup: DiagnosisTroubleGroup[] = this.stateService.retrieveTicket().listDiagnosisTroubleGroup;
    this.listDiagnosisTrouble = listDiagnosisTroubleGroup;

    if(!this.stateService.retrieveEdit())
      this.diagnosisTroubleForm.disable();

      this.diagnosisTroubleForm.patchValue({
        hasDtc:  this.stateService.retrieveTicket().hasDtc
      });
  }

  createForm() {
    this.diagnosisTroubleForm = this.formBuilder.group({
      code: [null, null],
      troubleDescription: [null, null],
      troubleStatus: [null, null],
      hasDtc: ['Sim', Validators.required],
    });
  }

  actionSubmitDiagnosisTrouble() {
    console.log("submit diagnosis trouble");
    this.hasDtc = this.diagnosisTroubleForm.get("hasDtc").value;
  }

  actionAdd() {
    this.diagnosisTroubleGroup = this.diagnosisTroubleForm.getRawValue() as DiagnosisTroubleGroup;
    if(!this.diagnosisTroubleGroup || !this.diagnosisTroubleGroup.code || !this.stateService.retrieveEdit())
      return;
    if(!this.listDiagnosisTrouble)
      this.listDiagnosisTrouble = [this.diagnosisTroubleGroup];
    else
      this.listDiagnosisTrouble.push(this.diagnosisTroubleGroup);
    this.diagnosisTroubleForm.get("code").setValue(null);
    this.diagnosisTroubleForm.get("troubleDescription").setValue(null);
    this.diagnosisTroubleForm.get("troubleStatus").setValue(null);
  }

  actionRemove(item: DiagnosisTroubleGroup) {
    if(!this.stateService.retrieveEdit())
      return;
    this.listDiagnosisTrouble.splice(this.listDiagnosisTrouble.indexOf(item), 1);
  }
}
