import { Component } from '@angular/core';

@Component({
    selector: 'etads-card-box',
    templateUrl: './card-box.component.html',
    styleUrls: ['./card-box.component.css']
})
export class CardBoxComponent { 

    constructor() {}
    
}