import { Component, Input, EventEmitter, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Radio } from '../../../../interfaces/radio';

@Component({
    selector: 'etads-input-radio',
    templateUrl: './input-radio.component.html',

})
export class InputRadioButtonComponent { 

    @Input() formGroup: FormGroup;
    @Input() controlName: string;
    @Input() radioList: Radio[];
 
    @Output() changeEmmiter = new EventEmitter(); 

    actionChange(value: any) { 
       this.changeEmmiter.emit(); 
    } 

}