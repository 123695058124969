<!--p-card>
    <ngx-spinner></ngx-spinner>
    <etads-alert></etads-alert>
    <ng-content></ng-content>
</p-card-->

<div class="card" styleClass="dark-panel" id="p-card-content" >
    <div class="card-body">
        <ngx-spinner></ngx-spinner>
        <etads-alert></etads-alert>
        <ng-content></ng-content>
    </div>
</div>