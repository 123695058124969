import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { Distributor, DistributorForm, EtadsDistributor } from '../../../interfaces/distributor';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './distributor-create.component.html'
})
export class DistributorCreateComponent implements OnInit {

    form:FormGroup;
    distributorId: number;
    distributorList: Distributor[];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private distributorCrudService: DistributorCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Distribuidor", "");
        this.loadData();
        this.createForm();


    }

    loadData() {
        this.distributorList = this.stateService.retrieveDistributorList();
        this.spinnerService.hide();
    }

    createForm () {
        this.form = this.formBuilder.group({
            distributorName: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(20) ] ],
            distributorCode: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(7) ] ],
            importRequired: [true , null ],
        });
    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Distribuidor Inválido ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const distForm = this.form.getRawValue() as DistributorForm;
        let newDistributor = new EtadsDistributor();

        newDistributor.distributorName = distForm.distributorName.valueOf().toUpperCase();
        newDistributor.distributorCode = distForm.distributorCode.valueOf();
        newDistributor.importRequired = distForm.importRequired.valueOf();

        this.subscription = this.distributorCrudService.add(newDistributor).subscribe((data) => {
            let idList = this.distributorList.push(newDistributor);
            this.stateService.storeDistributorList(this.distributorList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });


    }

    actionBack() {
        this.router.navigate(['distributor-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newDist = this.form.getRawValue() as DistributorForm;

        for (let distData of this.distributorList) {
            let parse1 = distData.distributorName.toUpperCase();
            let parse2 = newDist.distributorName.toUpperCase();
            let parse3 = distData.distributorCode;
            let parse4 = newDist.distributorCode;

            if (parse1 == parse2) {
                return false;
            }

            if (parse3 == parse4) {
                return false;
            }

        }
        return true;

    }

}
