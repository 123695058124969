import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { StateService } from '../../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../../shared/components/components.module';
import { DirectImportChassis, EtadsDirectImportChassis,  DirectImportChassisForm } from '../../../../interfaces/direct-import-chassis';
import { DirectImportChassiCrudService } from '../../../../services/direct-import-chassis-crud.service';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './chassis-create.component.html'
})

export class DirectImportChassisCreateComponent implements OnInit {

    form: FormGroup;
    chassisId: number;
    filterMap: Map<string,string> = new Map<string, string>();
    dichassi: DirectImportChassis;
    chassisList : DirectImportChassis[] = [] ;
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private directImportChassiCrudService: DirectImportChassiCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Chassi", "");
        this.loadData();
        this.createForm();
        this.spinnerService.hide();
    }

    loadData() {

       this.chassisList = this.stateService.retrieveChassisList();
    }

    createForm() {

        this.form = this.formBuilder.group( {
            chassi:    ['',  [ Validators.required, Validators.minLength(17), Validators.maxLength(17)  ] ,   ],
            vehicle:   ['',  [ Validators.required, Validators.minLength(4),  Validators.maxLength(50)   ] ,   ],
            model:     ['',  [ Validators.required, Validators.minLength(4),  Validators.maxLength(50)   ] ,   ],
            modelyear: ['',  [ Validators.required, Validators.minLength(4),  Validators.maxLength(4)   ] ,   ],
            production_date: ['',  [ Validators.required  ] ,   ],
            sales_date:      ['',  [ Validators.required,  ] ,   ],
            motor_number:    ['',  [ Validators.required,Validators.maxLength(50) ] ,   ],
            transmission:    ['null',  [ Validators.required ] ,   ]

        });
    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Chassi Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const dataform   = this.form.getRawValue() as DirectImportChassisForm;
        let dataToInsert = new EtadsDirectImportChassis();

        dataToInsert.chassi          = dataform.chassi.toUpperCase();
        dataToInsert.vehicle         = dataform.vehicle.toUpperCase();
        dataToInsert.model           = dataform.model.toUpperCase();
        dataToInsert.modelyear       = dataform.modelyear;
        dataToInsert.production_date = this.datepipe.transform(dataform.production_date, 'yyyy-MM-dd');
        dataToInsert.sales_date      = this.datepipe.transform(dataform.sales_date, 'yyyy-MM-dd');
        dataToInsert.motor_number    = dataform.motor_number.toUpperCase();
        dataToInsert.transmission    = dataform.transmission;

        this.subscription = this.directImportChassiCrudService.add(dataToInsert).subscribe(
            (data ) => {
                let idList = this.chassisList.push(dataToInsert);
                this.stateService.storeChassisList(this.chassisList);
                this.stateService.storeAlertSuccessMessage("Dados cadastrados com Sucesso.");
                this.spinnerService.hide();
                dataToInsert = null;
                this.actionBack();
            },
            (error) => {
                this.stateService.storeChassisList(this.chassisList);
                this.alertService.danger(error.error.errorMessage);
                this.spinnerService.hide();
            });
    }

    actionBack() {
        this.router.navigate(['chassis-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newChassi = this.form.getRawValue() as DirectImportChassisForm;

            for (let chData of this.chassisList) {
               let parse1 = chData.chassi.toUpperCase();
               let parse2 = newChassi.chassi.toUpperCase();

               if (parse1 == parse2) {
                   return false;
               }
           }

        return true;
    }

}
