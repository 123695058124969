<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle>
            <tr>
                <th>Distribuidor</th>
                <th>Código</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let distdata of distributorList | paginate : {itemsPerPage: itemsPerPage, currentPage:currentPage } ">
                <td>{{ distdata.distributorName }}</td>
                <td>{{ distdata.distributorCode }}</td>
                <td>
                    <etads-tooltip label="Editar Distribuidor">
                        <etads-icon-edit (clickEmmiter)="actionEdit(distdata)" 
                            link="distributor-edit" [parameter]="distdata.distributorId" >
                        </etads-icon-edit> 
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Editar Distribuidor">
                        <etads-icon-remove (clickEmmiter)="actionRemove(distdata)" 
                            link="distributor-remove" [parameter]="distdata.distributorId" >
                        </etads-icon-remove> 
                    </etads-tooltip>
                </td>
            </tr>     
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Distribuidor "></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>

</etads-card-list>