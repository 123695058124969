// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { version } from '../../package.json';

export const environment = {
  production: false,
  //ApiUrl: 'http://localhost:7071/api/' ,
  ApiUrl: 'https://etads-functions.azurewebsites.net/api/' ,
  HomeUrl: 'https://etads.toyota.com.br/',
  //HomeUrl: 'https://orange-forest-07db29210.1.azurestaticapps.net/',
  //ApiUrl: 'https://etads-functions-homologacao.azurewebsites.net/api/' ,
  //HomeUrl: 'https://orange-forest-07db29210.1.azurestaticapps.net/',
  clientId: '6326e477-3efe-44f5-8043-420049b57773',
  apiScope: '',
  version: version ,
  environment : 'DEVELOPMENT',
  containerName: 'etads',
  sasToken: 'sv=2021-06-08&ss=bfqt&srt=sco&sp=rwdlacupitfx&se=2099-06-20T03:26:31Z&st=2022-06-19T19:26:31Z&spr=https&sig=xoMXIIKd%2BZLGgMxDN1BHAZF3euCkznZQOiHA0li0ixY%3D',
  storageUrl: 'https://prdstoetads.blob.core.windows.net?'

};

/*
 * For easier debugging in development mode, you can import the following files
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
