import { Component, Input, Output, EventEmitter} from '@angular/core'; 

@Component({
    selector: 'etads-button-del',
    templateUrl: './button-del.component.html',
    styleUrls: ['./button-del.component.css']
})
export class ButtonDelComponent { 

    constructor() {}

    @Input() label = 'Excluir';

    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}