import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[saTheadStyle]',
})
export class TheadStyle {

  constructor(private renderer: Renderer2, hostElement: ElementRef) {
    renderer.addClass(hostElement.nativeElement, 'thead-light');
  }
}