<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Prioridade</th>
                <th>Descrição</th>
                <th>Sequência</th>
                <th>Situação</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let pdata of priorityList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{ pdata.initials }}</td>
                <td>{{ pdata.alias }}</td>
                <td>{{ pdata.sequence }}</td>
                <td>{{ ( pdata.active === true ) ? "Ativo" : "Inativo"  }}</td>

                <td>
                    <etads-tooltip label="Editar Prioridade" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(pdata)"
                            link="priority-edit" [parameter]="pdata.priorityId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Prioridade" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(pdata)"
                            link="priority-remove" [parameter]="pdata.priorityId">
                        </etads-icon-remove>
                    </etads-tooltip>
                </td>

            </tr>
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls
            (pageChange)="currentPage = $event"
            previousLabel="Anterior"
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Nova Prioridade"></etads-button-add>
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
