<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle>
            <tr>
                <th>Área Relacionada</th>
                <th>Situação</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let areadata of areaRelatedList | paginate : {itemsPerPage: itemsPerPage, currentPage:currentPage } ">
                <td>{{ areadata.areaDescription }}</td>
                <td>{{ (areadata.active === true ) ? "Ativo" : "Inativo"  }}</td>
                <td>
                    <etads-tooltip label="Editar Área">
                        <etads-icon-edit (clickEmmiter)="actionEdit(areadata)" 
                            link="area-related-edit" [parameter]="areadata.areaRelatedId" >
                        </etads-icon-edit> 
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Editar Área">
                        <etads-icon-remove (clickEmmiter)="actionRemove(areadata)" 
                            link="area-related-remove" [parameter]="areadata.areaRelatedId" >
                        </etads-icon-remove> 
                    </etads-tooltip>
                </td>
            </tr>     
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Nova Área Relacionada"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>

</etads-card-list>