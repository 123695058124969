<li class="nav-item active dropdown"  [style.display]="(!visibleOnlyTdbUser || isTdbUser) && !hideMenu? 'inherit' : 'none'" >
  <a class="nav-link dropdown-toggle"  [id]="navbarDropdownId"

    role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{ label}}
  </a>
  <div class="dropdown-menu" [id]="dropDowmMenuId" >
    <ng-content></ng-content>
  </div>
</li>
