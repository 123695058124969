import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { ElibManual, ElibManualForm, EtadsElibManual } from '../../../interfaces/elib-manual';
import { ElibManualCrudService } from '../../../services/elib-manual-crud.service';
import { ElibBrand, EtadsElibBrand } from '../../../interfaces/elib-brand';
import { ElibModel, EtadsElibModel } from '../../../interfaces/elib-model';
import { ElibBrandCrudService } from '../../../services/elib-brand-crud.service';
import { ElibModelCrudService } from '../../../services/elib-model-crud.service';


@Component ({
    templateUrl: './elib-manual-edit.component.html'
})

export class ElibManualEditComponent implements OnInit {

    form: FormGroup;
    manualId : Number;
    filterMap: Map<string, string > = new Map<string, string>();
    elibManual: ElibManual;
    manualList: ElibManual[];
    listBrand: ElibBrand[];
    listModel: ElibModel[];
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private elibManualCrudService : ElibManualCrudService,
        private elibBrandCrudService: ElibBrandCrudService,
        private elibModelCrudService: ElibModelCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService) {}



    ngOnInit(): void {

        this.alertService.clear();
        this.filterMap.set("Editar Marca", "");
        this.manualId = this.route.snapshot.params.manualId;
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();


    }
    loadData() {

        this.elibManual = this.stateService.retrieveElibManual();
        this.manualList = this.stateService.retrieveElibManualList();
        this.listBrand = [];

        this.elibBrandCrudService.get().subscribe(lst => {
            this.listBrand = lst;
        }, error => {
            console.log(error);
        });

        this.listModel = [];

        this.elibModelCrudService.get().subscribe(lst => {
            this.listModel = lst;
        }, error => {
            console.log(error);
        });
    }

    createForm () {
      this.form = this.formBuilder.group({
        name: ['',   [] ],
        code: ['',   [  Validators.required,  Validators.minLength(3)  ] ],
        description: ['',   [] ],
        startPage: ['',   [  Validators.required  ] ],
        brandId: [null,   [  Validators.required  ] ],
        modelId: [null,   [  Validators.required  ] ],
        startDate: [null,   [  Validators.required ] ],
        endDate: [null,   [] ],
        hide: [null,   [  Validators.required ] ]
      });
    }


    updateForm() {
        this.form.patchValue({description:this.elibManual.description});
        this.form.patchValue({name:  this.elibManual.name});
        this.form.patchValue({code: this.elibManual.code});
        this.form.patchValue({startPage: this.elibManual.startPage});
        this.form.patchValue({brandId: this.elibManual.brand?.brandId});
        this.form.patchValue({modelId: this.elibManual.model?.modelId});
        this.form.patchValue({startDate: this.elibManual.startDate});
        this.form.patchValue({endDate: this.elibManual.endDate});
        this.form.patchValue({hide: this.elibManual.hide});
    }


    actionBack() {
        this.router.navigate(['elib-manual-list'])
    }

    actionClear() {
        this.ngOnInit();
    }

    actionSubmit () {

      this.alertService.clear();

      if (!this.validateForm()) {
          this.alertService.danger("Manual Inválido ou já existente!");
          return;
      } else {
          this.alertService.clear();
      }

      this.spinnerService.show();

      const prForm = this.form.getRawValue() as ElibManualForm;
      let pxdata = new EtadsElibManual() ;

      pxdata.id = this.elibManual.id;
      pxdata.manualId = this.elibManual.manualId;
      pxdata.name = prForm.name;
      pxdata.code = prForm.code;
      pxdata.description = prForm.description;
      pxdata.startPage = prForm.startPage;
      pxdata.startDate = prForm.startDate;
      pxdata.endDate = prForm.endDate;
      pxdata.hide = prForm.hide;
      pxdata.statusUpload = this.elibManual.statusUpload;
      pxdata.url = this.elibManual.url;
      let brand = this.listBrand.filter(t => t.brandId === Number(prForm.brandId))
      if (brand.length > 0) {
        pxdata.brand = new EtadsElibBrand();
        pxdata.brand.brandId = brand[0].brandId;
        pxdata.brand.description = brand[0].description;
      }
      let model = this.listModel.filter(t => t.modelId === Number(prForm.modelId))
      if (model.length > 0) {
        pxdata.model = new EtadsElibModel();
        pxdata.model.modelId = model[0].modelId;
        pxdata.model.description = model[0].description;
      }

        this.subscription = this.elibManualCrudService.upd(this.elibManual, pxdata).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
            this.reloadCurrentRoute();
          }
        );
    }

    validateForm() {
        const newPr = this.form.getRawValue() as ElibManualForm;

        for (let prData of this.manualList) {
             let parse1 = prData.description.toUpperCase();
             let parse2 = newPr.description.toUpperCase();

            if (parse1 == parse2 && this.manualId != prData.manualId) {
                return false;
            }
        }
        return true;
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
