<form [formGroup]="partCauseForm" (submit)="actionSubmitPartCause()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Peça Causadora"></etads-label>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-2">
        <etads-label label="Código"></etads-label>
        <etads-input-text [formGroup]="partCauseForm" controlName="partCode" placeholder="" (change)="actionChangePartCode()"></etads-input-text>
      </div>
      <div class="form-group col">
        <etads-label label="Descrição"></etads-label>
        <etads-input-text [formGroup]="partCauseForm" controlName="partDescription" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-2">
        <etads-label label="Status"></etads-label>
        <etads-input-select (change)="actionChangePartStatus()" [formGroup]="partCauseForm" firstOptionLabel="Selecione"
          controlName="partStatus">
          <option value="Não substituída">Não substituída</option>
          <option value="Armazenada">Armazenada</option>
          <option value="Enviada a Toyota">Enviada a Toyota</option>
        </etads-input-select>
      </div>
      <div class="form-group col-2">
        <etads-label label="NF de envio"></etads-label>
        <etads-input-text [formGroup]="partCauseForm" controlName="nfPart" placeholder="" *ngIf="sentToToyota"></etads-input-text>
      </div>
      <div class="form-group col-2">
        <etads-label label="Emissão da NF"></etads-label>
        <etads-input-date [formGroup]="partCauseForm" controlName="dateNfPart" *ngIf="sentToToyota"></etads-input-date>
      </div>
      <div class="form-group">
        <etads-label label="Add"></etads-label>
        <button type="button" id="btnpart" class="form-control btn btn-info" (click)='actionAdd()'> + </button>
      </div>
    </div>

    <etads-table class="table table-striped px-2">
      <thead>
        <tr>
          <th class="col-2">Código</th>
          <th class="col-6">Descrição</th>
          <th class="col-1">Status</th>
          <th class="col-1">NF de envio</th>
          <th class="col-1">Emissão da NF</th>
          <th class="col-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let it of listPartCauseGroup">
          <td>{{ it.partCode }}</td>
          <td>{{ it.partDescription }}</td>
          <td>{{ it.partStatus }}</td>
          <td>{{ it.nfPart }}</td>
          <td>{{it.dateNfPart | date: 'dd/MM/yyyy'}}</td>
          <td>
            <button type="button" id="btnRemoveTrouble" class="form-control btn btn-info" (click)='actionRemove(it)'> X
            </button>
          </td>
        </tr>
      </tbody>
    </etads-table>
  </div>
</form>