import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsGroupTdbSchedule, GroupTdbSchedule, GroupTdbScheduleForm } from '../../../interfaces/group-tdb-schedule';
import { GroupTdbScheduleCrudService } from '../../../services/group-tdb-schedule-crud.service';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { Distributor, DistributorToInput } from '../../../interfaces/distributor';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './group-tdb-schedule-edit.component.html',
})

export class GroupTdbScheduleEditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    distributorList: DistributorToInput[]=[];
    distributorSelList: DistributorToInput[]=[];

    groupTdbSchedule: GroupTdbSchedule;
    groupTdbScheduleId: number;
    groupTdbScheduleList: EtadsGroupTdbSchedule[]=[];

    levelList: Radio[]=[];
    subscrtipiton : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private groupTdbScheduleCrudService: GroupTdbScheduleCrudService,
        private distributorCrudService: DistributorCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Escalonamento Grupo TDB", "");
        this.levelList = this.getLevelList();
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();
    }

    getLevelList() {

        let newRadioList : Radio[]=[];

        let opt: number;
        for (opt = 1; opt <= 3; opt++) {
            let optR = new EtadsRadio();
            optR.itemId = opt ;
            optR.itemLabel = opt.toString();
            let lst = newRadioList.push(optR);
        }
        return newRadioList;
    }

    loadData() {

        this.groupTdbScheduleId = this.route.snapshot.params.groupTdbScheduleId;
        this.groupTdbSchedule = this.stateService.retrieveGroupTdbSchedule();
        this.groupTdbScheduleList = this.stateService.retrieveGroupTdbScheduleList();

         // caregando lista de Distribuidores
         this.distributorList = [];
         this.distributorSelList = [];
         this.subscrtipiton = this.distributorCrudService.get().subscribe( lst => {

              for (let distx of lst) {
                  let dist = new DistributorToInput();
                  dist.distributorCode = distx.distributorCode;
                  dist.distributorName = distx.distributorName;
                  this.distributorList.push(dist);

                  for (let disL of this.groupTdbSchedule.distributor) {
                        if(disL == distx.distributorCode ) {
                            this.distributorSelList.push(dist);
                        }
                  }
              }
              this.spinnerService.hide();
         }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
         });

    }

    createForm() {

        this.form = this.formBuilder.group( {
            groupTdbScheduleTitle: ['',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            level:  ['', [Validators.required], ],
            email:  ['', [Validators.required], ],
            active: [null, [Validators.required], ] ,
            distributorToSelect: [this.distributorList, [], ],
            distributorSelected: [ '', [Validators.required], ],
            // this.distributorSelList
        });

    }

    updateForm() {
        this.form.patchValue({groupTdbScheduleTitle: this.groupTdbSchedule.groupTdbScheduleTitle});
        this.form.patchValue({level: this.groupTdbSchedule.level});
        this.form.patchValue({email: this.groupTdbSchedule.email});
        this.form.patchValue({active: this.groupTdbSchedule.active });
        this.form.patchValue({distributorToSelect: this.distributorList});
      //  this.form.patchValue({distributorSelected: this.distributorSelList});

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const gsform   = this.form.getRawValue() as GroupTdbScheduleForm;

        let gsToUpd = new EtadsGroupTdbSchedule();

        gsToUpd.id = this.groupTdbSchedule.id;
        gsToUpd.groupTdbScheduleId = this.groupTdbSchedule.groupTdbScheduleId;
        gsToUpd.groupTdbScheduleTitle = gsform.groupTdbScheduleTitle.toUpperCase();
        gsToUpd.level = gsform.level;
        gsToUpd.email = gsform.email.toString();
        gsToUpd.active = gsform.active;
        gsToUpd.distributor = [];

        // atribuindo selecionados
        for ( let disSel of this.distributorSelList) {
           gsToUpd.distributor.push(disSel.distributorCode.toString());
        }

        this.groupTdbScheduleCrudService.upd(this.groupTdbSchedule, gsToUpd).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            gsToUpd = null;
            this.actionBack();
        }, (error) => {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
        });
    }

     actionDistributorChange() {

     }

     actionDistributorSelectChange() {

    }

    actionAddDist() {

        this.spinnerService.show();

        if ( this.form.get('distributorToSelect').touched) {

            let listToAdd = this.form.get('distributorToSelect').value;

            if (listToAdd != null && listToAdd.length != 0)  {

                for (let distSe of listToAdd) {

                    if (distSe != null ) {

                        if ( ! this.distributorSelList.find(ds => ds.distributorCode === distSe)) {
                            let newDist = new DistributorToInput();
                            newDist.distributorCode = distSe;
                            newDist.distributorName = this.distributorList.find( dl => dl.distributorCode === distSe).distributorName;
                            this.distributorSelList.push(newDist);
                        }
                    }
                }
            }
        }

        this.form.patchValue({distributorSelected:this.distributorSelList});
        this.spinnerService.hide();

    }

    actionDelDist() {

        this.spinnerService.show();

        if (this.form.get('distributorSelected').touched) {

            const listSelect  = this.form.get('distributorSelected').value;

            if (listSelect != null && listSelect.length != 0 ) {
                for (let distDel of listSelect ){
                    if (distDel != null) {
                        let idx = this.distributorSelList.findIndex( regd => regd.distributorCode === distDel);
                        this.distributorSelList.splice(idx , 1);
                    }
                }
            }

            this.form.patchValue({distributorSelected: this.distributorSelList});
        }
        this.spinnerService.hide();
    }

    actionBack() {
        this.router.navigate(['group-tdb-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {

        const newSg = this.form.get('groupTdbScheduleTitle').value;

        for (let sgData of this.groupTdbScheduleList) {
            let parse1 = sgData.groupTdbScheduleTitle.toUpperCase();
            let parse2 = newSg.toUpperCase();

            if (parse1 == parse2 && sgData.groupTdbScheduleId != this.groupTdbScheduleId) {
                return false;
            }
        }

        return true;
    }

}
