<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Sintoma</th>
                <th>Grupo Sintoma</th>
                <th>Área Relacionada</th>
                <th>Prioridade</th>
                <th>Situação</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let symptom of symptomList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{ symptom.symptomDescription }}</td>
                <td>{{ symptom.symptomGroupId }} - {{ symptom.symptomGroupDescription }} </td>
                <td>{{ symptom.areaRelatedId }} - {{ symptom.areaRelatedDescription }} </td>
                <td>{{ symptom.initials }} </td>
                <td>{{ (symptom.active === true ) ? "Ativo" : "Inativo"  }}</td>

                <td>
                    <etads-tooltip label="Editar Sintoma" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(symptom)"
                            link="symptom-edit" [parameter]="symptom.symptomId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Sintoma" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(symptom)"
                            link="symptom-remove" [parameter]="symptom.symptomId">
                        </etads-icon-remove>
                    </etads-tooltip>
                </td>

            </tr>
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls
            (pageChange)="currentPage = $event"
            previousLabel="Anterior"
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Sintoma"></etads-button-add>
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
