import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DealerGroup } from './dealer-group';
import { DealerService } from 'src/app/services/dealer-service';
import { ConstantPool } from '@angular/compiler';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/core/auth/user.service';

@Component({
  selector: 'etads-div-dealer-group',
  templateUrl: './div-dealer-group.component.html',

})
export class DivDealerGroupComponent {
  dealerForm: FormGroup;
  dealerGroup: DealerGroup;

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private dealerService: DealerService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.createForm();

    let ticket = this.stateService.retrieveTicket()

    if(!ticket.dealerGroup || !ticket.dealerGroup.code){
      let dealerNumber = this.userService.getDealerNumber();
      
      if(dealerNumber == 'NA'){
        this.userService.getCompany().subscribe(company => {
          dealerNumber = company.value;
          this.loadDealerInfo(dealerNumber);
        }) 
      }else{
        this.loadDealerInfo(dealerNumber);
      }
      
    }
  

    let dealerGroup: DealerGroup = ticket.dealerGroup;
    ///console.log("Dealer Group: " + JSON.stringify(dealerGroup));
    if (dealerGroup != undefined){
      this.updateForm(dealerGroup);
    }
  }

  loadDealerInfo(dealerNumber: string){
    this.dealerService.getByCode(dealerNumber).subscribe(data => {
      this.dealerForm.get("code").setValue(dealerNumber);
      this.dealerForm.get("name").setValue(data.NOME);
      this.dealerForm.get("city").setValue(data.CIDADE);
      this.dealerForm.get("state").setValue(data.UF);

      
      this.userService.getAdditionalInfo().subscribe(info => {
        this.dealerForm.get("contact").setValue(this.userService.getUserName());
        this.dealerForm.get("telephone").setValue(info?.businessPhones[0]);
        this.dealerForm.get("cellphone").setValue(info?.mobilePhone);
        this.dealerForm.get("role").setValue(info?.jobTitle);

      });
  
    }, error => {
      console.log(error);
    });
  }

  createForm() {
    this.dealerForm = this.formBuilder.group({
      code: [null, null],
      name: [null, null],
      contact: [null, null],
      role: [null, null],
      telephone: [null, null],
      cellphone: [null, null],
      id: [null, null],
      state: [null, null],
      city: [null, null]
    });
  }

  updateForm(dealerGroup: DealerGroup) {
    this.dealerForm.patchValue({
      code: dealerGroup.code,
      name: dealerGroup.name,
      contact: dealerGroup.contact,
      role: dealerGroup.role,
      telephone: dealerGroup.telephone,
      cellphone: dealerGroup.cellphone,
      state: dealerGroup.state,
      city: dealerGroup.city
    });

  }

  actionSubmitDealer() {
    console.log(this.dealerForm.valid);
    console.log("submit dealer");

    this.dealerGroup = this.dealerForm.getRawValue() as DealerGroup;

  }
}
