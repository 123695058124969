export interface AnswerSchedule {
    id: string;
    answerScheduleId: number;
    answerScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
    created: string;
    createdUser: string;
    modified: String;
    modifiedUser: string;

}

export interface AnswerScheduleForm {
    answerScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
}

export class EtadsAnswerSchedule implements AnswerSchedule {
    id: string;
    answerScheduleId: number;
    answerScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
    created: string;
    createdUser: string;
    modified: String;
    modifiedUser: string;

}

