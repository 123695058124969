import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-tooltip',
    templateUrl: './tooltip.component.html'
})
export class TooltipComponent { 

    constructor() {}

    @Input() label = '';
    
}