import { Component, AfterViewInit, OnInit } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { UserService } from '../auth/user.service';
import * as $ from 'jquery';
import { NgxSpinnerService } from 'ngx-spinner';
import { StatusElibraryService } from '../../services/status-elibrary.service';
import { DistributorCrudService } from 'src/app/services/distributor-crud.service';



@Component({
  selector: 'etads-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements AfterViewInit, OnInit {

  loggedIn = true;
  isAdminUser: boolean = false;
  isTdbUser: boolean = false;
  isDealerUser: boolean = false;
  isDealerElibraryUSer: boolean = false;
  isTdbElibraryUser: boolean = false;
  readOnlyEtads: boolean = false;
  isOnlyBoletim: boolean = false;
  username: string;
  userlogin: string;

  constructor(
    private userService: UserService,
    private authService: MsalService,
    private statusElibraryService: StatusElibraryService,
    private distributorService: DistributorCrudService,
    private spinnerService: NgxSpinnerService
  ) { }

  ngOnInit() {
    this.spinnerService.show();


      this.checkAccount();

      this.authService.handleRedirectObservable().subscribe({
        next: (result) => console.log(result),
        error: (error) => console.log(error)
      });

      this.auth();
  }

  logout() {
    this.userService.logout();
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
    for (let account of this.authService.instance.getAllAccounts()) {
      this.username = account.name;
      this.userlogin = account.username;
      this.userService.storeUserName(this.username);
      this.userService.storeUserLogin(this.userlogin);
    }
  }

  async checkStatusElibrary() {

    await this.statusElibraryService.getStatusElibrary().subscribe(res =>{
      if (((this.isAdminUser || this.isTdbUser) &&  res["block_tdb"]) || (this.isDealerUser && res["block_dealer"]))
      {
        var element = <HTMLElement>document.getElementById('e_library_menu');
        element.style.display= 'none';
      }
    });

  }

  auth() {
    this.userService.loadAnalysts();
    this.userService.getCompany().subscribe(company => {
      try{
        this.isAdminUser = this.userService.checkAdminUser();
        this.isTdbUser = this.userService.checkTdbUser();
        this.isDealerUser = this.userService.checkDealerUser();
        this.isDealerElibraryUSer = this.userService.checkDealerElibrary();
        this.isTdbElibraryUser = this.userService.checkTdbElibrary();
        this.readOnlyEtads = this.userService.checkReadOnlyEtads();
        this.isOnlyBoletim = this.userService.checkOnlyBoletim();
        this.userService.storeIsAdminUser(this.isAdminUser);
        this.userService.storeIsTdbUser(this.isTdbUser);
        this.userService.storeIsDealerUser(this.isDealerUser);
        if (this.isTdbUser || this.readOnlyEtads) {
          this.userService.storeDealerNumber(null);
          this.userService.storeImportOsFileRequired(false);
        } else {
          this.userService.storeDealerNumber(company.value);
          let importOsFileRequired = true;
          try{
            this.distributorService.getByCode(company.value).subscribe(dist => {
              if(dist?.importRequired == false){
                importOsFileRequired = false;
              }
              this.userService.storeImportOsFileRequired(importOsFileRequired);
            });
          }catch{}
        }
      }catch(e){}

      try{
        this.checkStatusElibrary();
      }catch(e){}
    });

    this.userService.getAdditionalInfo().subscribe(info => {
      this.userService.storeUserContact(this.userService.getUserName());
      this.userService.storeUserBusinessPhone(info?.businessPhones[0]);
      this.userService.storeUserMobilePhone(info?.mobilePhone);
      this.userService.storeUserJobTitle(info?.jobTitle);
    });

  }

  ngAfterViewInit(): void {

    $(document).ready(function () {
      $('#headerId').mouseleave(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      // Menu Cadastros
      $('#navbarDropdown1').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideDown(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown1').mousedown(function () {
        $('#dropDowmMenu1').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu1').mouseleave(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
      });


      // Menu Chamados
      $('#navbarDropdown2').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideDown(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown2').mousedown(function () {
        $('#dropDowmMenu2').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu2').mouseleave(function () {
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
      });

      // Menu Consultas
      $('#navbarDropdown3').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideDown(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown3').mousedown(function () {
        $('#dropDowmMenu3').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu3').mouseleave(function () {
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
      });

      // Menu Toyota
      $('#navbarDropdown4').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideDown(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown4').mousedown(function () {
        $('#dropDowmMenu4').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu4').mouseleave(function () {
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
      });

      // Menu e-Library
      $('#navbarDropdown5').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideDown(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown5').mousedown(function () {
        $('#dropDowmMenu5').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu5').mouseleave(function () {
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown6').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideDown(300, "linear");
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown6').mousedown(function () {
        $('#dropDowmMenu6').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu6').mouseleave(function () {
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
      });

      $('#navbarDropdown7').mouseenter(function () {
        $('#dropDowmMenu1').stop().slideUp(300, "linear");
        $('#dropDowmMenu2').stop().slideUp(300, "linear");
        $('#dropDowmMenu3').stop().slideUp(300, "linear");
        $('#dropDowmMenu4').stop().slideUp(300, "linear");
        $('#dropDowmMenu5').stop().slideUp(300, "linear");
        $('#dropDowmMenu6').stop().slideUp(300, "linear");
        $('#dropDowmMenu7').stop().slideDown(300, "linear");
      });

      $('#navbarDropdown7').mousedown(function () {
        $('#dropDowmMenu7').stop().slideDown(300, "linear");
      });

      $('#dropDowmMenu7').mouseleave(function () {
        $('#dropDowmMenu7').stop().slideUp(300, "linear");
      });

    });

    this.spinnerService.hide();
  }


  navbarOpen = false;

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }




}
