import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-label-small',
    templateUrl: './label-small.component.html'
})
export class LabelSmallComponent { 

    constructor() {}

    @Input() label = '';
    
}