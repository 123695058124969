import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { AcessoriesCrudService } from '../../../services/acessories-crud.service';
import { Acessories, AcessoriesForm, EtadsAcessories } from '../../../interfaces/acessories';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './acessories-create.component.html'
})
export class AcessoriesCreateComponent implements OnInit {

    form:FormGroup;
    acessoriesId: number;
    acessoriesList: Acessories[];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private acessoriesCrudService: AcessoriesCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Acessórios", "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();
    }

    loadData() {
        this.acessoriesList = this.stateService.retrieveAcessoriesList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            acessoriesDescription: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(50) ] ],
            active:   [null, [  Validators.required  ] ],
        });
    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Acessório Inválido ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const acessoriesForm = this.form.getRawValue() as AcessoriesForm;
        let newAacessories = new EtadsAcessories();

        newAacessories.acessoriesDescription = acessoriesForm.acessoriesDescription.valueOf().toUpperCase();
        newAacessories.active = acessoriesForm.active.valueOf();

        this.subscription = this.acessoriesCrudService.add(newAacessories).subscribe((data) => {
            let idList = this.acessoriesList.push(newAacessories);
            this.stateService.storeAcessoriesList(this.acessoriesList);
            this.stateService.storeAlertSuccessMessage('Dados cadastrados com sucesso.');
            this.spinnerService.hide();
            this.actionBack();
        }, (error) => {
            this.stateService.storeAcessoriesList(this.acessoriesList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['acessories-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newAr = this.form.getRawValue() as AcessoriesForm;

        for (let arData of this.acessoriesList) {
             let parse1 = arData.acessoriesDescription.toUpperCase();
             let parse2 = newAr.acessoriesDescription.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }
        return true;

    }

}
