<etads-div-center >
  <div class="row">
    <div class="col-5 text-left" >
      <br>
      <br>
      <br>
      <br>
      <p class="font-weight-bold" style="color: #4F81BD; font-size: 15pt;">Bem-vindo ao Portal SAN (e-TADS) Toyota!</p>

      Antes de abrir um chamado e-TADS, verifique se já existe alguma “Dica Técnica” ou Boletim de Serviços relacionado ao veículo e/ou sintoma apresentado no veículo.
      <br>
      Vale lembrar que os passos de diagnóstico do Manual de Reparação também devem ser realizados antes da abertura de um chamado.
      <br><br>
      Obrigado!
      <br><br>
      Departamento de Engenharia de Serviços – Pós-venda
    </div>
    <div class="col-4 align-middle"><br><br><img src="assets/images/toyota_home.png" style="opacity: 0.4; width: 50%;" class="img-fluid"></div>
  </div>

</etads-div-center>
