import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-menu-dropdown-item',
    templateUrl: './menu-dropdown-item.component.html',
    styleUrls: ['./menu-dropdown-item.component.css']
})
export class MenuDropDownItemComponent {

  @Input() routerLink : string;
  @Input() label : string;
  @Input() isTdbUser: boolean = false;
  @Input() visibleOnlyTdbUser: boolean = true;
  @Input() hideMenu: boolean = false;

}
