import { Injectable } from '@angular/core';
import { DirectImportChassis } from '../interfaces/direct-import-chassis';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class DirectImportChassiCrudService {
  key = 'etads/directImportChassi';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : DirectImportChassis) {
    return this.azureCrudService.add(newReg, "addDirectImportChassi");
  }

  upd(oldReg : DirectImportChassis, newReg : DirectImportChassis) {
    return this.azureCrudService.upd(oldReg, newReg, "updateDirectImportChassi");
  }

  del(reg : DirectImportChassis) {
    return this.azureCrudService.del(reg, "deleteDirectImportChassi");
  }

  get() {
    return this.azureCrudService.get("getDirectImportChassi");
  }

}
