import { Injectable } from '@angular/core';
import { LocalStorageCrudService } from './local-storage-crud.service';
import { T1 } from '../interfaces/t1';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class T1CrudService {
  key = 'etads/t1';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg: T1) {
    return this.azureCrudService.add(newReg, "addT1");
  }

  upd(oldReg: T1, newReg: T1) {
    return this.azureCrudService.upd(oldReg, newReg, "updateT1");
  }

  del(reg: T1) {
    return this.azureCrudService.del(reg, "deleteT1");
  }

  get() {
    return this.azureCrudService.get("getT1");
  }

  getBySymptom(areaRelatedId: number, symptomGroupId: number, symptomId: number) {
    return this.azureCrudService.get("getT1BySymptom?areaRelatedId=" + areaRelatedId + "&symptomGroupId=" + symptomGroupId + "&symptomId=" + symptomId);
  }
}
