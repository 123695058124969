<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Codigo T1</th>    
                <th>Nome T1</th>    
                <th>Área Relacionada</th> 
                <th>Grupo de Sintoma</th>
                <th>Sintoma</th>
                <th>Situação</th> 
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let t1x of t1List | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{t1x.t1Code}}</td>
                <td>{{t1x.t1Name}}</td>
                <td>{{t1x.areaRelatedId}} - {{t1x.areaRelatedDescription}} </td>
                <td>{{t1x.symptomGroupId}} - {{t1x.symptomGroupDescription}}</td>
                <td>{{t1x.symptomId}} - {{t1x.symptomDescription}} </td>
                <td>{{(t1x.active === true ) ? "Ativo" : "Inativo"}}</td>

                <td>
                    <etads-tooltip label="Editar T1" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(t1x)" 
                            link="t1-edit" [parameter]="t1x.t1Id">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir T1" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(t1x)"
                            link="t1-remove" [parameter]="t1x.t1Id">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Item"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
