<form [formGroup]="vehicleForm" (submit)="actionSubmitVehicle()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Veículo"></etads-label>
    </div>
    <div class="form-row pt-2 pl-2 pr-2 pb-0">
      <div class="col-3">
        <etads-label label="Chassi"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="chassi" (change)="actionChangeChassi()" placeholder=""
          [readonly]="osFile || importOsFileRequired"></etads-input-text>
        <etads-vmessage *ngIf="vehicleForm.get('chassi').errors?.required" text="Campo obrigatório"></etads-vmessage>
        <etads-vmessage *ngIf="vehicleForm.get('chassi').errors?.minlength" text="Tamanho mínino de 17 caracter">
        </etads-vmessage>
      </div>
      <div class="col-1">
        <etads-label label="KM"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="km" placeholder="" [readonly]="osFile || importOsFileRequired">
        </etads-input-text>
        <etads-vmessage *ngIf="vehicleForm.get('km').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
      <div class="col-1">
        <etads-label label="OS"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="os" placeholder="" [readonly]="osFile || importOsFileRequired">
        </etads-input-text>
        <etads-vmessage *ngIf="vehicleForm.get('os').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
      <div class="col-3">
        <etads-label label="Abertura da OS"></etads-label>
        <etads-input-datetime [formGroup]="vehicleForm" controlName="dtStart" inputLabel="Abertura da OS" [readonly]="osFile || importOsFileRequired">
        </etads-input-datetime>
        <etads-vmessage *ngIf="vehicleForm.get('dtStart').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
      <div class="col-1">
        <etads-label label="Tipo de OS"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="typeOs" placeholder="" [readonly]="osFile || importOsFileRequired">
        </etads-input-text>
        <etads-vmessage *ngIf="vehicleForm.get('typeOs').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Veículo"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="vehicle" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-2">
        <etads-label label="Ano Fab. / mod."></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="yearProdMod" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-2">
        <etads-label label="Modelo"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="model" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-2">
        <etads-label label="Motor"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="motor" placeholder="" readonly="true">
        </etads-input-text>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-md-2">
        <etads-label label="Data de produção"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="dtProduction" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-2">
        <etads-label label="Data de venda"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="dtSale" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-1">
        <etads-label label="Transmissão"></etads-label>
        <etads-input-text [formGroup]="vehicleForm" controlName="transmission" placeholder="" readonly="true">
        </etads-input-text>
      </div>
    </div>
  </div>
</form>
