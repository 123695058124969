import { Injectable } from '@angular/core';
import { LocalStorageCrudService } from './local-storage-crud.service';
import { Finality } from '../interfaces/finality';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class FinalityCrudService {
  key = 'etads/finality';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : Finality) {
    return this.azureCrudService.add(newReg, "addFinality");
  }

  upd(oldReg : Finality, newReg : Finality) {
    return this.azureCrudService.upd(oldReg, newReg, "updateFinality");
  }

  del(reg : Finality) {
    return this.azureCrudService.del(reg, "deleteFinality");
  }

  get() {
    return this.azureCrudService.get("getFinality");
  }

}
