import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-number',
    templateUrl: './input-number.component.html',

})
export class InputNumberComponent { 

    @Input() formGroup: FormGroup;
    @Input() placeholder: string;

    @Input() controlName: string;

}