import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsGeneralSchedule, GeneralSchedule, GeneralScheduleForm } from '../../../interfaces/general-schedule';
import { GeneralScheduleCrudService } from '../../../services/general-schedule-crud.service';
import { Priority } from '../../../interfaces/priority';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './general-schedule-edit.component.html',
})

export class GeneralScheduleEditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    priorityList: Priority[];
    radioPriorList : Radio[]=[];

    generalSchedule: GeneralSchedule;
    generalScheduleId: number;
    generalScheduleList: EtadsGeneralSchedule[]=[];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private generalScheduleCrudService: GeneralScheduleCrudService,
        private priorityCrudService: PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Escalonamento", "");
        this.loadData();
        this.createForm();
        this.updateForm();
    }

    loadData() {
        this.generalScheduleId = this.route.snapshot.params.generalScheduleId;
        this.generalSchedule = this.stateService.retrieveGeneralSchedule();
        this.generalScheduleList = this.stateService.retrieveGeneralScheduleList();
        this.currentUser = "ToBeDefined";


         /* caregando lista de Prioridades */
         this.priorityList = [];
         this.subscription = this.priorityCrudService.get().subscribe( listx => {
              for (let regx of listx) {
                this.priorityList.push(regx);
                let rop = new  EtadsRadio ();
                rop.itemId = regx.priorityId;
                rop.itemLabel = regx.initials;
                this.radioPriorList.push(rop)
              }
              this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         });
    }


    createForm() {

        this.form = this.formBuilder.group( {
            generalScheduleDescription:  ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            priorityId: [this.generalSchedule.priorityId.toString(), [ Validators.required], ],
            // priorityInitials:   ['', [ Validators.required, Validators.minLength(1), Validators.maxLength(1) ] , ],
            rangeInit:   ['0',   [ Validators.required, Validators.minLength(1), Validators.maxLength(3) ] , ],
            rangeFinish: ['0',  [ Validators.required, Validators.minLength(1), Validators.maxLength(3) ] , ],
            dlr1: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            dlr2: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            dlr3: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb1: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb2: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb3: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],

        });
    }

    updateForm() {
        this.form.patchValue({generalScheduleDescription: this.generalSchedule.generalScheduleDescription});
        this.form.patchValue({priorityId: this.generalSchedule.priorityId});
        // this.form.patchValue({priorityInitials: this.generalSchedule.priorityInitials});
        this.form.patchValue({rangeInit: this.generalSchedule.rangeInit });
        this.form.patchValue({rangeFinish: this.generalSchedule.rangeFinish});
        this.form.patchValue({dlr1: this.generalSchedule.dlr1});
        this.form.patchValue({dlr2: this.generalSchedule.dlr2});
        this.form.patchValue({dlr3: this.generalSchedule.dlr3});
        this.form.patchValue({tdb1: this.generalSchedule.tdb1});
        this.form.patchValue({tdb2: this.generalSchedule.tdb2});
        this.form.patchValue({tdb3: this.generalSchedule.tdb3});

       this.form.controls['priorityId'].setValue(this.generalSchedule.priorityId);

    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const dtmodfied = new Date();
        const gsform   = this.form.getRawValue() as GeneralScheduleForm;
        let gsToUpd = new EtadsGeneralSchedule();
        gsToUpd.id = this.generalSchedule.id;
        gsToUpd.generalScheduleId = this.generalSchedule.generalScheduleId
        gsToUpd.generalScheduleDescription = gsform.generalScheduleDescription.toUpperCase();
        gsToUpd.priorityId = Number(gsform.priorityId);
        gsToUpd.priorityInitials = this.priorityList.find(pr => pr.priorityId == gsform.priorityId).initials;
        gsToUpd.rangeInit = Number(gsform.rangeInit);
        gsToUpd.rangeFinish = Number(gsform.rangeFinish);
        gsToUpd.dlr1 = Number(gsform.dlr1);
        gsToUpd.dlr2 = Number(gsform.dlr2);
        gsToUpd.dlr3 = Number(gsform.dlr3);
        gsToUpd.tdb1 = Number(gsform.tdb1);
        gsToUpd.tdb2 = Number(gsform.tdb2);
        gsToUpd.tdb3 = Number(gsform.tdb3);
        gsToUpd.created = this.generalSchedule.created;
        gsToUpd.createdUser  = this.generalSchedule.createdUser;
        gsToUpd.modified     = this.datepipe.transform(dtmodfied, 'dd-MM-yyyy h:mm:ss a').toString();;
        gsToUpd.modifiedUser = this.currentUser;

        this.subscription = this.generalScheduleCrudService.upd(this.generalSchedule, gsToUpd).subscribe((data) => {
            let idList = this.generalScheduleList.push(gsToUpd);
            this.stateService.storeAlertSuccessMessage("Dados editados com sucesso.");
            this.spinnerService.hide();
            gsToUpd = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['general-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    onPriorityIdChange(value){
        this.form.patchValue({priorityId: value});
    }

    validateForm( ) {

        const newSg = this.form.getRawValue() as GeneralScheduleForm;

            for (let sgData of this.generalScheduleList) {
               let parse1 = sgData.generalScheduleDescription.toUpperCase();
               let parse2 = newSg.generalScheduleDescription.toUpperCase();

               if (parse1 == parse2 && sgData.generalScheduleId != this.generalScheduleId) {
                   return false;
               }
           }

        return true;
    }

}
