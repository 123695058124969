import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule, DatePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import { StateService } from './../services/state.service';
import { AlertService } from '../shared/components/alert/alert.service';
import { DirectivesModule } from '../directives/directives.module';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ComponentsModule } from '../shared/components/components.module';
import { PriorityCrudService } from '../services/priority-crud.service';
import { PriorityListComponent } from './priority/list/priority-list.component';
import { PriorityCreateComponent } from './priority/create/priority-create.component';
import { PriorityRemoveComponent } from './priority/remove/priority-remove.component';
import { PriorityEditComponent } from './priority/edit/priority-edit.component';
import { DirectImportChassisCreateComponent } from './direct-import/chassis/create/chassis-create.component';
import { DirectImportChassisListComponent } from './direct-import/chassis/list/chassis-list.component';
import { DirectImportChassisRemoveComponent } from './direct-import/chassis/remove/chassis-remove.component';
import { DirectImportChassisEditComponent } from './direct-import/chassis/edit/chassis-edit.component';
import { AreaRelatedCreateComponent } from './area-related/create/area-related-create.component';
import { AreaRelatedListComponent } from './area-related/list/area-related-list.component';
import { AreaRelatedEditComponent } from './area-related/edit/area-related-edit.component';
import { AreaRelatedRemoveComponent } from './area-related/remove/area-related-remove.component';
import { SymptomGroupCreateComponent } from './symptom-group/create/symptom-group-create.component';
import { SymptomGroupListComponent } from './symptom-group/list/symptom-group-list.component';
import { SymptomGroupEditComponent } from './symptom-group/edit/symptom-group-edit.component';
import { SymptomGroupRemoveComponent } from './symptom-group/remove/symptom-group-remove.component';
import { SymptomCreateComponent } from './symptom/create/symptom-create.component';
import { SymptomEditComponent } from './symptom/edit/symptom-edit.component';
import { SymptomListComponent } from './symptom/list/symptom-list.component';
import { SymptomRemoveComponent } from './symptom/remove/symptom-remove.component';
import { AcessoriesCreateComponent } from './acessories/create/acessories-create.component';
import { AcessoriesEditComponent } from './acessories/edit/acessories-edit.component';
import { AcessoriesListComponent } from './acessories/list/acessories-list.component';
import { AcessoriesRemoveComponent } from './acessories/remove/acessories-remove.component';
import { T1CreateComponent } from './t1/create/t1-create.component';
import { T1EditComponent } from './t1/edit/t1-edit.component';
import { T1ListComponent } from './t1/list/t1-list.component';
import { T1RemoveComponent } from './t1/remove/t1-remove.component';
import { T2CreateComponent } from './t2/create/t2-create.component';
import { T2EditComponent } from './t2/edit/t2-edit.component';
import { T2ListComponent } from './t2/list/t2-list.component';
import { T2RemoveComponent } from './t2/remove/t2-remove.component';
import { GeneralScheduleCreateComponent } from './general-schedule/create/general-schedule-create.component';
import { GeneralScheduleEditComponent } from './general-schedule/edit/general-schedule-edit.component';
import { GeneralScheduleListComponent } from './general-schedule/list/general-schedule-list.component';
import { GeneralScheduleRemoveComponent } from './general-schedule/remove/general-schedule-remove.component';
import { DailyScheduleCreateComponent } from './daily-schedule/create/daily-schedule-create.component';
import { DailyScheduleEditComponent } from './daily-schedule/edit/daily-schedule-edit.component';
import { DailyScheduleListComponent } from './daily-schedule/list/daily-schedule-list.component';
import { DailyScheduleRemoveComponent } from './daily-schedule/remove/daily-schedule-remove.component';
import { AnswerScheduleCreateComponent } from './answer-schedule/create/answer-schedule-create.component';
import { AnswerScheduleEditComponent } from './answer-schedule/edit/answer-schedule-edit.component';
import { AnswerScheduleListComponent } from './answer-schedule/list/answer-schedule-list.component';
import { AnswerScheduleRemoveComponent } from './answer-schedule/remove/answer-schedule-remove.component';
import { FinalityCreateComponent } from './finality/create/finality-create.component';
import { FinalityEditComponent } from './finality/edit/finality-edit.component';
import { FinalityListComponent } from './finality/list/finality-list.component';
import { FinalityRemoveComponent } from './finality/remove/finality-remove.component';
import { DistributorCreateComponent } from './distributor/create/distributor-create.component';
import { DistributorEditComponent } from './distributor/edit/distributor-edit.component';
import { DistributorListComponent } from './distributor/list/distributor-list.component';
import { DistributorRemoveComponent } from './distributor/remove/distributor-remove.component';
import { GroupTdbScheduleCreateComponent } from './group-tdb-schedule/create/group-tdb-schedule-create.component';
import { GroupTdbScheduleEditComponent } from './group-tdb-schedule/edit/group-tdb-schedule-edit.component';
import { GroupTdbScheduleListComponent } from './group-tdb-schedule/list/group-tdb-schedule-list.component';
import { GroupTdbScheduleRemoveComponent } from './group-tdb-schedule/remove/group-tdb-schedule-remove.component';
import { GroupDlrScheduleCreateComponent } from './group-dlr-schedule/create/group-dlr-schedule-create.component';
import { GroupDlrScheduleEditComponent } from './group-dlr-schedule/edit/group-dlr-schedule-edit.component';
import { GroupDlrScheduleListComponent } from './group-dlr-schedule/list/group-dlr-schedule-list.component';
import { GroupDlrScheduleRemoveComponent } from './group-dlr-schedule/remove/group-dlr-schedule-remove.component';
import { TicketCreateComponent } from './ticket/create/ticket-create.component';
import { TicketListComponent } from './ticket/list/ticket-list.component';
import { TicketReportComponent } from './ticket/report/ticket-report.component';
import { TicketEditComponent } from './ticket/edit/ticket-edit.component';
import { TicketRemoveComponent } from './ticket/remove/ticket-remove.component';
import { TicketViewComponent } from './ticket/view/ticket-view.component';
import { TicketEncaminharComponent } from './ticket/ticket-encaminhar/ticket-encaminhar.component';
import { ElibBrandListComponent } from './elib-brand/list/elib-brand-list.component';
import { ElibBrandCreateComponent } from './elib-brand/create/elib-brand-create.component';
import { ElibBrandEditComponent } from './elib-brand/edit/elib-brand-edit.component';
import { ElibBrandRemoveComponent } from './elib-brand/remove/elib-brand-remove.component';
import { ElibModelListComponent } from './elib-model/list/elib-model-list.component';
import { ElibModelCreateComponent } from './elib-model/create/elib-model-create.component';
import { ElibModelEditComponent } from './elib-model/edit/elib-model-edit.component';
import { ElibModelRemoveComponent } from './elib-model/remove/elib-model-remove.component';
import { ElibManualListComponent } from './elib-manual/list/elib-manual-list.component';
import { ElibManualCreateComponent } from './elib-manual/create/elib-manual-create.component';
import { ElibManualEditComponent } from './elib-manual/edit/elib-manual-edit.component';
import { ElibManualRemoveComponent } from './elib-manual/remove/elib-manual-remove.component';
import { TechnicalTipsCreateComponent } from './technicaltips/create/technicaltips-create.component'
import { TechnicalTipsEditComponent } from './technicaltips/edit/technicaltips-edit.component'
import { TechnicalTipsListComponent } from './technicaltips/list/technicaltips-list.component'
import { TechnicalTipsRemoveComponent } from './technicaltips/remove/technicaltips-remove.component'

@NgModule({
    declarations: [
       PriorityListComponent,
       PriorityCreateComponent,
       PriorityRemoveComponent,
       PriorityEditComponent,
       DirectImportChassisCreateComponent,
       DirectImportChassisListComponent,
       DirectImportChassisRemoveComponent,
       DirectImportChassisEditComponent,
       AreaRelatedCreateComponent,
       AreaRelatedListComponent,
       AreaRelatedEditComponent,
       AreaRelatedRemoveComponent,
       SymptomGroupCreateComponent,
       SymptomGroupListComponent,
       SymptomGroupEditComponent,
       SymptomGroupRemoveComponent,
       SymptomCreateComponent,
       SymptomEditComponent,
       SymptomListComponent,
       SymptomRemoveComponent,
       AcessoriesCreateComponent,
       AcessoriesEditComponent,
       AcessoriesListComponent,
       AcessoriesRemoveComponent,
       T1CreateComponent,
       T1EditComponent,
       T1ListComponent ,
       T1RemoveComponent,
       T2CreateComponent,
       T2EditComponent,
       T2ListComponent,
       T2RemoveComponent,
       GeneralScheduleCreateComponent,
       GeneralScheduleEditComponent,
       GeneralScheduleListComponent,
       GeneralScheduleRemoveComponent,
       DailyScheduleCreateComponent,
       DailyScheduleEditComponent,
       DailyScheduleListComponent,
       DailyScheduleRemoveComponent,
       AnswerScheduleCreateComponent,
       AnswerScheduleEditComponent,
       AnswerScheduleListComponent,
       AnswerScheduleRemoveComponent,
       FinalityCreateComponent,
       FinalityEditComponent,
       FinalityListComponent,
       FinalityRemoveComponent,
       DistributorCreateComponent,
       DistributorEditComponent,
       DistributorListComponent,
       DistributorRemoveComponent,
       GroupTdbScheduleCreateComponent,
       GroupTdbScheduleEditComponent,
       GroupTdbScheduleListComponent,
       GroupTdbScheduleRemoveComponent,
       GroupDlrScheduleCreateComponent,
       GroupDlrScheduleEditComponent,
       GroupDlrScheduleListComponent,
       GroupDlrScheduleRemoveComponent,
       TicketCreateComponent,
       TicketListComponent,
       TicketReportComponent,
       TicketEditComponent,
       TicketRemoveComponent,
       TicketViewComponent,
       TicketEncaminharComponent,
       ElibBrandListComponent,
       ElibBrandCreateComponent,
       ElibBrandEditComponent,
       ElibBrandRemoveComponent,
       ElibModelListComponent,
       ElibModelCreateComponent,
       ElibModelEditComponent,
       ElibModelRemoveComponent,
       ElibManualListComponent,
       ElibManualCreateComponent,
       ElibManualEditComponent,
       ElibManualRemoveComponent,
       TechnicalTipsCreateComponent,
       TechnicalTipsEditComponent,
       TechnicalTipsListComponent,
       TechnicalTipsRemoveComponent
    ],
    exports: [
        PriorityListComponent,
        PriorityCreateComponent,
        PriorityRemoveComponent,
        PriorityEditComponent,
        DirectImportChassisCreateComponent,
        DirectImportChassisListComponent,
        DirectImportChassisRemoveComponent,
        DirectImportChassisEditComponent,
        AreaRelatedCreateComponent,
        AreaRelatedListComponent,
        AreaRelatedEditComponent,
        AreaRelatedRemoveComponent,
        SymptomGroupCreateComponent,
        SymptomGroupListComponent,
        SymptomGroupEditComponent,
        SymptomGroupRemoveComponent,
        SymptomCreateComponent,
        SymptomEditComponent,
        SymptomListComponent,
        SymptomRemoveComponent,
        AcessoriesCreateComponent,
        AcessoriesEditComponent,
        AcessoriesListComponent,
        AcessoriesRemoveComponent,
        T1CreateComponent,
        T1EditComponent,
        T1ListComponent ,
        T1RemoveComponent,
        T2CreateComponent,
        T2EditComponent,
        T2ListComponent,
        T2RemoveComponent,
        GeneralScheduleCreateComponent,
        GeneralScheduleEditComponent,
        GeneralScheduleListComponent,
        GeneralScheduleRemoveComponent,
        DailyScheduleCreateComponent,
        DailyScheduleEditComponent,
        DailyScheduleListComponent,
        DailyScheduleRemoveComponent,
        AnswerScheduleCreateComponent,
        AnswerScheduleEditComponent,
        AnswerScheduleListComponent,
        AnswerScheduleRemoveComponent,
        FinalityCreateComponent,
        FinalityEditComponent,
        FinalityListComponent,
        FinalityRemoveComponent,
        DistributorCreateComponent,
        DistributorEditComponent,
        DistributorListComponent,
        DistributorRemoveComponent,
        GroupTdbScheduleCreateComponent,
        GroupTdbScheduleEditComponent,
        GroupTdbScheduleListComponent,
        GroupTdbScheduleRemoveComponent,
        GroupDlrScheduleCreateComponent,
        GroupDlrScheduleEditComponent,
        GroupDlrScheduleListComponent,
        GroupDlrScheduleRemoveComponent,
        TicketCreateComponent,
        TicketListComponent,
        TicketReportComponent,
        TicketEditComponent,
        TicketRemoveComponent,
        TicketViewComponent,
        ElibBrandListComponent,
        ElibBrandCreateComponent,
        ElibBrandEditComponent,
        ElibBrandRemoveComponent,
        ElibModelListComponent,
        ElibModelCreateComponent,
        ElibModelEditComponent,
        ElibModelRemoveComponent,
        ElibManualListComponent,
        ElibManualCreateComponent,
        ElibManualEditComponent,
        ElibManualRemoveComponent,
        TechnicalTipsCreateComponent,
        TechnicalTipsEditComponent,
        TechnicalTipsListComponent,
        TechnicalTipsRemoveComponent
    ],
    imports: [
        ComponentsModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPaginationModule,
        DirectivesModule,
        NgxSpinnerModule
    ],
    providers: [
        PriorityCrudService,
        AlertService,
        StateService,
        DatePipe
    ]
})

export class AdministrationModule { }
