<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Sintoma"></etads-label>
            <etads-input-text [formGroup]="form" controlName="symptomDescription" placeholder="Informe um sintoma."></etads-input-text>
            <etads-vmessage *ngIf="form.get('symptomDescription').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('symptomDescription').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('symptomDescription').errors?.maxlength" text="Tamanho máximo de 200 caracteres"></etads-vmessage>

            <etads-label label="Grupo de Sintoma"></etads-label>
            <etads-input-select-without-default [formGroup]="form" controlName="symptomGroupId" >
                <option *ngFor= "let grp of symptomGroupList" [value]="grp.symptomGroupId" [selected]="grp.symptomGroupId==symptom.symptomGroupId">{{grp.symptomGroupDescription}}</option>
            </etads-input-select-without-default>
            <etads-vmessage *ngIf="form.get('symptomGroupId').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Prioridade"></etads-label>
            <etads-input-select-without-default [formGroup]="form" controlName="priorityDescription">
                <option *ngFor=" let priority of priorityList" [value]="priority.alias" [selected]="priority.alias==symptom.priorityDescription">{{priority.alias}}</option>
            </etads-input-select-without-default>
            <etads-vmessage *ngIf="form.get('priorityDescription').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Situação"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>
            <etads-vmessage *ngIf="form.get('active').errors?.required" text="Campo obrigatório"></etads-vmessage>


            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>
