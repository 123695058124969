<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
            
            <etads-label label="Escalonamento Geral"></etads-label>
            <etads-input-text [formGroup]="form" controlName="generalScheduleDescription" placeholder="Informe uma descrição"></etads-input-text>
            <etads-vmessage *ngIf="form.get('generalScheduleDescription').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('generalScheduleDescription').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('generalScheduleDescription').errors?.maxlength" text="Tamanho máximo de 50 caracteres"></etads-vmessage>

            <etads-label label="Prioridade Chamado"></etads-label>
            <etads-div-input-group>
                <etads-input-radio [radioList]="radioPriorList" [formGroup]="form" controlName="priorityId" ></etads-input-radio>
            </etads-div-input-group>
          
            <etads-tooltip label="Tempos inferiores a este valor serão exibidos com o indicador verde.">
                <etads-label label="Tempo em Dias Abaixo de"></etads-label>
                <etads-input-text [formGroup]="form" controlName="rangeInit" placeholder="Informe um valor de tempo"></etads-input-text>
                <etads-vmessage *ngIf="form.get('rangeInit').errors?.required" text="Campo obrigatório"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('rangeInit').errors?.minlength" text="Tamanho mínino de 1 caractere"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('rangeInit').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>

            </etads-tooltip>
          
            <etads-tooltip  label="Tempos entre o campo Abaixo de e este valor serão exibidos com o indicador amarelo.Tempos superiores a este valor serão exibidos com o indicador vermelho." > 
                <etads-label label="Tempo em Dias Acima de"></etads-label>    
                <etads-input-text [formGroup]="form" controlName="rangeFinish" placeholder="Informe um valor de tempo"></etads-input-text>
                <etads-vmessage *ngIf="form.get('rangeFinish').errors?.required" text="Campo obrigatório"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('rangeFinish').errors?.minlength" text="Tamanho mínino de 1 caractere"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('rangeFinish').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>
            </etads-tooltip>
            <!--etads-label-small label="Tempos entre o campo Abaixo de e este valor serão exibidos com o indicador amarelo."></etads-label-small>
            <etads-label-small label="Tempos superiores a este valor serão exibidos com o indicador vermelho."></etads-label-small -->
          
            <etads-label label="Tempo em Dias DLR 1"></etads-label>
            <etads-input-text [formGroup]="form" controlName="dlr1" placeholder="Informe um valor de tempo"></etads-input-text>
            <etads-vmessage *ngIf="form.get('dlr1').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('dlr1').errors?.minlength" text="Tamanho mínino de 1 caractere"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('dlr1').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>

            <etads-label label="Tempo em Dias DLR 2"></etads-label>
            <etads-input-text [formGroup]="form" controlName="dlr2" placeholder="Informe um valor de tempo"></etads-input-text>
            <etads-vmessage *ngIf="form.get('dlr2').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('dlr2').errors?.minlength" text="Tamanho mínino de 1 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('dlr2').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>

            <etads-label label="Tempo em Dias DLR 3"></etads-label>
            <etads-input-text [formGroup]="form" controlName="dlr3" placeholder="Informe um valor de tempo"></etads-input-text>
            <etads-vmessage *ngIf="form.get('dlr3').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('dlr3').errors?.minlength" text="Tamanho mínino de 1 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('dlr3').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>


            <etads-label label="Tempo em Dias TDB 1"></etads-label>
            <etads-input-text [formGroup]="form" controlName="tdb1" placeholder="Informe um valor de tempo"></etads-input-text>
            <etads-vmessage *ngIf="form.get('tdb1').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('tdb1').errors?.minlength" text="Tamanho mínino de 1 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('tdb1').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>


            <etads-label label="Tempo em Dias TDB 2"></etads-label>
            <etads-input-text [formGroup]="form" controlName="tdb2" placeholder="Informe um valor de tempo"></etads-input-text>
            <etads-vmessage *ngIf="form.get('tdb2').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('tdb2').errors?.minlength" text="Tamanho mínino de 1 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('tdb2').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>

            <etads-label label="Tempo em Dias TDB 3"></etads-label>
            <etads-input-text [formGroup]="form" controlName="tdb3" placeholder="Informe um valor de tempo"></etads-input-text>
            <etads-vmessage *ngIf="form.get('tdb3').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('tdb3').errors?.minlength" text="Tamanho mínino de 1 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('tdb3').errors?.maxlength" text="Tamanho máximo de 3 caracteres"></etads-vmessage>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>