import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { T2CrudService } from '../../../services/t2-crud.service';
import { EtadsT2, T2 } from '../../../interfaces/t2';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './t2-list.component.html'
})
export class T2ListComponent implements OnInit {

    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    t2List : EtadsT2[] = [];   
    t2 : T2;
    t2Id : number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private t2CrudService:  T2CrudService,
        ) {}

  

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Item T2",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
        this.spinnerService.hide();
    }

    loadData() {

        this.t2List = [];
        this.subscription = this.t2CrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.t2List.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }      
           
    actionAdd() {
        this.stateService.storeT2List(this.t2List);
        this.router.navigate(['t2-create']);
    }

    actionEdit( t2data: T2) {
        this.stateService.storeT2(t2data);
        this.stateService.storeT2List(this.t2List);
    }

    actionRemove( t2: T2 ) {
        this.stateService.storeT2 (t2);
    }


}