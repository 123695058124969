import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsSymptomGroup, SymptomGroup, SymptomGroupForm } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './symptom-group-create.component.html'
})

export class SymptomGroupCreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();
    symptomGroupId: number;
    symptomGroup: SymptomGroup;
    symptomGroupList : SymptomGroup[] = [] ;
    areaRelatedList : AreaRelated[]=[];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private areaRelatedCrudService: AreaRelatedCrudService,

        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Grupo de Sintoma", "");
        this.loadData();
        this.createForm();
        this.spinnerService.hide();
    }

    loadData() {

        this.symptomGroupList = this.stateService.retrieveSymptomGroupList();

        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( list => {
            for (let reg of list) {
                this.areaRelatedList.push(reg);
            }
            this.spinnerService.hide();
        }, (error) => {
            this.alertService.danger(error.error);
            this.spinnerService.hide();
        } );

    }


    createForm() {

        this.form = this.formBuilder.group( {
            symptomGroupDescription: ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(200)  ] ,   ],
            areaRelatedId :     ['null', [ Validators.required], ],
            active:             [false, null ],
        });
    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Grupo de Sintoma Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const dataform   = this.form.getRawValue() as SymptomGroupForm;
        let dataToInsert = new EtadsSymptomGroup();

        if (this.symptomGroupList.length <= 0 ) {
            dataToInsert.symptomGroupId = 1;
         } else {
             dataToInsert.symptomGroupId = this.symptomGroupList.length + 1 ;
        }

        dataToInsert.symptomGroupDescription  = dataform.symptomGroupDescription;
        dataToInsert.areaRelatedId       = Number(dataform.areaRelatedId.valueOf());
        dataToInsert.active              = dataform.active;
        dataToInsert.areaRelatedDescription = this.areaRelatedList.find(ar => ar.areaRelatedId == dataform.areaRelatedId).areaDescription;

        this.subscription = this.symptomGroupCrudService.add(dataToInsert).subscribe( (data) => {
            let idList = this.symptomGroupList.push(dataToInsert);
            this.stateService.storeSymptomGroupList(this.symptomGroupList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            dataToInsert = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });
    }

    actionBack() {
        this.router.navigate(['symptom-group-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as SymptomGroupForm;

            for (let sgData of this.symptomGroupList) {
               let parse1 = sgData.symptomGroupDescription.toUpperCase();
               let parse2 = newSg.symptomGroupDescription.toUpperCase();

               if (parse1 == parse2) {
                   return false;
               }
           }

        return true;
    }

}
