export interface SymptomGroup {
    id: string;
    symptomGroupId : number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    active:boolean;
}

export interface SymptomGroupForm {
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    active:boolean;
}

export class EtadsSymptomGroup implements SymptomGroup {
    id: string;
    symptomGroupId : number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    active:boolean;
}