import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { ElibModel } from '../../../interfaces/elib-model';
import { ElibModelCrudService } from '../../../services/elib-model-crud.service';


@Component({
    templateUrl: './elib-model-remove.component.html'
})
export class ElibModelRemoveComponent implements OnInit {


    form: FormGroup;
    modelId : number;
    messageLabel : string;
    elibModelToDel : ElibModel;
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private elibModelCrudService: ElibModelCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.modelId = this.route.snapshot.params.modelId;
        this.elibModelToDel = this.stateService.retrieveElibModel();

        this.alertService.warning("Deseja excluir o Modelo: " + this.elibModelToDel.description + "?");

        this.form = this.formBuilder.group({
            modelId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.elibModelCrudService.del(this.elibModelToDel).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeAlertSuccessMessage(error.error);
            this.spinnerService.hide();
          }
        );
    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['elib-model-list']);
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
