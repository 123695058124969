import { Component, OnInit, ɵresetCompiledComponents } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../../services/state.service';
import { ComponentsModule } from '../../../../shared/components/components.module';
import { EtadsDirectImportChassis, DirectImportChassis } from '../../../../interfaces/direct-import-chassis';
import { DirectImportChassiCrudService } from '../../../../services/direct-import-chassis-crud.service';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './chassis-list.component.html'
})
export class DirectImportChassisListComponent implements OnInit {

    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    chassisList : EtadsDirectImportChassis[] = [];   
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor (
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private directImportChassisCrudService : DirectImportChassiCrudService
    ) {}


    ngOnInit(): void {
    
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Manter Chassis Importação Direta", "");
        this.loadData();
        const successAlertMessage = this.stateService.retrieveAlertSuccessMessage();
        if (successAlertMessage != null) {
            this.alertService.success(successAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
        }

    }

    loadData() {

        this.subscription = this.directImportChassisCrudService.get().subscribe((regList) => {
            for (let data of regList) {
                let idlist = this.chassisList.push(data);
            }
            this.spinnerService.hide();
        },(err) => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }

    actionAdd() {

        this.stateService.storeChassisList(this.chassisList);
        this.router.navigate(['chassis-create']);

    }

    actionEdit (chassisData : DirectImportChassis) {
        this.stateService.storeChassis(chassisData);
        this.stateService.storeChassisList(this.chassisList);
        //  this.router.navigate(['chassis-edit']);
    }

    actionRemove( chassisData: DirectImportChassis ) {

        this.stateService.storeChassis(chassisData);
        this.router.navigate(['chassis-remove']);
    }


}
