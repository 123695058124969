import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'etads-button-upload',
    templateUrl: './button-upload.component.html',
    styleUrls: ['./button-upload.component.css']
})
export class ButtonUploadComponent { 

    constructor() {}

    @Input() label = 'Upload';
    @Input() disabled: boolean = false; 

    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}