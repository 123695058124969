<form [formGroup]="requestForm" (submit)="actionSubmitRequest()"></form>
<div class="card mt-2">
  <div class="card-header">
    <etads-label label="Solicitação"></etads-label>
  </div>
    <div class="form-row pt-2 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Área relacionada"></etads-label>
        <etads-input-select (change)="actionChangeArea()" [formGroup]="requestForm" firstOptionLabel="Selecione" controlName="relatedAreaId">
          <option *ngFor="let it of listAreaRelated"  [value]="it.areaRelatedId" [selected]="(requestGroup && it.areaRelatedId == requestGroup.relatedAreaId)">{{it.areaDescription}}</option>
        </etads-input-select>
        <etads-vmessage *ngIf="requestForm.get('relatedAreaId').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
      <div class="form-group col-md-3">
        <etads-label label="Finalidade"></etads-label>
        <etads-input-select (change)="actionChangeFinality()" [formGroup]="requestForm" firstOptionLabel="Selecione" controlName="finalityId">
          <option *ngFor="let it of listFinality"  [value]="it.finalityId" [selected]="(requestGroup && it.finalityId == requestGroup.finalityId)">{{it.finalityDescription}}</option>
        </etads-input-select>
        <etads-vmessage *ngIf="requestForm.get('finalityId').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Grupo de sintomas"></etads-label>
        <etads-input-select (change)="actionChangeGroup()" [formGroup]="requestForm" firstOptionLabel="Selecione" controlName="symptomGroupId">
          <option *ngFor="let it of listSymptomGroup"  [value]="it.symptomGroupId" [selected]="(requestGroup && it.symptomGroupId == requestGroup.symptomGroupId)">{{it.symptomGroupDescription}}</option>
        </etads-input-select>
        <etads-vmessage *ngIf="requestForm.get('symptomGroupId').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
      <div class="form-group col-md-3">
        <etads-label label="Prioridade"></etads-label>
        <etads-input-text [formGroup]="requestForm" controlName="initials" placeholder="" readonly="true"></etads-input-text>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Sintoma"></etads-label>
        <etads-input-select (change)="actionChangeSymptom()" [formGroup]="requestForm" firstOptionLabel="Selecione" controlName="symptomId">
          <option *ngFor="let it of listSymptom"  [value]="it.symptomId" [selected]="(requestGroup && it.symptomId == requestGroup.symptomId)">{{it.symptomDescription}}</option>
        </etads-input-select>
        <etads-vmessage *ngIf="requestForm.get('symptomId').errors?.required" text="Campo obrigatório"></etads-vmessage>
      </div>
      <div class="form-group col-md-3">
        <etads-label label="T1"></etads-label>
        <etads-input-text [formGroup]="requestForm" controlName="t1Description" placeholder="" readonly="true"></etads-input-text>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col">
        <etads-label label="Informações do manual"></etads-label>
        <etads-input-textArea [formGroup]="requestForm" controlName="guideInformation" style="height: 100px;"></etads-input-textArea>
      </div>
    </div>
</div>
