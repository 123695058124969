import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { ElibModel, ElibModelForm } from '../../../interfaces/elib-model';
import { ElibModelCrudService } from '../../../services/elib-model-crud.service';


@Component ({
    templateUrl: './elib-model-edit.component.html'
})

export class ElibModelEditComponent implements OnInit {

    form: FormGroup;
    modelId : Number;
    filterMap: Map<string, string > = new Map<string, string>();
    elibModel: ElibModel;
    modelList: ElibModel[];
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private elibModelCrudService : ElibModelCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService) {}



    ngOnInit(): void {

        this.alertService.clear();
        this.filterMap.set("Editar Modelo", "");
        this.modelId = this.route.snapshot.params.modelId;
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();


    }
    loadData() {

        this.elibModel = this.stateService.retrieveElibModel();
        this.modelList = this.stateService.retrieveElibModelList();

    }

    createForm () {
        this.form = this.formBuilder.group({
          description: ['',   [  Validators.required,  Validators.minLength(3)  ] ]
        });
    }


    updateForm() {
        this.form.patchValue({description:this.elibModel.description});
    }


    actionBack() {
        this.router.navigate(['elib-model-list'])
    }

    actionClear() {
        this.ngOnInit();
    }

    actionSubmit () {

        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger("Modelo Inválida ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();
        this.alertService.clear();
        const prEdit = this.form.getRawValue() as ElibModel;

        prEdit.id         = this.elibModel.id;
        prEdit.modelId = this.elibModel.modelId;
        prEdit.description   = prEdit.description.toUpperCase();

        this.subscription = this.elibModelCrudService.upd(this.elibModel, prEdit).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
            this.reloadCurrentRoute();
          }
        );
    }

    validateForm() {
        const newPr = this.form.getRawValue() as ElibModelForm;

        for (let prData of this.modelList) {
             let parse1 = prData.description.toUpperCase();
             let parse2 = newPr.description.toUpperCase();

            if (parse1 == parse2 && this.modelId != prData.modelId) {
                return false;
            }
        }
        return true;
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
