import { Injectable } from '@angular/core';
import { Ticket } from '../administration/ticket/ticket';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class TicketCrudService {

  constructor(private azureCrudService: AzureStorageCrudService) { }

  save(newReg : Ticket, checkExisting: boolean) {
    return this.azureCrudService.saveOrUpdateTicket(newReg, "saveTicket", checkExisting);
  }

  send(newReg : Ticket, checkExisting: boolean) {
    return this.azureCrudService.saveOrUpdateTicket(newReg, "sendTicket", checkExisting);
  }

  close(reg : Ticket) {
    return this.azureCrudService.add(reg, "closeTicket");
  }

  allocateTicket(newReg : Ticket, checkExisting: boolean) {
    return this.azureCrudService.add(newReg, "allocateTicket");
  }

  upd(oldReg : Ticket, newReg : Ticket) {
    return this.azureCrudService.upd(oldReg, newReg, "updateTicket");
  }

  del(reg : Ticket) {
    return this.azureCrudService.del(reg, "deleteTicket");
  }

  get() {
    return this.azureCrudService.get("getTicket");
  }

  getByPage(page: number, pageSize: number, filter: Map<string,string>, sortField, sortOrder) {
    let url = "getTicket?page=" + page + "&pagesize=" + pageSize;
    if(filter){
      filter.forEach((value,key) => {
        if(value && value != '')
          url = url + "&" + key + "=" + value;
      });
    }
    if(sortOrder)
      url = url + "&sortField=" + sortField + "&sortOrder=" + sortOrder;
    return this.azureCrudService.get(url);
  }

  getAll(filter: Map<string,string>) {
    let url = "getTicket?";
    if(filter){
      filter.forEach((value,key) => {
        if(key.startsWith("`"))
          key = key.replace("`", "")
        if(value && value != '')
          url = url + "&" + key + "=" + value;
      });
    }
    return this.azureCrudService.get(url);
  }

  getTotalItems(filter: Map<string,string>) {
    let url = "getTicket?count=true";
    if(filter){
      filter.forEach((value,key) => {
        if(value && value != '')
          url = url + "&" + key + "=" + value;
      });
    }
    return this.azureCrudService.get(url);
  }

}
