<div style="  display: block;
width: 1240px;
height: 123px;
margin: 0 auto;">
<form [formGroup]="form">

  <etads-card-form>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-3">
        <etads-label label="Usuário de Abertura"></etads-label>
        <etads-input-text [formGroup]="form" controlName="createUserName" readonly="true" placeholder="">
        </etads-input-text>
      </div>
      <div class="form-group col-3">
        <etads-label label="Responsável Atual"></etads-label>
        <etads-input-text [formGroup]="form" controlName="analystName" readonly="true" placeholder="">
        </etads-input-text>
      </div>
      <div class="form-group col-3">
        <etads-label label="Número do Chamado"></etads-label>
        <etads-input-text [formGroup]="form" controlName="ticketId" readonly="true" placeholder="">
        </etads-input-text>
      </div>
      <div class="form-group col-3">
        <etads-label label="Status do Chamado"></etads-label>
        <etads-input-text [formGroup]="form" controlName="status" readonly="true" placeholder="">
        </etads-input-text>
      </div>
    </div>

    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-3">
        <etads-label label="Importar dados da O.S."></etads-label>
        <etads-input-file-all [formGroup]="form" (change)="onFileSelected($event)">
        </etads-input-file-all>
      </div>
      <div class="form-group col-3">
        <etads-label label="Data e Hora da Importação"></etads-label>
        <etads-input-datetime [formGroup]="form" controlName="osImportDate" readonly="true" inputLabel="Data e Hora da Importação">
        </etads-input-datetime>
      </div>
      <div class="form-group col-3">
        <etads-label label="Arquivo"></etads-label>
        <etads-input-text [formGroup]="form" controlName="osImportFileName" readonly="true" placeholder="">
        </etads-input-text>
      </div>
      <div class="form-group col-3">
        <etads-days-open-group [ticket]="ticket"></etads-days-open-group>
      </div>
    </div>
    <etads-div-dealer-group></etads-div-dealer-group>
    <etads-div-vehicle-group></etads-div-vehicle-group>
    <etads-div-request-group></etads-div-request-group>
    <etads-div-diagnosis-group></etads-div-diagnosis-group>
    <etads-div-diagnosis-trouble-group></etads-div-diagnosis-trouble-group>
    <etads-div-part-cause-group></etads-div-part-cause-group>
    <etads-div-accessory-group></etads-div-accessory-group>
    <etads-div-repair-history-group></etads-div-repair-history-group>
    <etads-div-feedback-group [canAdd]="true"></etads-div-feedback-group>

    <div class="card px-2 mt-2">
      <div class="row">
        <div class="col-1">
        </div>
        <div class="col-3">
          Uso Distribuidor
        </div>
        <div class="col-5">
          Uso Toyota
        </div>
        <div class="col-3">
          Status
        </div>
      </div>
      <div class="row">
        <div class="col-1">
          <button type="submit" (click)="actionSend()" class="btn btn-primary mr-1"  [disabled]="ticket.status == 'Encerrado' || ticket.status == 'Pendente' || readOnly">
            Enviar
          </button>
        </div>
        <div class="col-3">
          <div class="card p-1">
            <div class="row">
                <button type="submit" (click)="actionSave()" class="btn btn-primary btn-sm m-1 ml-3"
                    [disabled]="(!edit || isTdbUser)">
                  Salvar Rascunho
                </button>
                <button type="button" (click)="actionRemove()" class="btn btn-primary btn-sm m-1"
                    [disabled]="ticket.status != 'Rascunho'">
                  Excluir
                </button>
                <button type="button" (click)="actionClose()" class="btn btn-primary btn-sm m-1"
                    [disabled]="!canClose || !edit">
                  Encerrar
                </button>
            </div>

          </div>
        </div>
        <div class="col-5">
          <div class="card px-2">
            <div class="row">
              <div class="col-3">
                <etads-label label="Peça Toyota"></etads-label>
                <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="pnToyota">
                  <option value="Aguardando" [selected]="(ticket.pnToyota == 'Aguardando')">Aguardando</option>
                  <option value="Entregue" [selected]="(ticket.pnToyota == 'Entregue')">Entregue</option>
                </etads-input-select>
              </div>
              <div class="col-3">
                <etads-label label="Improcedente"></etads-label>
                <etads-input-checkbox [formGroup]="form" controlName="unfounded"></etads-input-checkbox>
              </div>
              <div class="col-3">
                <button type="submit"  (click)="actionEncaminhar()" class="btn btn-primary btn-sm m-1" [disabled]="!edit || !isTdbUser">
                  Encaminhar
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-3">
                <etads-label label="Incorreto"></etads-label>
                <etads-input-checkbox [formGroup]="form" controlName="incorrect"></etads-input-checkbox>
              </div>
              <div class="col-8">
                <etads-label label="Liberado p/ encerramento"></etads-label>
                <etads-input-checkbox [formGroup]="form" controlName="closeReleased"></etads-input-checkbox>
              </div>
            </div>

          </div>
        </div>
        <div class="col-3 mb-2">
          <div class="card px-2 p-1">
            <etads-label label="Aberto em"></etads-label>
            <etads-input-text [formGroup]="form" controlName="openDate" readonly="true" placeholder=""></etads-input-text>
            <etads-label label="Encerrado em"></etads-label>
            <etads-input-text [formGroup]="form" controlName="closeDate" readonly="true" placeholder=""></etads-input-text>
          </div>
        </div>
      </div>
    </div>

  </etads-card-form>

</form>

</div>
