import { VehicleGroup } from '../../shared/components/ticket/div-vehicle-group/vehicle-group';
import { RequestGroup } from '../../shared/components/ticket/div-request-group/request-group';
import { DiagnosisGroup } from '../../shared/components/ticket/div-diagnosis-group/diagnosis-group';
import { DiagnosisTroubleGroup } from '../../shared/components/ticket/div-diagnosis-trouble-group/diagnosis-trouble-group';
import { RepairHistoryGroup } from '../../shared/components/ticket/div-repair-history-group/repair-history-group';
import { PartCauseGroup } from 'src/app/shared/components/ticket/div-part-cause-group/part-cause-group';
import { DealerGroup } from 'src/app/shared/components/ticket/div-dealer-group/dealer-group';
import { AccessoryGroup } from 'src/app/shared/components/ticket/div-accessory-group/accessory-group';
import { FeedbackGroup } from 'src/app/shared/components/ticket/div-feedback-group/feedback-group';

export class Ticket {
    ticketId: number;
    status: string;
    analyst: string;
    analystName: string;
    createDate: Date;
    openDate: Date;
    createUser: string;
    createUserName: string;
    updateUser: string;
    updateUserName: string;
    sendDate: Date;
    allocationDate: Date;
    closeDate: Date;
    lastModification: Date;
    unfounded: boolean;
    incorrect: boolean;
    closeReleased: boolean;
    osImportDate: Date;
    osImportFileName: string;
    pnToyota: string;
    currentProfile: string;
    lastProfile: string;
    hasDtc: string;
    emailDailyDrl1: string;
    emailDailyDrl2: string;
    emailDailyDrl3: string;
    emailDailyTdb1: string;
    emailDailyTdb2: string;
    emailDailyTdb3: string;

    dealerGroup: DealerGroup;
    vehicleGroup: VehicleGroup;
    requestGroup: RequestGroup;
    diagnosisGroup: DiagnosisGroup;
    listDiagnosisTroubleGroup: DiagnosisTroubleGroup[];
    listPartCauseGroup: PartCauseGroup[];
    listAccessoryGroup: AccessoryGroup[];
    repairHistoryGroup: RepairHistoryGroup;
    listFeedbackGroup: FeedbackGroup[];
    listFeedbackGroupDraft: FeedbackGroup[];
}
