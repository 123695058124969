import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsT2, T2, T2Form } from '../../../interfaces/t2';
import { T2CrudService } from '../../../services/t2-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './t2-edit.component.html'
})

export class T2EditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    t2: T2;
    t2Id: number;
    t2List: EtadsT2[]=[];
    subscrtiption : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private t2CrudService: T2CrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Item T2", "");
        this.loadData();
        this.createForm();
        this.updateForm();

    }

    loadData() {

        this.t2Id = this.route.snapshot.params.t2Id;
        this.t2 = this.stateService.retrieveT2();
        this.t2List = this.stateService.retrieveT2List();
        this.spinnerService.hide();
    }


    createForm() {

        this.form = this.formBuilder.group( {
            t2Code:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(10) ] , ],
            t2Name:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            active:           [null, [  Validators.required  ] , ],

        });


    }

    updateForm() {
        this.form.patchValue({t2Code : this.t2.t2Code});
        this.form.patchValue({t2Name: this.t2.t2Name});
        this.form.patchValue({active: this.t2.active });

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("T2 Inválido ou já existente! Verifique o Código ou Nome!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const t2form   = this.form.getRawValue() as T2Form;
        let updT2 = new EtadsT2();

        updT2.id = this.t2.id;
        updT2.t2Id = this.t2.t2Id;
        updT2.t2Code = t2form.t2Code;
        updT2.t2Name = t2form.t2Name;
        updT2.active         = t2form.active;

        this.subscrtiption =  this.t2CrudService.upd(this.t2, updT2).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            updT2 = null;
            this.actionBack();
        },err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });


    }

    actionBack() {
        this.router.navigate(['t2-list']);
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as T2Form;

            for (let sgData of this.t2List) {
               let parse1 = sgData.t2Name.toUpperCase();
               let parse2 = newSg.t2Name.toUpperCase();
               let parse3 =  Number(sgData.t2Code);
               let parse4 = Number(newSg.t2Code);

               if (parse1 == parse2  && sgData.t2Id!=this.t2Id) {
                   return false;
               }
                if (parse3 == parse4  && sgData.t2Id!=this.t2Id) {
                 return false;
                }
           }

        return true;
    }

}
