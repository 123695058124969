import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { Ticket } from '../ticket';
import { UserService } from 'src/app/core/auth/user.service';
import { TicketCrudService } from '../../../services/ticket-crud-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Finality } from 'src/app/interfaces/finality';
import { AreaRelatedCrudService } from 'src/app/services/area-related-crud.service';
import { FinalityCrudService } from 'src/app/services/finality-crud.service';
import { SymptomGroupCrudService } from 'src/app/services/symptom-group-crud.service';
import { SymptomCrudService } from 'src/app/services/symptom-crud.service';
import { AreaRelated } from 'src/app/interfaces/area-related';
import { Symptom } from 'src/app/interfaces/symptom';
import { SymptomGroup } from 'src/app/interfaces/symptom-group';
import { Subscription } from 'rxjs';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
@Component({
    templateUrl: './ticket-report.component.html'
})
export class TicketReportComponent implements OnInit {

    form: FormGroup;
    ticketList: Ticket[] = [];
    currentPage: number = 1;
    filterMap: Map<string, string> = new Map<string, string>();
    status: string;
    analyst: string;
    dealerCode: string;
    profile: string;
    listFinality: Finality[];
    listAreaRelated = [];
    areaRelatedList : AreaRelated[] = [];
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    subscription : Subscription;
    dtStart: string;
    dtEnd: string;
    distributorId: string;
    finalityId: string;
    chassi: string;
    ticketId: string;
    model: string;
    areaRelatedId: string;
    symptomGroupId: string;
    symptomId: string;

    constructor(
        private formBuilder: FormBuilder,
        private stateService: StateService,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private ticketCrudServce: TicketCrudService,
        private userService: UserService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        private finalityCrudService: FinalityCrudService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private symptomCrudService: SymptomCrudService,
    ) { }

    ngOnInit(): void {
      this.createForm();
        this.filterMap.set("Relatorio de Chamados", "");

        this.spinnerService.show();
        this.alertService.clear();
        this.loadData();

        const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
        if (sucessAlertMessage != null) {
            this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
        }
    }

    createForm() {
      this.form = this.formBuilder.group({
        dtStart: [null, null],
        dtEnd: [null, null],
        distributorId: [null, null],
        finalityId: [null, null],
        chassi: [null, null],
        ticketId: [null, null],
        status: [null, null],
        model: [null, null],
        areaRelatedId: [null, null],
        symptomGroupId: [null, null],
        symptomId: [null, null]
      });
    }

    loadData() {

        this.listAreaRelated = [];

        this.areaRelatedCrudService.get().subscribe(lst => {
            this.listAreaRelated = lst;
        }, error => {
            console.log(error);
        });

        this.listFinality = [];

        this.finalityCrudService.get().subscribe(lst => {
            this.listFinality = lst;
        }, error => {
          console.log(error);
        });

        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( (lista) => {
            for (let reg of lista) {
                this.areaRelatedList.push(reg);
            }
            this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;


    }

    loadPage(page: any) {

    }

    actionChangeArea() {
      this.symptomGroupList = [];
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || this.form.get("areaRelatedId").value == '')
        return;

      this.symptomGroupCrudService.getByAreaRelated(this.form.get("areaRelatedId").value).subscribe(lst => {
          this.symptomGroupList = lst;
      }, error => {
        console.log(error);
      });
    }

    actionChangeGroup() {
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || !this.form.get("symptomGroupId")
          || this.form.get("areaRelatedId").value == '' || this.form.get("symptomGroupId").value == '')
        return;

      this.symptomCrudService.getByGroup(this.form.get("areaRelatedId").value, this.form.get("symptomGroupId").value).subscribe(lst => {
          this.symptomList = lst;
      }, error => {
        console.log(error);
      });
    }

    actionSubmit (){
      this.spinnerService.show();

      this.dtStart = this.form.get("dtStart").value;
      
      this.dtEnd = this.form.get("dtEnd").value;
      const endDate = new Date(this.dtEnd);
      endDate.setDate(endDate.getDate() + 1)
      this.dtEnd = endDate.toISOString();

      this.distributorId = this.form.get("distributorId").value;
      this.finalityId = this.form.get("finalityId").value;
      this.chassi = this.form.get("chassi").value;
      this.ticketId = this.form.get("ticketId").value;
      this.model = this.form.get("model").value;
      this.areaRelatedId = this.form.get("areaRelatedId").value;
      this.symptomGroupId = this.form.get("symptomGroupId").value;
      this.symptomId = this.form.get("symptomId").value;
      this.status = this.form.get("status").value;

      this.ticketCrudServce.getAll(this.getMapFilter()).subscribe(data => {
        this.ticketList = data;
        this.spinnerService.hide();
      }, err => {
          this.alertService.danger(err.message);
          this.spinnerService.hide();
      });
    }

    getMapFilter(){
      const endDate = new Date(this.dtEnd);
      endDate.setDate(endDate.getDate() + 1)
      this.dtEnd = endDate.toISOString();
      return new Map([
        ['createDate', (this.dtStart && this.dtStart != null) ? '>=' + formatDate(this.dtStart,'yyyy-MM-dd','en') : null],
        ['`createDate', (this.dtEnd && this.dtEnd != null) ? '<=' + formatDate(this.dtEnd,'yyyy-MM-dd','en') : null],
        ['dealerGroup.code', this.distributorId],
        ['requestGroup.finalityId', this.finalityId],
        ['vehicleGroup.chassi', (this.chassi && this.chassi != '') ? '~' + this.chassi + '~' : this.chassi],
        ['ticketId', this.ticketId],
        ['status', this.status],
        ['vehicleGroup.model', (this.model && this.model != '') ? '~' + this.model + '~' : this.model],
        ['requestGroup.relatedAreaId', this.areaRelatedId],
        ['requestGroup.symptomGroupId', this.symptomGroupId],
        ['requestGroup.symptomId', this.symptomId]
      ]);
    }

    actionEdit(ticket: Ticket) {
        console.log(ticket);
        this.stateService.storeTicket(ticket);
    }

    actionView(ticket: Ticket) {
        console.log(ticket);
        this.stateService.storeTicket(ticket);
    }

    actionRemove(ticket: Ticket) {
        console.log(ticket);
        this.stateService.storeTicket(ticket);

    }

    exportExcel(): void
    {
      this.spinnerService.show();
        let element = document.getElementById('data-table');
        const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, "relatorioChamados.xlsx");

        this.spinnerService.hide();
    }

}
