import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';
import { ElibModel } from '../interfaces/elib-model';

@Injectable({
  providedIn: 'root'
})
export class ElibModelCrudService {
  key = 'etads/elib_model';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : ElibModel) {
    return this.azureCrudService.add(newReg, "addelibmodel");
  }

  upd(oldReg : ElibModel, newReg : ElibModel) {
    return this.azureCrudService.upd(oldReg, newReg, "updateelibmodel");
  }

  del(reg : ElibModel) {
    return this.azureCrudService.del(reg, "deleteelibmodel");
  }

  get() {
    return this.azureCrudService.get("getelibmodel");
  }

}
