import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './dealer.service';
import { TdbUserGuard } from './tdbUser.guard';

@Injectable({ providedIn: 'root'})
export class AdminGuard implements CanActivate {

    constructor(
        private userService: UserService
    ) {}

    //AdminGuard, TdbUserGuard
        canActivate(
            route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

            return this.userService.checkAdminUser()
                ||this.userService.checkTdbUser();

        }
}
