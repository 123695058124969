import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { Ticket } from '../ticket';
import { TicketCrudService } from '../../../services/ticket-crud-service';
import { UserService } from 'src/app/core/auth/user.service';
import { FormBuilder, FormGroup } from '@angular/forms';
@Component({
  templateUrl: './ticket-list.component.html'
})
export class TicketListComponent implements OnInit {

  form: FormGroup;
  ticketList: Ticket[] = [];
  currentPage: number = 1;
  itemsPerPage: number = 10;
  totalItems: any;
  filterMap: Map<string, string> = new Map<string, string>();
  status: string;
  analyst: string;
  analystName: string;
  dealerCode: string;
  profileFilter: string;
  profile: string;
  dealerName: string;
  dtProduction: string;
  km: string;
  ticketFilter: string;
  priorityFilter: string;
  openDateFilter: string;
  vehicleFilter: string;
  finalityFilter: string;
  relatedAreaFilter: string;
  symptomFilter: string;
  dealerFilter: string;
  statusFilter: string;
  userFilter: string;
  groupFilter: string;
  dealerNameFilter: string;
  dtProductionFilter: string;
  kmFilter: string;
  sortOrder: string;
  sortField: string;

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private route: ActivatedRoute,
    private spinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private ticketCrudServce: TicketCrudService,
    private userService: UserService

  ) { }

  ngOnInit(): void {
    this.createForm();
    this.profile = this.userService.getProfile();
    this.filterMap.set("Consulta de Chamados", "Todos");
    this.dealerCode = this.userService.getDealerNumber();
    if (this.route.snapshot.url[0].path == 'ticket-open') {
      this.status = "Pendente,Aberto";
      this.filterMap.set("Consulta de Chamados", "Abertos");
    } else if (this.route.snapshot.url[0].path == 'ticket-close') {
      this.status = "Encerrado";
      this.filterMap.set("Consulta de Chamados", "Fechados");
    } else if (this.route.snapshot.url[0].path == 'ticket-draft') {
      this.status = "Rascunho";
      this.filterMap.set("Consulta de Chamados", "Rascunho");
    } else if (this.route.snapshot.url[0].path == 'ticket-pending') {
      this.status = "Pendente";
      this.filterMap.set("Consulta de Chamados", "Pendentes");
    } else if (this.route.snapshot.url[0].path == 'ticket-entry') {
      this.status = "Pendente,Aberto";
      this.analyst = this.userService.getUserLogin();
      this.analystName = this.userService.getUserName();
      this.profileFilter = this.profile;
      this.filterMap.set("Consulta de Chamados", "Entrada");
    } else if (this.route.snapshot.url[0].path == 'ticket-sent') {
      this.status = "Pendente,Aberto";
      this.analyst = this.userService.getUserLogin();
      this.analystName = this.userService.getUserName();
      this.profileFilter = this.userService.dealerProfile;
      this.filterMap.set("Consulta de Chamados", "Enviados");
    } else if (this.route.snapshot.url[0].path == 'ticket-pending-tdb') {
      this.status = "Pendente";
      this.filterMap.set("Consulta de Chamados", "Pendentes de Alocação");
    }

    this.spinnerService.show();
    this.alertService.clear();

    if (this.dealerCode == 'NA') {
      this.userService.getCompany().subscribe(company => {
        this.dealerCode = company.value;

        if (this.dealerCode) {
          this.loadData();
        }
      });
    } else {
      this.loadData();
    }


    const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
    if (sucessAlertMessage != null) {
      this.alertService.success(sucessAlertMessage);
      this.stateService.storeAlertSuccessMessage(null);
    }
  }

  createForm() {
    this.form = this.formBuilder.group({
      vehicleFilter: [null, null],
      ticketFilter: [null, null],
      priorityFilter: [null, null],
      openDateFilter: [null, null],
      finalityFilter: [null, null],
      relatedAreaFilter: [null, null],
      symptomFilter: [null, null],
      dealerFilter: [null, null],
      statusFilter: [null, null],
      userFilter: [null, null],
      groupFilter: [null, null],
      dealerNameFilter: [null, null],
      dtProductionFilter: [null, null],
      kmFilter: [null, null]
    });
  }

  loadData() {

    if (!this.sortField || this.sortField == '') {
      this.sortField = 'ticketId';
      this.sortOrder = 'desc'
    }

    this.ticketCrudServce.getByPage(this.currentPage, this.itemsPerPage, this.getMapFilter(), this.sortField, this.sortOrder).subscribe(data => {
      this.ticketList = data;
      this.spinnerService.hide();
    }, err => {
      this.alertService.danger(err.message);
      this.spinnerService.hide();
    });

    this.ticketCrudServce.getTotalItems(this.getMapFilter()).subscribe(data => {
      this.totalItems = data.count;
      this.spinnerService.hide();
    }, err => {
      this.alertService.danger(err.message);
      this.spinnerService.hide();
    });
  }

  loadPage(page: any) {

    this.ticketCrudServce.getByPage(this.currentPage, this.itemsPerPage, this.getMapFilter(), this.sortField, this.sortOrder).subscribe(data => {
      this.ticketList = data;
      this.spinnerService.hide();
    }, err => {
      this.alertService.danger(err.message);
      this.spinnerService.hide();
    });
  }

  getMapFilter() {
    return new Map([
      ['status', this.status],
      ['analyst', this.analyst],
      ['dealerGroup.code', (this.dealerCode && this.dealerCode != '') ? this.dealerCode : this.dealerFilter],
      ['vehicleGroup.vehicle', (this.vehicleFilter && this.vehicleFilter != '') ? '~' + this.vehicleFilter + '~' : this.vehicleFilter],
      ['ticketId', this.ticketFilter],
      ['requestGroup.priorityDescription', (this.priorityFilter && this.priorityFilter != '') ? '~' + this.priorityFilter + '~' : this.priorityFilter],
      ['openDate', this.openDateFilter],
      ['requestGroup.finalityDescription', (this.finalityFilter && this.finalityFilter != '') ? '~' + this.finalityFilter + '~' : this.finalityFilter],
      ['requestGroup.relatedAreaDescription', (this.relatedAreaFilter && this.relatedAreaFilter != '') ? '~' + this.relatedAreaFilter + '~' : this.relatedAreaFilter],
      ['requestGroup.symptomDescription', (this.symptomFilter && this.symptomFilter != '') ? '~' + this.symptomFilter + '~' : this.symptomFilter],
      ['statusFilter', (this.statusFilter && this.statusFilter != '') ? '~' + this.statusFilter + '~' : this.statusFilter],
      ['userResponsible', (this.userFilter && this.userFilter != '') ? '~' + this.userFilter + '~' : this.userFilter],
      ['currentProfile', (this.profileFilter) ? this.profileFilter : ((this.groupFilter == 'DLR') ? 'frontdealer' : ((this.groupFilter) ? 'fronttdb' : null))],
      ['dealerGroup.name', (this.dealerName && this.dealerName != '') ? this.dealerName : this.dealerNameFilter],
      ['vehicleGroup.dtProduction', (this.dtProductionFilter && this.dtProductionFilter != '') ? '~' + this.dtProductionFilter + '~' : this.dtProductionFilter],
      ['vehicleGroup.km', (this.km && this.km != '') ? this.km : this.kmFilter],
    ]);
  }

  actionEdit(ticket: Ticket) {
    console.log(ticket);
    this.stateService.storeTicket(ticket);
  }

  actionView(ticket: Ticket) {
    console.log(ticket);
    this.stateService.storeTicket(ticket);
  }

  actionRemove(ticket: Ticket) {
    console.log(ticket);
    this.stateService.storeTicket(ticket);

  }

  actionTicketFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("ticketFilter").setValue('');
      this.ticketFilter = '';
    } else
      this.ticketFilter = this.form.get("ticketFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionPriorityFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("priorityFilter").setValue('');
      this.priorityFilter = '';
    } else
      this.priorityFilter = this.form.get("priorityFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionVehicleFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("vehicleFilter").setValue('');
      this.vehicleFilter = '';
    } else
      this.vehicleFilter = this.form.get("vehicleFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionVehicleKmFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("kmFilter").setValue('');
      this.kmFilter = '';
    } else
      this.kmFilter = this.form.get("kmFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionDtProductionFilter(clear?: boolean) {
    console.log("actionDtProductionFilter")
    this.currentPage = 1;
    if (clear) {
      this.form.get("dtProductionFilter").setValue('');
      this.dtProductionFilter = '';
    } else
      this.dtProductionFilter = this.form.get("dtProductionFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionFinalityFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("finalityFilter").setValue('');
      this.finalityFilter = '';
    } else
      this.finalityFilter = this.form.get("finalityFilter").value;
    this.finalityFilter = this.form.get("finalityFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionRelatedAreaFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("relatedAreaFilter").setValue('');
      this.relatedAreaFilter = '';
    } else
      this.relatedAreaFilter = this.form.get("relatedAreaFilter").value;
    this.relatedAreaFilter = this.form.get("relatedAreaFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionDealerNameFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("dealerNameFilter").setValue('');
      this.dealerNameFilter = '';
    } else
      this.dealerNameFilter = this.form.get("dealerNameFilter").value;
    this.dealerNameFilter = this.form.get("dealerNameFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }


  actionSymptomFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("symptomFilter").setValue('');
      this.symptomFilter = '';
    } else
      this.symptomFilter = this.form.get("symptomFilter").value;
    this.symptomFilter = this.form.get("symptomFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionDealerFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("dealerFilter").setValue('');
      this.dealerFilter = '';
    } else
      this.dealerFilter = this.form.get("dealerFilter").value;
    this.dealerFilter = this.form.get("dealerFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionStatusFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("statusFilter").setValue('');
      this.statusFilter = '';
    } else
      this.statusFilter = this.form.get("statusFilter").value;
    this.statusFilter = this.form.get("statusFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionUserFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("userFilter").setValue('');
      this.userFilter = '';
    } else
      this.userFilter = this.form.get("userFilter").value;
    this.userFilter = this.form.get("userFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionGroupFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("groupFilter").setValue('');
      this.groupFilter = '';
    } else
      this.groupFilter = this.form.get("groupFilter").value;
    this.groupFilter = this.form.get("groupFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  actionOpenDateFilter(clear?: boolean) {
    this.currentPage = 1;
    if (clear) {
      this.form.get("openDateFilter").setValue('');
      this.openDateFilter = '';
    } else
      this.openDateFilter = this.form.get("openDateFilter").value;
    this.openDateFilter = this.form.get("openDateFilter").value;
    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }

  sortHeaderClick(field: string) {
    this.currentPage = 1;
    if (this.sortField && this.sortField != field)
      this.sortOrder = null;
    this.sortOrder = (!this.sortOrder) ? 'asc' : ((this.sortOrder == 'asc') ? 'desc' : null);
    this.sortField = field;

    this.spinnerService.show();
    this.alertService.clear();
    this.loadData();
  }
}
