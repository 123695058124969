import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";  
import { AlertService } from '../../../shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { GroupDlrSchedule } from '../../../interfaces/group-dlr-schedule';
import { GroupDlrScheduleCrudService } from '../../../services/group-dlr-schedule-crud.service';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './group-dlr-schedule-remove.component.html'
})
export class GroupDlrScheduleRemoveComponent implements OnInit {


    form: FormGroup;    
    groupDlrScheduleId : number;
    messageLabel : string;
    groupDlrScheduleToDel : GroupDlrSchedule;
    subscription : Subscription;
    
    constructor(
        private formBuilder: FormBuilder,
        private groupDlrScheduleCrudService: GroupDlrScheduleCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.groupDlrScheduleId = this.route.snapshot.params.groupDlrScheduleId;
        this.groupDlrScheduleToDel = this.stateService.retrieveGroupDlrSchedule();
      
        this.alertService.warning("Deseja excluir Ecalonamento de Grupo DLR : " + this.groupDlrScheduleToDel.groupDlrScheduleTitle + " ?");

        this.form = this.formBuilder.group({
           groupDlrScheduleId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        
        this.subscription = this.groupDlrScheduleCrudService.del(this.groupDlrScheduleToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

        
    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['group-dlr-schedule-list']);
    }

}
