import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../../services/state.service';
import { DirectImportChassis, EtadsDirectImportChassis } from '../../../../interfaces/direct-import-chassis';
import { DirectImportChassiCrudService } from '../../../../services/direct-import-chassis-crud.service';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './chassis-remove.component.html'
})
export class DirectImportChassisRemoveComponent implements OnInit {


    form: FormGroup;    
    directImportChassisId : number;
    messageLabel : string;
    chassiToDel : DirectImportChassis;
    subscription: Subscription;    

    constructor(
        private formBuilder: FormBuilder,
        private directImportChassiCrudService: DirectImportChassiCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.directImportChassisId = this.route.snapshot.params.directImportChassisId;
        this.chassiToDel = this.stateService.retrieveChassis();
        
        console.log( this.chassiToDel.chassi );

        this.alertService.warning("Deseja excluir o Chassi : " + this.chassiToDel.chassi + " ?");

        this.form = this.formBuilder.group({
            directImportChassisId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        
        this.subscription = this.directImportChassiCrudService.del(this.chassiToDel).subscribe(
            (data) => {
                this.stateService.storeAlertSuccessMessage(data.message);
                this.spinnerService.hide();
                this.actionBack();  
            },
            (error) => {
                this.stateService.storeAlertSuccessMessage(error.message);
                this.spinnerService.hide();
            });

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['chassis-list']);
    }

}
