<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Título</th>    
                <th>Nível</th> 
                <th>Email</th> 
                <th>Situação</th>
                <th>Distribuidores</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let gtsreg of groupTdbScheduleList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{gtsreg.groupTdbScheduleTitle}}</td>
                <td>{{gtsreg.level}}</td>
                <td>{{gtsreg.email}}</td>
                <td>{{(gtsreg.active === true) ? "Ativo" : "Inativo" }}</td>
                <td>{{gtsreg.distributor}}</td>
                <td>
                    <etads-tooltip label="Editar Escalonamento Grupo Tdb" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(gtsreg)" 
                            link="group-tdb-schedule-edit" [parameter]="gtsreg.groupTdbScheduleId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Escalonamento Grupo TDB">
                        <etads-icon-remove  (clickEmmiter)="actionRemove(gtsreg)"
                            link="group-tdb-schedule-remove" [parameter]="gtsreg.groupTdbScheduleId">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Nova Escalonamento Grupo Tdb"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>