import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { FeedbackGroup } from './feedback-group';

@Component({
  selector: 'etads-div-feedback-history-group',
  templateUrl: './div-feedback-history-group.component.html',

})
export class DivFeedbackHistoryGroupComponent {

  listFeedbackGroup: FeedbackGroup[];

  constructor(
  ) { }

  ngOnInit(): void {
    this.listFeedbackGroup = [];
  }
}
