<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle>
            <tr>
                <th>Acessório</th>
                <th>Situação</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let acessorio of acessoriesList | paginate : {itemsPerPage: itemsPerPage, currentPage:currentPage } ">
                <td>{{ acessorio.acessoriesDescription }}</td>
                <td>{{ (acessorio.active === true ) ? "Ativo" : "Inativo"  }}</td>
                <td>
                    <etads-tooltip label="Editar Acessório">
                        <etads-icon-edit (clickEmmiter)="actionEdit(acessorio)" 
                            link="acessories-edit" [parameter]="acessorio.acessoriesId" >
                        </etads-icon-edit> 
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Editar Acessório">
                        <etads-icon-remove (clickEmmiter)="actionRemove(acessorio)" 
                            link="acessories-remove" [parameter]="acessorio.acessoriesId" >
                        </etads-icon-remove> 
                    </etads-tooltip>
                </td>
            </tr>     
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Acessório "></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>

</etads-card-list>