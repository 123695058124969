<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th rowspan="2">Escalonamento</th>    
                <th rowspan="2">Prioridade</th> 
                <th colspan="8" allign="center">Tempo em Dias</th> 
                <th rowspan="2">Criação</th>
                <th rowspan="2">Modificação</th>
                <th rowspan="2">Editar</th>
                <th rowspan="2">Excluir</th>

            </tr>
            <tr>    
                <th>Abaixo de</th>
                <th>Acima de</th>
                <th>DLR 1</th>
                <th>DLR 2</th>
                <th>DLR 3</th>
                <th>TDB 1</th>
                <th>TDB 2</th>
                <th>TDB 3</th>

            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let dailysched of dailyScheduleList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{dailysched.dailyScheduleDescription}}</td>
                <td>{{dailysched.priorityInitials}}</td>
                <td>{{dailysched.rangeInit}}</td>
                <td>{{dailysched.rangeFinish}}</td>
                <td>{{dailysched.dlr1}}</td>
                <td>{{dailysched.dlr2}}</td>
                <td>{{dailysched.dlr3}}</td>
                <td>{{dailysched.tdb1}}</td>
                <td>{{dailysched.tdb2}}</td>
                <td>{{dailysched.tdb3}}</td>
                <td>{{dailysched.created}}</td>
                <td>{{dailysched.modified}}</td>
              
                <td>
                    <etads-tooltip label="Editar Escalonamento Diário" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(dailysched)" 
                            link="daily-schedule-edit" [parameter]="dailysched.dailyScheduleId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Escalonamento Diário" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(dailysched)"
                            link="daily-schedule-remove" [parameter]="dailysched.dailyScheduleId">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Escalonamento"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>