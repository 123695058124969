<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Id</th>
                <th>Descrição</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let pdata of modelList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{ pdata.modelId }}</td>
                <td>{{ pdata.description }}</td>

                <td>
                    <etads-tooltip label="Editar Modelo" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(pdata)"
                            link="elib-model-edit" [parameter]="pdata.modelId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Modelo" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(pdata)"
                            link="elib-model-remove" [parameter]="pdata.modelId">
                        </etads-icon-remove>
                    </etads-tooltip>
                </td>

            </tr>
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls
            (pageChange)="currentPage = $event"
            previousLabel="Anterior"
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Modelo"></etads-button-add>
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
