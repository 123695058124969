<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Finalidade</th>    
                <th>Grupo Usários</th> 
                <th>Situação</th>
                <th>Reincidente</th>
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let finreg of finalityList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{finreg.finalityDescription}}</td>
                <td>{{finreg.userGroup}}</td>
                <td>{{ (finreg.active === true ) ? "Ativo" : "Inativo" }}</td>
                <td>{{ (finreg.repeatEvent === true ) ? "Sim" : "Não" }}</td>
                <td>
                    <etads-tooltip label="Editar Finalidade" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(finreg)" 
                            link="finality-edit" [parameter]="finreg.finalityId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Finalidade" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(finreg)"
                            link="finality-remove" [parameter]="finreg.finalityId">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Nova Finalidade"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>