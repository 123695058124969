import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { EtadsSymptomGroup, SymptomGroup } from '../../../interfaces/symptom-group';
import { Subscription } from 'rxjs';
import { ThrowStmt } from '@angular/compiler';


@Component({
    templateUrl: './symptom-group-remove.component.html'
})
export class SymptomGroupRemoveComponent implements OnInit {


    form: FormGroup;    
    symptomGroupId : number;
    messageLabel : string;
    symptomGroupToDel : SymptomGroup;
    subscription: Subscription;
    
        
    constructor(
        private formBuilder: FormBuilder,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.symptomGroupId = this.route.snapshot.params.symptomGroupId;
        this.symptomGroupToDel = this.stateService.retrieveSymptomGroup();
        
        this.alertService.warning("Deseja excluir a Prioridade: " + this.symptomGroupId + " - " + this.symptomGroupToDel.symptomGroupDescription + " ?");

        this.form = this.formBuilder.group({
            symptomGroupId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        this.subscription = this.symptomGroupCrudService.del(this.symptomGroupToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
        

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['symptom-group-list']);
    }

}
