import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Acessories } from 'src/app/interfaces/acessories';
import { AcessoriesCrudService } from 'src/app/services/acessories-crud.service';
import { StateService } from 'src/app/services/state.service';
import { AccessoryGroup } from './accessory-group';

@Component({
  selector: 'etads-div-accessory-group',
  templateUrl: './div-accessory-group.component.html',

})
export class DivAccessoryGroupComponent {

  accessoryForm: FormGroup;
  accessoryGroup: AccessoryGroup;
  sentToToyota: boolean;

  listAccessories: Acessories[];

  listAccessoryGroup: AccessoryGroup[];

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private accessoriesCrudService: AcessoriesCrudService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadData();
    this.listAccessoryGroup = [];
    this.sentToToyota = false;

    let accessoryGroup: AccessoryGroup[] = this.stateService.retrieveTicket().listAccessoryGroup;
    this.listAccessoryGroup = accessoryGroup;

    if(!this.stateService.retrieveEdit())
      this.accessoryForm.disable();
  }

  createForm() {
    this.accessoryForm = this.formBuilder.group({
      acessoriesId: [null, null],
      acessoriesDescription: [null, null],
      others: [null, null],
      genuine: [null, null]
    });
  }

  loadData() {
    // caregando lista de Distribuidores
    this.listAccessories = [];

    this.accessoriesCrudService.get().subscribe(lst => {
        this.listAccessories = lst;
    }, error => {
      console.log(error);
    });
  }

  actionSubmitAccessory() {
    console.log("submit accessory");
  }

  actionAdd() {
    this.accessoryGroup = this.accessoryForm.getRawValue() as AccessoryGroup;
    if(this.accessoryGroup && this.accessoryGroup.acessoriesId && this.stateService.retrieveEdit())
    {
      let acessories = this.listAccessories.filter(a => a.acessoriesId === parseInt(this.accessoryForm.get("acessoriesId").value))
      this.accessoryGroup.acessoriesDescription = acessories[0].acessoriesDescription;
    }
    if(!this.listAccessoryGroup)
      this.listAccessoryGroup = [this.accessoryGroup];
    else
      this.listAccessoryGroup.push(this.accessoryGroup);
    this.accessoryForm.reset();
  }

  actionRemove(item: AccessoryGroup) {
    if(!this.stateService.retrieveEdit())
      return;
    this.listAccessoryGroup.splice(this.listAccessoryGroup.indexOf(item), 1);
  }
}
