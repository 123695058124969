import { HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Context } from 'node:vm';
import { BehaviorSubject, Observable } from 'rxjs';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class StatusElibraryService {

  constructor(private azureCrudService: AzureStorageCrudService) { }



  getBlockStatusElibrary(): Object {
    let statusTDB = true;
    let statusDiler = false;

    let res: Object =
     {
        "status": 200,
        "body": {"block_tdb": statusTDB, "block_dealer": statusDiler},
        "headers": {
            'Content-Type': 'application/json'
        }
    };

    return res;
};



  /*async getStatusElibrary():Promise<Object> {
    let resp = this.getBlockStatusElibrary();

    ////Alterar para chamar a API
    ///this.azureCrudService.get("getBlockStatusElibrary").subscribe;

    return resp['body'];
  }*/

  getStatusElibrary() {
    return this.azureCrudService.get("getBlockStatusElibrary");
  }
}
