import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-card-form',
    templateUrl: './card-form.component.html',
    styleUrls: ['./card-form.component.css']
})
export class CardFormComponent { 

    constructor() {}
    
}