import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";  
import { AlertService } from '../../../shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { Finality } from '../../../interfaces/finality';
import { FinalityCrudService } from '../../../services/finality-crud.service';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './finality-remove.component.html'
})
export class FinalityRemoveComponent implements OnInit {


    form: FormGroup;    
    finalityId : number;
    messageLabel : string;
    finalityToDel : Finality;
    subscripiton : Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private finalityCrudService: FinalityCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.finalityId = this.route.snapshot.params.finalityId;
        this.finalityToDel = this.stateService.retrieveFinality();
      
        this.alertService.warning("Deseja excluir a Finalidade : " + this.finalityToDel.finalityDescription + " ?");

        this.form = this.formBuilder.group({
           finalityId: [''] 
        });
        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        
        this.subscripiton = this.finalityCrudService.del(this.finalityToDel).subscribe ((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();    
        });

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['finality-list']);
    }

}
