import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { AcessoriesCrudService } from '../../../services/acessories-crud.service';
import { Acessories } from '../../../interfaces/acessories';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './acessories-remove.component.html'
})
export class AcessoriesRemoveComponent implements OnInit {


    form: FormGroup;
    acessoriesId : number;
    messageLabel : string;
    acessoriesToDel: Acessories;
    subscription : Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private acessoriesCrudService: AcessoriesCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.acessoriesId = this.route.snapshot.params.acessoriesId;
        this.acessoriesToDel = this.stateService.retrieveAcessories();

        console.log( this.acessoriesToDel.acessoriesDescription );

        this.alertService.warning("Deseja excluir o Acessório : " + this.acessoriesToDel.acessoriesId + " - " + this.acessoriesToDel.acessoriesDescription + " ?");

        this.form = this.formBuilder.group({
            acessoriesId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.acessoriesCrudService.del(this.acessoriesToDel).subscribe(
            (data) => {
                this.stateService.storeAlertSuccessMessage(data.message);
                this.spinnerService.hide();
                this.actionBack();
            },
            (error) => {
                this.stateService.storeAlertSuccessMessage(error.error);
                this.spinnerService.hide();
            });

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['acessories-list']);
    }

}
