import { Injectable } from '@angular/core';
import { LocalStorageCrudService } from './local-storage-crud.service';
import { GroupTdbSchedule } from '../interfaces/group-tdb-schedule';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class GroupTdbScheduleCrudService {
  key = 'etads/groupTdbSchedule';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : GroupTdbSchedule) {
    return this.azureCrudService.add(newReg, "addGroupTdbSchedule");
  }

  upd(oldReg : GroupTdbSchedule, newReg : GroupTdbSchedule) {
    return this.azureCrudService.upd(oldReg, newReg, "updateGroupTdbSchedule");
  }

  del(reg : GroupTdbSchedule) {
    return this.azureCrudService.del(reg, "deleteGroupTdbSchedule");
  }

  get() {
    return this.azureCrudService.get("getGroupTdbSchedule");
  }
}
