import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { Symptom } from '../../../interfaces/symptom';
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './symptom-list.component.html'
})
export class SymptomListComponent implements OnInit {


    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    symptomList : Symptom[] = [];   
    symptomGroupList : SymptomGroup[] = [];   
    symptom : Symptom;
    symptomGroupId : number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;


    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private symptomCrudService:  SymptomCrudService,
        ) {}

  

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Sintomas",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
        
      
    }

    loadData() {

         this.subscription = this.symptomCrudService.get().subscribe( reg => {
             for (let data of reg ) {
              let idList = this.symptomList.push(data);
             }
             this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         });
         
    }      
           
    actionAdd() {
        this.stateService.storeSymptomList(this.symptomList);
        this.router.navigate(['symptom-create']);
    }

    actionEdit( symptom: Symptom ) {
        this.stateService.storeSymptom(symptom);
        this.stateService.storeSymptomList(this.symptomList);
    }

    actionRemove( symptom: Symptom ) {
        this.stateService.storeSymptom (symptom);
    }


}