<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th *ngIf="isTdbUser">Modificado</th>
                <th>Veículo(Divisão)</th>
                <th>Ano Modelo</th>
                <th>Descrição</th>
                <th>Area Relacionada</th>
                <th>Sintoma</th>
                <th>Prioridade</th>
                <th *ngIf="isTdbUser">Editar</th>
                <th *ngIf="isTdbUser">Excluir</th>
                <th>Visualizar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let obj of list | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td *ngIf="isTdbUser">{{obj.lastModification}}</td>
                <td>{{obj.vehicleDescription}}</td>
                <td>{{obj.modelYear}}</td>
                <td>{{obj.description}}</td>
                <td>{{obj.areaRelatedId}} - {{obj.areaRelatedDescription}} </td>
                <td>{{obj.symptomId}} - {{obj.symptomDescription}} </td>
                <td>{{obj.initials}} </td>

                <td *ngIf="isTdbUser">
                    <etads-tooltip label="Editar" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(obj)"
                            link="technicaltips-edit" [parameter]="obj.technicalTipsId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td *ngIf="isTdbUser">
                    <etads-tooltip label="Excluir" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(obj)"
                            link="technicaltips-remove" [parameter]="obj.technicalTipsId">
                        </etads-icon-remove>
                    </etads-tooltip>
                </td>
                <td>
                  <etads-tooltip label="Visualizar Dica Tecnica" >
                    <a [href]="obj.attachUrl" target="_blank"><img src="assets/images/lupa.png"></a>
                  </etads-tooltip>
                </td>

            </tr>
        </tbody>
    </etads-table>
    <etads-div-center>
        <pagination-controls
            (pageChange)="currentPage = $event"
            previousLabel="Anterior"
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center *ngIf="isTdbUser">
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Item"></etads-button-add>
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
