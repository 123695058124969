import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { Priority } from '../../../interfaces/priority';
import { EtadsPriority } from '../../../interfaces/priority';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './priority-remove.component.html'
})
export class PriorityRemoveComponent implements OnInit {


    form: FormGroup;
    priorityId : number;
    messageLabel : string;
    priorityToDel : Priority;
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private priorityCrudService: PriorityCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.priorityId = this.route.snapshot.params.priorityId;
        this.priorityToDel = this.stateService.retrievePriority();

        this.alertService.warning("Deseja excluir a Prioridade: " + this.priorityToDel.initials + " - " + this.priorityToDel.alias + " ?");

        this.form = this.formBuilder.group({
            priorityId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.priorityCrudService.del(this.priorityToDel).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeAlertSuccessMessage(error.error);
            this.spinnerService.hide();
          }
        );
    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['priority-list']);
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
