export interface AreaRelated {
    id: string;
    areaRelatedId: number;
    areaDescription: string;
    active: boolean;
}

export interface AreaRelatedForm {
    areaDescription: string;
    active: boolean;
}

export class EtadsAreaRelated implements AreaRelated {
    id: string;
    areaRelatedId: number;
    areaDescription: string;
    active: boolean;
}

