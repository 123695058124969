export interface T1 {
    id: string;
    t1Id: number;
    t1Code: number;
    t1Name: string;
    symptomId : number;
    symptomDescription: string;
    symptomGroupId: number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    active:boolean ;
}

export interface T1Form {
    t1Code: number;
    t1Name: string;
    symptomId : number;
    symptomGroupId: number;
    areaRelatedId: number;
    active:boolean ;
}

export class EtadsT1 implements T1 {
    id: string;
    t1Id: number;
    t1Code: number;
    t1Name: string;
    symptomId : number;
    symptomDescription: string;
    symptomGroupId: number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    active:boolean ;
}