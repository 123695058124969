<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
            
            <etads-label label="Chassi"></etads-label>
            <etads-input-text [formGroup]="form" controlName="chassi" placeholder="Informe um chassi."></etads-input-text>
            <etads-vmessage *ngIf="form.get('chassi').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('chassi').errors?.minlength" text="Tamanho mínino de 17 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('chassi').errors?.maxlength" text="Tamanho máximo de 17 caracteres"></etads-vmessage>
            
            <etads-label label="Veículo"></etads-label>
            <etads-input-text [formGroup]="form" controlName="vehicle" placeholder="Informe a descrição do Veículo."></etads-input-text>
            <etads-vmessage *ngIf="form.get('vehicle').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('vehicle').errors?.minlength" text="Tamanho mínino de 4 caracteres"></etads-vmessage>
            
            <etads-label label="Modelo"></etads-label>
            <etads-input-text [formGroup]="form" controlName="model" placeholder="Informe o modelo do veículo."></etads-input-text>
            <etads-vmessage *ngIf="form.get('model').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('model').errors?.minlength" text="Tamanho mínino de 4 caracteres"></etads-vmessage>

            <etads-label label="Ano Modelo"></etads-label>
            <etads-input-text [formGroup]="form" controlName="modelyear" placeholder="Informe o ano/modelo."></etads-input-text>
            <etads-vmessage *ngIf="form.get('modelyear').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('modelyear').errors?.minlength" text="Tamanho mínino de 4 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('modelyear').errors?.maxlength" text="Tamanho máximo de 4 caracteres"></etads-vmessage>
            
            <etads-label label="Data de Produção"></etads-label>
            <etads-input-date [formGroup]="form" controlName="production_date" placeholder="Informe Data de Produção."></etads-input-date>
            <etads-vmessage *ngIf="form.get('production_date').errors?.requied" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Data de Venda"></etads-label>
            <etads-input-date [formGroup]="form" controlName="sales_date" placeholder="Informe a Data de Venda."></etads-input-date>
            <etads-vmessage *ngIf="form.get('sales_date').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            
            <etads-label label="Motor"></etads-label>
            <etads-input-text [formGroup]="form" controlName="motor_number" placeholder="Informe o numero do Motor ."></etads-input-text>
            <etads-vmessage *ngIf="form.get('motor_number').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            
            <etads-label label="Transmissão"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="transmission">
                <option>AUTOMATICO</option>
                <option>MANUAL</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('transmission').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>