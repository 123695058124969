import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../../shared/components/alert/alert.service';
import { StateService } from '../../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../../shared/components/components.module';
import { DirectImportChassis, DirectImportChassisForm, EtadsDirectImportChassis } from '../../../../interfaces/direct-import-chassis';
import { DirectImportChassiCrudService } from '../../../../services/direct-import-chassis-crud.service';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './chassis-edit.component.html'
})

export class DirectImportChassisEditComponent implements OnInit {

    form: FormGroup;
    chassisId: number;
    filterMap: Map<string,string> = new Map<string, string>();
    dichassi: DirectImportChassis;
    chassisList : DirectImportChassis[] = [] ;
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private directImportChassiCrudService: DirectImportChassiCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Chassi", "");
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();
    }

    loadData() {
        this.chassisId   = this.route.snapshot.params.directImportChassisId;
        this.dichassi    = this.stateService.retrieveChassis();
        this.chassisList = this.stateService.retrieveChassisList();
    }

    createForm() {

        this.form = this.formBuilder.group( {
            chassi:    ['',  [ Validators.required, Validators.minLength(17), Validators.maxLength(17)  ] ,   ],
            vehicle:   ['',  [ Validators.required, Validators.minLength(4),  Validators.maxLength(50)   ] ,   ],
            model:     ['',  [ Validators.required, Validators.minLength(4),  Validators.maxLength(50)   ] ,   ],
            modelyear: ['',  [ Validators.required, Validators.minLength(4),  Validators.maxLength(4)   ] ,   ],
            production_date: ['',  [ Validators.required  ] ,   ],
            sales_date:      ['',  [ Validators.required,  ] ,   ],
            motor_number:    ['',  [ Validators.required,Validators.maxLength(50) ] ,   ],
            transmission:    ['null',  [ Validators.required ] ,   ]

        });
    }

    updateForm() {

        this.form.patchValue({chassi:this.dichassi.chassi});
        this.form.patchValue({vehicle:this.dichassi.vehicle});
        this.form.patchValue({model:this.dichassi.model});
        this.form.patchValue({modelyear:this.dichassi.modelyear});
        this.form.patchValue({production_date:this.dichassi.production_date});
        this.form.patchValue({sales_date:this.dichassi.sales_date});
        this.form.patchValue({motor_number:this.dichassi.motor_number});
        this.form.patchValue({transmission:this.dichassi.transmission});

    }


    actionSubmit() {

        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger("Chassi Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const dataOrigin = this.stateService.retrieveChassis();
        const dataform   = this.form.getRawValue() as DirectImportChassisForm;
        let dataToUpdate = new EtadsDirectImportChassis();

        dataToUpdate.id              = dataOrigin.id;
        dataToUpdate.directImportChassisId = dataOrigin.directImportChassisId;
        dataToUpdate.chassi          = dataform.chassi.toUpperCase();
        dataToUpdate.vehicle         = dataform.vehicle.toUpperCase();
        dataToUpdate.model           = dataform.model.toUpperCase();
        dataToUpdate.modelyear       = dataform.modelyear;
        dataToUpdate.production_date = this.datepipe.transform(dataform.production_date, 'yyyy-MM-dd');
        dataToUpdate.sales_date      = this.datepipe.transform(dataform.sales_date, 'yyyy-MM-dd');
        dataToUpdate.motor_number    = dataform.motor_number.toUpperCase();
        dataToUpdate.transmission    = dataform.transmission;

        this.subscription = this.directImportChassiCrudService.upd(dataOrigin , dataToUpdate).subscribe (
            (data) => {
                let idList = this.chassisList.push(dataToUpdate);
                this.stateService.storeChassisList(this.chassisList);
                this.stateService.storeAlertSuccessMessage("Dados alterados com Sucesso.");
                this.spinnerService.hide();
                dataToUpdate = null;
                this.actionBack();

            },
            (error) => {
                this.alertService.danger(error.error.errorMessage);
                this.spinnerService.hide();
            });

    }

    actionBack() {
        this.router.navigate(['chassis-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newChassi = this.form.getRawValue() as DirectImportChassisForm;
        const dataOrigin = this.stateService.retrieveChassis();


            for (let chData of this.chassisList) {
               let parse1 = chData.chassi.toUpperCase();
               let parse2 = newChassi.chassi.toUpperCase();

               if (parse1 == parse2 &&  this.dichassi.directImportChassisId != chData.directImportChassisId) {
                   return false;
               }
           }

        return true;
    }

}
