import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Ticket } from '../administration/ticket/ticket';
import { TechnicalTips } from '../interfaces/technicaltips';

@Injectable({
  providedIn: 'root'
})
export class AzureStorageCrudService {

  constructor(private http: HttpClient) { }

  // trocar para variavel de ambiente
  //"https://etads-functions.azurewebsites.net/api/"
  private baseUrl = environment.ApiUrl;

  //-------------------------------------------------------------
  add(newReg: any, keyStorage: string) {

    const url = this.baseUrl + keyStorage;
    const body = JSON.stringify(newReg);

    return this.http.post<any>(url, body);
  }

  saveOrUpdateTicket(newReg: Ticket, keyStorage: string, checkExisting: boolean) {

    const url = this.baseUrl + keyStorage + (checkExisting?"":"?checkExisting=false");

    const ticket = JSON.stringify(newReg);
    const formData = new FormData();
    formData.append('ticket', ticket);

    if (newReg.listFeedbackGroup && newReg.listFeedbackGroup.length > 0){
      newReg.listFeedbackGroup.forEach(f =>{
        if(f.attach){
          formData.append(new Date(f.insertDate).toISOString(), f.attach);
        }
      });
    }

    const body = formData;

    return this.http.post<any>(url, body);
  }

  saveTechnicalTips(newReg: TechnicalTips, keyStorage: string) {

    const url = this.baseUrl + keyStorage;

    const technicalTips = JSON.stringify(newReg);
    const formData = new FormData();
    formData.append('technicalTips', technicalTips);

    if (newReg.attach){
          formData.append("attach", newReg.attach);
    }

    const body = formData;

    return this.http.post<any>(url, body);
  }

  //-------------------------------------------------------------
  upd(oldReg: any, newReg: any, keyStorage: string) {

    const url = this.baseUrl + keyStorage;
    const body = JSON.stringify(newReg);

    return this.http.put<any>(url, body);
  }

  //-------------------------------------------------------------
  del(delReg: any, keyStorage: string) {

    const url = this.baseUrl + keyStorage;
    const bodyStr = JSON.stringify(delReg);
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: bodyStr,
    };
    return this.http.delete<any>(url, options);
  }

  //-------------------------------------------------------------
  get(keyStorage: string) {

    const url = this.baseUrl + keyStorage;
    return this.http.get<any>(url)
  }

  //-------------------------------------------------------------
  getById(pid: number, keyStorage: string) {

    const url = this.baseUrl + keyStorage;
    const bodyStr = JSON.stringify({ id: pid });
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: bodyStr,
    };
    return this.http.get<any>(url, options);
  }

  fileUpload(file: File, keyStorage: string) {

    const url = this.baseUrl + keyStorage;

    const formData = new FormData();
    formData.append("file", file);

    return this.http.post<any>(url, formData);
  }

  fileUpload2(formData: FormData, keyStorage: string) {

    const url = this.baseUrl + keyStorage;

    return this.http.post<any>(url, formData);
  }

}
