import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { AnswerScheduleCrudService } from '../../../services/answer-schedule-crud.service';
import { EtadsAnswerSchedule, AnswerSchedule } from '../../../interfaces/answer-schedule';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './answer-schedule-list.component.html'
})
export class AnswerScheduleListComponent implements OnInit {


    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    answerScheduleList : AnswerSchedule[] = [];   
    answerSchedule : AnswerSchedule;
    answerScheduleId : number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private answerScheduleCrudService:  AnswerScheduleCrudService,
        ) {}

  

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Escalonamento Tempo de Resposta",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
    
    }

    loadData() {

        this.answerScheduleList = [];
        this.subscription = this.answerScheduleCrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.answerScheduleList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }      
           
    actionAdd() {
        this.stateService.storeAnswerScheduleList(this.answerScheduleList);
        this.router.navigate(['answer-schedule-create']);
    }

    actionEdit( answerScheduledata: AnswerSchedule) {
        this.stateService.storeAnswerSchedule(answerScheduledata);
        this.stateService.storeAnswerScheduleList(this.answerScheduleList);
    }

    actionRemove( answerSchedule: AnswerSchedule ) {
        this.stateService.storeAnswerSchedule (answerSchedule);
    }


}