import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'etads-button-download',
    templateUrl: './button-download.component.html',
    styleUrls: ['./button-download.component.css']
})
export class ButtonDownloadComponent { 

    constructor() {}

    @Input() label = 'Download';
    @Input() disabled: boolean = false; 
    
    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}