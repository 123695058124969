import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { AreaRelated, EtadsAreaRelated } from '../../../interfaces/area-related';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './area-related-list.component.html'
})
export class AreaRelatedListComponent implements OnInit {
    
      
    areaRelatedId : number;
    areaRelatedList: AreaRelated[] = [];
    areaRelated: AreaRelated;
    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription: Subscription;

    constructor (
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private areaRelatedCrudService : AreaRelatedCrudService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Área Relacionada", "");
        this.loadData();

        const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
        if (sucessAlertMessage != null) {
            this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
        }
    }

    loadData() {

        this.subscription = this.areaRelatedCrudService.get().subscribe(
            (reg) => {
                for (let data of reg ) {
                    let idList = this.areaRelatedList.push(data);
                }    
                this.spinnerService.hide();
            }, (err) => {
                this.alertService.danger(err.message);
                this.spinnerService.hide();
            });
    }
 
    actionAdd() {
        this.stateService.storeAreaRelatedList(this.areaRelatedList);
        this.router.navigate(['area-related-create']);
    }

    actionEdit( areaRelatedData: AreaRelated) {
        console.log(areaRelatedData);
        this.stateService.storeAreaRelated( areaRelatedData );
        this.stateService.storeAreaRelatedList(this.areaRelatedList);
    }

    actionRemove( areaRelatedData: EtadsAreaRelated) {
        this.stateService.storeAreaRelated(areaRelatedData);
    }

}