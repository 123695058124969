import { ErrorHandler, Injectable, Injector, NgZone } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private injector: Injector, private zone: NgZone) {}

    handleError(error: any): void {

        this.injector.get(Router)
        const router = this.injector.get(Router);
        let message : string = error.message ? error.message : error.toString();

        if (message.includes("Problema Autenticação Sales Audit")) {
            message = "Usuário sem permissão de acesso a aplicação, favor abrir chamado solicitando acesso";
        }

        if (message.includes("403")) {
            message = "Usuário sem permissão de acesso a essa funcionalidade";
        }

        this.zone.run(() => router.navigate(['/error', "Ocorreu um erro inesperado: " + message]));
           
    }
}