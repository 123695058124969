<form [formGroup]="form">
    <etads-card-form>
        <div class="row mt-2">
            <div class="col-3" style="padding: 1px;">
                <div class="card mt-2">
                    <div class="card-header">
                        <etads-label label="Veículo"></etads-label>
                    </div>
                    <div class="row px-2">
                        <div class="col-12">
                            <label><strong>Chassi:</strong> {{ticket.vehicleGroup.chassi}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-12">
                            <label><strong>Veiculo:</strong> {{ticket.vehicleGroup.vehicle}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-12">
                            <label><strong>Modelo:</strong> {{ticket.vehicleGroup.model}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-4">
                            <label><strong>KM:</strong> {{ticket.vehicleGroup.km}}</label>
                        </div>
                        <div class="col-8">
                            <label><strong>Motor:</strong> {{ticket.vehicleGroup.motor}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col-6">
                            <label><strong>Ano:</strong> {{ticket.vehicleGroup.yearProdMod}}</label>
                        </div>
                        <div class="col-6">
                            <label><strong>Dt Prod:</strong> {{ticket.vehicleGroup.dtProduction}}</label>
                        </div>
                    </div>
                </div>

                <div class="card mt-2">
                    <div class="card-header">
                        <etads-label label="Solicitação"></etads-label>
                    </div>
                    <div class="row px-2">
                        <div class="col">
                            <label><strong>Área Relacionada:</strong>
                                {{ticket.requestGroup.relatedAreaDescription}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col">
                            <label><strong>Grupo Sintoma:</strong>
                                {{ticket.requestGroup.symptomGroupDescription}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col">
                            <label><strong>Sintoma:</strong> {{ticket.requestGroup.symptomDescription}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col">
                            <label><strong>Prioridade:</strong> {{ticket.requestGroup.priorityDescription}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col">
                            <label><strong>Finalidade:</strong> {{ticket.requestGroup.finalityDescription}}</label>
                        </div>
                    </div>
                    <div class="row px-2">
                        <div class="col">
                            <label><strong>T1:</strong> {{ticket.requestGroup.t1Description}}</label>
                        </div>
                    </div>
                </div>

                <div class="card mt-2">
                    <div class="card-header">
                        <etads-label label="Distribuidor"></etads-label>
                    </div>
                    <div class="row px-2">
                        <div class="col-12">
                            <label><strong>Dealer Nr:</strong> {{ticket.dealerGroup.code}}</label>
                        </div>
                    </div>
                    <div class="row  px-2">
                        <div class="col-12">
                            <label><strong>Nome:</strong> {{ticket.dealerGroup.name}}</label>
                        </div>
                    </div>
                    <div class="row  px-2">
                        <div class="col-12">
                            <label><strong>Contato:</strong> {{ticket.dealerGroup.contact}}</label>
                        </div>
                    </div>
                    <div class="row  px-2">
                        <div class="col-12">
                            <label><strong>Cargo:</strong> {{ticket.dealerGroup.role}}</label>
                        </div>
                    </div>
                    <div class="row  px-2">
                        <div class="col-12">
                            <label><strong>Fone:</strong> {{ticket.dealerGroup.telephone}}</label>
                        </div>
                    </div>
                    <div class="row  px-2">
                        <div class="col-12">
                            <label><strong>Cel:</strong> {{ticket.dealerGroup.cellphone}}</label>
                        </div>
                    </div>
                    <div class="row  px-2">
                        <div class="col-4">
                            <label><strong>UF:</strong> {{ticket.dealerGroup.state}}</label>
                        </div>
                        <div class="col-8">
                            <label><strong>Cidade:</strong> {{ticket.dealerGroup.city}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-7" style="padding: 5px; padding-top: 1px">
                <div class="card mt-2">
                    <div class="row">
                        <div class="col-4 numTkt font18">
                            <etads-label label="Nr: {{ticket.ticketId}}"></etads-label>
                        </div>
                        <div class="col-4 statusTkt font18">
                            <etads-label label="Status: {{ticket.status}}"></etads-label>
                        </div>
                        <div class="col-4 openTkt font18" [style.display]="tktStatusNum != 4 ? '' : 'none'">
                            <etads-days-open-group [ticket]="ticket"></etads-days-open-group>
                        </div>
                    </div>
                </div>
                <div class="card mt-2">
                    <div class="row" style="margin: auto;">
                        <div class="arrow">
                            <div class="rect" [style.background-color]="tktStatusNum == 1 ? 'yellow' : tktStatusNum > 1 ? 'limegreen' : 'lightgray'">
                                Rascunho
                            </div>
                            <div class="triang" [style.border-left-color]="tktStatusNum == 1 ? 'yellow' : tktStatusNum > 1 ? 'limegreen' : 'lightgray'"></div>
                        </div>
                        <div class="arrow">
                            <div class="rect" [style.background-color]="tktStatusNum == 2 ? 'yellow' : tktStatusNum > 2 ? 'limegreen' : 'lightgray'">
                                Pend. Alocação
                            </div>
                            <div class="triang" [style.border-left-color]="tktStatusNum == 2 ? 'yellow' : tktStatusNum > 2 ? 'limegreen' : 'lightgray'"></div>
                        </div>
                        <div class="arrow">
                            <div class="rect" [style.background-color]="tktStatusNum == 3 ? 'yellow' : tktStatusNum > 3 ? 'limegreen' : 'lightgray'">
                                Atendimento
                            </div>
                            <div class="triang" [style.border-left-color]="tktStatusNum == 3 ? 'yellow' : tktStatusNum > 3 ? 'limegreen' : 'lightgray'"></div>
                        </div>
                        <div class="arrow">
                            <div class="rect" [style.background-color]="tktStatusNum == 4 ? 'limegreen' : 'lightgray'">
                                Finalizado
                            </div>
                            <div class="triang" [style.border-left-color]="tktStatusNum == 4 ? 'limegreen' : 'lightgray'"></div>
                        </div>
                    </div>
                </div>

                <div class="row tabs">
                    <ul class="nav nav-tabs" style="width: 100%;">
                        <li class="nav-item">
                            <a (click)="selectTab('Tratativa')" [class]="tabSelected == 'Tratativa' ? 'nav-link active' : 'nav-link'">
                                Tratativa
                            </a>
                        </li>
                        <li class="nav-item">
                            <a (click)="selectTab('Diagnostico')" [class]="tabSelected == 'Diagnostico' ? 'nav-link active' : 'nav-link'">
                                Diagnósticos
                            </a>
                        </li>
                        <!--li class="nav-item">
                            <a (click)="selectTab('Passagem')" [class]="tabSelected == 'Passagem' ? 'nav-link active' : 'nav-link'">
                                Passagens do Veículo (HSV)
                            </a>
                        </li-->
                    </ul>
                </div>

                <div class="card" style="padding: 0">
                    <div class="row ml-1" style="padding: 0">
                        <div class="col-12 tab-body" style="padding: 0;" *ngIf="tabSelected == 'Tratativa'">
                          <etads-table class="table table-striped" style="padding: 0;">
                            <thead>
                              <tr>
                                <th class="col-2">Anexo</th>
                                <th class="col-1">Tam(KB)</th>
                                <th class="col-6">Descrição</th>
                                <th class="col-1">Usuário</th>
                                <th class="col-2">Data e Hora</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let it of listFeedbackGroup">
                                <td *ngIf="it.attachUrl">
                                  <a [href]="it.attachUrl">{{ it.attachName }}</a>
                                </td>
                                <td *ngIf="!it.attachUrl">
                                  {{ it.attachName }}
                                </td>
                                <td>{{ it.attachSize }}</td>
                                <td>{{ it.descriptionFeed }}</td>
                                <td>{{ it.insertUser }}</td>
                                <td>{{ it.insertDate | date: 'dd/MM/yyyy HH:mm'}}</td>
                              </tr>
                            </tbody>
                          </etads-table>
                        </div>
                        <div class="col-12 tab-body" *ngIf="tabSelected == 'Diagnostico'">
                            <div class="row diag-box">
                                <div class="col">
                                    <label>
                                        <strong>Reclamação do Cliente:</strong><br>
                                        {{ticket.diagnosisGroup.clientComplaint}}
                                    </label>
                                </div>
                            </div>
                            <div class="row diag-box">
                                <div class="col">
                                    <label>
                                        <strong>Detalhes da confirmação do sintoma:</strong><br>
                                        {{ticket.diagnosisGroup.symptomDetail}}
                                    </label>
                                </div>
                            </div>
                            <div class="row diag-box">
                                <div class="col">
                                    <label>
                                        <strong>Resultados Diagnósticos:</strong><br>
                                        {{ticket.diagnosisGroup.diagnosisResult}}
                                    </label>
                                </div>
                            </div>
                            <div class="row diag-box">
                                <div class="col">
                                    <label>
                                        <strong>Causa Provável:</strong><br>
                                        {{ticket.diagnosisGroup.cause}}
                                    </label>
                                </div>
                            </div>
                            <div class="row diag-box">
                                <div class="col">
                                    <label>
                                        <strong>T2:</strong><br>
                                        {{ticket.diagnosisGroup.t2Code}} -
                                        {{ticket.diagnosisGroup.t2Name}}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 tab-body" *ngIf="tabSelected == 'Passagem'">
                            <div class="row">
                                <div class="col">
                                    <label><strong>OS:</strong> {{ticket.vehicleGroup.os}}</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-2" style="padding: 1px; padding-left: 10px;">
                <div class="card mt-2">
                    <div class="card-header">
                        <etads-label label="Peças Causadoras"></etads-label>
                    </div>

                    <div class="accordion" id="accordionPartCause" style="font-size: 12px;">
                        <div class="card" *ngFor="let p of ticket.listPartCauseGroup; let i = index" [attr.data-index]="i">
                            <div class="panel-heading" role="tab" [id]="'headingPartCause'+ i">
                                <a role="button" class="btn btn-link"
                                    data-toggle="collapse" data-parent="#accordionPartCause"
                                    [href]="'#collapsePartCause'+ i" aria-expanded="true"
                                    [attr.aria-controls]="'collapsePartCause'+ i" style="font-size: 12px;">
                                    {{p.partCode}} - {{p.partDescription}}
                                </a>
                            </div>
                            <div [id]="'collapsePartCause'+ i" class="panel-collapse collapse" role="tabpanel"
                                [attr.aria-labelledby]="'headingPartCause'+ i">
                                <div class="card mt-2">
                                    <div class="row px-2">
                                        <div class="col">
                                            <label><strong>Código:</strong> {{p.partCode}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Descrição:</strong> {{p.partDescription}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Status:</strong> {{p.partStatus}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>NF de envio:</strong> {{p.nfPart}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Emissão de NF:</strong> {{p.dateNfPart | date:
                                                'dd/MM/yyyy'}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-2">
                    <div class="card-header">
                        <etads-label label="Diag. Trouble Code (DTC)"></etads-label>
                    </div>

                    <div class="accordion" id="accordionDiagnosisTrouble">
                        <div class="card" *ngFor="let d of ticket.listDiagnosisTroubleGroup; let i = index"
                            [attr.data-index]="i">
                            <div class="panel-heading" role="tab" [id]="'headingDiagnosisTrouble'+ i">
                                <a role="button" class="btn btn-link"
                                    data-toggle="collapse" data-parent="#accordionDiagnosisTrouble"
                                    [href]="'#collapseDiagnosisTrouble'+ i" aria-expanded="true"
                                    [attr.aria-controls]="'collapseDiagnosisTrouble'+ i" style="font-size: 12px;">
                                    {{d.code}} - {{d.troubleDescription}}
                                </a>
                            </div>
                            <div [id]="'collapseDiagnosisTrouble'+ i" class="panel-collapse collapse" role="tabpanel"
                                [attr.aria-labelledby]="'headingDiagnosisTrouble'+ i">
                                <div class="card mt-2">
                                    <div class="row px-2">
                                        <div class="col">
                                            <label><strong>Código:</strong> {{d.code}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Descrição:</strong> {{d.troubleDescription}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Status:</strong> {{d.troubleStatus}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card mt-2">
                    <div class="card-header">
                        <etads-label label="Acessório(s) Instalado(s)"></etads-label>
                    </div>

                    <div class="accordion" id="accordionAccessory">
                        <div class="card" *ngFor="let a of ticket.listAccessoryGroup; let i = index" [attr.data-index]="i">
                            <div class="panel-heading" role="tab" [id]="'headingAccessory'+i">
                                <a role="button" class="btn btn-link"
                                    data-toggle="collapse" data-parent="#accordionAccessory"
                                    [href]="'#collapseAccessory'+ i" aria-expanded="true"
                                    [attr.aria-controls]="'collapseAccessory'+i" style="font-size: 12px;">
                                    {{a.acessoriesDescription}}
                                </a>
                            </div>
                            <div [id]="'collapseAccessory'+ i" class="panel-collapse collapse" role="tabpanel"
                                [attr.aria-labelledby]="'headingAccessory'+ i">
                                <div class="card mt-2">
                                    <div class="row px-2">
                                        <div class="col">
                                            <label><strong>Descrição:</strong> {{a.acessoriesDescription}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Outros:</strong> {{a.other}}</label>
                                        </div>
                                    </div>
                                    <div class="row  px-2">
                                        <div class="col">
                                            <label><strong>Genuino:</strong> {{a.genuine}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-12">
                <div class="card mt-2">
                    <div class="row p-1">
                        <div class="col-1"></div>
                        <div class="col-5">
                            <div class="row">
                                <div class="col-4">
                                    <etads-label label="Peça Toyota"></etads-label>
                                </div>
                                <div class="col-4">
                                    <etads-input-checkbox  [formGroup]="form" controlName="unfounded" checkLabel="Improcedente"></etads-input-checkbox>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-4">
                                    <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="pnToyota">
                                        <option value="Aguardando">Aguardando</option>
                                        <option value="Entregue">Entregue</option>
                                    </etads-input-select>
                                </div>
                                <div class="col-3">
                                    <etads-input-checkbox  [formGroup]="form" controlName="incorrect" checkLabel="Incorreto"></etads-input-checkbox>
                                </div>
                                <div class="col-5">
                                    <etads-input-checkbox  [formGroup]="form" controlName="closeReleased" checkLabel="Liberado p/ encerramento"></etads-input-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="col-1"></div>
                        <div class="col-5" style="margin:auto">
                            <button class="btn btn-primary btn-big" (click)="actionEncaminhar()" [disabled]="!isTdbUser || ticket.status === 'Encerrado'">
                                Encaminhar
                            </button>
                            <button class="btn btn-primary btn-big" routerLink="/ticket-edit/{{ticketId}}">
                                Editar
                            </button>
                            <button class="btn btn-primary btn-big" (click)="actionSend()" [disabled]="tktStatusNum === 4" [disabled]="!edit">
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </etads-card-form>
</form>
