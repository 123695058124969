import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { TicketCrudService } from '../../../services/ticket-crud-service';
import { Acessories } from '../../../interfaces/acessories';
import { Subscription } from 'rxjs';
import { Ticket } from '../ticket';
import { UserService } from 'src/app/core/auth/user.service';



@Component({
    templateUrl: './ticket-remove.component.html'
})
export class TicketRemoveComponent implements OnInit {


    form: FormGroup;
    ticketId: number;
    messageLabel: string;
    ticketToDel: Ticket;
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private ticketCrudService: TicketCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private userService: UserService,
        private router: Router) { }


    ngOnInit(): void {
        this.spinnerService.show();

        this.ticketId = this.route.snapshot.params.ticketId;
        this.ticketToDel = this.stateService.retrieveTicket();

        this.alertService.warning("Deseja excluir o Chamado : " + this.ticketToDel.ticketId + " ?");

        this.form = this.formBuilder.group({
            ticketId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {
        this.spinnerService.show();

        this.subscription = this.ticketCrudService.del(this.ticketToDel).subscribe(
            (data) => {
                this.stateService.storeAlertSuccessMessage(data.message);
                this.spinnerService.hide();
                this.actionBack();
            },
            (error) => {
                this.stateService.storeAlertSuccessMessage(error.error.errorMessage);
                this.spinnerService.hide();
            }
        );

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        if(this.userService.isDealerUser()){
          this.router.navigate(['ticket-draft']);
        }else{
          this.router.navigate(['ticket-list']);
        }
    }

    ngOnDestroy() {
        if (this.subscription)
            this.subscription.unsubscribe();
    }


}
