import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'etads-icon-remove',
    templateUrl: './icon-remove.component.html',
    styleUrls: ['./icon-remove.component.css']
})
export class IconRemoveComponent implements OnInit {
    

    @Input() link : string;
    @Input() parameter : string;

    routerLink : string;

    ngOnInit(): void {
        this.routerLink="/" + this.link + "/" + this.parameter;
    } 

    @Output() clickEmmiter = new EventEmitter(); 

    actionClick() { 
        this.clickEmmiter.emit(); 
    } 
    
}