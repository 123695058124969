import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { T2 } from 'src/app/interfaces/t2';
import { StateService } from 'src/app/services/state.service';
import { T2CrudService } from 'src/app/services/t2-crud.service';

import { DiagnosisGroup } from './diagnosis-group';

@Component({
  selector: 'etads-div-diagnosis-group',
  templateUrl: './div-diagnosis-group.component.html',

})
export class DivDiagnosisGroupComponent {

  diagnosisForm: FormGroup;
  diagnosisGroup: DiagnosisGroup;
  listT2: T2[];
  osFile: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private t2CrudService: T2CrudService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.loadData();
    this.osFile = false;

    this.diagnosisGroup = this.stateService.retrieveTicket().diagnosisGroup;
    ///console.log("diagnosisGroup: " + JSON.stringify(diagnosisGroup));
    if (this.diagnosisGroup != undefined){
      this.updateForm(this.diagnosisGroup);
    }

    if(!this.stateService.retrieveEdit())
      this.diagnosisForm.disable();
  }

  createForm() {
    this.diagnosisForm = this.formBuilder.group({
      clientComplaint: [null, [Validators.required]],
      symptomDetail: [null, [Validators.required]],
      diagnosisResult: [null, [Validators.required]],
      cause: [null, null],
      t2Id: [null, null],
      t2Id2: [null, null]
    });
  }

  updateForm(diagnosisGroup: DiagnosisGroup) {

    this.diagnosisForm.patchValue({
      clientComplaint:  diagnosisGroup.clientComplaint,
      symptomDetail: diagnosisGroup.symptomDetail,
      diagnosisResult: diagnosisGroup.diagnosisResult,
      cause: diagnosisGroup.cause,
      t2Id: diagnosisGroup.t2Id,
      t2Id2: diagnosisGroup.t2Id
    });

    this.actionChangeT2();
  }

  loadData() {
    // caregando lista de Distribuidores
    this.listT2 = [];

    this.t2CrudService.get().subscribe(lst => {
      this.listT2 = lst;
    }, error => {
      console.log(error);
    });
  }

  actionSubmitDiagnosis() {
    console.log("submit diagnosis");
    this.diagnosisGroup = this.diagnosisForm.getRawValue() as DiagnosisGroup;

    let t2 = this.listT2.filter(t => t.t2Id === parseInt(this.diagnosisForm.get("t2Id").value))
    if (t2.length > 0) {
      this.diagnosisGroup.t2Code = t2[0].t2Code;
      this.diagnosisGroup.t2Name = t2[0].t2Name;
    }
  }

  actionChangeT2() {
    this.diagnosisForm.get("t2Id2").setValue(this.diagnosisForm.get("t2Id").value);
  }

  actionChangeT2Description() {
    this.diagnosisForm.get("t2Id").setValue(this.diagnosisForm.get("t2Id2").value);
  }

}
