<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Chassi</th>
                <th>Veículo</th> 
                <th>Modelo</th> 
                <th>Ano/Modelo</th> 
                <th>Data Produção</th> 
                <th>Data Venda</th> 
                <th>Motor</th> 
                <th>Transmissão</th> 
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let cdata of chassisList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{ cdata.chassi }}</td>
                <td>{{ cdata.vehicle }}</td>
                <td>{{ cdata.model }}</td>
                <td>{{ cdata.modelyear }}</td>
                <td>{{ cdata.production_date | date: 'dd/MM/yyyy' }}</td>
                <td>{{ cdata.sales_date | date: 'dd/MM/yyyy'}}</td>
                <td>{{ cdata.motor_number }}</td>
                <td>{{ cdata.transmission }}</td>

                <td>
                    <etads-tooltip label="Editar Chassi" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(cdata)" 
                            link="chassis-edit" [parameter]="cdata.directImportChassisId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Chassi" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(cdata)"
                            link="chassis-remove" [parameter]="cdata.directImportChassisId">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Chassi"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
