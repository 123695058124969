import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-label-without-space',
    templateUrl: './label-without-space.component.html'
})
export class LabelWithoutSpaceComponent { 

    constructor() {}

    @Input() label = '';
    
}