import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { ElibManual } from '../../../interfaces/elib-manual';
import { ElibManualCrudService } from '../../../services/elib-manual-crud.service';


@Component({
    templateUrl: './elib-manual-remove.component.html'
})
export class ElibManualRemoveComponent implements OnInit {


    form: FormGroup;
    manualId : number;
    messageLabel : string;
    elibManualToDel : ElibManual;
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private elibManualCrudService: ElibManualCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.manualId = this.route.snapshot.params.manualId;
        this.elibManualToDel = this.stateService.retrieveElibManual();

        this.alertService.warning("Deseja excluir o Manual: " + this.elibManualToDel.code + "?");

        this.form = this.formBuilder.group({
            manualId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.elibManualCrudService.del(this.elibManualToDel).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeAlertSuccessMessage(error.error);
            this.spinnerService.hide();
          }
        );
    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['elib-manual-list']);
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
