export interface Acessories {
    id: string;
    acessoriesId: number;
    acessoriesDescription: string;
    active: boolean;
}

export interface AcessoriesForm {
    acessoriesDescription: string;
    active: boolean;
}

export class EtadsAcessories {
    id: string;
    acessoriesId: number;
    acessoriesDescription: string;
    active: boolean;
}