import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { ElibManualCrudService } from '../../../services/elib-manual-crud.service';
import { ElibManual, ElibManualForm, EtadsElibManual } from '../../../interfaces/elib-manual';
import { ElibBrand, EtadsElibBrand } from 'src/app/interfaces/elib-brand';
import { ElibModel, EtadsElibModel } from 'src/app/interfaces/elib-model';
import { ElibBrandCrudService } from 'src/app/services/elib-brand-crud.service';
import { ElibModelCrudService } from 'src/app/services/elib-model-crud.service';
import { UploadService } from 'src/app/services/upload-service';
import {BlobServiceClient,AnonymousCredential,newPipeline } from '@azure/storage-blob';
import { CryptoJS } from 'crypto-js';
import { environment } from 'src/environments/environment';

@Component ({
    templateUrl: './elib-manual-create.component.html'
})

export class ElibManualCreateComponent implements OnInit {

    form: FormGroup;
    manualId: number;
    filterMap: Map<string, string> = new Map<string, string>();
    manualList: ElibManual[];
    listBrand: ElibBrand[];
    listModel: ElibModel[];
    subscription: Subscription;
    file: File;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private elibManualCrudService : ElibManualCrudService,
        private elibBrandCrudService: ElibBrandCrudService,
        private elibModelCrudService: ElibModelCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService,
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Manual" , "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();

    }

    loadData() {
        this.manualList = this.stateService.retrieveElibManualList();

        this.listBrand = [];

        this.elibBrandCrudService.get().subscribe(lst => {
            this.listBrand = lst;
        }, error => {
            console.log(error);
        });

        this.listModel = [];

        this.elibModelCrudService.get().subscribe(lst => {
            this.listModel = lst;
        }, error => {
            console.log(error);
        });
    }

    createForm () {
        this.form = this.formBuilder.group({
          name: ['',   [] ],
          code: ['',   [  Validators.required,  Validators.minLength(3)  ] ],
          description: ['',   [] ],
          startPage: ['',   [  Validators.required  ] ],
          brandId: [null,   [  Validators.required  ] ],
          modelId: [null,   [  Validators.required  ] ],
          startDate: [null,   [  Validators.required ] ],
          endDate: [null,   [] ],
          hide: [null,   [] ],
          attach: [null,   [] ],
          attachName: [null,   [] ]
        });
    }


    actionSubmit() {
        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Manual Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const prForm = this.form.getRawValue() as ElibManualForm;
        let pxdata = new EtadsElibManual() ;

        pxdata.name = prForm.name;
        pxdata.code = prForm.code;
        pxdata.description = prForm.description;
        pxdata.startPage = prForm.startPage;
        pxdata.startDate = prForm.startDate;
        pxdata.endDate = prForm.endDate;
        pxdata.hide = (prForm.hide != undefined && prForm.hide != null) ? prForm.hide : false;
        pxdata.statusUpload = "Uploading..."
        let brand = this.listBrand.filter(t => t.brandId === Number(prForm.brandId))
        if (brand.length > 0) {
          pxdata.brand = new EtadsElibBrand();
          pxdata.brand.brandId = brand[0].brandId;
          pxdata.brand.description = brand[0].description;
        }
        let model = this.listModel.filter(t => t.modelId === Number(prForm.modelId))
        if (model.length > 0) {
          pxdata.model = new EtadsElibModel();
          pxdata.model.modelId = model[0].modelId;
          pxdata.model.description = model[0].description;
        }

        this.subscription = this.elibManualCrudService.add(pxdata).subscribe(
          (data) => {
            this.stateService.storeElibManualList(this.manualList);
            this.stateService.storeAlertSuccessMessage(data.message + " - Não feche o aplicativo até que o upload esteja finalizado, mas você pode continuar executando outras atividades.");

            try{
              this.elibManualCrudService.getByCode(pxdata.code).subscribe(dat => {
                this.upload(dat[0]);
              });
            }catch(e){
              this.elibManualCrudService.getByCode(pxdata.code).subscribe(dat => {
                let current = this.elibManualCrudService.getByCode(pxdata.code)[0];
                let oldData = current;
                pxdata.statusUpload  = "Erro no upload!";
                this.subscription = this.elibManualCrudService.upd(oldData, current).subscribe();
              });

            }

            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeElibManualList(this.manualList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
          }
        );
    }

    async upload(pxdata: EtadsElibManual) {

// generate account sas token
const sasToken = environment.sasToken;
const containerName= environment.containerName;
const storageUrl= environment.storageUrl;

      const pipeline = newPipeline(new AnonymousCredential(), {
        retryOptions: { maxTries: 4 }, // Retry options
        userAgentOptions: { userAgentPrefix: "AdvancedSample V1.0.0" }, // Customized telemetry string
        keepAliveOptions: {
          // Keep alive is enabled by default, disable keep alive by setting false
          enable: false
        }
      });

      const blobServiceClient = new BlobServiceClient(`${storageUrl}${sasToken}`,
        pipeline)
      const containerClient = blobServiceClient.getContainerClient(containerName)
      if (!containerClient.exists()) {
        console.log("the container does not exist")
        await containerClient.create()

      }
      const client = containerClient.getBlockBlobClient('manual/' + pxdata.code + '.zip')
      const response = await client.uploadBrowserData(this.file, {
        blockSize: 4 * 1024 * 1024, // 4MB block size
        concurrency: 20, // 20 concurrency
        onProgress: (ev) => console.log(ev),
        blobHTTPHeaders: { blobContentType: this.file.type }
      }).then(res => {
        let oldData = pxdata;
        pxdata.statusUpload  = "Upload finalizado";
        this.subscription = this.elibManualCrudService.upd(oldData, pxdata).subscribe(
          (data) => {
            console.log(data.message)
          },
          (error) => {
            console.log(error.error.errorMessage)
          }
        );
      }).catch(exc => {
        let oldData = pxdata;
        pxdata.statusUpload  = "Erro no upload!";
        this.subscription = this.elibManualCrudService.upd(oldData, pxdata).subscribe(
          (data) => {
            console.log(data.message)
          },
          (error) => {
            console.log(error.error.errorMessage)
          }
        );
      });

      console.log(response);
    }

    actionClear() {
        this.ngOnInit();
    }

    actionBack() {
        this.router.navigate(['elib-manual-list']);
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    validateForm() {
        const newPr = this.form.getRawValue() as ElibManualForm;

        for (let prData of this.manualList) {
             let parse1 = prData.code.toUpperCase();
             let parse2 = newPr.code.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }

        if(!this.file)
          return false;

        if(!this.file.name.endsWith(".zip"))
          return false;

        return true;
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

    onFileSelected(event: any) {

      let file: File = event.target.files[0];

      if (file) {
        this.form.get("attachName").setValue(file.name);
        this.file = file;
      }
    }

    actionRemoveAttach() {
      this.file = null;
      this.form.get("attachName").setValue(null);
    }

}

