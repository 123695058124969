<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Finalidade"></etads-label>
            <etads-input-text [formGroup]="form" controlName="finalityDescription" placeholder="Título da Finalidade."></etads-input-text>
            <etads-vmessage *ngIf="form.get('finalityDescription').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('finalityDescription').errors?.minLength" text="Tamanho mínimo de 5 caractéres"></etads-vmessage> 
            <etads-vmessage *ngIf="form.get('finalityDescription').errors?.maxLength" text="Tamanho maximo de 100 caractéres"></etads-vmessage>
            
            <etads-tooltip label="Grupo de Usuários (da aplicação ETADS) responsáveis pelas informações associadas a esta finalidade.">
                <etads-label label="Grupo de Usuários ETADS"></etads-label>
                <etads-input-text [formGroup]="form" controlName="userGroup" placeholder="Informe o grupo de usuários"></etads-input-text>
                <etads-vmessage *ngIf="form.get('userGroup').errors?.required" text="Campo obrigatório"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('userGroup').errors?.minlength" text="Tamanho mínino de 1 caractere"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('userGroup').errors?.maxlength" text="Tamanho máximo de 200 caracteres"></etads-vmessage>

            </etads-tooltip>


            <etads-label label="Situação"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>

            <etads-label label="Reincidente"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="repeatEvent" checkLabel=" "></etads-input-checkbox>
            </etads-div-input-group>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>