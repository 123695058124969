<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Chassi"></etads-label>
            <etads-input-text [formGroup]="form" controlName="chassi" placeholder=""></etads-input-text>
           
            <etads-label label="Situação"></etads-label>
            <etads-input-text [formGroup]="form" controlName="km" placeholder=""></etads-input-text>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>