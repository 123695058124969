import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsGroupTdbSchedule, GroupTdbSchedule, GroupTdbScheduleForm } from '../../../interfaces/group-tdb-schedule';
import { GroupTdbScheduleCrudService } from '../../../services/group-tdb-schedule-crud.service';
import { Priority } from '../../../interfaces/priority';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { Distributor, DistributorToInput } from '../../../interfaces/distributor';
import { connectableObservableDescriptor } from 'rxjs/internal/observable/ConnectableObservable';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './group-tdb-schedule-create.component.html',
})

export class GroupTdbScheduleCreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    distributorList: DistributorToInput[]=[];
    distributorSelList: DistributorToInput[]=[];

    groupTdbSchedule: GroupTdbSchedule;
    groupTdbScheduleId: number;
    groupTdbScheduleList: EtadsGroupTdbSchedule[]=[];

    levelList: Radio[]=[];
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private groupTdbScheduleCrudService: GroupTdbScheduleCrudService,
        private distributorCrudService: DistributorCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Escalonamento Grupo TDB", "");
        this.levelList = this.getLevelList();
        this.loadData();
        this.createForm();
        this.spinnerService.hide();
    }

    getLevelList() {
        let newRadioList : Radio[]=[];

        let opt: number;
        for (opt = 1; opt <= 3; opt++) {
            let optR = new EtadsRadio();
            optR.itemId = opt ;
            optR.itemLabel = opt.toString();
            let lst = newRadioList.push(optR);
        }
        return newRadioList;
    }

    loadData() {
        this.groupTdbScheduleList = this.stateService.retrieveGroupTdbScheduleList();

         // caregando lista de Distribuidores
         this.distributorList = [];
         this.distributorSelList = [];
         this.subscription = this.distributorCrudService.get().subscribe( lst => {
              for (let distx of lst) {
                  let dist = new DistributorToInput();
                  dist.distributorCode = distx.distributorCode;
                  dist.distributorName = distx.distributorName;
                  this.distributorList.push(dist);
              }
              this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         });
    }


    createForm() {

        this.form = this.formBuilder.group( {
            groupTdbScheduleTitle: ['',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            level:  ['', [Validators.required], ],
            email:  ['', [Validators.required], ],
            active: [null, [Validators.required], ] ,
            distributorToSelect: [this.distributorList, [Validators.required], ],
            distributorSelected: [this.distributorSelList, [Validators.required], ],
        });
    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const gsform   = this.form.getRawValue() as GroupTdbScheduleForm;

        let gsToInsert = new EtadsGroupTdbSchedule();

        gsToInsert.groupTdbScheduleTitle = gsform.groupTdbScheduleTitle.toUpperCase();
        gsToInsert.level = gsform.level
        gsToInsert.email = gsform.email.toString();
        gsToInsert.active = gsform.active;
        gsToInsert.distributor = [];

        // atribuindo selecionados
        for ( let disSel of this.distributorSelList) {
            gsToInsert.distributor.push(disSel.distributorCode.toString());
        }

        this.subscription = this.groupTdbScheduleCrudService.add(gsToInsert).subscribe((data) => {
            let idList = this.groupTdbScheduleList.push(gsToInsert);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            gsToInsert = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

     actionDistributorChange() {

     }

     actionDistributorSelectChange() {

    }

    actionAddDist() {

        this.spinnerService.show();

        if (this.form.get('distributorToSelect').touched) {

            this.form.get('distributorToSelect').value.forEach( (distSe: string)  => {

                if (distSe != null ) {

                    if ( ! this.distributorSelList.find(ds => ds.distributorCode === distSe)) {
                        let newDist = new DistributorToInput();
                        newDist.distributorCode = distSe;
                        newDist.distributorName = this.distributorList.find( dl => dl.distributorCode === distSe).distributorName;
                        this.distributorSelList.push(newDist);
                }
                }

            });
        }


        this.form.patchValue({distributorSelected:this.distributorSelList});
        this.spinnerService.hide();

    }

    actionDelDist() {

        this.spinnerService.show();

        if (this.form.get('distributorSelected').touched) {

            const listSelect  = this.form.get('distributorSelected').value;

            if (listSelect != null && listSelect.length != 0 ) {
                for (let distDel of listSelect ){
                    if (distDel != null) {
                        let idx = this.distributorSelList.findIndex( regd => regd.distributorCode === distDel);
                        this.distributorSelList.splice(idx , 1);
                    }
                }
            }

            this.form.patchValue({distributorSelected: this.distributorSelList});
        }
        this.spinnerService.hide();

    }

    actionBack() {
        this.router.navigate(['group-tdb-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {

        const newSg = this.form.get('groupTdbScheduleTitle').value;

        for (let sgData of this.groupTdbScheduleList) {
            let parse1 = sgData.groupTdbScheduleTitle.toUpperCase();
            let parse2 = newSg.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }

        return true;
    }

}
