import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class VehicleService {

  constructor(private azureCrudService: AzureStorageCrudService) { }

  getByChassi(chassi: string) { 
    return this.azureCrudService.get("getVehicle?chassi="+chassi);
  }

}
