import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class PartService {

  constructor(private azureCrudService: AzureStorageCrudService) { }

  get(partnumber: string) { 
    return this.azureCrudService.get("getPart?partnumber="+partnumber);
  }

}
