import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from './user.service';

@Injectable({ providedIn: 'root'})
export class ValidGuard implements CanActivate {

    constructor(
        private userService: UserService
    ) {}

        canActivate(
            route: ActivatedRouteSnapshot,
            state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {

            return this.userService.checkAuthorized();

        }
}
