import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsDailySchedule, DailySchedule, DailyScheduleForm } from '../../../interfaces/daily-schedule';
import { DailyScheduleCrudService } from '../../../services/daily-schedule-crud.service';
import { Priority } from '../../../interfaces/priority';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './daily-schedule-edit.component.html',
})

export class DailyScheduleEditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    priorityList: Priority[];
    radioPriorList : Radio[]=[];

    dailySchedule: DailySchedule;
    dailyScheduleId: number;
    dailyScheduleList: EtadsDailySchedule[]=[];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private dailyScheduleCrudService: DailyScheduleCrudService,
        private priorityCrudService: PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Escalonamento Diário", "");
        this.loadData();
        this.createForm();
        this.updateForm();
    }

    loadData() {
        this.dailyScheduleId = this.route.snapshot.params.dailyScheduleId;
        this.dailySchedule = this.stateService.retrieveDailySchedule();
        this.dailyScheduleList = this.stateService.retrieveDailyScheduleList();
        this.currentUser = "ToBeDefined";

         /* caregando lista de Prioridades */
         this.priorityList = [];
         this.subscription = this.priorityCrudService.get().subscribe( listx => {
              for (let regx of listx) {
                this.priorityList.push(regx);
                let rop = new  EtadsRadio ();
                rop.itemId = regx.priorityId;
                rop.itemLabel = regx.initials;
                this.radioPriorList.push(rop);
              }
              this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         });
    }


    createForm() {

        this.form = this.formBuilder.group( {
            dailyScheduleDescription:           ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            priorityId:['', [Validators.required], ],
            // priorityId2:['', [Validators.required], ],
            rangeInit:       ['0',   [ Validators.required, Validators.minLength(1), Validators.maxLength(3) ] , ],
            rangeFinish:      ['0',  [ Validators.required, Validators.minLength(1), Validators.maxLength(3) ] , ],
            dlr1: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            dlr2: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            dlr3: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb1: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb2: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb3: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],

        });
    }

    updateForm() {

        this.form.patchValue({dailyScheduleDescription: this.dailySchedule.dailyScheduleDescription });
        this.form.patchValue({priorityId: this.dailySchedule.priorityId});
        this.form.patchValue({rangeInit: this.dailySchedule.rangeInit});
        this.form.patchValue({rangeFinish: this.dailySchedule.rangeFinish});
        this.form.patchValue({dlr1: this.dailySchedule.dlr1});
        this.form.patchValue({dlr2: this.dailySchedule.dlr2});
        this.form.patchValue({dlr3: this.dailySchedule.dlr3});
        this.form.patchValue({tdb1: this.dailySchedule.tdb1});
        this.form.patchValue({tdb2: this.dailySchedule.tdb2});
        this.form.patchValue({tdb3: this.dailySchedule.tdb3});

    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const dtModified = new Date();
        const gsform   = this.form.getRawValue() as DailyScheduleForm;
        let dsToUpd = new EtadsDailySchedule();
        dsToUpd.id = this.dailySchedule.id;
        dsToUpd.dailyScheduleId = this.dailySchedule.dailyScheduleId;
        dsToUpd.dailyScheduleDescription = gsform.dailyScheduleDescription.toUpperCase();
        dsToUpd.priorityId = Number(gsform.priorityId);
        dsToUpd.priorityInitials = this.priorityList.find( pr => pr.priorityId == gsform.priorityId)?.initials;
        dsToUpd.rangeInit = Number(gsform.rangeInit);
        dsToUpd.rangeFinish = Number(gsform.rangeFinish);
        dsToUpd.dlr1 = Number(gsform.dlr1);
        dsToUpd.dlr2 = Number(gsform.dlr2);
        dsToUpd.dlr3 = Number(gsform.dlr3);
        dsToUpd.tdb1 = Number(gsform.tdb1);
        dsToUpd.tdb2 = Number(gsform.tdb2);
        dsToUpd.tdb3 = Number(gsform.tdb3);
        dsToUpd.created = this.dailySchedule.created;
        dsToUpd.createdUser = this.dailySchedule.createdUser;
        dsToUpd.modified = this.datepipe.transform(dtModified, 'dd-MM-yyyy h:mm:ss a').toString();
        dsToUpd.modifiedUser  = this.currentUser;

        this.subscription = this.dailyScheduleCrudService.upd(this.dailySchedule, dsToUpd).subscribe((data) => {
            let idList = this.dailyScheduleList.push(dsToUpd);
            this.stateService.storeAlertSuccessMessage("Dados Editados com sucesso.");
            this.spinnerService.hide();
            dsToUpd = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['daily-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    onPriorityIdChange(value){
        this.form.patchValue({priorityId: value});
    }

    validateForm( ) {

        const newSg = this.form.getRawValue() as DailyScheduleForm;

            for (let sgData of this.dailyScheduleList) {
               let parse1 = sgData.dailyScheduleDescription.toUpperCase();
               let parse2 = newSg.dailyScheduleDescription.toUpperCase();

               if (parse1 == parse2 && sgData.dailyScheduleId != this.dailySchedule.dailyScheduleId) {
                   return false;
               }
           }

        return true;
    }

}
