import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsT2, T2, T2Form } from '../../../interfaces/t2';
import { T2CrudService } from '../../../services/t2-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './t2-create.component.html'
})

export class T2CreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    t2: T2;
    t2Id: number;
    t2List: EtadsT2[]=[];
    subscripton: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private t2CrudService: T2CrudService,

        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Item T2", "");
        this.loadData();
        this.createForm();
    }

    loadData() {
        this.t2List = this.stateService.retrieveT2List();
        this.spinnerService.hide();
    }


    createForm() {

        this.form = this.formBuilder.group( {
            t2Code:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(10) ] , ],
            t2Name:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            active:           [null, [  Validators.required  ] , ],
        });


    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("T2 Inválido ou já existente! Verifique o Código ou Nome!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const t2form   = this.form.getRawValue() as T2Form;
        let t2ToInsert = new EtadsT2();

        t2ToInsert.t2Code = t2form.t2Code;
        t2ToInsert.t2Name = t2form.t2Name;
        t2ToInsert.active = t2form.active;

        this.subscripton = this.t2CrudService.add(t2ToInsert).subscribe((data) => {
            let idList = this.t2List.push(t2ToInsert);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            t2ToInsert = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });
    }

    actionBack() {
        this.router.navigate(['t2-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as T2Form;

            for (let sgData of this.t2List) {
               let parse1 = sgData.t2Name.toUpperCase();
               let parse2 = newSg.t2Name.toUpperCase();
               let parse3 = sgData.t2Code.toUpperCase();
               let parse4 = newSg.t2Code.toUpperCase();

               if (parse1 == parse2) {
                   return false;
               }
               if (parse3 == parse4 ) {
                return false;
               }
           }

        return true;
    }

}
