import { Injectable } from '@angular/core';
import { SymptomGroup } from '../interfaces/symptom-group';
import { AzureStorageCrudService } from './azure-storage-crud.service';
import { LocalStorageCrudService } from './local-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class SymptomGroupCrudService {
  key = 'etads/SymptomGroup';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : SymptomGroup) {
    return this.azureCrudService.add(newReg, "addSymptomGroup");
  }

  upd(oldReg : SymptomGroup, newReg : SymptomGroup) {
    return this.azureCrudService.upd(oldReg, newReg, "updateSymptomGroup");
  }

  del(reg : SymptomGroup) {
    return this.azureCrudService.del(reg, "deleteSymptomGroup");
  }

  get() {
    return this.azureCrudService.get("getSymptomGroup");
  }

  getByAreaRelated(areaRelatedId: number) { 
    return this.azureCrudService.get("getSymptomGroupByAreaRelated?areaRelatedId="+areaRelatedId);
  }


}
