<form [formGroup]="form" (submit)= "actionSubmit()">
<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table style="font-size: 13px;">
      <tr><td class="p-3">
        <div class="row">
          <div class="col-3">
            <etads-label label="Modelo"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="modelId" style="width: 400px;">
              <option *ngFor="let it of listModel"  [value]="it.modelId" [selected]="(model && it.modelId == model.modelId)">{{it.description}}</option>
            </etads-input-select>
          </div>
          <div class="form-group col-md-2">
            <br>
            <etads-button-search [disabled]="false"></etads-button-search>
          </div>
        </div>
      </td></tr>
    </etads-table>
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th *ngIf="isTdbUser">Código</th>
                <th>Marca</th>
                <th>Modelo</th>
                <th>Descrição</th>
                <th *ngIf="isTdbUser">Página inicial</th>
                <th>Data de início</th>
                <th>Data de término</th>
                <th *ngIf="isTdbUser">Ocultar</th>
                <th *ngIf="isTdbUser">Data de modificação</th>
                <th *ngIf="isTdbUser">Status upload</th>
                <th *ngIf="isTdbUser">Editar</th>
                <th *ngIf="isTdbUser">Excluir</th>
                <th>Visualizar</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let pdata of manualList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td *ngIf="isTdbUser">{{ pdata.code }}</td>
                <td>{{ pdata.brand.description }}</td>
                <td>{{ pdata.model.description }}</td>
                <td>{{ pdata.description }}</td>
                <td *ngIf="isTdbUser">{{ pdata.startPage }}</td>
                <td>{{ pdata.startDate }}</td>
                <td>{{ pdata.endDate }}</td>
                <td *ngIf="isTdbUser">{{ pdata.hide }}</td>
                <td *ngIf="isTdbUser">{{ pdata.modificationDate }}</td>
                <td *ngIf="isTdbUser">{{ pdata.statusUpload }}<img *ngIf="(pdata.statusUpload === 'Uploading...')" src="assets/images/uploading.gif" style="width: 22px; height: 22px;"></td>

                <td *ngIf="isTdbUser">
                    <etads-tooltip label="Editar Manual" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(pdata)"
                            link="elib-manual-edit" [parameter]="pdata.manualId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td *ngIf="isTdbUser">
                    <etads-tooltip label="Excluir Manual" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(pdata)"
                            link="elib-manual-remove" [parameter]="pdata.manualId">
                        </etads-icon-remove>
                    </etads-tooltip>
                </td>
                <td>
                  <etads-tooltip label="Visualizar Manual" >
                    <a [href]="pdata.url" target="_blank"><img src="assets/images/lupa.png"></a>
                  </etads-tooltip>
                </td>

            </tr>
        </tbody>
    </etads-table>
    <div *ngIf="!manualList?.length" style="font-size: 13px;">Nenhum registro encontrado</div>
    <etads-div-center>
        <pagination-controls
            (pageChange)="currentPage = $event"
            previousLabel="Anterior"
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add *ngIf="isTdbUser" (clickEmmiter)="actionAdd()" label="Novo Manual"></etads-button-add>
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
</form>
