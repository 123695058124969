import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-email',
    templateUrl: './input-email.component.html', 
})
export class InputEmailComponent { 

    @Input() formGroup: FormGroup;
    @Input() placeholder: string;

    @Input() controlName: string;

}