import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './symptom-group-list.component.html'
})
export class SymptomGroupListComponent implements OnInit {


    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    symptomGroupList : SymptomGroup[] = [];   
    symptomGroup : SymptomGroup;
    symptomGroupId : number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private symptomGroupCrudService:  SymptomGroupCrudService,
        ) {}

  

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Grupo de Sintoma",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
        
        this.spinnerService.hide();
    }

    loadData() {

         this.subscription = this.symptomGroupCrudService.get().subscribe( (reg) => {
             for (let data of reg ) {
              let idList = this.symptomGroupList.push(data);
             }
             this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         })
         

    }      
           
    actionAdd() {
        this.stateService.storeSymptomGroupList(this.symptomGroupList);
        this.router.navigate(['symptom-group-create']);
    }

    actionEdit( symptom: SymptomGroup ) {
        this.stateService.storeSymptomGroup(symptom);
        this.stateService.storeSymptomGroupList(this.symptomGroupList);
    }

    actionRemove( symptom: SymptomGroup ) {
        this.stateService.storeSymptomGroup (symptom);
    }


}