import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { Finality, FinalityForm, EtadsFinality } from '../../../interfaces/finality';
import { FinalityCrudService } from '../../../services/finality-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './finality-edit.component.html'
})
export class FinalityEditComponent implements OnInit {

    form:FormGroup;
    finalityId: number;
    finality: Finality;
    finalityList: Finality[];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private finalityCrudService: FinalityCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Editar Finalidade", "");
        this.loadData();
        this.createForm();
        this.updateForm();
    }

    loadData() {
        this.finalityId = this.route.snapshot.params.finalityId;
        this.finality = this.stateService.retrieveFinality();
        this.finalityList = this.stateService.retrieveFinalityList();
        this.spinnerService.hide();
    }

    createForm () {
        this.form = this.formBuilder.group({
            finalityDescription: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(100) ] ],
            userGroup: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(200) ] ],
            active:   [null, [  Validators.required  ] ],
            repeatEvent:   [null, [  Validators.required  ] ],

        });
    }

    updateForm() {
        this.form.patchValue({finalityDescription: this.finality.finalityDescription });
        this.form.patchValue({userGroup:  this.finality.userGroup});
        this.form.patchValue({active: this.finality.active});
        this.form.patchValue({repeatEvent: this.finality.repeatEvent});
    }


    actionSubmit() {
        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger('Finalidade Inválida ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const finalityForm = this.form.getRawValue() as FinalityForm;
        let newFinality = new EtadsFinality();

        newFinality.id = this.finality.id;
        newFinality.finalityId = this.finality.finalityId;
        newFinality.finalityDescription = finalityForm.finalityDescription.toUpperCase();
        newFinality.userGroup = finalityForm.userGroup;
        newFinality.active = finalityForm.active.valueOf();
        newFinality.repeatEvent = finalityForm.repeatEvent.valueOf();

        this.subscription = this.finalityCrudService.upd(this.finality,newFinality).subscribe((data) =>{
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['finality-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newFin = this.form.getRawValue() as FinalityForm;

        for (let finData of this.finalityList) {
             let parse1 = finData.finalityDescription.toUpperCase();
             let parse2 = newFin.finalityDescription.toUpperCase();

            if (parse1 == parse2 && finData.finalityId != this.finalityId) {
                return false;
            }
        }
        return true;

    }

}
