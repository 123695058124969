import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { T1CrudService } from '../../../services/t1-crud.service';
import { EtadsT1, T1 } from '../../../interfaces/t1';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './t1-list.component.html'
})
export class T1ListComponent implements OnInit {


    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    t1List : EtadsT1[] = [];   
    t1 : T1;
    t1Id : number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private t1CrudService:  T1CrudService,
        ) {}

  

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Item T1",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
    }

    loadData() {

        this.t1List = [];
        this.subscription = this.t1CrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.t1List.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }      
           
    actionAdd() {
        this.stateService.storeT1List(this.t1List);
        this.router.navigate(['t1-create']);
    }

    actionEdit( t1data: T1) {
        this.stateService.storeT1(t1data);
        this.stateService.storeT1List(this.t1List);
    }

    actionRemove( t1: T1 ) {
        this.stateService.storeT1 (t1);
    }


}