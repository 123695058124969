import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'etads-input-file-all',
    templateUrl: './input-file-all.component.html',

})
export class InputFileAllComponent { 

    @Output() changeEmmiter = new EventEmitter(); 

    @Input() fileName = 'Selecionar arquivo';
    file: File;

    handleFile(file: File) { 
        this.changeEmmiter.emit(file); 
    } 

}