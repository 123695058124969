import { Injectable } from '@angular/core';
import { LocalStorageCrudService } from './local-storage-crud.service';
import { AreaRelated } from '../interfaces/area-related';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AreaRelatedCrudService {
  key = 'etads/areaRelated';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : AreaRelated) {
    return this.azureCrudService.add(newReg, "addAreaRelated");
  }

  upd(oldReg : AreaRelated, newReg : AreaRelated) {
    return this.azureCrudService.upd(oldReg, newReg, "updateAreaRelated");
  }

  del(reg : AreaRelated) {
    return this.azureCrudService.del(reg, "deleteAreaRelated");
  }

  get() {
    return this.azureCrudService.get("getAreaRelated");
  }
}
