<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
              <etads-label label="Anexo"></etads-label>
              <etads-input-file-all *ngIf="!file" [formGroup]="form" controlName="attach"
                (change)="onFileSelected($event)">
              </etads-input-file-all>

            <etads-label label="Nome"></etads-label>
            <etads-input-text [formGroup]="form" controlName="name" placeholder="Informe um Nome"></etads-input-text>
            <etads-vmessage *ngIf="form.get('name').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('name').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>

            <etads-label label="Veículo (Divisão)"></etads-label>
            <etads-input-text [formGroup]="form" controlName="vehicleDescription" placeholder="Informe a descricao do veiculo"></etads-input-text>

            <etads-label label="Ano Modelo"></etads-label>
            <etads-input-text [formGroup]="form" controlName="modelYear" placeholder="Informe o Ano/Modelo"></etads-input-text>

            <etads-label label="Descricao"></etads-label>
            <etads-input-text [formGroup]="form" controlName="description" placeholder="Informe uma Descrição"></etads-input-text>

            <etads-label label="Área Relacionada"></etads-label>
            <etads-input-select [formGroup]="form" (change)="actionChangeArea()" firstOptionLabel="Selecione" controlName="areaRelatedId">
                <option *ngFor=" let area of areaRelatedList " [value]="area.areaRelatedId" >{{area.areaDescription}}</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('areaRelatedId').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Grupo de Sintoma"></etads-label>
            <etads-input-select [formGroup]="form" (change)="actionChangeGroup()" firstOptionLabel="Selecione" controlName="symptomGroupId">
                <option *ngFor=" let gr of symptomGroupList " [value]="gr.symptomGroupId" >{{gr.symptomGroupDescription}}</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('symptomGroupId').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Sintoma"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="symptomId">
                <option *ngFor=" let gs of symptomList " [value]="gs.symptomId" >{{gs.symptomDescription}}</option>
            </etads-input-select>
            <etads-vmessage *ngIf="form.get('symptomId').errors?.required" text="Campo obrigatório"></etads-vmessage>

            <etads-label label="Prioridade"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="priorityId">
                <option *ngFor=" let priority of priorityList " [value]="priority.priorityId" >{{ priority.initials}} - {{ priority.alias}}</option>
            </etads-input-select>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>
