import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { AcessoriesCrudService } from '../../../services/acessories-crud.service';
import { Acessories, EtadsAcessories } from '../../../interfaces/acessories';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './acessories-list.component.html'
})
export class AcessoriesListComponent implements OnInit {
    
      
    acessoriesdId : number;
    acessoriesList: Acessories[] = [];
    acessories: Acessories;
    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription:  Subscription;

    constructor (
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private acessoriesCrudServce: AcessoriesCrudService,
        
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Acessórios", "");
        this.loadData();

        const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
        if (sucessAlertMessage != null) {
            this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
        }
        
    }

    loadData() {

        this.subscription = this.acessoriesCrudServce.get().subscribe( reg => {
            for (let data of reg ) {
                let idList = this.acessoriesList.push(data);
            }
            this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
    }
 
    actionAdd() {
        this.stateService.storeAcessoriesList(this.acessoriesList);
        this.router.navigate(['acessories-create']);
    }

    actionEdit( acessoriesData: Acessories) {
        console.log(acessoriesData);
        this.stateService.storeAcessories( acessoriesData );
        this.stateService.storeAcessoriesList(this.acessoriesList);
    }

    actionRemove( acessoriesData: EtadsAcessories) {
        this.stateService.storeAcessories( acessoriesData);
    }

}