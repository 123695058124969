import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { Symptom } from '../../../interfaces/symptom';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './symptom-remove.component.html'
})
export class SymptomRemoveComponent implements OnInit {


    form: FormGroup;    
    symptomId : number;
    messageLabel : string;
    symptomToDel : Symptom;
    subscription : Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private symptomCrudService: SymptomCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.symptomId = this.route.snapshot.params.symptomId;
        this.symptomToDel = this.stateService.retrieveSymptom();
        
        this.alertService.warning("Deseja excluir a Prioridade: " + this.symptomId + " - " + this.symptomToDel.symptomDescription + " ?");

        this.form = this.formBuilder.group({
            symptomGroupId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        this.subscription =  this.symptomCrudService.del(this.symptomToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide;
        });
        

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['symptom-list']);
    }

}
