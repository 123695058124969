export interface DailySchedule {
    id: string;
    dailyScheduleId: number;
    dailyScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
    created: string;
    createdUser: string;
    modified: String;
    modifiedUser: string;

}

export interface DailyScheduleForm {
    dailyScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
}

export class EtadsDailySchedule implements DailySchedule {
    id: string;
    dailyScheduleId: number;
    dailyScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
    created: string;
    createdUser: string;
    modified: String;
    modifiedUser: string;

}

