import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './core/home/home.component';
import { NotFoundComponent } from './core/errors/not-found/not-found.component';
import { PriorityListComponent } from './administration/priority/list/priority-list.component';
import { PriorityCreateComponent } from './administration/priority/create/priority-create.component';
import { PriorityRemoveComponent } from './administration/priority/remove/priority-remove.component';
import { PriorityEditComponent } from './administration/priority/edit/priority-edit.component';
import { DirectImportChassisCreateComponent } from './administration/direct-import/chassis/create/chassis-create.component';
import { DirectImportChassisListComponent } from './administration/direct-import/chassis/list/chassis-list.component';
import { DirectImportChassisRemoveComponent } from './administration/direct-import/chassis/remove/chassis-remove.component';
import { DirectImportChassisEditComponent } from './administration/direct-import/chassis/edit/chassis-edit.component';
import { AreaRelatedCreateComponent } from './administration/area-related/create/area-related-create.component';
import { AreaRelatedListComponent } from './administration/area-related/list/area-related-list.component';
import { AreaRelatedEditComponent } from './administration/area-related/edit/area-related-edit.component';
import { AreaRelatedRemoveComponent } from './administration/area-related/remove/area-related-remove.component';
import { SymptomGroupCreateComponent } from './administration/symptom-group/create/symptom-group-create.component';
import { SymptomGroupListComponent } from './administration/symptom-group/list/symptom-group-list.component';
import { SymptomGroupEditComponent } from './administration/symptom-group/edit/symptom-group-edit.component';
import { SymptomGroupRemoveComponent } from './administration/symptom-group/remove/symptom-group-remove.component';
import { SymptomCreateComponent } from './administration/symptom/create/symptom-create.component';
import { SymptomEditComponent } from './administration/symptom/edit/symptom-edit.component';
import { SymptomListComponent } from './administration/symptom/list/symptom-list.component';
import { SymptomRemoveComponent } from './administration/symptom/remove/symptom-remove.component';
import { AcessoriesCreateComponent } from './administration/acessories/create/acessories-create.component';
import { AcessoriesEditComponent } from './administration/acessories/edit/acessories-edit.component';
import { AcessoriesListComponent } from './administration/acessories/list/acessories-list.component';
import { AcessoriesRemoveComponent } from './administration/acessories/remove/acessories-remove.component';
import { T1CreateComponent } from './administration/t1/create/t1-create.component';
import { T1ListComponent } from './administration/t1/list/t1-list.component';
import { T1EditComponent } from './administration/t1/edit/t1-edit.component';
import { T1RemoveComponent } from './administration/t1/remove/t1-remove.component';
import { T2CreateComponent } from './administration/t2/create/t2-create.component';
import { T2EditComponent } from './administration/t2/edit/t2-edit.component';
import { T2ListComponent } from './administration/t2/list/t2-list.component';
import { T2RemoveComponent } from './administration/t2/remove/t2-remove.component';
import { GeneralScheduleCreateComponent } from './administration/general-schedule/create/general-schedule-create.component';
import { GeneralScheduleEditComponent } from './administration/general-schedule/edit/general-schedule-edit.component';
import { GeneralScheduleListComponent } from './administration/general-schedule/list/general-schedule-list.component';
import { GeneralScheduleRemoveComponent } from './administration/general-schedule/remove/general-schedule-remove.component';
import { DailyScheduleCreateComponent } from './administration/daily-schedule/create/daily-schedule-create.component';
import { DailyScheduleEditComponent } from './administration/daily-schedule/edit/daily-schedule-edit.component';
import { DailyScheduleListComponent } from './administration/daily-schedule/list/daily-schedule-list.component';
import { DailyScheduleRemoveComponent } from './administration/daily-schedule/remove/daily-schedule-remove.component';
import { AnswerScheduleCreateComponent } from './administration/answer-schedule/create/answer-schedule-create.component';
import { AnswerScheduleEditComponent } from './administration/answer-schedule/edit/answer-schedule-edit.component';
import { AnswerScheduleListComponent } from './administration/answer-schedule/list/answer-schedule-list.component';
import { AnswerScheduleRemoveComponent } from './administration/answer-schedule/remove/answer-schedule-remove.component';
import { FinalityCreateComponent } from './administration/finality/create/finality-create.component';
import { FinalityEditComponent } from './administration/finality/edit/finality-edit.component';
import { FinalityListComponent } from './administration/finality/list/finality-list.component';
import { FinalityRemoveComponent } from './administration/finality/remove/finality-remove.component';
import { DistributorCreateComponent } from './administration/distributor/create/distributor-create.component';
import { DistributorEditComponent } from './administration/distributor/edit/distributor-edit.component';
import { DistributorListComponent } from './administration/distributor/list/distributor-list.component';
import { DistributorRemoveComponent } from './administration/distributor/remove/distributor-remove.component';
import { GroupTdbScheduleCreateComponent } from './administration/group-tdb-schedule/create/group-tdb-schedule-create.component';
import { GroupTdbScheduleEditComponent } from './administration/group-tdb-schedule/edit/group-tdb-schedule-edit.component';
import { GroupTdbScheduleListComponent } from './administration/group-tdb-schedule/list/group-tdb-schedule-list.component';
import { GroupTdbScheduleRemoveComponent } from './administration/group-tdb-schedule/remove/group-tdb-schedule-remove.component';
import { GroupDlrScheduleCreateComponent } from './administration/group-dlr-schedule/create/group-dlr-schedule-create.component';
import { GroupDlrScheduleEditComponent } from './administration/group-dlr-schedule/edit/group-dlr-schedule-edit.component';
import { GroupDlrScheduleListComponent } from './administration/group-dlr-schedule/list/group-dlr-schedule-list.component';
import { GroupDlrScheduleRemoveComponent } from './administration/group-dlr-schedule/remove/group-dlr-schedule-remove.component';
import { TicketCreateComponent } from './administration/ticket/create/ticket-create.component';
import { TicketListComponent } from './administration/ticket/list/ticket-list.component';
import { TicketRemoveComponent } from './administration/ticket/remove/ticket-remove.component';
import { TicketViewComponent } from './administration/ticket/view/ticket-view.component';
import { TicketReportComponent } from './administration/ticket/report/ticket-report.component';
import { ElibBrandListComponent } from './administration/elib-brand/list/elib-brand-list.component';
import { ElibBrandCreateComponent } from './administration/elib-brand/create/elib-brand-create.component';
import { ValidGuard } from './core/auth/valid.guard';
import { MsalGuard } from '@azure/msal-angular';
import { AdminGuard } from './core/auth/admin.guard';
import { TdbUserGuard } from './core/auth/tdbUser.guard';
import { ElibBrandEditComponent } from './administration/elib-brand/edit/elib-brand-edit.component';
import { ElibBrandRemoveComponent } from './administration/elib-brand/remove/elib-brand-remove.component';
import { ElibModelListComponent } from './administration/elib-model/list/elib-model-list.component';
import { ElibModelCreateComponent } from './administration/elib-model/create/elib-model-create.component';
import { ElibModelEditComponent } from './administration/elib-model/edit/elib-model-edit.component';
import { ElibModelRemoveComponent } from './administration/elib-model/remove/elib-model-remove.component';
import { ReadOnlyEtadsGuard } from './core/auth/readOnlyEtads.guard';
import { ElibManualListComponent } from './administration/elib-manual/list/elib-manual-list.component';
import { ElibManualCreateComponent } from './administration/elib-manual/create/elib-manual-create.component';
import { ElibManualEditComponent } from './administration/elib-manual/edit/elib-manual-edit.component';
import { ElibManualRemoveComponent } from './administration/elib-manual/remove/elib-manual-remove.component';
import { TechnicalTipsCreateComponent } from './administration/technicaltips/create/technicaltips-create.component';
import { TechnicalTipsListComponent } from './administration/technicaltips/list/technicaltips-list.component';
import { TechnicalTipsEditComponent } from './administration/technicaltips/edit/technicaltips-edit.component';
import { TechnicalTipsRemoveComponent } from './administration/technicaltips/remove/technicaltips-remove.component';
import { DealerGuard } from './core/auth/dealer.guard';
import { TdbELibraryGuard } from './core/auth/tdbELibrary.guard';
import { DealerELibraryGuard } from './core/auth/dealerELibrary.guard';
import { TechnicalTipsGuard } from './core/auth/technicalTips.guard';

const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'home' },
  {
    path: 'home',
    component: HomeComponent ,
    canActivate: [MsalGuard, ValidGuard]
  },
  { path: 'priority-create',     component: PriorityCreateComponent,     canActivate: [MsalGuard, AdminGuard]   },
  { path: 'priority-edit/:priorityId', component: PriorityEditComponent,   canActivate: [MsalGuard, AdminGuard]  },
  { path: 'priority-list',    component: PriorityListComponent,  canActivate: [MsalGuard, AdminGuard]  },
  { path: 'priority-remove/:priorityId', component: PriorityRemoveComponent,  canActivate: [MsalGuard, AdminGuard]   },

  { path: 'chassis-create', component: DirectImportChassisCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'chassis-list',   component: DirectImportChassisListComponent,   canActivate: [MsalGuard, AdminGuard] },
  { path: 'chassis-remove', component: DirectImportChassisRemoveComponent, canActivate: [MsalGuard, AdminGuard] } ,
  { path: 'chassis-edit/:directImportChassisId',  component: DirectImportChassisEditComponent, canActivate: [MsalGuard, AdminGuard] },

  { path: 'area-related-create', component: AreaRelatedCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'area-related-list'  , component: AreaRelatedListComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'area-related-edit/:areaRelatedId',  component: AreaRelatedEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'area-related-remove/:areaRelatedId', component: AreaRelatedRemoveComponent, canActivate: [MsalGuard, AdminGuard] } ,

  { path: 'symptom-group-create', component : SymptomGroupCreateComponent,  canActivate: [MsalGuard, AdminGuard] },
  { path: 'symptom-group-list',   component : SymptomGroupListComponent,    canActivate: [MsalGuard, AdminGuard] },
  { path: 'symptom-group-edit/:symptomGroupId' , component : SymptomGroupEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'symptom-group-remove/:symptomGroupId' , component : SymptomGroupRemoveComponent, canActivate: [MsalGuard, AdminGuard] },

  { path: 'symptom-create', component : SymptomCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'symptom-edit/:symptomId', component : SymptomEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'symptom-list',  component: SymptomListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'symptom-remove/:symptomId' ,  component : SymptomRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'acessories-create', component: AcessoriesCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'acessories-edit/:acessoriesId', component : AcessoriesEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'acessories-list',  component: AcessoriesListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'acessories-remove/:acessoriesId' , component: AcessoriesRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 't1-create', component: T1CreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 't1-edit/:t1Id', component : T1EditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 't1-list',  component: T1ListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 't1-remove/:t1Id' , component: T1RemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 't2-create', component: T2CreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 't2-edit/:t2Id', component : T2EditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 't2-list',  component: T2ListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 't2-remove/:t2Id' , component: T2RemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'general-schedule-create', component: GeneralScheduleCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'general-schedule-edit/:generalScheduleId', component : GeneralScheduleEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'general-schedule-list',  component: GeneralScheduleListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'general-schedule-remove/:generalScheduleId' , component: GeneralScheduleRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'daily-schedule-create', component: DailyScheduleCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'daily-schedule-edit/:dailyScheduleId', component : DailyScheduleEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'daily-schedule-list',  component: DailyScheduleListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'daily-schedule-remove/:dailyScheduleId' , component: DailyScheduleRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'answer-schedule-create', component: AnswerScheduleCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'answer-schedule-edit/:answerScheduleId', component : AnswerScheduleEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'answer-schedule-list',  component: AnswerScheduleListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'answer-schedule-remove/:answerScheduleId' , component: AnswerScheduleRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'finality-create', component: FinalityCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'finality-edit/:finalityId', component : FinalityEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'finality-list',  component: FinalityListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'finality-remove/:finalityId' , component: FinalityRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'distributor-create', component: DistributorCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'distributor-edit/:distributorId', component : DistributorEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'distributor-list',  component: DistributorListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'distributor-remove/:distributorId' , component: DistributorRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'group-tdb-schedule-create', component: GroupTdbScheduleCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'group-tdb-schedule-edit/:groupTdbScheduleId', component : GroupTdbScheduleEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'group-tdb-schedule-list',  component: GroupTdbScheduleListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'group-tdb-schedule-remove/:groupTdbScheduleId' , component: GroupTdbScheduleRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'group-dlr-schedule-create', component: GroupDlrScheduleCreateComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'group-dlr-schedule-edit/:groupDlrScheduleId', component : GroupDlrScheduleEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'group-dlr-schedule-list',  component: GroupDlrScheduleListComponent , canActivate: [MsalGuard, AdminGuard] },
  { path: 'group-dlr-schedule-remove/:groupDlrScheduleId' , component: GroupDlrScheduleRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'ticket-create',     component: TicketCreateComponent,     canActivate: [MsalGuard, DealerGuard]  },
  { path: 'ticket-list',     component: TicketListComponent,     canActivate: [MsalGuard, AdminGuard]   },
  { path: 'ticket-report',     component: TicketReportComponent,     canActivate: [MsalGuard, ReadOnlyEtadsGuard]   },
  { path: 'ticket-open',     component: TicketListComponent,    canActivate: [MsalGuard, TechnicalTipsGuard]   },
  { path: 'ticket-close',     component: TicketListComponent,    canActivate: [MsalGuard, TechnicalTipsGuard]   },
  { path: 'ticket-draft',     component: TicketListComponent,    canActivate: [MsalGuard, DealerGuard]   },
  { path: 'ticket-pending',     component: TicketListComponent,    canActivate: [MsalGuard, AdminGuard]   },
  { path: 'ticket-pending-tdb',     component: TicketListComponent,    canActivate: [MsalGuard, AdminGuard]   },
  { path: 'ticket-entry',     component: TicketListComponent,    canActivate: [MsalGuard, AdminGuard]   },
  { path: 'ticket-sent',     component: TicketListComponent,    canActivate: [MsalGuard, AdminGuard]   },
  { path: 'ticket-all',     component: TicketListComponent,    canActivate: [MsalGuard, ReadOnlyEtadsGuard]   },
  { path: 'ticket-edit/:ticketId',     component: TicketCreateComponent,     canActivate: [MsalGuard, DealerGuard]   },
  { path: 'ticket-remove/:ticketId',     component: TicketRemoveComponent,     canActivate: [MsalGuard, AdminGuard]    },
  { path: 'ticket-view/:ticketId',     component: TicketViewComponent,     canActivate: [MsalGuard, TechnicalTipsGuard]   },

  { path: 'elib-brand-create',    component: ElibBrandCreateComponent,  canActivate: [MsalGuard, AdminGuard]  },
  { path: 'elib-brand-list',    component: ElibBrandListComponent,  canActivate: [MsalGuard, AdminGuard, TdbELibraryGuard]  },
  { path: 'elib-brand-edit/:brandId', component : ElibBrandEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'elib-brand-remove/:brandId' , component: ElibBrandRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'elib-model-create',    component: ElibModelCreateComponent,  canActivate: [MsalGuard, AdminGuard]  },
  { path: 'elib-model-list',    component: ElibModelListComponent,  canActivate: [MsalGuard, TdbELibraryGuard]  },
  { path: 'elib-model-edit/:modelId', component : ElibModelEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'elib-model-remove/:modelId' , component: ElibModelRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  { path: 'elib-manual-create',    component: ElibManualCreateComponent,  canActivate: [MsalGuard, AdminGuard]  },
  { path: 'elib-manual-list',    component: ElibManualListComponent,  canActivate: [MsalGuard, TdbELibraryGuard]  },
  { path: 'elib-manual-edit/:manualId',    component: ElibManualEditComponent,  canActivate: [MsalGuard, AdminGuard]  },
  { path: 'elib-manual-remove/:manualId' , component: ElibManualRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },
  { path: 'elib-manual-onlyread',    component: ElibManualListComponent,  canActivate: [MsalGuard, DealerELibraryGuard]  },

  { path: 'technicaltips-create',    component: TechnicalTipsCreateComponent,  canActivate: [MsalGuard, AdminGuard]  },
  { path: 'technicaltips-list',    component: TechnicalTipsListComponent,  canActivate: [MsalGuard, TechnicalTipsGuard]  },
  { path: 'technicaltips-edit/:technicalTipsId', component : TechnicalTipsEditComponent, canActivate: [MsalGuard, AdminGuard] },
  { path: 'technicaltips-remove/:technicalTipsId' , component: TechnicalTipsRemoveComponent,  canActivate: [MsalGuard, AdminGuard] },

  {
    path: 'not-found',
    component: NotFoundComponent,
    //canActivate: []
  },
  { path: '**', redirectTo: 'home' }


];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabled' : 'disabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
