export interface TechnicalTips {
    id: string;
    technicalTipsId: number;
    name: string;
    vehicleDescription: string;
    modelYear: string;
    description: string;
    symptomId : number;
    symptomDescription: string;
    symptomGroupId: number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    priorityId: number;
    initials: string;
    priorityDescription: string;
    attachName: string;
    attachUrl: string;
    attach: File;
}

export interface TechnicalTipsForm {
  id: string;
  technicalTipsId: number;
  name: string;
  vehicleDescription: string;
  modelYear: string;
  description: string;
  symptomId : number;
  symptomDescription: string;
  symptomGroupId: number;
  symptomGroupDescription: string;
  areaRelatedId: number;
  areaRelatedDescription: string;
  priorityId: number;
  initials: string;
  priorityDescription: string;
  attachName: string;
  attachUrl: string;
  attach: File;
}

export class EtadsTechnicalTips implements TechnicalTips {
  id: string;
  technicalTipsId: number;
  name: string;
  vehicleDescription: string;
  modelYear: string;
  description: string;
  symptomId : number;
  symptomDescription: string;
  symptomGroupId: number;
  symptomGroupDescription: string;
  areaRelatedId: number;
  areaRelatedDescription: string;
  priorityId: number;
  initials: string;
  priorityDescription: string;
  attachName: string;
  attachUrl: string;
  attach: File;
}
