import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-text-sized',
    templateUrl: './input-text-sized.component.html',

})
export class InputTextSizedComponent { 

    @Input() formGroup: FormGroup;
    @Input() classField: string;
    @Input() placeholder: string;
    @Input() controlName: string;
    
}