import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { ElibModelCrudService } from '../../../services/elib-model-crud.service';
import { ElibModel, EtadsElibModel } from '../../../interfaces/elib-model';

@Component({
    templateUrl: './elib-model-list.component.html'
})
export class ElibModelListComponent implements OnInit {

    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private elibModelCrudService: ElibModelCrudService
        ) {}

    currentPage : Number = 1;
    itemsPerPage : Number = 13;
    modelList : EtadsElibModel[] = [];
    filterMap: Map<string, string> = new Map<string, string>();

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Modelo",  "" );
        this.loadData();

       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);

        }

    }

    loadData() {

       this.subscription = this.elibModelCrudService.get().subscribe( reg => {
            for (let data of reg ) {
                let idList = this.modelList.push(data);
            }
            this.spinnerService.hide();
        } , err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
    }

    actionAdd() {
        this.stateService.storeElibModelList(this.modelList);
        this.router.navigate(['elib-model-create']);
    }

    actionEdit( elibModelData: ElibModel ) {
        this.stateService.storeElibModel(elibModelData);
        this.stateService.storeElibModelList(this.modelList);
    }

    actionRemove( modelData: EtadsElibModel ) {
        this.stateService.storeElibModel(modelData);
    }


}
