import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';
import { TechnicalTips } from '../interfaces/technicaltips';

@Injectable({
  providedIn: 'root'
})
export class TechnicalTipsCrudService {
  key = 'etads/technicaltips';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg: TechnicalTips) {
    return this.azureCrudService.saveTechnicalTips(newReg, "addTechnicalTips");
  }

  upd(oldReg: TechnicalTips, newReg: TechnicalTips) {
    return this.azureCrudService.upd(oldReg, newReg, "updateTechnicalTips");
  }

  del(reg: TechnicalTips) {
    return this.azureCrudService.del(reg, "deleteTechnicalTips");
  }

  get() {
    return this.azureCrudService.get("getTechnicalTips?sortField=technicalTipsId&sortOrder=desc");
  }
}
