<form [formGroup]="form">
  <etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table>
      <thead saTheadStyle>
        <tr>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('ticketId')" style="cursor: pointer;">Chamado</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="ticketFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionTicketFilter()" controlName="ticketFilter"
                  placeholder="Filtro por Chamado" autofocus></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionTicketFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionTicketFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'ticketId' && sortOrder == 'asc')" style="float: right; margin-right: 2px;"><img
                src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'ticketId' && sortOrder == 'desc')" style="float: right; margin-right: 2px;"><img
                src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('requestGroup.initials')"
              style="cursor: pointer;">Prioridade</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="priorityFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionPriorityFilter()" controlName="priorityFilter"
                  placeholder="Filtro por Prioridade"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionPriorityFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionPriorityFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'requestGroup.initials' && sortOrder == 'asc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'requestGroup.initials' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('createDate')" style="cursor: pointer;">Aberto em</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="openDateFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                ng-class="{active:openDateFilter}" type="button" data-toggle="dropdown"><span
                  class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionOpenDateFilter()" controlName="openDateFilter"
                  placeholder="Filtro por Aberto em"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionOpenDateFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionOpenDateFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'createDate' && sortOrder == 'asc')" style="float: right; margin-right: 2px;"><img
                src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'createDate' && sortOrder == 'desc')" style="float: right; margin-right: 2px;">
              <img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('vehicleGroup.vehicle')"
              style="cursor: pointer;">Veículo</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="vehicleFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionVehicleFilter()" controlName="vehicleFilter"
                  placeholder="Filtro por Veículo"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionVehicleFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionVehicleFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'vehicleGroup.vehicle' && sortOrder == 'asc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'vehicleGroup.vehicle' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('requestGroup.finalityDescription')"
              style="cursor: pointer;">Finalidade</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="finalityFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionFinalityFilter()" controlName="finalityFilter"
                  placeholder="Filtro por Finalidade"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionFinalityFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionFinalityFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'requestGroup.finalityDescription' && sortOrder == 'asc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'requestGroup.finalityDescription' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('requestGroup.relatedAreaDescription')"
              style="cursor: pointer;">Área Relac.</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="relatedAreaFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionRelatedAreaFilter()"
                  controlName="relatedAreaFilter" placeholder="Filtro por Área Relacionada"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionRelatedAreaFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionRelatedAreaFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'requestGroup.relatedAreaDescription' && sortOrder == 'asc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'requestGroup.relatedAreaDescription' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('requestGroup.symptomDescription')"
              style="cursor: pointer;">Sintoma</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="symptomFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionSymptomFilter()" controlName="symptomFilter"
                  placeholder="Filtro por Sintoma"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionSymptomFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionSymptomFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'requestGroup.symptomDescription' && sortOrder == 'asc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'requestGroup.symptomDescription' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('dealerGroup.code')"
              style="cursor: pointer;">Distribuidor</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="dealerFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionDealerFilter()" controlName="dealerFilter"
                  placeholder="Filtro por Distribuidor"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionDealerFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionDealerFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'dealerGroup.code' && sortOrder == 'asc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'dealerGroup.code' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('status')" style="cursor: pointer;">Status</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="statusFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionStatusFilter()" controlName="statusFilter"
                  placeholder="Filtro por Status"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionStatusFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionStatusFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'status' && sortOrder == 'asc')" style="float: right; margin-right: 2px;"><img
                src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'status' && sortOrder == 'desc')" style="float: right; margin-right: 2px;"><img
                src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('createUserName')" style="cursor: pointer;">Usuário
              Resp</label>
            <div class="dropdown" style="float: right">
              <button [ngClass]="userFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionUserFilter()" controlName="userFilter"
                  placeholder="Filtro por Usuário Responsável"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionUserFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionUserFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'createUserName' && sortOrder == 'asc')" style="float: right; margin-right: 2px;">
              <img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'createUserName' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('currentProfile')" style="cursor: pointer;">Grupo</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="groupFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionGroupFilter()" controlName="groupFilter"
                  placeholder="Filtro por Grupo"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionGroupFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionGroupFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'asc')" style="float: right; margin-right: 2px;">
              <img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('currentProfile')" style="cursor: pointer;">Nome do Dealer</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="dealerNameFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionDealerNameFilter()" controlName="dealerNameFilter"
                  placeholder="Filtro por Nome"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionDealerNameFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionDealerNameFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'asc')" style="float: right; margin-right: 2px;">
              <img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('currentProfile')" style="cursor: pointer;">Data de Produção</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="dtProductionFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionDtProductionFilter()" controlName="dtProductionFilter"
                  placeholder="Filtro por Data"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionDtProductionFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionDtProductionFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'asc')" style="float: right; margin-right: 2px;">
              <img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>
            <label class="m-0 p-0" (click)="sortHeaderClick('currentProfile')" style="cursor: pointer;">KM</label>
            <div class="dropdown" style="float: right">
              <button
                [ngClass]="kmFilter ? 'dropdown-toggle filterdropdownactive' : 'dropdown-toggle filterdropdown'"
                type="button" data-toggle="dropdown"><span class="glyphicon glyphicon-filter"></span></button>
              <div class="dropdown-menu prop" aria-labelledby="btnSearchBatch" style="width: 250px; padding: 15px;">
                <etads-input-text [formGroup]="form" (keyup.enter)="actionVehicleKmFilter()" controlName="kmFilter"
                  placeholder="Filtro por KM"></etads-input-text>
                <div class="text-right m-1">
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionVehicleKmFilter(true);'> Limpar </button>
                  <button type="button" style="width: 75px; height: 30px; margin: 1px" class="form-control btn btn-info"
                    (click)='actionVehicleKmFilter()'> Filtrar </button>
                </div>
              </div>
            </div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'asc')" style="float: right; margin-right: 2px;">
              <img src="assets/images/sortasc.png"></div>
            <div *ngIf="(sortField == 'currentProfile' && sortOrder == 'desc')"
              style="float: right; margin-right: 2px;"><img src="assets/images/sortdesc.png"></div>
          </th>
          <th>Visualizar</th>
          <th>Excluir</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let ticket of ticketList | paginate : {itemsPerPage: itemsPerPage, currentPage:currentPage, totalItems: totalItems } ">
          <td>{{ ticket.ticketId }}</td>
          <td>{{ ticket.requestGroup.initials }}</td>
          <td>{{ ticket.createDate | date: 'dd/MM/yyyy HH:mm' }}</td>
          <td>{{ ticket.vehicleGroup.vehicle }}</td>
          <td>{{ ticket.requestGroup.finalityDescription }}</td>
          <td>{{ ticket.requestGroup.relatedAreaDescription }}</td>
          <td>{{ ticket.requestGroup.symptomDescription }}</td>
          <td>{{ ticket.dealerGroup.code }}</td>          
          <td>{{ ticket.status + ((ticket.status === 'Aberto' && ticket.closeReleased) ? ' (Lib. encerramento)' : '') }}
          </td>          
          <td>{{ (ticket.currentProfile == 'frontdealer') ? ticket.createUserName : ticket.analystName }}</td>
          <td>{{ (ticket.currentProfile == 'frontdealer') ? 'DLR' : 'TDB' }}</td>
          <td>{{ ticket.dealerGroup.name }}</td>
          <td>{{ticket.vehicleGroup.dtProduction}}</td>
          <td> {{ticket.vehicleGroup.km}}</td>
          <td>
            <etads-tooltip label="Visualizar">
              <etads-icon-edit (clickEmmiter)="actionView(ticket)" link="ticket-view" [parameter]="ticket.ticketId">
              </etads-icon-edit>
            </etads-tooltip>
          </td>
          <td>
            <etads-tooltip label="Excluir">
              <etads-icon-remove (clickEmmiter)="actionRemove(ticket)" link="ticket-remove"
                [parameter]="ticket.ticketId" *ngIf="ticket.status == 'Rascunho'">
              </etads-icon-remove>
            </etads-tooltip>
          </td>
        </tr>
      </tbody>
    </etads-table>
    <etads-div-center>
      <pagination-controls (pageChange)="loadPage((currentPage = $event))" previousLabel="Anterior" nextLabel="Próximo"
        autoHide="true">
      </pagination-controls>
    </etads-div-center>
  </etads-card-list>
</form>