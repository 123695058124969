export interface GeneralSchedule {
    id: string;
    generalScheduleId: number;
    generalScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
    created: string;
    createdUser: string;
    modified: String;
    modifiedUser: string;

}

export interface GeneralScheduleForm {
    generalScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
}

export class EtadsGeneralSchedule implements GeneralSchedule {
    id: string;
    generalScheduleId: number;
    generalScheduleDescription: string;
    priorityId: number;
    priorityInitials: string;
    rangeInit: number;
    rangeFinish: number;
    dlr1: number;
    dlr2: number;
    dlr3: number;
    tdb1: number;
    tdb2: number;
    tdb3: number;
    created: string;
    createdUser: string;
    modified: String;
    modifiedUser: string;

}

