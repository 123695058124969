import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Symptom } from '../../../interfaces/symptom';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { EtadsT1, T1, T1Form } from '../../../interfaces/t1';
import { T1CrudService } from '../../../services/t1-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './t1-create.component.html'
})

export class T1CreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    areaRelated: AreaRelated;
    sympton: Symptom;
    symptomGroup: SymptomGroup;

    t1: T1;
    tiId: number;
    t1List: EtadsT1[]=[];

    areaRelatedList : AreaRelated[] = [];
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    subscription : Subscription;


    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        private symptomCrudService: SymptomCrudService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private t1CrudService: T1CrudService,

        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Item T1", "");
        this.loadData();
        this.createForm();
        this.spinnerService.hide();
    }

    loadData() {

        this.t1List = this.stateService.retrieveT1List();

        /* carregando Area Relacionada */
        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( (lista) => {
            for (let reg of lista) {
                this.areaRelatedList.push(reg);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        /* carregando grupo de sintomas */
        /*this.symptomGroupList = [];
        this.subscription = this.symptomGroupCrudService.get().subscribe( (listg) => {
            for(let sg of listg) {
                this.symptomGroupList.push(sg);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;*/

        /* Carregando sintomas */
        /*this.symptomList = [];
        this.subscription = this.symptomCrudService.get().subscribe( (lists) => {
            for(let sm of lists) {
                this.symptomList.push(sm);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;*/

    }

    actionChangeArea() {
      this.symptomGroupList = [];
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || this.form.get("areaRelatedId").value == '')
        return;

      this.symptomGroupCrudService.getByAreaRelated(this.form.get("areaRelatedId").value).subscribe(lst => {
          this.symptomGroupList = lst;
      }, error => {
        console.log(error);
      });
    }

    actionChangeGroup() {
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || !this.form.get("symptomGroupId")
          || this.form.get("areaRelatedId").value == '' || this.form.get("symptomGroupId").value == '')
        return;

      this.symptomCrudService.getByGroup(this.form.get("areaRelatedId").value, this.form.get("symptomGroupId").value).subscribe(lst => {
          this.symptomList = lst;
      }, error => {
        console.log(error);
      });
    }

    createForm() {

        this.form = this.formBuilder.group( {
            t1Code:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(10) ] , ],
            t1Name:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            areaRelatedId :   ['null', [ Validators.required ] , ],
            symptomGroupId:   ['',     [ Validators.required ] , ],
            symptomId:        ['',     [ Validators.required ] , ],
            active:           [null, [  Validators.required  ] , ],

        });

    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("T1 Inválido ou já existente! Verifique o Código ou Nome!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const t1form   = this.form.getRawValue() as T1Form;
        let t1ToInsert = new EtadsT1();

        t1ToInsert.t1Code = t1form.t1Code;
        t1ToInsert.t1Name = t1form.t1Name;
        t1ToInsert.areaRelatedId  = Number(t1form.areaRelatedId.valueOf());
        t1ToInsert.symptomGroupId = Number(t1form.symptomGroupId.valueOf());
        t1ToInsert.symptomId = Number(t1form.symptomId.valueOf());
        t1ToInsert.active         = t1form.active;

        for (let gr of this.symptomGroupList) {
            if (Number(gr.symptomGroupId) === Number(t1form.symptomGroupId) )   {
                t1ToInsert.symptomGroupDescription = gr.symptomGroupDescription;
                break;
            }
        }

        for (let sy of this.symptomList) {
            if (Number(sy.symptomId) === Number(t1form.symptomId) )   {
                t1ToInsert.symptomDescription = sy.symptomDescription;
                break;
            }
        }

        t1ToInsert.areaRelatedDescription  = this.areaRelatedList.find(ar => ar.areaRelatedId == t1form.areaRelatedId).areaDescription;

        this.subscription = this.t1CrudService.add(t1ToInsert).subscribe((data) => {
            let idList = this.t1List.push(t1ToInsert);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            t1ToInsert = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['t1-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as T1Form;

            for (let sgData of this.t1List) {
               let parse1 = sgData.t1Code;
               let parse2 = newSg.t1Code;
               let parse3 = Number(sgData.t1Code);
               let parse4 = Number(newSg.t1Code);

               if (parse1 == parse2) {
                   return false;
               }
               if (parse3 == parse4 ) {
                return false;
               }
           }

        return true;
    }

}
