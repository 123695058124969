export interface ElibBrand {
    id: string;
    brandId: number;
    description: string;
}

export interface ElibBrandForm {
    id: string;
    brandId: number;
    description: string;
}
export class EtadsElibBrand implements ElibBrand {
    id: string;
    brandId: number;
    description: string;

    constructor(){}
}
