import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-button-confirm',
    templateUrl: './button-confirm.component.html',
    styleUrls: ['./button-confirm.component.css']
})
export class ButtonConfirmComponent { 

    constructor() {}

    @Input() label = 'Confirmar';
    @Input() disabled: boolean = false; 
    
}