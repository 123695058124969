import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsGroupDlrSchedule, GroupDlrSchedule, GroupDlrScheduleForm } from '../../../interfaces/group-dlr-schedule';
import { GroupDlrScheduleCrudService } from '../../../services/group-dlr-schedule-crud.service';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { DistributorToInput } from '../../../interfaces/distributor';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './group-dlr-schedule-create.component.html',
})

export class GroupDlrScheduleCreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    distributorList: DistributorToInput[]=[];
    distributorSelList: DistributorToInput[]=[];

    groupDlrSchedule: GroupDlrSchedule;
    groupDlrScheduleId: number;
    groupDlrScheduleList: EtadsGroupDlrSchedule[]=[];

    levelList: Radio[]=[];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private groupDlrScheduleCrudService: GroupDlrScheduleCrudService,
        private distributorCrudService: DistributorCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Escalonamento Grupo DLR", "");
        this.levelList = this.getLevelList();
        this.loadData();
        this.createForm();
        this.spinnerService.hide();
    }

    getLevelList() {
        let newRadioList : Radio[]=[];

        let opt: number;
        for (opt = 1; opt <= 3; opt++) {
            let optR = new EtadsRadio();
            optR.itemId = opt ;
            optR.itemLabel = opt.toString();
            let lst = newRadioList.push(optR);
        }
        return newRadioList;
    }

    loadData() {
        this.groupDlrScheduleList = this.stateService.retrieveGroupDlrScheduleList();

         // caregando lista de Distribuidores
         this.distributorList = [];
         this.distributorSelList = [];
         this.subscription = this.distributorCrudService.get().subscribe( lst => {
              for (let distx of lst) {
                  let dist = new DistributorToInput();
                  dist.distributorCode = distx.distributorCode;
                  dist.distributorName = distx.distributorName;
                  this.distributorList.push(dist);
              }
              this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         });
    }


    createForm() {

        this.form = this.formBuilder.group( {
            groupDlrScheduleTitle: ['',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            level:  ['', [Validators.required], ],
            email:  ['', [Validators.required], ],
            active: [null, [Validators.required], ] ,
            distributor: ['', [Validators.required], ],

        });
    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const gsform   = this.form.getRawValue() as GroupDlrScheduleForm;

        let gsToInsert = new EtadsGroupDlrSchedule();

        gsToInsert.groupDlrScheduleTitle = gsform.groupDlrScheduleTitle.toUpperCase();
        gsToInsert.level = gsform.level
        gsToInsert.email = gsform.email.toString();
        gsToInsert.active = gsform.active;
        gsToInsert.distributor = gsform.distributor;

        this.subscription = this.groupDlrScheduleCrudService.add(gsToInsert).subscribe((data) => {
            let idList = this.groupDlrScheduleList.push(gsToInsert);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            gsToInsert = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

     actionDistributorChange() {

     }

     actionDistributorSelectChange() {

    }

    actionAddDist() {

        this.spinnerService.show();

        this.form.get('distributorToSelect').value.forEach( (distSe: string)  => {

            if (distSe != null ) {

                if ( ! this.distributorSelList.find(ds => ds.distributorCode === distSe)) {
                    let newDist = new DistributorToInput();
                    newDist.distributorCode = distSe;
                    newDist.distributorName = this.distributorList.find( dl => dl.distributorCode === distSe).distributorName;
                    this.distributorSelList.push(newDist);
             }
            }

        }); ;

        this.form.patchValue({distributorSelected:this.distributorSelList});
        this.spinnerService.hide();

    }

    actionDelDist() {

        this.spinnerService.show();

        let newList : DistributorToInput[] = this.distributorSelList

        this.form.get('distributorSelected').value.forEach( (distDel: string) => {


            if (distDel != null) {

                let idx = newList.findIndex( regd => regd.distributorCode === distDel);

                newList.splice(idx);

                this.distributorSelList = newList;
            }

        });  ;

        this.form.patchValue({distributorSelected:newList});
        this.spinnerService.hide();
    }

    actionBack() {
        this.router.navigate(['group-dlr-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {

        const newSg = this.form.get('groupDlrScheduleTitle').value;

        for (let sgData of this.groupDlrScheduleList) {
            let parse1 = sgData.groupDlrScheduleTitle.toUpperCase();
            let parse2 = newSg.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }

        return true;
    }

}
