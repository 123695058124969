<form [formGroup]="form" (submit)= "actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Descrição"></etads-label>
            <etads-input-text [formGroup]="form" controlName="description" placeholder="Descrição"></etads-input-text>
            <etads-vmessage *ngIf="form.get('description').errors?.required" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('description').errors?.minlength" text="Tamanho mínino de 3 caracter"></etads-vmessage>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>

    </etads-card-form>

</form>
