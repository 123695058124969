import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsGeneralSchedule, GeneralSchedule, GeneralScheduleForm } from '../../../interfaces/general-schedule';
import { GeneralScheduleCrudService } from '../../../services/general-schedule-crud.service';
import { Priority } from '../../../interfaces/priority';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';



@Component ({
    templateUrl: './general-schedule-create.component.html',
})

export class GeneralScheduleCreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    priorityList: Priority[];
    radioPriorList : Radio[]=[];

    generalSchedule: GeneralSchedule;
    generalScheduleId: number;
    generalScheduleList: EtadsGeneralSchedule[]=[];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private generalScheduleCrudService: GeneralScheduleCrudService,
        private priorityCrudService: PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Escalonamento Geral", "");
        this.loadData();
        this.createForm();
    }

    loadData() {
        this.generalScheduleList = this.stateService.retrieveGeneralScheduleList();
        this.currentUser = "ToBeDefined";

         /* caregando lista de Prioridades */
         this.priorityList = [];
         this.subscription = this.priorityCrudService.get().subscribe( listx => {
              for (let regx of listx) {
                this.priorityList.push(regx);
                let rop = new  EtadsRadio ();
                rop.itemId = regx.priorityId;
                rop.itemLabel = regx.initials;
                this.radioPriorList.push(rop);
              }
              this.spinnerService.hide();
         }, err => {
             this.alertService.danger(err.message);
             this.spinnerService.hide();
         });
    }


    createForm() {

        this.form = this.formBuilder.group( {
            generalScheduleDescription:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            priorityId:['', [Validators.required], ],
            rangeInit:       ['0',   [ Validators.required, Validators.minLength(1), Validators.maxLength(3) ] , ],
            rangeFinish:      ['0',  [ Validators.required, Validators.minLength(1), Validators.maxLength(3) ] , ],
            dlr1: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            dlr2: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            dlr3: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb1: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb2: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],
            tdb3: ['0',[Validators.required, Validators.minLength(1), Validators.maxLength(3) ], ],

        });
    }


    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const dtcreate = new Date();
        const gsform   = this.form.getRawValue() as GeneralScheduleForm;

        let gsToInsert = new EtadsGeneralSchedule();

        gsToInsert.generalScheduleDescription = gsform.generalScheduleDescription.toUpperCase();
        gsToInsert.priorityId = Number(gsform.priorityId);
        gsToInsert.priorityInitials = this.priorityList.find( pr => pr.priorityId == gsform.priorityId).initials;
        //gsToInsert.priorityId = this.priorityList.find( pr => pr.initials == gsform.priorityInitials.toUpperCase()).priorityId;
        // gsToInsert.priorityInitials = gsform.priorityInitials.toUpperCase();
        gsToInsert.rangeInit = Number(gsform.rangeInit);
        gsToInsert.rangeFinish = Number(gsform.rangeFinish);
        gsToInsert.dlr1 = Number(gsform.dlr1);
        gsToInsert.dlr2 = Number(gsform.dlr2);
        gsToInsert.dlr3 = Number(gsform.dlr3);
        gsToInsert.tdb1 = Number(gsform.tdb1);
        gsToInsert.tdb2 = Number(gsform.tdb2);
        gsToInsert.tdb3 = Number(gsform.tdb3);
        gsToInsert.created = this.datepipe.transform(dtcreate, 'dd-MM-yyyy h:mm:ss a').toString();
        gsToInsert.createdUser  = this.currentUser;
       // gsToInsert.modified     = gsToInsert.created;
       // gsToInsert.modifiedUser = gsToInsert.createdUser;

        this.subscription = this.generalScheduleCrudService.add(gsToInsert).subscribe( (data) => {
            let idList = this.generalScheduleList.push(gsToInsert);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            gsToInsert = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide()
        } );

    }

    actionBack() {
        this.router.navigate(['general-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {

        const newSg = this.form.getRawValue() as GeneralScheduleForm;

            for (let sgData of this.generalScheduleList) {
               let parse1 = sgData.generalScheduleDescription.toUpperCase();
               let parse2 = newSg.generalScheduleDescription.toUpperCase();

               if (parse1 == parse2) {
                   return false;
               }
           }

        return true;
    }

}
