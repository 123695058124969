import { Injectable } from "@angular/core";
import { environment }  from '../../environments/environment';
import { DirectImportChassis, EtadsDirectImportChassis } from "../interfaces/direct-import-chassis";
import { EtadsPriority, Priority } from '../interfaces/priority';
import { EtadsAreaRelated, AreaRelated } from '../interfaces/area-related';
import { SymptomGroup } from '../interfaces/symptom-group';
import { Symptom } from '../interfaces/symptom';
import { Acessories } from '../interfaces/acessories';
import { T1 } from '../interfaces/t1';
import { T2 } from '../interfaces/t2';
import { GeneralSchedule } from '../interfaces/general-schedule';
import { DailySchedule } from '../interfaces/daily-schedule';
import { AnswerSchedule } from '../interfaces/answer-schedule';
import { Finality } from '../interfaces/finality';
import { ElibBrand, EtadsElibBrand } from '../interfaces/elib-brand';
import { ElibModel, EtadsElibModel } from '../interfaces/elib-model';
import { ElibManual, EtadsElibManual } from '../interfaces/elib-manual';
import { Distributor } from '../interfaces/distributor';
import { GroupTdbSchedule } from '../interfaces/group-tdb-schedule';
import { GroupDlrSchedule } from "../interfaces/group-dlr-schedule";
import { Ticket } from "../administration/ticket/ticket";
import { TechnicalTips } from "../interfaces/technicaltips";


@Injectable({providedIn: 'root'})
export class StateService {

    brandList: ElibBrand[];
    elibModelList: ElibModel[];
    manualList: ElibManual[];
    priorityList: Priority[];
    priorityId : number;
    priority: Priority;
    alertsuccesMessage: string;
    chassisList : DirectImportChassis[];
    chassisId: number;
    chassis: DirectImportChassis;
    areaRelatedId : number;
    areaRelatedList : AreaRelated[];
    areaRelated : AreaRelated;
    symptomGroupId : number;
    symptomGroup : SymptomGroup;
    symptomGroupList : SymptomGroup[];
    symptomId : number;
    symptom : Symptom;
    symptomList : Symptom[];
    acessoriesId : number;
    acessories : Acessories;
    acessoriesList : Acessories[];
    t1Id : number;
    t1 : T1;
    t1List : T1[];
    t2Id : number;
    t2 : T2;
    t2List : T2[];
    generalScheduleId : number;
    generalSchedule : GeneralSchedule;
    generalScheduleList : GeneralSchedule[];
    dailyScheduleId : number;
    dailySchedule : DailySchedule;
    dailyScheduleList : DailySchedule[];
    answerScheduleId : number;
    answerSchedule : AnswerSchedule;
    answerScheduleList : AnswerSchedule[];
    finalityId : number;
    finality : Finality;
    finalityList : Finality[];
    distributorId : number;
    distributor : Distributor;
    distributorList : Distributor[];
    groupTdbScheduleId : number;
    groupTdbSchedule : GroupTdbSchedule;
    groupTdbScheduleList : GroupTdbSchedule[];
    groupDlrScheduleId : number;
    groupDlrSchedule : GroupDlrSchedule;
    groupDlrScheduleList : GroupDlrSchedule[];
    ticket: Ticket;
    elibBrand: ElibBrand;
    elibModel: ElibModel;
    elibManual: ElibManual;
    duplicated: boolean;
    edit: boolean;
    technicalTipsList : TechnicalTips[];
    technicalTips: TechnicalTips;


    storePriorityList(listToStore:  EtadsPriority[] ) {
        this.priorityList = listToStore  ;
    }

    retrievePriorityList() {
        return this.priorityList ;
    }

    storeElibBrandList(listToStore:  EtadsElibBrand[] ) {
      this.brandList = listToStore  ;
    }

    retrieveElibBrandList() {
      return this.brandList ;
    }

    storeElibModelList(listToStore:  EtadsElibModel[] ) {
      this.elibModelList = listToStore  ;
    }

    retrieveElibModelList() {
      return this.elibModelList ;
    }

    storeElibManualList(listToStore:  EtadsElibManual[] ) {
      this.manualList = listToStore  ;
    }

    retrieveElibManualList() {
      return this.manualList ;
    }

    storePriorityId(objecToStore: number   ) {
        this.priorityId = objecToStore  ;
    }

    retrievePriorityId() {
        return this.priorityId ;
    }

    storePriority(objecToStore: Priority   ) {
        this.priority = objecToStore  ;
    }

    retrievePriority() {
        return this.priority ;
    }

    storeElibBrand(objecToStore: ElibBrand   ) {
      this.elibBrand = objecToStore  ;
    }

    retrieveElibBrand() {
        return this.elibBrand ;
    }

    storeElibModel(objecToStore: ElibModel   ) {
      this.elibModel = objecToStore  ;
    }

    retrieveElibModel() {
        return this.elibModel ;
    }

    storeElibManual(objecToStore: ElibManual   ) {
      this.elibManual = objecToStore  ;
    }

    retrieveElibManual() {
        return this.elibManual ;
    }

    storeAlertSuccessMessage(objecToStore: string|null) {
        this.alertsuccesMessage = objecToStore;
    }

    retrieveAlertSuccessMessage() {
        return this.alertsuccesMessage ;
    }

    storeChassisList (listToStore : EtadsDirectImportChassis[]) {
        this.chassisList = listToStore;
    }

    retrieveChassisList () {
        return this.chassisList ;
    }

    storeChassisId (objecToStore : number) {
        this.chassisId = objecToStore;
    }

    retrieveChassisId () {
        return this.chassisId;
    }

    storeChassis (objecToStore : EtadsDirectImportChassis) {
        this.chassis = objecToStore;
    }

    retrieveChassis () {
        return this.chassis;
    }

    storeAreaRelatedList(objectoToStore : EtadsAreaRelated[]) {
        this.areaRelatedList = objectoToStore;
    }

    retrieveAreaRelatedList() {
        return this.areaRelatedList;
    }

    storeAreaRelated (objectoToStore: AreaRelated) {
        this.areaRelated = objectoToStore;
    }

    retrieveAreaRelated () {
        return this.areaRelated;
    }

    storeAreaRelatedId ( objecToStore : number) {
        this.areaRelatedId = objecToStore;
    }

    retrieveAreaRelatedId() {
        return this.areaRelatedId
    }

    storeSymptomGroupId (objecToStore: number) {
        this.symptomGroupId = objecToStore;
    }

    retrieveSymptomGroupId() {
        return this.symptomGroupId;
    }

    storeSymptomGroup (objectoToStore : SymptomGroup ) {
        this.symptomGroup = objectoToStore;
    }

    retrieveSymptomGroup() {
        return this.symptomGroup;
    }

    storeSymptomGroupList (objectoToStore : SymptomGroup[] ) {
        this.symptomGroupList = objectoToStore;
    }

    retrieveSymptomGroupList() {
        return this.symptomGroupList;
    }


    storeSymptomId (objecToStore: number) {
        this.symptomId = objecToStore;
    }

    retrieveSymptomId() {
        return this.symptomId;
    }

    storeSymptom (objectoToStore : Symptom ) {
        this.symptom = objectoToStore;
    }

    retrieveSymptom() {
        return this.symptom;
    }

    storeSymptomList (objectoToStore : Symptom[] ) {
        this.symptomList = objectoToStore;
    }

    retrieveSymptomList() {
        return this.symptomList;
    }

    storeAcessoriesId (objecToStore: number) {
        this.symptomId = objecToStore;
    }

    retrieveAcessoriesId() {
        return this.symptomId;
    }

    storeAcessories (objectoToStore : Acessories ) {
        this.acessories = objectoToStore;
    }

    retrieveAcessories() {
        return this.acessories;
    }

    storeAcessoriesList (objectoToStore : Acessories[] ) {
        this.acessoriesList = objectoToStore;
    }

    retrieveAcessoriesList() {
        return this.acessoriesList;
    }

    storeT1Id (objecToStore: number) {
        this.t1Id = objecToStore;
    }

    retrieveT1Id() {
        return this.t1Id;
    }

    storeT1 (objectoToStore : T1 ) {
        this.t1 = objectoToStore;
    }

    retrieveT1() {
        return this.t1;
    }

    storeT1List (objectoToStore : T1[] ) {
        this.t1List = objectoToStore;
    }

    retrieveT1List() {
        return this.t1List;
    }

    storeT2Id (objecToStore: number) {
        this.t2Id = objecToStore;
    }

    retrieveT2Id() {
        return this.t2Id;
    }

    storeT2 (objectoToStore : T2 ) {
        this.t2 = objectoToStore;
    }

    retrieveT2() {
        return this.t2;
    }

    storeT2List (objectoToStore : T2[] ) {
        this.t2List = objectoToStore;
    }

    retrieveT2List() {
        return this.t2List;
    }

    storeGeneralScheduleId (objecToStore: number) {
        this.generalScheduleId = objecToStore;
    }

    retrieveGeneralScheduleId() {
        return this.generalScheduleId;
    }

    storeGeneralSchedule (objectoToStore : GeneralSchedule ) {
        this.generalSchedule = objectoToStore;
    }

    retrieveGeneralSchedule() {
        return this.generalSchedule;
    }

    storeGeneralScheduleList (objectoToStore : GeneralSchedule[] ) {
        this.generalScheduleList = objectoToStore;
    }

    retrieveGeneralScheduleList() {
        return this.generalScheduleList;
    }


    storeDailyScheduleId (objecToStore: number) {
        this.dailyScheduleId = objecToStore;
    }

    retrieveDailyScheduleId() {
        return this.dailyScheduleId;
    }

    storeDailySchedule (objectoToStore : DailySchedule ) {
        this.dailySchedule = objectoToStore;
    }

    retrieveDailySchedule() {
        return this.dailySchedule;
    }

    storeDailyScheduleList (objectoToStore : DailySchedule[] ) {
        this.dailyScheduleList = objectoToStore;
    }

    retrieveDailyScheduleList() {
        return this.dailyScheduleList;
    }

    storeAnswerScheduleId (objecToStore: number) {
        this.answerScheduleId = objecToStore;
    }

    retrieveAnswerScheduleId() {
        return this.answerScheduleId ;
    }

    storeAnswerSchedule (objectoToStore : AnswerSchedule ) {
        this.answerSchedule = objectoToStore;
    }

    retrieveAnswerSchedule() {
        return this.answerSchedule;
    }

    storeAnswerScheduleList (objectoToStore : AnswerSchedule[] ) {
        this.answerScheduleList = objectoToStore;
    }

    retrieveAnswerScheduleList() {
        return this.answerScheduleList;
    }

    storeFinalityId (objecToStore: number) {
        this.finalityId = objecToStore;
    }

    retrieveFinalityId() {
        return this.answerScheduleId ;
    }

    storeFinality (objectoToStore : Finality ) {
        this.finality = objectoToStore;
    }

    retrieveFinality() {
        return this.finality;
    }

    storeFinalityList (objectoToStore : Finality[] ) {
        this.finalityList = objectoToStore;
    }

    retrieveFinalityList() {
        return this.finalityList;
    }


    storeDistributorId (objecToStore: number) {
        this.distributorId = objecToStore;
    }

    retrieveDistributorId() {
        return this.distributorId ;
    }

    storeDistributor (objectoToStore : Distributor ) {
        this.distributor = objectoToStore;
    }

    retrieveDistributor() {
        return this.distributor;
    }

    storeDistributorList (objectoToStore : Distributor[] ) {
        this.distributorList = objectoToStore;
    }

    retrieveDistributorList() {
        return this.distributorList;
    }

    storeGroupTdbScheduleId (objecToStore: number) {
        this.groupTdbScheduleId = objecToStore;
    }

    retrieveGroupTdbScheduleId() {
        return this.groupTdbScheduleId ;
    }

    storeGroupTdbSchedule (objectoToStore : GroupTdbSchedule ) {
        this.groupTdbSchedule = objectoToStore;
    }

    retrieveGroupTdbSchedule() {
        return this.groupTdbSchedule;
    }

    storeGroupTdbScheduleList (objectoToStore : GroupTdbSchedule[] ) {
        this.groupTdbScheduleList = objectoToStore;
    }

    retrieveGroupTdbScheduleList() {
        return this.groupTdbScheduleList;
    }
    storeGroupDlrScheduleId (objecToStore: number) {
        this.groupDlrScheduleId = objecToStore;
    }

    retrieveGroupDlrScheduleId() {
        return this.groupDlrScheduleId ;
    }

    storeGroupDlrSchedule (objectoToStore : GroupDlrSchedule ) {
        this.groupDlrSchedule = objectoToStore;
    }

    retrieveGroupDlrSchedule() {
        return this.groupDlrSchedule;
    }

    storeGroupDlrScheduleList (objectoToStore : GroupDlrSchedule[] ) {
        this.groupDlrScheduleList = objectoToStore;
    }

    retrieveGroupDlrScheduleList() {
        return this.groupDlrScheduleList;
    }

    storeTicket (objectoToStore : Ticket ) {
        this.ticket = objectoToStore;
    }

    retrieveTicket() : Ticket {
        return this.ticket;
    }

    storeDuplicated(dup: boolean) {
      this.duplicated = dup;
    }

    retrieveDuplicated() : boolean {
      return this.duplicated;
    }

    storeEdit(ed: boolean) {
      this.edit = ed;
    }

    retrieveEdit() : boolean {
      if(this.edit == null)
        return true;
      return this.edit;
    }

    retrieveTechinicalTipsList() {
      return this.technicalTipsList;
    }

    storeTechnicalTipsList (objectoToStore : TechnicalTips[] ) {
      this.technicalTipsList = objectoToStore;
  }

  storeTechnicalTips (objectoToStore : TechnicalTips ) {
    this.technicalTips = objectoToStore;
  }

  retrieveTechnicalTips() : TechnicalTips {
      return this.technicalTips;
  }
}
