import { Injectable } from '@angular/core';
import { Ticket } from '../administration/ticket/ticket';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private azureCrudService: AzureStorageCrudService) { }


  addFeedbackFile(ticketId: number, feedbackSeq: number, file: File) {
    return this.azureCrudService.fileUpload(file, "upload?path=ticket/" + ticketId + "/" + feedbackSeq + "&filename=" + file.name);
  }

  addFeedbackFile2(ticketId: number, feedbackSeq: number, formData: FormData) {
    return this.azureCrudService.fileUpload2(formData, "upload?path=ticket/" + ticketId + "/" + feedbackSeq + "&filename=teste.pdf");
  }

  addManual(code: String, file: File) {
    return this.azureCrudService.fileUpload(file, "uploadManual?path=manual/" + code + "&filename=" + file.name);
  }
}
