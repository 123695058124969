import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-period',
    templateUrl: './input-period.component.html'
})
export class InputPeriodComponent { 

    @Input() formGroup: FormGroup;
    @Input() controlNameStart: string;
    @Input() controlNameEnd: string;

}