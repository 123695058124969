import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';
import { ElibBrand } from '../interfaces/elib-brand';

@Injectable({
  providedIn: 'root'
})
export class ElibBrandCrudService {
  key = 'etads/elib_brand';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : ElibBrand) {
    return this.azureCrudService.add(newReg, "addElibBrand");
  }

  upd(oldReg : ElibBrand, newReg : ElibBrand) {
    return this.azureCrudService.upd(oldReg, newReg, "updateelibbrand");
  }

  del(reg : ElibBrand) {
    return this.azureCrudService.del(reg, "deleteelibbrand");
  }

  get() {
    return this.azureCrudService.get("getelibbrand");
  }

}
