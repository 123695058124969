import { Component } from "@angular/core";
import { StateService } from "src/app/services/state.service";
import { Alert, AlertType } from './alert';
import { AlertService } from './alert.service';

@Component({
    selector: 'etads-alert',
    templateUrl: './alert.component.html'
})
export class AlertComponent {

    alerts: Alert[] = [];

    constructor(private alertService: AlertService, private stateService: StateService) {

        this.alertService
            .getAlert()
            .subscribe(alert => {
                if(!alert) {
                    this.alerts = [];
                    return;
                }
                this.alerts.push(alert);
            })
    }

    removeAlert(alertToRemove: Alert) {
        this.alerts = this.alerts.filter(alert => alert != alertToRemove);
    }

    getAlertClass(alert:  Alert) {

        if(!alert) return '';

        switch (alert.alertType) {

            case AlertType.DANGER:
                return 'alert alert-danger'
            case AlertType.INFO:
                return 'alert alert-info'
            case AlertType.SUCCESS:
                return 'alert alert-success'
            case AlertType.WARNING:
                return 'alert alert-warning'

        }
    }

}
