export interface Distributor {
    id : string;
    distributorId: number;
    distributorName: string;
    distributorCode: string;
    importRequired: Boolean;
}

export interface DistributorForm {
    distributorName: string;
    distributorCode: string;
    importRequired: Boolean;
}

export class EtadsDistributor implements Distributor  {
    id : string;
    distributorId: number;
    distributorName: string;
    distributorCode: string;
    importRequired: Boolean;
}

export class DistributorToInput {
    distributorName: string;
    distributorCode: string;
    importRequired: Boolean;
    selected: boolean;
}
