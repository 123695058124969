<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>

            <etads-label label="Distribuidor"></etads-label>
            <etads-input-text [formGroup]="form" controlName="distributorName" placeholder="Informe uma descrição."></etads-input-text>

            <etads-label label="Código"></etads-label>
            <etads-input-text [formGroup]="form" controlName="distributorCode" placeholder="Informe um código."></etads-input-text>
            <br>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="importRequired" checkLabel="Obrigatório informar arquivo de OS"></etads-input-checkbox>
            </etads-div-input-group>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]="form.invalid || form.pending"></etads-button-save>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>
