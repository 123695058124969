import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { TechnicalTips } from 'src/app/interfaces/technicaltips';
import { TechnicalTipsCrudService } from 'src/app/services/technicaltips-crud.service';

@Component({
    templateUrl: './technicaltips-remove.component.html'
})
export class TechnicalTipsRemoveComponent implements OnInit {

    form: FormGroup;
    technicalTipsId : number;
    messageLabel : string;
    technicalTipsToDel : TechnicalTips;
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private technicalTipsCrudService: TechnicalTipsCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.technicalTipsId = this.route.snapshot.params.technicalTipsId;
        this.technicalTipsToDel = this.stateService.retrieveTechnicalTips();

        this.alertService.warning("Deseja excluir o cadastro Dicas Tecnicas: " + this.technicalTipsToDel.name + " ?");

        this.form = this.formBuilder.group({
            technicalTipsId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.technicalTipsCrudService.del(this.technicalTipsToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['technicaltips-list']);
    }

}
