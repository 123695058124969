import { Component, Input } from '@angular/core';
import { Ticket } from '../../../../administration/ticket/ticket';
import { PriorityCrudService } from '../../../../services/priority-crud.service';

@Component({
  selector: 'etads-days-open-group',
  templateUrl: './days-open-group.component.html',
  styleUrls: ['./days-open-group.component.css']
})
export class DaysOpenGroupComponent {

  diasAberto: number;
  statusIcon: string;

  @Input() ticket: Ticket;

  constructor(
    private priorityService: PriorityCrudService,
  ) { }

  ngOnInit(): void {
    if (this.ticket.createDate) {
      let currentDate = new Date().getTime();
      let startDate = new Date(this.ticket.vehicleGroup.dtStart).getTime();
      this.diasAberto = Math.round((currentDate-startDate)/(1000*60*60*24)*10)/10;

      if (this.ticket.requestGroup.initials == 'S') {
        if (this.diasAberto < 3) {
            this.statusIcon = "EmDia";
        } else if (this.diasAberto <= 5) {
            this.statusIcon = "Atencao";
        } else {
            this.statusIcon = "Atrasado";
        }
      } else if (this.ticket.requestGroup.initials == 'A') {
        if (this.diasAberto < 6) {
            this.statusIcon = "EmDia";
        } else if (this.diasAberto <= 11) {
            this.statusIcon = "Atencao";
        } else {
            this.statusIcon = "Atrasado";
        }
      } else if (this.ticket.requestGroup.initials == 'B') {
        if (this.diasAberto < 7) {
            this.statusIcon = "EmDia";
        } else if (this.diasAberto <= 12) {
            this.statusIcon = "Atencao";
        } else {
            this.statusIcon = "Atrasado";
        }
      } else if (this.ticket.requestGroup.initials == 'C') {
        if (this.diasAberto < 8) {
            this.statusIcon = "EmDia";
        } else if (this.diasAberto <= 13) {
            this.statusIcon = "Atencao";
        } else {
            this.statusIcon = "Atrasado";
        }
      }

    }
  }

}
