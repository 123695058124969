<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>   
    <etads-table>
        <thead saTheadStyle >
            <tr>
                <th>Grupo de Sintoma</th>
                <th>Área Relacionada</th> 
                <th>Situação</th> 
                <th>Editar</th>
                <th>Excluir</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let symptom of symptomGroupList | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                <td>{{ symptom.symptomGroupDescription }}</td>
                <td>{{ symptom.areaRelatedId }} - {{ symptom.areaRelatedDescription }} </td>
                <td>{{ (symptom.active === true ) ? "Ativo" : "Inativo"  }}</td>

                <td>
                    <etads-tooltip label="Editar Grupo Sintoma" >
                        <etads-icon-edit (clickEmmiter)="actionEdit(symptom)" 
                            link="symptom-group-edit" [parameter]="symptom.symptomGroupId">
                        </etads-icon-edit>
                    </etads-tooltip>
                </td>
                <td>
                    <etads-tooltip label="Excluir Grupo Sintoma" >
                        <etads-icon-remove  (clickEmmiter)="actionRemove(symptom)"
                            link="symptom-group-remove" [parameter]="symptom.symptomGroupId">
                        </etads-icon-remove>
                    </etads-tooltip> 
                </td>
                
            </tr>
        </tbody>
    </etads-table>   
    <etads-div-center>
        <pagination-controls 
            (pageChange)="currentPage = $event" 
            previousLabel="Anterior" 
            nextLabel="Próximo"
            autoHide="true" >
        </pagination-controls>
    </etads-div-center>
    <etads-div-center>
        <etads-div-button-sm-screen>
            <etads-button-add (clickEmmiter)="actionAdd()" label="Novo Grupo de Sintoma"></etads-button-add> 
        </etads-div-button-sm-screen>
    </etads-div-center>
</etads-card-list>
