export interface ElibModel {
    id: string;
    modelId: number;
    description: string;
}

export interface ElibModelForm {
    id: string;
    modelId: number;
    description: string;
}
export class EtadsElibModel implements ElibModel {
    id: string;
    modelId: number;
    description: string;

    constructor(){}
}
