import { ElibBrand } from "./elib-brand";
import { ElibModel } from "./elib-model";

export interface ElibManual {
  id: string;
  manualId: number;
  name: string;
  code: string;
  description: string;
  startPage: string;
  startDate: Date;
  endDate: Date;
  createDate: Date;
  modificationDate: Date;
  hide: boolean;
  statusUpload: String;
  url: String;
  brand: ElibBrand;
  model: ElibModel;
}

export interface ElibManualForm {
    id: string;
    manualId: number;
    name: string;
    code: string;
    description: string;
    startPage: string;
    startDate: Date;
    endDate: Date;
    createDate: Date;
    modificationDate: Date;
    hide: boolean;
    statusUpload: String;
    url: String;
    brandId: number;
    modelId: number;
}
export class EtadsElibManual implements ElibManual {
  id: string;
  manualId: number;
  name: string;
  code: string;
  description: string;
  startPage: string;
  startDate: Date;
  endDate: Date;
  createDate: Date;
  modificationDate: Date;
  hide: boolean;
  statusUpload: String;
  url: String;
  brand: ElibBrand;
  model: ElibModel;

    constructor(){}
}
