import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { Subscription } from 'rxjs';
import { ElibBrandCrudService } from '../../../services/elib-brand-crud.service';
import { ElibBrand, EtadsElibBrand } from '../../../interfaces/elib-brand';

@Component({
    templateUrl: './elib-brand-list.component.html'
})
export class ElibBrandListComponent implements OnInit {

    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private elibBrandCrudService: ElibBrandCrudService
        ) {}

    currentPage : Number = 1;
    itemsPerPage : Number = 13;
    brandList : EtadsElibBrand[] = [];
    filterMap: Map<string, string> = new Map<string, string>();

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Marca",  "" );
        this.loadData();

       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);

        }

    }

    loadData() {

       this.subscription = this.elibBrandCrudService.get().subscribe( reg => {
            for (let data of reg ) {
                let idList = this.brandList.push(data);
            }
            this.spinnerService.hide();
        } , err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });
    }

    actionAdd() {
        this.stateService.storeElibBrandList(this.brandList);
        this.router.navigate(['elib-brand-create']);
    }

    actionEdit( elibBrandData: ElibBrand ) {
        this.stateService.storeElibBrand(elibBrandData);
        this.stateService.storeElibBrandList(this.brandList);
    }

    actionRemove( brandData: EtadsElibBrand ) {
        this.stateService.storeElibBrand(brandData);
    }


}
