<div class="card-header" style="text-align: center;">
    <h4 class="modal-title">{{modal_title}}</h4>
</div>
<div class="modal-body">
    <br>
    <div>
        <div class="container-fluid d-flex h-100 flex-column">
            <h5>Direcionar o chamado para:</h5>
        </div>
        <div style="padding-top: 20px;">
            <div class="container-fluid d-flex h-100 flex-column">
                <div class="row h-60">
                    <div class="col-12">
                        <select [(ngModel)]="selectedAnalista" class="form-control" style="width: 100%;">
                            <option *ngFor="let analista of listAnalistas" [ngValue]="analista">
                                {{analista.name}}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

<!--
        PARA DEBUG DESCOMENTAR
        <br> {{selectedAnalista | json}}
        <br> Analista: {{selectedTicket.analyst}}
        <br> {{selectedTicket | json}}
-->
    </div>

</div>
<div class="modal-footer">
    <button (click)='actionEnviarClick()'type="button" class="btn btn-primary mr-auto" [disabled]="selectedAnalista == null" data-dismiss="modal" style="width: 20%;">Enviar</button>
    <button type="button" class="btn btn-primary" data-dismiss="modal" (click)="activeModal.close('mr_cancel')" style="width: 20%;">Cancelar</button>
</div>

