<form [formGroup]="accessoryForm" (submit)="actionSubmitAccessory()">
  <div class="card mt-2 m-2">
    <div class="card-header">
      <etads-label label="Acessório(s) Instalado(s)"></etads-label>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-6">
        <etads-label label="Descrição"></etads-label>
        <etads-input-select [formGroup]="accessoryForm" firstOptionLabel="Selecione"
          controlName="acessoriesId">
          <option *ngFor="let it of listAccessories"  [value]="it.acessoriesId">{{it.acessoriesDescription}}</option>
        </etads-input-select>
      </div>
      <div class="form-group col-4">
        <etads-label label="Outros"></etads-label>
        <etads-input-text [formGroup]="accessoryForm" controlName="others" placeholder="">
        </etads-input-text>
      </div>
      <div class="form-group col-1">
        <etads-label label="Genuína"></etads-label>
        <etads-input-select [formGroup]="accessoryForm" firstOptionLabel="Selecione"
          controlName="genuine">
          <option value="Sim">Sim</option>
          <option value="Não">Não</option>
        </etads-input-select>
      </div>
      <div class="form-group">
        <etads-label label="Add"></etads-label>
        <button type="button" id="btnpart" class="form-control btn btn-info" (click)='actionAdd()'> + </button>
      </div>
    </div>

    <etads-table class="table table-striped px-2">
      <thead>
        <tr>
          <th class="col-6">Descrição</th>
          <th class="col-4">Outros</th>
          <th class="col-1">Genuína</th>
          <th class="col-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let it of listAccessoryGroup">
          <td>{{ it.acessoriesDescription }}</td>
          <td>{{ it.others }}</td>
          <td>{{ it.genuine }}</td>
          <td>
            <button type="button" id="btnRemoveTrouble" class="form-control btn btn-info" (click)='actionRemove(it)'> X
            </button>
          </td>
        </tr>
      </tbody>
    </etads-table>
  </div>
</form>
