import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, ReplaySubject } from 'rxjs';
import { UserService } from 'src/app/core/auth/user.service';
import { StateService } from 'src/app/services/state.service';
import { FeedbackGroup } from './feedback-group';

@Component({
  selector: 'etads-div-feedback-group',
  templateUrl: './div-feedback-group.component.html',

})
export class DivFeedbackGroupComponent {
  feedbackForm: FormGroup;
  feedbackGroup: FeedbackGroup;
  file: File;
  @Input() canAdd: boolean;
  unfounded: boolean;

  listFeedbackGroup: FeedbackGroup[];

  constructor(
    private stateService: StateService,
    private formBuilder: FormBuilder,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.listFeedbackGroup = [];

    let ticket = this.stateService.retrieveTicket();
    let listFeedbackGroup: FeedbackGroup[] = ticket.listFeedbackGroup;
    this.listFeedbackGroup = listFeedbackGroup;
    this.unfounded = ticket.unfounded;
  }

  createForm() {
    this.feedbackForm = this.formBuilder.group({
      descriptionFeed: [null, null],
      attachName: [null, null],
      attachSize: [null, null],
      insertUser: [null, null],
      insertDate: [null, null],
      attach: [null, null]
    });
  }

  actionSubmitFeedback() {
    console.log("submit feedback");
  }

  actionAdd() {
    if(this.unfounded && this.userService.isDealerUser()){
      return;
    }
    this.feedbackForm.get("insertUser").setValue(this.userService.getUserName());
    this.feedbackForm.get("insertDate").setValue(new Date());

    this.feedbackGroup = this.feedbackForm.getRawValue() as FeedbackGroup;
    if(!this.feedbackGroup?.descriptionFeed)
      return;
    this.feedbackGroup.draft = true;

    if (this.file) {
      this.feedbackGroup.attach = this.file;
      console.log(this.file);
    }

    if(!this.listFeedbackGroup){
      this.listFeedbackGroup = [this.feedbackGroup];
    }else{
      this.listFeedbackGroup.push(this.feedbackGroup);
    }

    this.feedbackForm.reset();
    this.file = null;
  }

  actionRemove(item: FeedbackGroup) {
    this.listFeedbackGroup.splice(this.listFeedbackGroup.indexOf(item), 1);
  }

  actionRemoveAttach() {
    this.file = null;
    this.feedbackForm.get("attachName").setValue(null);
    this.feedbackForm.get("attachSize").setValue(null);
  }

  onFileSelected(event: any) {

    let file: File = event.target.files[0];

    if (file) {
      console.log(file);
      this.feedbackForm.get("attachName").setValue(file.name);
      this.feedbackForm.get("attachSize").setValue(Math.round(file.size / 1024));

      this.file = file;
    }
  }
}
