import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-label-between-inputs',
    templateUrl: './label-between-inputs.component.html'
})
export class LabelBetweenInputsComponent { 

    constructor() {}

    @Input() label = '';
    
}