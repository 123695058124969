import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { EtadsPriority, Priority,  PriorityForm } from '../../../interfaces/priority';
import { Subscription } from 'rxjs';
import { ElibBrandCrudService } from '../../../services/elib-brand-crud.service';
import { ElibBrand, ElibBrandForm, EtadsElibBrand } from '../../../interfaces/elib-brand';

@Component ({
    templateUrl: './elib-brand-create.component.html'
})

export class ElibBrandCreateComponent implements OnInit {

    form: FormGroup;
    brandId: number;
    filterMap: Map<string, string> = new Map<string, string>();
    brandList: ElibBrand[];
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private elibBrandCrudService : ElibBrandCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService,
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Marca" , "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();

    }

    loadData() {
        this.brandList = this.stateService.retrieveElibBrandList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            description: ['',   [  Validators.required,  Validators.minLength(3)  ] ]
        });
    }


    actionSubmit() {
        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Marca Inválida ou  já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const prForm = this.form.getRawValue() as ElibBrandForm;
        let pxdata = new EtadsElibBrand() ;

        pxdata.description = prForm.description.valueOf().toUpperCase();

        this.subscription = this.elibBrandCrudService.add(pxdata).subscribe(
          (data) => {
            let idList = this.brandList.push(pxdata);
            this.stateService.storeElibBrandList(this.brandList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeElibBrandList(this.brandList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
          }
        );
    }

    actionClear() {
        this.ngOnInit();
    }

    actionBack() {
        this.router.navigate(['elib-brand-list']);
    }

    reloadCurrentRoute() {
      let currentUrl = this.router.url;
      this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
          this.router.navigate([currentUrl]);
      });
    }

    validateForm() {
        const newPr = this.form.getRawValue() as ElibBrandForm;

        for (let prData of this.brandList) {
             let parse1 = prData.description.toUpperCase();
             let parse2 = newPr.description.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }

        return true;
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}

