import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsSymptomGroup, SymptomGroup, SymptomGroupForm } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './symptom-group-edit.component.html'
})

export class SymptomGroupEditComponent implements OnInit {

    form: FormGroup;
    symptomGroupId: number;
    symptomGroup: SymptomGroup;
    symptomGroupList : SymptomGroup[] = [] ;
    areaRelatedList : AreaRelated[]=[];
    filterMap: Map<string,string> = new Map<string, string>();
    subscription : Subscription;


    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}
    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Editar Grupo de Sintomas","");
        this.loadData();
        this.createForm();
        this.updateForm();

    }

    loadData() {
        this.symptomGroupId = this.route.snapshot.params.symptomGroupId;
        this.symptomGroup = this.stateService.retrieveSymptomGroup();
        this.symptomGroupList = this.stateService.retrieveSymptomGroupList();

        /* carregando areas relacionadas */
        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( list => {
             for (let reg of list) {
                 this.areaRelatedList.push(reg);
             }
             this.spinnerService.hide();
        }, (error) => {
            this.alertService.danger(error.error);
            this.spinnerService.hide();
        } );

    }

    createForm() {

        this.form = this.formBuilder.group( {
            symptomGroupDescription: ['', [ Validators.required, Validators.minLength(3), Validators.maxLength(200)  ] ,   ],
            areaRelatedDescription : ['', [ Validators.required ],  ],
            active:  [this.symptomGroup.active,  [ Validators.required], ]

        });

    }

    updateForm() {
        this.form.patchValue({symptomGroupDescription: this.symptomGroup.symptomGroupDescription});
        this.form.patchValue({areaRelatedDescription: this.symptomGroup.areaRelatedDescription});
        this.form.patchValue({active: this.symptomGroup.active });

    }


    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Grupo de Sintoma Inválido ou já existente!');
            return;
        } else {
            this.alertService.clear();
        }
        this.spinnerService.show();

        const dataform = this.form.getRawValue() as SymptomGroupForm;
        let updSymptomGroup = new EtadsSymptomGroup();

        console.log(dataform);

        updSymptomGroup.id                  = this.symptomGroup.id;
        updSymptomGroup.symptomGroupId      = Number(this.symptomGroupId);
        updSymptomGroup.symptomGroupDescription  = dataform.symptomGroupDescription;
        updSymptomGroup.areaRelatedDescription = dataform.areaRelatedDescription;
        updSymptomGroup.areaRelatedId          =  Number(this.areaRelatedList.find(ar => ar.areaDescription === dataform.areaRelatedDescription).areaRelatedId);

        updSymptomGroup.active              = dataform.active;

        this.subscription = this.symptomGroupCrudService.upd( this.symptomGroup, updSymptomGroup).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            updSymptomGroup = null;
            this.symptomGroupId = null;
            this.symptomGroup = null;
            this.actionBack();
        },err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionClear() {
        this.ngOnInit();
    }

    actionBack() {
        this.symptomGroupId = null;
        this.symptomGroup = null;
        this.router.navigate(['symptom-group-list']);
    }

    validateForm() {

        const newSg = this.form.getRawValue() as SymptomGroupForm;

        for (let sgData of this.symptomGroupList) {
             let parse1 = sgData.symptomGroupDescription.toUpperCase();
             let parse2 = newSg.symptomGroupDescription.toUpperCase();

            if (parse1 == parse2 && sgData.symptomGroupId != this.symptomGroupId ) {
                return false;
            }
        }
        return true;

    }


}
