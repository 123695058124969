import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { EtadsGroupDlrSchedule, GroupDlrSchedule, GroupDlrScheduleForm } from '../../../interfaces/group-dlr-schedule';
import { GroupDlrScheduleCrudService } from '../../../services/group-dlr-schedule-crud.service';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { Distributor, DistributorToInput } from '../../../interfaces/distributor';
import { EtadsRadio, Radio } from 'src/app/interfaces/radio';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './group-dlr-schedule-edit.component.html',
})

export class GroupDlrScheduleEditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    currentUser : string;
    distributorList: DistributorToInput[]=[];

    groupDlrSchedule: GroupDlrSchedule;
    groupDlrScheduleId: number;
    groupDlrScheduleList: EtadsGroupDlrSchedule[]=[];

    levelList: Radio[]=[];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private groupDlrScheduleCrudService: GroupDlrScheduleCrudService,
        private distributorCrudService: DistributorCrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Escalonamento Grupo DLR", "");
        this.levelList = this.getLevelList();
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();
    }

    getLevelList() {
        let newRadioList : Radio[]=[];

        let opt: number;
        for (opt = 1; opt <= 3; opt++) {
            let optR = new EtadsRadio();
            optR.itemId = opt ;
            optR.itemLabel = opt.toString();
            let lst = newRadioList.push(optR);
        }
        return newRadioList;
    }

    loadData() {

        this.groupDlrScheduleId = this.route.snapshot.params.groupDlrScheduleId;
        this.groupDlrSchedule = this.stateService.retrieveGroupDlrSchedule();
        this.groupDlrScheduleList = this.stateService.retrieveGroupDlrScheduleList();

         // caregando lista de Distribuidores
         this.distributorList = [];
         this.subscription = this.distributorCrudService.get().subscribe( lst => {

              for (let distx of lst) {
                  let dist = new DistributorToInput();
                  dist.distributorCode = distx.distributorCode;
                  dist.distributorName = distx.distributorName;
                  this.distributorList.push(dist);
              }
              this.spinnerService.hide();
         }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
         });
    }

    createForm() {

        this.form = this.formBuilder.group( {
            groupDlrScheduleTitle: ['',  [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            level:  ['', [Validators.required], ],
            email:  ['', [Validators.required], ],
            active: [null, [Validators.required], ] ,
            distributor: ['', [Validators.required], ],
        });

    }

    updateForm() {
        this.form.patchValue({groupDlrScheduleTitle: this.groupDlrSchedule.groupDlrScheduleTitle});
        this.form.patchValue({level: this.groupDlrSchedule.level});
        this.form.patchValue({email: this.groupDlrSchedule.email});
        this.form.patchValue({active: this.groupDlrSchedule.active });
        this.form.patchValue({distributor: this.groupDlrSchedule.distributor});

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Escalonamento Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const gsform   = this.form.getRawValue() as GroupDlrScheduleForm;

        let gsToUpd = new EtadsGroupDlrSchedule();

        gsToUpd.id = this.groupDlrSchedule.id;
        gsToUpd.groupDlrScheduleId = this.groupDlrSchedule.groupDlrScheduleId;
        gsToUpd.groupDlrScheduleTitle = gsform.groupDlrScheduleTitle.toUpperCase();
        gsToUpd.level = gsform.level;
        gsToUpd.email = gsform.email.toString();
        gsToUpd.active = gsform.active;
        gsToUpd.distributor = gsform.distributor;

        this.subscription = this.groupDlrScheduleCrudService.upd(this.groupDlrSchedule, gsToUpd).subscribe((data) => {
            let idList = this.groupDlrScheduleList.push(gsToUpd);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            gsToUpd = null;
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        } );
    }

    actionDistributorChange() {

    }


    actionBack() {
        this.router.navigate(['group-dlr-schedule-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {

        const newSg = this.form.get('groupDlrScheduleTitle').value;

        for (let sgData of this.groupDlrScheduleList) {
            let parse1 = sgData.groupDlrScheduleTitle.toUpperCase();
            let parse2 = newSg.toUpperCase();

            if (parse1 == parse2 && sgData.groupDlrScheduleId != this.groupDlrScheduleId) {
                return false;
            }
        }

        return true;
    }

}
