import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    templateUrl: './global-error.component.html'
})
export class GlobalErrorComponent implements OnInit {

    errorMessage: string;

    constructor(
        private route: ActivatedRoute,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.errorMessage = this.route.snapshot.params.errorMessage;
        this.spinnerService.hide();
    }
}