export interface Analyst {
    id: string;
    login: string;
    name: string;
}
export class EtadsAnalyst {
  id: string;
  login: string;
  name: string;
}
