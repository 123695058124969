import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'etads-menu-dropdown',
    templateUrl: './menu-dropdown.component.html',
    styleUrls: ['./menu-dropdown.component.css']
})
export class MenuDropDownComponent implements OnInit {

  @Input() menuSequence : string;
  @Input() label: string;
  @Input() isTdbUser: boolean = false;
  @Input() visibleOnlyTdbUser: boolean = true;
  @Input() hideMenu: boolean = false;

  navbarDropdownId : string;
  dropDowmMenuId : string;

  constructor () {}

  ngOnInit(): void {
    this.navbarDropdownId = 'navbarDropdown' + this.menuSequence;
    this.dropDowmMenuId = 'dropDowmMenu' + this.menuSequence;
  }

}
