import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Symptom } from '../../../interfaces/symptom';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { Subscription } from 'rxjs';
import { Priority } from 'src/app/interfaces/priority';
import { EtadsTechnicalTips, TechnicalTips, TechnicalTipsForm } from 'src/app/interfaces/technicaltips';
import { TechnicalTipsCrudService } from 'src/app/services/technicaltips-crud.service';
import { PriorityCrudService } from 'src/app/services/priority-crud.service';

@Component ({
    templateUrl: './technicaltips-edit.component.html'
})

export class TechnicalTipsEditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    areaRelated: AreaRelated;
    sympton: Symptom;
    symptomGroup: SymptomGroup;
    priority: Priority;

    technicalTips: TechnicalTips;
    technicalTipsId: number;

    areaRelatedList : AreaRelated[] = [];
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    priorityList : Priority[] = [] ;
    subscription : Subscription;
    technicalTipsList: EtadsTechnicalTips[]=[];

    constructor (
      private formBuilder: FormBuilder,
      private alertService: AlertService,
      private areaRelatedCrudService: AreaRelatedCrudService,
      private symptomCrudService: SymptomCrudService,
      private symptomGroupCrudService: SymptomGroupCrudService,
      private technicalTipsCrudService: TechnicalTipsCrudService,
      private priorityCrudService: PriorityCrudService,
      public stateService: StateService,
      private router: Router,
      public datepipe: DatePipe,
      private route: ActivatedRoute,
      private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Item Dicas Técnicas", "");
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();
    }

    loadData() {

        this.technicalTipsId = this.route.snapshot.params.technicalTipsId;
        this.technicalTips = this.stateService.retrieveTechnicalTips();
        this.technicalTipsList = this.stateService.retrieveTechinicalTipsList();

        /* carregando Area Relacionada */
        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( (lista) => {
            for (let reg of lista) {
                this.areaRelatedList.push(reg);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        this.symptomGroupList = [];
        this.subscription = this.symptomGroupCrudService.get().subscribe( (lista) => {
            for (let reg of lista) {
                this.symptomGroupList.push(reg);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        this.symptomList = [];
        this.subscription = this.symptomCrudService.get().subscribe( (lista) => {
            for (let reg of lista) {
                this.symptomList.push(reg);
            }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        /* caregando lista de Prioridades */
        this.priorityList = [];
        this.subscription = this.priorityCrudService.get().subscribe( (listx) => {
             for (let regx of listx) {
                 this.priorityList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;


    }

    actionChangeArea() {
      this.symptomGroupList = [];
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || this.form.get("areaRelatedId")?.value == '')
        return;

      this.symptomGroupCrudService.getByAreaRelated(this.form.get("areaRelatedId")?.value).subscribe(lst => {
          this.symptomGroupList = lst;
      }, error => {
        console.log(error);
      });
    }

    actionChangeGroup() {
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || !this.form.get("symptomGroupId")
          || this.form.get("areaRelatedId")?.value == '' || this.form.get("symptomGroupId")?.value == '')
        return;

      this.symptomCrudService.getByGroup(this.form.get("areaRelatedId")?.value, this.form.get("symptomGroupId")?.value).subscribe(lst => {
          this.symptomList = lst;
      }, error => {
        console.log(error);
      });
    }

    createForm() {

      this.form = this.formBuilder.group( {
          name:           ['',     [ Validators.required, Validators.minLength(3) ] , ],
          vehicleDescription:  ['',  null, ],
          modelYear:  ['',  null, ],
          description:  ['',  null, ],
          areaRelatedId :   ['null', null, ],
          symptomGroupId:   ['null', null, ],
          symptomId:        ['null', null, ],
          priorityId:        ['null', null, ],
        }
      );

  }

    updateForm() {
        this.form.patchValue({name : this.technicalTips.name});
        this.form.patchValue({vehicleDescription: this.technicalTips.vehicleDescription});
        this.form.patchValue({modelYear: this.technicalTips.modelYear});
        this.form.patchValue({description: this.technicalTips.description});
        this.form.patchValue({areaRelatedId : this.technicalTips.areaRelatedId});
        this.form.patchValue({symptomGroupId: this.technicalTips.symptomGroupId });
        this.form.patchValue({symptomId: this.technicalTips.symptomId });
        this.form.patchValue({priorityId: this.technicalTips.priorityId });

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Dica Técnica Inválida ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const form   = this.form.getRawValue() as TechnicalTipsForm;
        let upd = new EtadsTechnicalTips();

        upd.id = this.technicalTips.id;
        upd.attachName = this.technicalTips.attachName;
        upd.technicalTipsId = Number(this.technicalTipsId.valueOf());
        upd.name = form.name;
        upd.vehicleDescription = form.vehicleDescription;
        upd.modelYear = form.modelYear;
        upd.description = form.description;
        upd.symptomId = Number(form.symptomId.valueOf());
        upd.symptomGroupId = Number(form.symptomGroupId.valueOf());
        upd.areaRelatedId  = Number(form.areaRelatedId.valueOf());
        upd.priorityId         = form.priorityId;

        for (let gr of this.symptomGroupList) {
            if (Number(gr.symptomGroupId) === Number(form.symptomGroupId) )   {
                upd.symptomGroupDescription = gr.symptomGroupDescription;
                break;
            }
        }

        for (let sy of this.symptomList) {
            if (Number(sy.symptomId) === Number(form.symptomId) )   {
                upd.symptomDescription = sy.symptomDescription;
                break;
            }
        }

        for (let p of this.priorityList) {
          if (Number(p.priorityId) === Number(form.priorityId) )   {
            upd.initials = p.initials;
            upd.priorityDescription = p.alias;
              break;
          }
      }

        upd.areaRelatedDescription  = this.areaRelatedList.find(ar => ar.areaRelatedId == form.areaRelatedId).areaDescription;

        this.subscription = this.technicalTipsCrudService.upd(this.technicalTips, upd).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            upd = null;
            this.spinnerService.hide();
            this.actionBack();

        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['technicaltips-list']);
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as TechnicalTipsForm;

            for (let sgData of this.technicalTipsList) {
               let parse1 = sgData.name.toUpperCase();
               let parse2 = newSg.name.toUpperCase();

               if (parse1 == parse2  && sgData.technicalTipsId!=this.technicalTipsId) {
                   return false;
               }
           }

        return true;
    }

}
