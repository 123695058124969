import { Component } from '@angular/core';

@Component({
    selector: 'etads-card-list',
    templateUrl: './card-list.component.html'
})
export class CardListComponent { 

    constructor() {}
    
}