
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";  
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { T2CrudService } from '../../../services/t2-crud.service';
import { T2 } from '../../../interfaces/t2';
import { Subscription } from 'rxjs';

@Component({
    templateUrl: './t2-remove.component.html'
})
export class T2RemoveComponent implements OnInit {

    form: FormGroup;    
    t2Id : number;
    messageLabel : string;
    t2ToDel : T2;
    subscription: Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private t2CrudService: T2CrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.t2Id = this.route.snapshot.params.areaRelatedId;
        this.t2ToDel = this.stateService.retrieveT2();
      
        this.alertService.warning("Deseja excluir o cadastro T2: " + this.t2ToDel.t2Code + " - " + this.t2ToDel.t2Name + " ?");

        this.form = this.formBuilder.group({
            t2Id: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        
        this.subscription =  this.t2CrudService.del(this.t2ToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

        

        this.actionBack();

    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['t2-list']);
    }

}
