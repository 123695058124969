import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { EtadsSymptom, Symptom, SymptomForm } from '../../../interfaces/symptom';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { Priority } from '../../../interfaces/priority';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { Subscription } from 'rxjs';


@Component ({
    templateUrl: './symptom-create.component.html'
})

export class SymptomCreateComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();
    symptomId: number;
    symptom: Symptom;
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    priorityList : Priority[] = [];
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private symptomCrudService: SymptomCrudService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private priorityCrudService: PriorityCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Adicionar Sintoma", "");
        this.loadData();
        this.createForm();
    }

    loadData() {

        this.symptomList = this.stateService.retrieveSymptomList();

        /* caregando lista de Grupo de Sintomas  */
        this.symptomGroupList = [];
        this.subscription = this.symptomGroupCrudService.get().subscribe( (list) => {
             for (let reg of list) {
                 this.symptomGroupList.push(reg);
             }
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

        /* caregando lista de Prioridades */
        this.priorityList = [];
        this.subscription = this.priorityCrudService.get().subscribe( (listx) => {
             for (let regx of listx) {
                 this.priorityList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        }) ;

    }

    createForm() {

        this.form = this.formBuilder.group( {
            symptomDescription:    ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(200)  ] ,   ],
            symptomGroupId:        ['',     [ Validators.required,  ] ,   ],
            priorityDescription :  ['', [ Validators.required ], ],
            active:  [null, [  Validators.required  ] ],
        });

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("Sintoma Inválido ou já existente!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const symform   = this.form.getRawValue() as SymptomForm;
        let symToInsert = new EtadsSymptom();

        symToInsert.symptomDescription  = symform.symptomDescription;
        symToInsert.symptomGroupId      = Number(symform.symptomGroupId);
        symToInsert.priorityDescription = symform.priorityDescription;
        symToInsert.active              = symform.active;

        for (let gr of this.symptomGroupList) {
            if (Number(gr.symptomGroupId) === Number(symform.symptomGroupId) )   {
                symToInsert.symptomGroupDescription = gr.symptomGroupDescription;
                symToInsert.areaRelatedDescription = gr.areaRelatedDescription;
                symToInsert.areaRelatedId = Number(gr.areaRelatedId)
                break;
            }

        }

        symToInsert.priorityId     = this.priorityList.find(pr => pr.alias === symform.priorityDescription).priorityId;

        this.subscription = this.symptomCrudService.add(symToInsert).subscribe((data) => {
            let idList = this.symptomList.push(symToInsert);
            this.stateService.storeSymptomList(this.symptomList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            symToInsert = null;
            this.actionBack();

        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['symptom-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as SymptomForm;

            for (let sgData of this.symptomList) {
               let parse1 = sgData.symptomDescription.toUpperCase();
               let parse2 = newSg.symptomDescription.toUpperCase();

               if (parse1 == parse2 && sgData.symptomGroupId == newSg.symptomGroupId) {
                   return false;
               }
           }

        return true;
    }

}
