<div class="card" >
    <ngx-spinner></ngx-spinner>
    <div class="card-body">
    <etads-alert></etads-alert>
        <div class="row">
            <div class="col">
                <ng-content></ng-content>
            </div>
        </div>
    </div>
</div>