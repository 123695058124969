import { Injectable } from '@angular/core';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class DealerService {

  constructor(private azureCrudService: AzureStorageCrudService) { }

  getByCode(dealerCode: string) {
    return this.azureCrudService.get("getDistributorAdditionalInfo?distributorCode=" + dealerCode);
  }

}
