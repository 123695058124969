import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { HomeComponent } from './home.component';
import { ComponentsModule } from '../../shared/components/components.module';


@NgModule({
    declarations: [ 
        HomeComponent
    ],
    exports: [
        HomeComponent
    ],
    imports: [ 
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        FormsModule,
        ComponentsModule,
    ]
})
export class HomeModule {  }