import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-month-range',
    templateUrl: './input-month-range.component.html'
})
export class InputMonthRangeComponent {

    @Input() formGroup: FormGroup;
    @Input() controlNameStart: string;
    @Input() controlNameEnd: string;
}