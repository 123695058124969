import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { GroupTdbScheduleCrudService } from '../../../services/group-tdb-schedule-crud.service';
import { EtadsGroupTdbSchedule, GroupTdbSchedule } from '../../../interfaces/group-tdb-schedule';
import { Subscription } from 'rxjs';



@Component({
    templateUrl: './group-tdb-schedule-list.component.html'
})
export class GroupTdbScheduleListComponent implements OnInit {


    currentPage: Number = 1;
    itemsPerPage: Number = 13; 
    groupTdbScheduleList: GroupTdbSchedule[] = [];   
    groupTdbSchedule: GroupTdbSchedule;
    groupTdbScheduleId: number;
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor(
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService : AlertService,
        private groupTdbScheduleCrudService: GroupTdbScheduleCrudService,
        ) {}

    ngOnInit() {

        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Escalonamento Grupo TDB",  "" );
        this.loadData();
        
       const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
       if (sucessAlertMessage != null) {
           this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
           
        }
       
    }

    loadData() {

        this.groupTdbScheduleList = [];
        this.subscription = this.groupTdbScheduleCrudService.get().subscribe( listx => {
             for (let regx of listx) {
                 this.groupTdbScheduleList.push(regx);
             }
             this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }      
           
    actionAdd() {
        this.stateService.storeGroupTdbScheduleList(this.groupTdbScheduleList);
        this.router.navigate(['group-tdb-schedule-create']);
    }

    actionEdit( groupTdbScheduledata: GroupTdbSchedule) {
        this.stateService.storeGroupTdbSchedule(groupTdbScheduledata);
        this.stateService.storeGroupTdbScheduleList(this.groupTdbScheduleList);
    }

    actionRemove( groupTdbSchedule: GroupTdbSchedule) {
        this.stateService.storeGroupTdbSchedule(groupTdbSchedule);
    }


}