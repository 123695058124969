import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AnalystCrudService } from '../../../services/analyst-crud.service';
import { Analyst } from '../../../interfaces/analyst';

import { Ticket } from '../ticket';
import { AlertService } from 'src/app/shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/core/auth/user.service';

@Component({
  selector: 'app-ticket-encaminhar',
  templateUrl: './ticket-encaminhar.component.html',
  styleUrls: ['./ticket-encaminhar.component.css']
})

export class TicketEncaminharComponent implements OnInit {
  @Input() modal_title;
  @Input() modal_content;
  @Input() selectedTicket : Ticket;

  selectedAnalista : Analyst;

  public listAnalistas : Analyst[] = [];
  public confirmation : boolean = false;

  constructor(public activeModal: NgbActiveModal, private userService: UserService,
    private spinnerService: NgxSpinnerService) {

  }

  ngOnInit() {
    this.loadData();
    this.selectedAnalista = null;
  }

  loadData() {
    // caregando lista de Distribuidores

    this.listAnalistas = this.userService.getAnalysts();
    /*this.analystCrudService.get().subscribe(lst => {
        this.listAnalistas = lst;
        console.log(this.listAnalistas);
    }, error => {
      this.alertService.danger(error.error.errorMessage);
      this.spinnerService.hide();
    });*/
  }

  actionEnviarClick() {
    this.spinnerService.show();
    if (this.selectedAnalista != null) {
      this.selectedTicket.analyst = this.selectedAnalista.login;
      this.selectedTicket.analystName = this.selectedAnalista.name;
    }

    this.activeModal.close("mr_save");
  }
}

