import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { PriorityCrudService } from '../../../services/priority-crud.service';
import { Priority } from '../../../interfaces/priority';
import { EtadsPriority } from '../../../interfaces/priority';
import { Subscription } from 'rxjs';
import { ElibBrand } from '../../../interfaces/elib-brand';
import { ElibBrandCrudService } from '../../../services/elib-brand-crud.service';


@Component({
    templateUrl: './elib-brand-remove.component.html'
})
export class ElibBrandRemoveComponent implements OnInit {


    form: FormGroup;
    brandId : number;
    messageLabel : string;
    elibBrandToDel : ElibBrand;
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private elibBrandCrudService: ElibBrandCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.brandId = this.route.snapshot.params.brandId;
        this.elibBrandToDel = this.stateService.retrieveElibBrand();

        this.alertService.warning("Deseja excluir a Marca: " + this.elibBrandToDel.description + "?");

        this.form = this.formBuilder.group({
            brandId: ['']
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();

        this.subscription = this.elibBrandCrudService.del(this.elibBrandToDel).subscribe(
          (data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
          },
          (error) => {
            this.stateService.storeAlertSuccessMessage(error.error);
            this.spinnerService.hide();
          }
        );
    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['elib-brand-list']);
    }

    ngOnDestroy() {
      if(this.subscription)
        this.subscription.unsubscribe();
    }

}
