<form [formGroup]="form" (submit)= "actionSubmit()">
<etads-card-list>
    <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
    <etads-table style="font-size: 13px;">
      <tr><td class="p-3">
        <div class="row">
          <div class="col-1">
            <etads-label label="Distribuidor"></etads-label>
            <etads-input-text [formGroup]="form" controlName="distributorId" placeholder=""></etads-input-text>
          </div>
          <div class="col-2">
            <etads-label label="Chassi"></etads-label>
            <etads-input-text [formGroup]="form" controlName="chassi" placeholder=""></etads-input-text>
          </div>
          <div class="col-1">
            <etads-label label="Chamado"></etads-label>
            <etads-input-text [formGroup]="form" controlName="ticketId" placeholder=""></etads-input-text>
          </div>
          <div class="col-1">
            <etads-label label="Status"></etads-label>
            <etads-input-text [formGroup]="form" controlName="status" placeholder=""></etads-input-text>
          </div>
          <div class="col-2">
            <etads-label label="Data início"></etads-label>
            <etads-input-date [formGroup]="form" controlName="dtStart"></etads-input-date>
            <etads-vmessage *ngIf="form.get('dtStart').errors?.required" text="Campo obrigatório"></etads-vmessage>
          </div>
          <div class="col-2">
            <etads-label label="Data fim"></etads-label>
            <etads-input-date [formGroup]="form" controlName="dtEnd"></etads-input-date>
            <etads-vmessage *ngIf="form.get('dtEnd').errors?.required" text="Campo obrigatório"></etads-vmessage>
          </div>
        </div>
        <div class="row">
          <div class="col-1">
            <etads-label label="Modelo"></etads-label>
            <etads-input-text [formGroup]="form" controlName="model" placeholder=""></etads-input-text>
          </div>
          <div class="form-group col-md-2">
            <etads-label label="Finalidade"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="finalityId">
              <option *ngFor="let it of listFinality"  [value]="it.finalityId" [selected]="(requestGroup && it.finalityId == requestGroup.finalityId)">{{it.finalityDescription}}</option>
            </etads-input-select>
          </div>
          <div class="form-group col-md-2">
            <etads-label label="Área Relacionada"></etads-label>
            <etads-input-select [formGroup]="form" (change)="actionChangeArea()" firstOptionLabel="Selecione" controlName="areaRelatedId">
                <option *ngFor=" let area of areaRelatedList " [value]="area.areaRelatedId" >{{area.areaDescription}}</option>
            </etads-input-select>
          </div>
          <div class="form-group col-md-2">
            <etads-label label="Grupo de Sintoma"></etads-label>
            <etads-input-select [formGroup]="form" (change)="actionChangeGroup()" firstOptionLabel="Selecione" controlName="symptomGroupId">
                <option *ngFor=" let gr of symptomGroupList " [value]="gr.symptomGroupId" >{{gr.symptomGroupDescription}}</option>
            </etads-input-select>
          </div>
          <div class="form-group col-md-2">
            <etads-label label="Sintoma"></etads-label>
            <etads-input-select [formGroup]="form" firstOptionLabel="Selecione" controlName="symptomId">
                <option *ngFor=" let gs of symptomList " [value]="gs.symptomId" >{{gs.symptomDescription}}</option>
            </etads-input-select>
          </div>
          <div class="form-group col-md-2">
            <br>
            <etads-button-search [disabled]="false"></etads-button-search>
          </div>
        </div>
      </td></tr>
    </etads-table>
    <etads-table id="data-table" style="font-size: 12px;">
        <thead saTheadStyle>
            <tr>
              <th style="width: 40px;">Chamado</th>
              <th>Prioridade</th>
              <th>Abertura</th>
              <th>Encerramento</th>
              <th>Veículo</th>
              <th>Modelo</th>
              <th>Finalidade</th>
              <th>Área Relacionada</th>
              <th>Grupo Sintoma</th>
              <th>Sintoma</th>
              <th>Cod. Dist.</th>
              <th>Distribuidor</th>
              <th>Improc.</th>
              <th>Incor.</th>
              <th>Peça Toyota</th>
              <th>Peça Causadora</th>
              <th>OS</th>
              <th>Dat. Abertura</th>
              <th>Chassi</th>
              <th>Km</th>
              <th>SAC</th>
              <th>Status</th>
            </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ticket of ticketList">
              <td>{{ ticket.ticketId }}</td>
              <td>{{ ticket.requestGroup?.initials }}</td>
              <td>{{ ticket.createDate | date: 'dd/MM/yyyy HH:mm' }}</td>
              <td>{{ ticket.closeDate | date: 'dd/MM/yyyy HH:mm' }}</td>
              <td>{{ ticket.vehicleGroup?.vehicle }}</td>
              <td>{{ ticket.vehicleGroup?.model }}</td>
              <td>{{ ticket.requestGroup?.finalityDescription }}</td>
              <td>{{ ticket.requestGroup?.relatedAreaDescription }}</td>
              <td>{{ ticket.requestGroup?.symptomGroupDescription }}</td>
              <td>{{ ticket.requestGroup?.symptomDescription }}</td>
              <td>{{ ticket.dealerGroup?.code }}</td>
              <td>{{ ticket.dealerGroup?.name }}</td>
              <td>{{ (!ticket.unfounded) ? 'Não' : 'Sim'}}</td>
              <td>{{ (!ticket.incorrect) ? 'Não' : 'Sim'}}</td>
              <td>{{ ticket.pnToyota }}</td>
              <td>{{ ticket.partCauseGroup?.partCode }}</td>
              <td>{{ ticket.vehicleGroup?.os }}</td>
              <td>{{ ticket.vehicleGroup?.dtStart | date: 'dd/MM/yyyy HH:mm' }}</td>
              <td>{{ ticket.vehicleGroup?.chassi }}</td>
              <td>{{ ticket.vehicleGroup?.km }}</td>
              <td>{{ ticket.repairHistoryGroup?.sac }}</td>
              <td>{{ ticket.status }}</td>
          </tr>
      </tbody>
    </etads-table>
    <div *ngIf="!ticketList?.length" style="font-size: 13px;">Nenhum registro encontrado</div>
    <div *ngIf="ticketList?.length"><etads-button-download (clickEmmiter)="exportExcel()"></etads-button-download></div>
</etads-card-list>
</form>
