export interface Finality {
    id: string;
    finalityId: number;
    finalityDescription: string;
    userGroup: string;
    active: boolean;
    repeatEvent: boolean;
}

export interface FinalityForm {
    finalityDescription: string;
    userGroup: string;
    active: boolean;
    repeatEvent: boolean;
}

export class EtadsFinality implements Finality {
    id: string;
    finalityId: number;
    finalityDescription: string;
    userGroup: string;
    active: boolean;
    repeatEvent: boolean;

}

