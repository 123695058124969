import { Injectable } from '@angular/core';
import { T2 } from '../interfaces/t2';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class T2CrudService {
  key = 'etads/t2';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : T2) {
    return this.azureCrudService.add(newReg, "addT2");
  }

  upd(oldReg : T2, newReg : T2) {
    return this.azureCrudService.upd(oldReg, newReg, "updateT2");
  }

  del(reg : T2) {
    return this.azureCrudService.del(reg, "deleteT2");
  }

  get() {
    return this.azureCrudService.get("getT2");
  }

}
