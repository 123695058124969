import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { AcessoriesCrudService } from '../../../services/acessories-crud.service';
import { Acessories, AcessoriesForm, EtadsAcessories } from '../../../interfaces/acessories';
import { Subscription } from 'rxjs';
import { Ticket } from '../ticket';
import { TicketCrudService } from 'src/app/services/ticket-crud-service';

@Component({
    templateUrl: './ticket-edit.component.html'
})
export class TicketEditComponent implements OnInit {

    form: FormGroup;
    ticketId: number;
    ticket: Ticket;
    filterMap: Map<string, string> = new Map<string, string>();
    subscription: Subscription;

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private ticketCrudService: TicketCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService
    ) { }

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Editar Ticket", "");
        this.loadData();
        this.createForm();
        this.updateForm();

        this.spinnerService.hide();
    }

    loadData() {
        this.ticketId = this.route.snapshot.params.ticketId;
        this.ticket = this.stateService.retrieveTicket();
    }

    createForm() {
        this.form = this.formBuilder.group({
            chassi: [null, null],
            km: [null, null]
        });
    }

    updateForm() {
        this.form.patchValue({ chassi: this.ticket.vehicleGroup.chassi });
        this.form.patchValue({ km: this.ticket.vehicleGroup.km });
    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Ticket Inválido ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        this.subscription = this.ticketCrudService.upd(this.ticket, this.ticket).subscribe((data) => {

            this.spinnerService.hide();
            this.actionBack();

        }, (error) => {
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['ticket-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {

        return true;

    }

}
