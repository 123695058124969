<form [formGroup]="feedbackForm" (submit)="actionSubmitFeedback()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Tratativa"></etads-label>
    </div>
    <div *ngIf="canAdd" class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-3">
        <etads-label label="Anexo"></etads-label>
        <etads-input-file-all *ngIf="!file" [formGroup]="feedbackForm" controlName="attach"
          (change)="onFileSelected($event)">
        </etads-input-file-all>
        <etads-input-text *ngIf="file" [formGroup]="feedbackForm" controlName="attachName" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-1" *ngIf="file">
        <etads-label label="-"></etads-label>
        <button type="button" id="btnfeedback" class="form-control btn btn-info" (click)='actionRemoveAttach()'> X
        </button>
      </div>
      <div class="form-group col">
        <etads-label label="Descrição"></etads-label>
        <etads-input-textArea [formGroup]="feedbackForm" controlName="descriptionFeed" style="height: 60px;">
        </etads-input-textArea>
      </div>
      <div class="form-group">
        <etads-label label="Add"></etads-label>
        <button type="button" id="btnfeedback" class="form-control btn btn-info" (click)='actionAdd()'> + </button>
      </div>
    </div>

    <etads-table class="table table-striped px-2 w-40">
      <thead>
        <tr>
          <th class="col-2">Anexo</th>
          <th class="col-1">Tamanho(KB)</th>
          <th class="col-6">Descrição</th>
          <th class="col-1">Usuário</th>
          <th class="col-2">Data e Hora</th>
          <th class="col-1"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let it of listFeedbackGroup">
          <td *ngIf="it.attachUrl">
            <a [href]="it.attachUrl">{{ it.attachName }}</a>
          </td>
          <td *ngIf="!it.attachUrl">
            {{ it.attachName }}
          </td>
          <td>{{ it.attachSize }}</td>
          <td>{{ it.descriptionFeed }}</td>
          <td>{{ it.insertUser }}</td>
          <td>{{ it.insertDate | date: 'dd/MM/yyyy HH:mm'}}</td>
          <td>
            <button *ngIf="it.draft" type="button" id="btnRemoveTrouble" class="form-control btn btn-info" (click)='actionRemove(it)'> X
            </button>
          </td>
        </tr>
      </tbody>
    </etads-table>
  </div>
</form>
