export interface Symptom {
    id: string;
    symptomId : number;
    symptomDescription: string;
    symptomGroupId: number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    priorityId: number;
    initials: string;
    priorityDescription: string;
    active:boolean ;
}

export interface SymptomForm {
    symptomDescription: string;
    symptomGroupId: number;
    symptomGroupDescription: string;
    priorityId: number;
    initials: string;
    priorityDescription: string;
    active:boolean;
}

export class EtadsSymptom implements Symptom {
    id: string;
    symptomId : number;
    symptomDescription: string;
    symptomGroupId: number;
    symptomGroupDescription: string;
    areaRelatedId: number;
    areaRelatedDescription: string;
    priorityId: number;
    initials: string;
    priorityDescription: string;
    active:boolean;
}

export class SymptomScrSel {
    symptomGroupId: string;
    symptomGroupDescription: string;
}

