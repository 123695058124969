<div [formGroup]="formGroup">
    <div [id]="controlName" class="form-check form-check-inline" >
        <div class="form-check form-check-inline" *ngFor="let itemrl of radioList">
            <label class="radio-inline" >
                <input class="form-check-input form-check-inline" type="radio" 
                    [formControlName]="controlName" 
                    [value]="itemrl.itemId" 
                    (change)="actionChange($event.target.value)"><strong>{{ itemrl.itemLabel }}</strong> 
            </label>
        </div>
    </div>
</div>
