import { Injectable } from '@angular/core';
import { AnswerSchedule } from '../interfaces/answer-schedule';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class AnswerScheduleCrudService {
  key = 'etads/answerSchedule';
  // constructor(private crudServ: CosmosDbCrudService) { }
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : AnswerSchedule) {
    return this.azureCrudService.add(newReg, "addAnswerSchedule");
  }

  upd(oldReg : AnswerSchedule, newReg : AnswerSchedule) {
    return this.azureCrudService.upd(oldReg, newReg, "updateAnswerSchedule");
  }

  del(reg : AnswerSchedule) {
    return this.azureCrudService.del(reg, "deleteAnswerSchedule");
  }

  get() {
    return this.azureCrudService.get("getAnswerSchedule");
  }

}
