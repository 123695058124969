import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { MenuDropDownComponent } from './menu/menu-dropdown/menu-dropdown.component';
import { MenuDropDownItemComponent } from './menu/menu-dropdown-item/menu-dropdown-item.component';
import { ComponentsModule } from '../shared/components/components.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { GlobalErrorComponent } from '../core/errors/global-error/global-error.component';
import { NotFoundComponent} from '../core/errors/not-found/not-found.component';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        MenuDropDownComponent,
        MenuDropDownItemComponent,
        GlobalErrorComponent,
        NotFoundComponent

    ],
    exports: [
        HeaderComponent,
        FooterComponent,
        MenuDropDownComponent,
        MenuDropDownItemComponent,
        GlobalErrorComponent,
        NotFoundComponent
    ],
    imports:[
        CommonModule,
        RouterModule,
        ComponentsModule,
        NgxSpinnerModule,
    ]
})
export class CoreModule {}