import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";  
import { AlertService } from '../../../shared/components/alert/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StateService } from '../../../services/state.service';
import { GroupTdbSchedule } from '../../../interfaces/group-tdb-schedule';
import { GroupTdbScheduleCrudService } from '../../../services/group-tdb-schedule-crud.service';
import { Subscription } from 'rxjs';


@Component({
    templateUrl: './group-tdb-schedule-remove.component.html'
})
export class GroupTdbScheduleRemoveComponent implements OnInit {


    form: FormGroup;    
    groupTdbScheduleId : number;
    messageLabel : string;
    groupTdbScheduleToDel : GroupTdbSchedule;
    subscription : Subscription;
        
    constructor(
        private formBuilder: FormBuilder,
        private groupTdbScheduleCrudService: GroupTdbScheduleCrudService,
        private alertService: AlertService,
        private spinnerService: NgxSpinnerService,
        private route: ActivatedRoute,
        private stateService: StateService,
        private router: Router) {}


    ngOnInit(): void {
        this.spinnerService.show();

        this.groupTdbScheduleId = this.route.snapshot.params.groupTdbScheduleId;
        this.groupTdbScheduleToDel = this.stateService.retrieveGroupTdbSchedule();
      
        this.alertService.warning("Deseja excluir Escalomamento de Grupo TDB : " + this.groupTdbScheduleToDel.groupTdbScheduleTitle + " ?");

        this.form = this.formBuilder.group({
           groupTdbScheduleId: [''] 
        });

        this.spinnerService.hide();
    }

    actionSubmit() {

        this.spinnerService.show();
        
        this.subscription = this.groupTdbScheduleCrudService.del(this.groupTdbScheduleToDel).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide()
        });
        
    }

    actionCancel() {
        this.actionBack();
    }


    actionBack() {
        this.router.navigate(['group-tdb-schedule-list']);
    }

}
