<span class="form-group" data-toggle="tooltip" title="{{label}}">
    <ng-content></ng-content>
</span>

<!-- span class="d-inline-block" tabindex="0" data-toggle="tooltip" title="{{label}}">
    <ng-content></ng-content>
</span-->


<!-- a [Tooltip]="label" tooltipPosition="top" >
    <ng-content></ng-content>
</a-->