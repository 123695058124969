import { Injectable } from '@angular/core';
import { GeneralSchedule } from '../interfaces/general-schedule';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class GeneralScheduleCrudService {
  key = 'etads/generalSchedule';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : GeneralSchedule) {
    return this.azureCrudService.add(newReg, "addGeneralSchedule");
  }

  upd(oldReg : GeneralSchedule, newReg : GeneralSchedule) {
    return this.azureCrudService.upd(oldReg, newReg, "updateGeneralSchedule");
  }

  del(reg : GeneralSchedule) {
    return this.azureCrudService.del(reg, "deleteGeneralSchedule");
  }

  get() {
    return this.azureCrudService.get("getGeneralSchedule");
  }

}
