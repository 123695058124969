<form [formGroup]="dealerForm" (submit)="actionSubmitDealer()">
  <div class="card mt-2">
    <div class="card-header">
      <etads-label label="Distribuidor"></etads-label>
    </div>
    <div class="form-row pt-2 pl-2 pr-2 pb-0">
      <div class="col-3">
        <etads-label label="Número"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="code" placeholder="" readonly="true"></etads-input-text>
      </div>
      <div class="col-3">
        <etads-label label="Nome"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="name" placeholder="" readonly="true"></etads-input-text>
      </div>
      <div class="col-3">
        <etads-label label="Contato"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="contact" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="col-3">
        <etads-label label="Cargo"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="role" placeholder="" readonly="true"></etads-input-text>
      </div>
    </div>
    <div class="form-row pt-0 pl-2 pr-2 pb-0">
      <div class="form-group col-md-3">
        <etads-label label="Tel"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="telephone" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-3">
        <etads-label label="Cel"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="cellphone" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-3">
        <etads-label label="ID"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="id" placeholder="" readonly="true"></etads-input-text>
      </div>
      <div class="form-group col-md-1">
        <etads-label label="UF"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="state" placeholder="" readonly="true">
        </etads-input-text>
      </div>
      <div class="form-group col-md-2">
        <etads-label label="Cidade"></etads-label>
        <etads-input-text [formGroup]="dealerForm" controlName="city" placeholder="" readonly="true"></etads-input-text>
      </div>
    </div>
  </div>
</form>
