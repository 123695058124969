import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { Finality, FinalityForm, EtadsFinality } from '../../../interfaces/finality';
import { FinalityCrudService } from '../../../services/finality-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './finality-create.component.html'
})
export class FinalityCreateComponent implements OnInit {

    form:FormGroup;
    finalityId: number;
    finalityList: Finality[];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private finalityCrudService: FinalityCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Adicionar Finalidade", "");
        this.loadData();
        this.createForm();

        this.spinnerService.hide();
    }

    loadData() {
        this.finalityList = this.stateService.retrieveFinalityList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            finalityDescription: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(100) ] ],
            userGroup: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(200) ] ],
            active:   [null, [  Validators.required  ] ],
            repeatEvent:   [false, [  Validators.required  ] ],

        });
    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Finalidade Inválida ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const finalityForm = this.form.getRawValue() as FinalityForm;
        let newFinality = new EtadsFinality();

        newFinality.finalityDescription = finalityForm.finalityDescription.toUpperCase();
        newFinality.userGroup = finalityForm.userGroup;
        newFinality.active = finalityForm.active.valueOf();
        newFinality.repeatEvent = finalityForm.repeatEvent.valueOf();

        this.subscription = this.finalityCrudService.add(newFinality).subscribe((data) => {
            let idList = this.finalityList.push(newFinality);
            this.stateService.storeFinalityList(this.finalityList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();
        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });


    }

    actionBack() {
        this.router.navigate(['finality-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newAr = this.form.getRawValue() as FinalityForm;

        for (let finData of this.finalityList) {
             let parse1 = finData.finalityDescription.toUpperCase();
             let parse2 = newAr.finalityDescription.toUpperCase();

            if (parse1 == parse2) {
                return false;
            }
        }
        return true;

    }

}
