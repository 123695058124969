export interface Priority {
    id: string;
    priorityId: number;
    initials: string;
    alias: string;
    sequence: number;
    active: boolean;
}

export interface PriorityForm {
    id: string;
    initials: string;
    alias: string;
    sequence: number;
    active: boolean;
}
export class EtadsPriority implements Priority {
    id: string;
    priorityId: number;
    initials: string;
    alias: string;
    sequence: number;
    active: boolean;

    constructor(){}
}