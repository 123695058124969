<form [formGroup]="form" (submit)="actionSubmit()">
    <etads-card-form>
        <etads-div-col-small>
            <etads-card-filter-list [map]="filterMap"></etads-card-filter-list>
            
            <etads-label label="Título"></etads-label>
            <etads-input-text [formGroup]="form" controlName="groupTdbScheduleTitle" placeholder="Informe uma descrição"></etads-input-text>
            <etads-vmessage *ngIf="form.get('groupTdbScheduleTitle').errors?.requied" text="Campo obrigatório"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('groupTdbScheduleTitle').errors?.minlength" text="Tamanho mínino de 3 caracteres"></etads-vmessage>
            <etads-vmessage *ngIf="form.get('groupTdbScheduleTitle').errors?.maxlength" text="Tamanho máximo de 50 caracteres"></etads-vmessage>

            <etads-label label="Nivel"></etads-label>
            <etads-div-input-group>
                <etads-input-radio [radioList]="levelList" [formGroup]="form" controlName="level" ></etads-input-radio>
            </etads-div-input-group> 
          
            <etads-tooltip label="Insira e-mails separados por ponto-e-vírgula.">
                <etads-label label="Email"></etads-label>
                <etads-input-text [formGroup]="form" controlName="email" placeholder="Informe um valor de tempo"></etads-input-text>
                <etads-vmessage *ngIf="form.get('email').errors?.required" text="Campo obrigatório"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('email').errors?.minlength" text="Tamanho mínino de 1 caractere"></etads-vmessage>
                <etads-vmessage *ngIf="form.get('email').errors?.maxlength" text="Tamanho máximo de 200 caracteres"></etads-vmessage>
            </etads-tooltip>
          
            <etads-label label="Situação"></etads-label>
            <etads-div-input-group>
                <etads-input-checkbox [formGroup]="form" controlName="active" checkLabel="Ativo"></etads-input-checkbox>
            </etads-div-input-group>

            <etads-label label="Distribuidores"></etads-label>
            <etads-div-form-row >
                <div class="col-md-5 align-self-start">
                    <etads-input-select-multiple (changeEmmiter)="actionDistributorChange()" [formGroup]="form" controlName="distributorToSelect">
                        <option *ngFor="let dist of distributorList"  [value]="dist.distributorCode">{{dist.distributorCode}}-{{dist.distributorName}}</option>
                    </etads-input-select-multiple>
                </div>    
                <div class="col-md-2 btn-group-vertical align-self-center">
                    <etads-button-add-arrow (clickEmmiter)="actionAddDist()" label=""></etads-button-add-arrow> 
                    <etads-button-del-arrow (clickEmmiter)="actionDelDist()" label=""></etads-button-del-arrow>
                </div> 

                <div class="col-md-5 align-self-end">
                    <etads-input-select-multiple (changeEmmiter)="actionDistributorSelectChange()" [formGroup]="form" controlName="distributorSelected">
                        <option *ngFor="let distx of distributorSelList" [value]="distx.distributorCode">{{distx.distributorCode}}-{{distx.distributorName}}</option>
                    </etads-input-select-multiple>
                </div>
            </etads-div-form-row>    
            <etads-vmessage *ngIf="form.get('distributorToSelect').errors?.required" text="Selecione com <CTRL> para mais de um item."></etads-vmessage>

            <etads-div-button-sm-screen>
                <etads-button-save [disabled]= "form.invalid || form.pending"></etads-button-save>
                <etads-button-clear (clickEmmiter)="actionClear()"></etads-button-clear>
                <etads-button-back (clickEmmiter)="actionBack()"></etads-button-back>
            </etads-div-button-sm-screen>

        </etads-div-col-small>
    </etads-card-form>
</form>