import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { SymptomGroup } from '../../../interfaces/symptom-group';
import { SymptomGroupCrudService } from '../../../services/symptom-group-crud.service';
import { AreaRelated } from '../../../interfaces/area-related';
import { AreaRelatedCrudService } from '../../../services/area-related-crud.service';
import { Symptom } from '../../../interfaces/symptom';
import { SymptomCrudService } from '../../../services/symptom-crud.service';
import { EtadsT1, T1, T1Form } from '../../../interfaces/t1';
import { T1CrudService } from '../../../services/t1-crud.service';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './t1-edit.component.html'
})

export class T1EditComponent implements OnInit {

    form: FormGroup;
    filterMap: Map<string,string> = new Map<string, string>();

    areaRelated: AreaRelated;
    symptom: Symptom;
    symptomGroup: SymptomGroup;

    t1: T1;
    t1Id: number;
    t1List: EtadsT1[]=[];

    areaRelatedList : AreaRelated[] = [];
    symptomList: Symptom[] = [];
    symptomGroupList : SymptomGroup[] = [] ;
    subscription : Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private areaRelatedCrudService: AreaRelatedCrudService,
        private symptomCrudService: SymptomCrudService,
        private symptomGroupCrudService: SymptomGroupCrudService,
        private t1CrudService: T1CrudService,
        public stateService: StateService,
        private router: Router,
        private route: ActivatedRoute,
        public datepipe: DatePipe,
        private spinnerService: NgxSpinnerService

    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set ("Editar Item T1", "");
        this.loadData();
        this.createForm();
        this.updateForm();
        this.spinnerService.hide();
    }

    loadData() {

        this.t1Id = this.route.snapshot.params.t1Id;
        this.t1 = this.stateService.retrieveT1();
        this.t1List = this.stateService.retrieveT1List();

        /* carregando Area Relacionada */
        this.areaRelatedList = [];
        this.subscription = this.areaRelatedCrudService.get().subscribe( lista => {
            for (let reg of lista) {
                this.areaRelatedList.push(reg);
            }
        },err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        } );

        this.symptomGroupCrudService.getByAreaRelated(this.t1.areaRelatedId).subscribe(lst => {
          this.symptomGroupList = lst;
        }, error => {
          console.log(error);
        });

        this.symptomCrudService.getByGroup(this.t1.areaRelatedId,this.t1.symptomGroupId).subscribe(lst => {
          this.symptomList = lst;
        }, error => {
          console.log(error);
        });

        /* carregando grupo de sintomas */
        /*this.symptomGroupList = [];
        this.subscription = this.symptomGroupCrudService.get().subscribe( listg => {
            for(let sg of listg) {
                this.symptomGroupList.push(sg);
            }
        },err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        } );*/

        /* Carregando simtomas */
        /*this.symptomList = [];
        this.subscription = this.symptomCrudService.get().subscribe( lists => {
            for(let sm of lists) {
                this.symptomList.push(sm);
            }
            this.spinnerService.hide();
        },err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        } );*/


    }

    actionChangeArea() {
      this.symptomGroupList = [];
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || this.form.get("areaRelatedId").value == '')
        return;

      this.symptomGroupCrudService.getByAreaRelated(this.form.get("areaRelatedId").value).subscribe(lst => {
          this.symptomGroupList = lst;
      }, error => {
        console.log(error);
      });
    }

    actionChangeGroup() {
      this.symptomList = [];

      if(!this.form.get("areaRelatedId") || !this.form.get("symptomGroupId")
          || this.form.get("areaRelatedId").value == '' || this.form.get("symptomGroupId").value == '')
        return;

      this.symptomCrudService.getByGroup(this.form.get("areaRelatedId").value, this.form.get("symptomGroupId").value).subscribe(lst => {
          this.symptomList = lst;
      }, error => {
        console.log(error);
      });
    }

    createForm() {

        this.form = this.formBuilder.group( {
            t1Code:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(10) ] , ],
            t1Name:           ['',     [ Validators.required, Validators.minLength(3), Validators.maxLength(50) ] , ],
            areaRelatedId :   ['null', [ Validators.required ] , ],
            symptomGroupId:   ['',     [ Validators.required ] , ],
            symptomId:        ['',     [ Validators.required ] , ],
            active:           [null, [  Validators.required  ] , ],

        });


    }

    updateForm() {
        this.form.patchValue({t1Code : this.t1.t1Code});
        this.form.patchValue({t1Name: this.t1.t1Name});
        this.form.patchValue({areaRelatedId : this.t1.areaRelatedId});
        this.form.patchValue({symptomGroupId: this.t1.symptomGroupId });
        this.form.patchValue({symptomId: this.t1.symptomId       });
        this.form.patchValue({active: this.t1.active });

    }

    actionSubmit() {

        this.alertService.clear();

        if (!this.validateForm()) {
            this.alertService.danger("T1 Inválido ou já existente! Verifique o Código ou Nome!");
            return;
        } else {
            this.alertService.clear();
        }

       this.spinnerService.show();

        const t1form   = this.form.getRawValue() as T1Form;
        let updT1 = new EtadsT1();

        updT1.id = this.t1.id;
        updT1.t1Id = Number(this.t1Id.valueOf());
        updT1.t1Code = t1form.t1Code;
        updT1.t1Name = t1form.t1Name;
        updT1.symptomId = Number(t1form.symptomId.valueOf());
        updT1.symptomGroupId = Number(t1form.symptomGroupId.valueOf());
        updT1.areaRelatedId  = Number(t1form.areaRelatedId.valueOf());
        updT1.active         = t1form.active;

        for (let gr of this.symptomGroupList) {
            if (Number(gr.symptomGroupId) === Number(t1form.symptomGroupId) )   {
                updT1.symptomGroupDescription = gr.symptomGroupDescription;
                break;
            }
        }

        for (let sy of this.symptomList) {
            if (Number(sy.symptomId) === Number(t1form.symptomId) )   {
                updT1.symptomDescription = sy.symptomDescription;
                break;
            }
        }

        updT1.areaRelatedDescription  = this.areaRelatedList.find(ar => ar.areaRelatedId == t1form.areaRelatedId).areaDescription;

        this.subscription = this.t1CrudService.upd(this.t1, updT1).subscribe((data) => {
            this.stateService.storeAlertSuccessMessage(data.message);
            updT1 = null;
            this.spinnerService.hide();
            this.actionBack();

        }, err => {
            this.alertService.danger(err.error.errorMessage);
            this.spinnerService.hide();
        });

    }

    actionBack() {
        this.router.navigate(['t1-list']);
    }

    validateForm( ) {
        const newSg = this.form.getRawValue() as T1Form;

            for (let sgData of this.t1List) {
               let parse1 = sgData.t1Name.toUpperCase();
               let parse2 = newSg.t1Name.toUpperCase();
               let parse3 =  Number(sgData.t1Code);
               let parse4 = Number(newSg.t1Code);

               if (parse1 == parse2  && sgData.t1Id!=this.t1Id) {
                   return false;
               }
                if (parse3 == parse4  && sgData.t1Id!=this.t1Id) {
                 return false;
                }
           }

        return true;
    }

}
