import { Injectable } from '@angular/core';
import { Distributor } from '../interfaces/distributor';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class DistributorCrudService {
  key = 'etads/distributor';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : Distributor) {
    return this.azureCrudService.add(newReg, "addDistributor");
  }

  upd(oldReg : Distributor, newReg : Distributor) {
    return this.azureCrudService.upd(oldReg, newReg, "updateDistributor");
  }

  del(reg : Distributor) {
    return this.azureCrudService.del(reg, "deleteDistributor");
  }

  get() {
    return this.azureCrudService.get("getDistributor");
  }

  getById(id: number) {
    return this.azureCrudService.getById(id, "getDistributor");
  }

  getByCode(code: String) {
    return this.azureCrudService.get("getDistributor?code=" + code);
  }

}
