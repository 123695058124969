import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { StateService } from '../../../services/state.service';
import { ComponentsModule } from '../../../shared/components/components.module';
import { DistributorCrudService } from '../../../services/distributor-crud.service';
import { Distributor, EtadsDistributor } from '../../../interfaces/distributor';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './distributor-list.component.html'
})
export class DistributorListComponent implements OnInit {
    
      
    distributorId : number;
    distributorList: Distributor[] = [];
    distributor: Distributor;
    currentPage : Number = 1;
    itemsPerPage : Number = 13; 
    filterMap: Map<string, string> = new Map<string, string>(); 
    subscription : Subscription;

    constructor (
        private stateService: StateService,
        private router: Router,
        private spinnerService: NgxSpinnerService,
        private alertService: AlertService,
        private distributorCrudService : DistributorCrudService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Manter Distribuidores", "");
        this.loadData();

        const sucessAlertMessage = this.stateService.retrieveAlertSuccessMessage();
        if (sucessAlertMessage != null) {
            this.alertService.success(sucessAlertMessage);
            this.stateService.storeAlertSuccessMessage(null);
        }
        
    }

    loadData() {
        this.subscription = this.distributorCrudService.get().subscribe( reg => {
            for (let data of reg ) {
                let idList = this.distributorList.push(data);
            }
            this.spinnerService.hide();
        }, err => {
            this.alertService.danger(err.message);
            this.spinnerService.hide();
        });

    }
 
    actionAdd() {
        this.stateService.storeDistributorList(this.distributorList);
        this.router.navigate(['distributor-create']);
    }

    actionEdit( distributorData: Distributor) {
        this.stateService.storeDistributor( distributorData );
        this.stateService.storeDistributorList(this.distributorList);
    }

    actionRemove( distributorData: EtadsDistributor) {
        this.stateService.storeDistributor(distributorData);
    }

}