import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'etads-input-textArea',
    templateUrl: './input-text-area.component.html',

})
export class InputTextAreaComponent { 

    @Input() formGroup: FormGroup;
    @Input() classField: string;
    @Input() style: string;
    @Input() controlName: string;
    @Input() readonly: string;
    
}