import { Component, Input } from '@angular/core';

@Component({
    selector: 'etads-vmessage',
    templateUrl: './vmessage.component.html'
})
export class VMessageComponent { 

    @Input() text = '';
    
}