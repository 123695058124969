import { Injectable } from '@angular/core';
import { DailySchedule } from '../interfaces/daily-schedule';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class DailyScheduleCrudService {
  key = 'etads/dailySchedule';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : DailySchedule) {
    return this.azureCrudService.add(newReg, "addDailySchedule");
  }

  upd(oldReg : DailySchedule, newReg : DailySchedule) {
    return this.azureCrudService.upd(oldReg, newReg, "updateDailySchedule");
  }

  del(reg : DailySchedule) {
    return this.azureCrudService.del(reg, "deleteDailySchedule");
  }

  get() {
    return this.azureCrudService.get("getDailySchedule");
  }

}
