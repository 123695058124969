import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { Subscription } from 'rxjs';
import { Ticket } from '../ticket';
import { FeedbackGroup } from 'src/app/shared/components/ticket/div-feedback-group/feedback-group';
import {NgbModal, ModalDismissReasons, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { TicketEncaminharComponent } from 'src/app/administration/ticket/ticket-encaminhar/ticket-encaminhar.component';
import { DivFeedbackGroupComponent } from 'src/app/shared/components/ticket/div-feedback-group/div-feedback-group.component';

import { TicketCrudService } from 'src/app/services/ticket-crud-service';
import { UserService } from 'src/app/core/auth/user.service';

@Component({
    templateUrl: './ticket-view.component.html',
    styleUrls: ['./ticket-view.component.css']
})
export class TicketViewComponent implements OnInit {

    @ViewChild(DivFeedbackGroupComponent)
    feedbackComponent: DivFeedbackGroupComponent;

    form: FormGroup;
    ticketId: number;
    tktStatusNum: number;
    ticket: Ticket;
    subscription: Subscription;
    edit: boolean;
    isTdbUser: boolean;
    diasAberto: number;
    tabSelected: string;
    listFeedbackGroup: FeedbackGroup[];

    constructor(
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService,
        private ticketCrudService: TicketCrudService,
        private userService: UserService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.createForm();
        this.loadData();

        this.tabSelected = "Tratativa";

        this.populateForm();

        if(this.userService.isDealerUser()){
          this.form.get("closeReleased").disable();
          this.form.get("unfounded").disable();
          this.form.get("incorrect").disable();
          this.form.get("pnToyota").disable();
        }

        this.spinnerService.hide();
    }

    createForm() {
        this.form = this.formBuilder.group({
          pnToyota: [{value: null}],
          unfounded: [{value: null}],
          incorrect: [{value: null}],
          closeReleased: [{value: null}],
        });
      }

      populateForm() {

        if (this.ticket.ticketId) {
          this.form.patchValue({
            pnToyota: this.ticket.pnToyota,
            unfounded: this.ticket.unfounded,
            incorrect: this.ticket.incorrect,
            closeReleased: this.ticket.closeReleased
          });
        }

      }

    ngAfterViewInit(){
    }

    loadData() {
        this.ticketId = this.route.snapshot.params.ticketId;
        this.ticket = this.stateService.retrieveTicket();

        let profile = this.userService.getProfile();
        this.edit = this.ticket.status != "Encerrado" && this.ticket.status != "Pendente" && (profile == this.ticket.currentProfile || profile == this.userService.adminProfile || !this.ticket.currentProfile);
        this.isTdbUser = this.userService.isTdbUser();

        this.listFeedbackGroup = this.ticket.listFeedbackGroup;

        switch (this.ticket.status){
            case "Rascunho":
                this.tktStatusNum = 1;
                break;
            case "Pendente":
                this.tktStatusNum = 2;
                break;
            case "Aberto":
                this.tktStatusNum = 3;
                break;
            case "Encerrado":
                this.tktStatusNum = 4;
                break;
            default:
                this.tktStatusNum = 0;
        }

    }

    actionBack() {
        this.router.navigate(['ticket-list']);
    }

    actionSubmit() {
        this.alertService.clear();
        this.spinnerService.show();
        this.ticket.closeReleased = this.form.get("closeReleased").value;
        this.ticket.unfounded = this.form.get("unfounded").value;
        this.ticket.incorrect = this.form.get("incorrect").value;
        this.ticket.pnToyota = this.form.get("pnToyota").value;
    }

    selectTab(tabName) {
        this.tabSelected = tabName;
    }

    actionSend() {
        this.actionSubmit();

        var lastprofile = this.ticket.currentProfile;
        this.ticket.currentProfile = this.userService.getProfile();

        this.ticketCrudService.send(this.ticket, true).subscribe(
          (data) => {
            this.spinnerService.hide();
            this.router.navigate(["ticket-open"]);
          },
          (error) => {
            this.ticket.currentProfile = lastprofile;
            if (error.error.errorName = "DuplicatedInfo") {
              this.alertService.danger(error.error.errorMessage);
              this.spinnerService.hide();
            }
          }
        );
    }

    actionEncaminhar() {
        const modalRef = this.modalService.open(TicketEncaminharComponent);
        modalRef.componentInstance.modal_title = 'Encaminhar Chamado';
        modalRef.componentInstance.modal_content = 'Selecione um Usuário';
        modalRef.componentInstance.selectedTicket = this.ticket;

        modalRef.result.then((result) => {
          const res : string = `${result}`;
          if (res == "mr_save"){
            this.ticketCrudService.allocateTicket(this.ticket, false).subscribe(
              (data) => {
                this.router.navigate(["ticket-pending-tdb"]);
                this.spinnerService.hide();
              },
              (error) => {
                if (error.error.errorName = "DuplicatedInfo") {
                  this.alertService.danger(error.error.errorMessage);
                  this.spinnerService.hide();
                }
              }
            );
          }
        })
    }
}
