export interface GroupTdbSchedule {
    id: string;
    groupTdbScheduleId: number;
    groupTdbScheduleTitle: string;
    level: number;
    email: string;
    active: boolean;
    distributor: string[];
}

export interface GroupTdbScheduleForm {
    groupTdbScheduleTitle: string;
    level: number;
    email: string;
    active: boolean;
    // distributorToSelect: any;
    // distributorSelected: any;
}

export class EtadsGroupTdbSchedule implements GroupTdbSchedule  {
    id: string;
    groupTdbScheduleId: number;
    groupTdbScheduleTitle: string;
    level: number;
    email: string;
    active: boolean;
    distributor: string[];
}