import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PartCauseGroup } from './part-cause-group';
import { PartService } from '../../../../services/part-service';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'etads-div-part-cause-group',
  templateUrl: './div-part-cause-group.component.html',

})
export class DivPartCauseGroupComponent {

  partCauseForm: FormGroup;
  partCauseGroup: PartCauseGroup;
  sentToToyota: boolean;

  listPartCauseGroup: PartCauseGroup[];

  constructor(
    private formBuilder: FormBuilder,
    private stateService: StateService,
    private partService: PartService
  ) { }

  ngOnInit(): void {
    this.createForm();
    this.listPartCauseGroup = [];
    this.sentToToyota = false;

    let listPartCauseGroup: PartCauseGroup[] = this.stateService.retrieveTicket().listPartCauseGroup;
    this.listPartCauseGroup = listPartCauseGroup;

    if(!this.stateService.retrieveEdit())
      this.partCauseForm.disable();
  }

  createForm() {
    this.partCauseForm = this.formBuilder.group({
      partCode: [null, null],
      partDescription: [null, null],
      partStatus: [null, null],
      nfPart: [null, null],
      dateNfPart: [null, null]
    });
  }

  actionSubmitPartCause() {
    console.log("submit part cause");
  }

  actionChangePartCode(){
    this.partService.get(this.partCauseForm.get("partCode").value).subscribe(p => {
      this.partCauseForm.get("partDescription").setValue(p.GetPecaToyotaResult);
    }, error => {
      console.log(error);
    });
  }

  actionChangePartStatus() {
    if (this.partCauseForm.get("partStatus").value === "Enviada a Toyota") {
      this.sentToToyota = true;
    } else {
      this.sentToToyota = false;
    }
  }

  actionAdd() {
    this.partCauseGroup = this.partCauseForm.getRawValue() as PartCauseGroup;
    if(!this.partCauseGroup || !this.partCauseGroup.partCode || !this.stateService.retrieveEdit())
      return;
    if(!this.listPartCauseGroup)
      this.listPartCauseGroup = [this.partCauseGroup];
    else
      this.listPartCauseGroup.push(this.partCauseGroup);
    this.partCauseForm.reset();
  }

  actionRemove(item: PartCauseGroup) {
    if(!this.stateService.retrieveEdit())
      return;
    this.listPartCauseGroup.splice(this.listPartCauseGroup.indexOf(item), 1);
  }
}
