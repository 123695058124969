import { Injectable } from '@angular/core';
import { LocalStorageCrudService } from './local-storage-crud.service';
import { Symptom } from '../../app/interfaces/symptom';
import { AzureStorageCrudService } from './azure-storage-crud.service';

@Injectable({
  providedIn: 'root'
})
export class SymptomCrudService {
  key = 'etads/symptom';
  constructor(private azureCrudService: AzureStorageCrudService) { }

  add(newReg : Symptom) {
    return this.azureCrudService.add(newReg, "addSymptom");
  }

  upd(oldReg : Symptom, newReg : Symptom) {
    return this.azureCrudService.upd(oldReg, newReg, "updateSymptom");
  }

  del(reg : Symptom) {
    return this.azureCrudService.del(reg, "deleteSymptom");
  }

  get() {
    return this.azureCrudService.get("getSymptom");
  }

  getByGroup(areaRelatedId: number, symptomGroupId: number) {
    return this.azureCrudService.get("getSymptomByGroup?areaRelatedId="+areaRelatedId + "&symptomGroupId="+symptomGroupId);
  }
}
