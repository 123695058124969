import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import {Component, OnInit} from '@angular/core';
import { AlertService } from '../../../shared/components/alert/alert.service';
import { StateService } from '../../../services/state.service';
import { DatePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ComponentsModule } from '../../../shared/components/components.module';
import { AcessoriesCrudService } from '../../../services/acessories-crud.service';
import { Acessories, AcessoriesForm, EtadsAcessories } from '../../../interfaces/acessories';
import { Subscription } from 'rxjs';

@Component ({
    templateUrl: './acessories-edit.component.html'
})
export class AcessoriesEditComponent implements OnInit {

    form:FormGroup;
    acessoriesdId: number;
    acessories: Acessories;
    acessoriesList: Acessories[] = [];
    filterMap: Map<string, string> = new Map<string,string>();
    subscription: Subscription;

    constructor (
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private acessoriesCrudService: AcessoriesCrudService,
        public stateService: StateService,
        private router: Router,
        public datepipe: DatePipe,
        private route: ActivatedRoute,
        private spinnerService: NgxSpinnerService
    ) {}

    ngOnInit(): void {
        this.spinnerService.show();
        this.alertService.clear();
        this.filterMap.set("Editar Acessórios", "");
        this.loadData();
        this.createForm();
        this.updateForm();

        this.spinnerService.hide();
    }

    loadData() {
        this.acessoriesdId =  this.route.snapshot.params.acessoriesId;
        this.acessories = this.stateService.retrieveAcessories();
        this.acessoriesList = this.stateService.retrieveAcessoriesList();
    }

    createForm () {
        this.form = this.formBuilder.group({
            acessoriesDescription: ['' , [ Validators.required, Validators.minLength(1), Validators.maxLength(50) ] ],
            active:   [null, [  Validators.required  ] ],
        });
    }

    updateForm() {
        this.form.patchValue({acessoriesDescription:this.acessories.acessoriesDescription});
        this.form.patchValue({active:this.acessories.active});
    }

    actionSubmit() {
        this.alertService.clear();
        if (!this.validateForm()) {
            this.alertService.danger('Acessório Inválido ou Já existente!');
            return;
        } else {
            this.alertService.clear();
        }

        this.spinnerService.show();

        const acessoriesOrigin = this.stateService.retrieveAcessories();
        const acessoriesForm = this.form.getRawValue() as AcessoriesForm;
        let updAcessories = new EtadsAcessories();

        updAcessories.id           = acessoriesOrigin.id;
        updAcessories.acessoriesId = acessoriesOrigin.acessoriesId;
        updAcessories.acessoriesDescription = acessoriesForm.acessoriesDescription.valueOf().toUpperCase();
        updAcessories.active =acessoriesForm.active.valueOf();

        this.subscription = this.acessoriesCrudService.upd (acessoriesOrigin, updAcessories).subscribe ((data) => {
            let idList = this.acessoriesList.push(updAcessories);
            this.stateService.storeAcessoriesList(this.acessoriesList);
            this.stateService.storeAlertSuccessMessage(data.message);
            this.spinnerService.hide();
            this.actionBack();

        }, (error) => {
            this.stateService.storeAcessoriesList(this.acessoriesList);
            this.alertService.danger(error.error.errorMessage);
            this.spinnerService.hide();
        } );

    }

    actionBack() {
        this.router.navigate(['acessories-list']);
    }

    actionClear() {
        this.ngOnInit();
    }

    validateForm() {
        const newAr = this.form.getRawValue() as AcessoriesForm;

        for (let arData of this.acessoriesList) {
             let parse1 = arData.acessoriesDescription.toUpperCase();
             let parse2 = newAr.acessoriesDescription.toUpperCase();

            if (parse1 == parse2 && arData.acessoriesId != this.acessoriesdId) {
                return false;
            }
        }
        return true;

    }

}
